import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { queryKeys } from '@/constants/queryKeys'

import { getContractorOnboardingState } from '../api'
import { ContractorOnboardingStepName } from '../types'

import { ContractorsOnboardingSubmitStep } from './ContractorsOnboardingSubmitStep'
import { PaymentMethodStep } from './PaymentMethodStep'
import { PersonalInformationStep } from './PersonalInformationStep'
import { SignContractStep } from './SignContractStep'
import { TaxFormsStep } from './TaxFormsStep'

export const ContractorOnboardingSteps = () => {
  const { step } = useParams<{ step?: string }>()

  const onboardingStateQuery = useQuery({
    queryKey: [queryKeys.getContractorOnboardingState],
    queryFn: () => getContractorOnboardingState(),
    select: (data) => data.data,
  })

  switch (step) {
    case ContractorOnboardingStepName.PERSONAL_INFORMATION:
      return (
        <PersonalInformationStep
          step={step}
          contractorState={onboardingStateQuery.data}
        />
      )

    case ContractorOnboardingStepName.PAYMENT_METHOD:
      return (
        <PaymentMethodStep
          step={step}
          contractorState={onboardingStateQuery.data}
        />
      )

    case ContractorOnboardingStepName.TAX_FORMS:
      return <TaxFormsStep />

    case ContractorOnboardingStepName.SIGN_CONTRACT:
      return <SignContractStep />

    case ContractorOnboardingStepName.REVIEW_AND_SUBMIT:
      return <ContractorsOnboardingSubmitStep />

    default:
      return null
  }
}
