import { useIntl } from 'react-intl'

import { daysOfWeek } from '@/lib/date'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { ContractPaymentDayType } from '../../types'

type Props = {
  disabled?: boolean
  paymentDayType: ContractPaymentDayType
  value?: string
  onChange: (v: ContractPaymentDayType) => void
}

export const CustomDaySelect = ({
  disabled,
  paymentDayType,
  onChange,
  value,
}: Props) => {
  const intl = useIntl()

  const weekdays = daysOfWeek(intl.locale, 5)

  const getOptionsByPaymentDayType = () => {
    switch (paymentDayType) {
      case ContractPaymentDayType.CUSTOM_DAY_OF_WEEK:
        return weekdays.map((day, index) => ({
          day,
          dayValue: String(index + 1),
        }))

      case ContractPaymentDayType.CUSTOM_DAY_OF_MONTH:
        return Array.from({ length: 31 }, (_, index) => {
          const dayOfMonth = String(index + 1)

          return {
            day: intl.formatMessage(
              {
                defaultMessage: '{day} of each month',
                id: 'label.dayOfEachMonth',
              },
              {
                day: intl.formatMessage(
                  {
                    id: 'day.ordinal',
                    defaultMessage:
                      '{dayOfMonth, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}',
                  },
                  { dayOfMonth },
                ),
              },
            ),
            dayValue: dayOfMonth,
          }
        })

      default:
        return []
    }
  }

  const placeholder =
    paymentDayType === ContractPaymentDayType.CUSTOM_DAY_OF_WEEK
      ? intl.formatMessage({
          id: 'label.customDayOfWeek',
          defaultMessage: 'Custom day of the week',
        })
      : intl.formatMessage({
          id: 'label.customDayOfMonth',
          defaultMessage: 'Custom day of the month',
        })

  return (
    <Select disabled={disabled} onValueChange={onChange} defaultValue={value}>
      <SelectTrigger>
        {value ? (
          <div className="flex flex-col items-start">
            <Typography variant="body-tiny">{placeholder}</Typography>
            <Typography className="text-neutral-gray-900">
              {
                getOptionsByPaymentDayType().find(
                  (option) => option.dayValue === value,
                )?.day
              }
            </Typography>
          </div>
        ) : (
          <SelectValue placeholder={placeholder}>{placeholder}</SelectValue>
        )}
      </SelectTrigger>

      <SelectContent>
        {getOptionsByPaymentDayType().map(({ day, dayValue }) => (
          <SelectItem
            disabled={dayValue === value}
            hideChecked
            className="flex h-[54px]"
            key={day}
            value={dayValue}
          >
            <Typography>{day}</Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
