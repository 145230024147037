import { FormattedDate, FormattedMessage } from 'react-intl'
import { useIntercom } from 'react-use-intercom'

import { PaymentLimit } from '@/domains/Business/types'
import { calculateProgress, formatMoney } from '@/lib/money'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, Progress, Typography } from '@/shared/ui'

export const LimitBanner = ({ limits }: { limits: PaymentLimit }) => {
  const { showNewMessage } = useIntercom()

  return (
    <Card size="large">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <Typography bold>
            <FormattedMessage
              id="dashboard.limit.banner.title"
              defaultMessage="Don't forget to check on your limits!"
            />
          </Typography>
          <Typography variant="body-small" className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.limit.banner.subtitle"
              defaultMessage="Increase your limit to continue transacting worry free"
            />
          </Typography>
        </div>

        <Button
          onClick={() => {
            showNewMessage()
          }}
          rightIcon={<ChevronRight />}
        >
          <FormattedMessage
            id="action.increaseYourLimit"
            defaultMessage="Increase your limit"
          />
        </Button>
      </div>

      <div className="p-2" />

      <div className="flex justify-between">
        <Typography className="font-semibold">
          {formatMoney(limits.periodTransferLimit?.usedAmount ?? 0)}
        </Typography>
        <Typography className="font-semibold">
          {formatMoney(limits.periodTransferLimit?.limitAmount ?? 0)} /{' '}
          <FormattedMessage
            id="card.table.limit.duration"
            defaultMessage="{limitType, select, DAILY {Day} WEEKLY {Week} MONTHLY {Month} YEARLY {Year} other {}}"
            values={{
              limitType: limits.periodTransferLimit?.limitType,
            }}
          />
        </Typography>
      </div>
      <Progress
        value={calculateProgress({
          value: limits.periodTransferLimit?.usedAmount ?? 0,
          total: limits.periodTransferLimit?.limitAmount ?? 0,
        })}
      />

      {limits.periodTransferLimit ? (
        <div className="flex justify-end">
          <Typography
            className="text-neutral-gray-600"
            variant="body-small"
            text="right"
          >
            <FormattedMessage
              id="card.limit.resetDate"
              defaultMessage="Limit resets on {resetDate}"
              values={{
                resetDate: (
                  <FormattedDate
                    value={new Date(limits.periodTransferLimit.periodResetDate)}
                    year="numeric"
                    month="long"
                    day="2-digit"
                    hour="2-digit"
                    minute="2-digit"
                  />
                ),
              }}
            />
          </Typography>
        </div>
      ) : null}
    </Card>
  )
}
