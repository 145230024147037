import { Currency } from '@/constants/currency'
import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

export type SendMoneyRequest = {
  amount: string
  requestId: string
  walletId: string
  currency: Currency
  beneficiaryId: string
  paymentMessage?: string
} & OTPCode

export function sendMoney(data: SendMoneyRequest) {
  return api.post<{
    withdrawalId: string
    transactionId?: string
  }>(`/v2/me/withdrawals`, data)
}
