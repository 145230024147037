import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, Typography } from '@/shared/ui'

import { Card as CardType } from '../../Cards/types'

export const CardsBanner = ({ cards }: { cards: CardType[] }) => {
  return (
    <Card size="large">
      <div className="flex items-center gap-12">
        <div className="flex-shrink-0">
          <img
            src={getImage({
              name: 'cards',
            })}
            alt=""
            aria-hidden
            className="size-32 object-contain"
          />
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <Typography bold>
              <FormattedMessage
                id="dashboard.card.banner.title"
                defaultMessage="Haven't heard? Your cards have new benefits!"
              />
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="dashboard.card.banner.subtitle"
                defaultMessage="Continue saving up to 5% on FX fees and now earn 1% cashback on local purchases 🔥"
              />
            </Typography>
          </div>

          <Button asChild rightIcon={<ChevronRight />} className="self-start">
            <Link to={BusinessRoute.Cards}>
              {cards.length > 0 ? (
                <FormattedMessage
                  id="action.goToMyCards"
                  defaultMessage="Go to my cards"
                />
              ) : (
                <FormattedMessage
                  id="action.createMyFirstCard"
                  defaultMessage="Create my first card"
                />
              )}
            </Link>
          </Button>
        </div>
      </div>
    </Card>
  )
}
