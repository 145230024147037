import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { Role } from '@/domains/Business/types/roles'
import { handleURLSearchParams } from '@/lib/utils'

import { Filter, User } from '../../icons/solid'
import {
  Button,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../../ui'

import { TEAM_ROLE_FILTER_NAME } from './data'

export const TeamRoleFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  function handleSearchParams(value: string) {
    return (
      setSearchParams((params) =>
        handleURLSearchParams(params, TEAM_ROLE_FILTER_NAME, value),
      ),
      { preventScrollReset: true }
    )
  }

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <User className="size-4" />
              <FormattedMessage defaultMessage="Role" id="label.role" />
            </span>
            {searchParams.get(TEAM_ROLE_FILTER_NAME) && (
              <Filter className="size-4" />
            )}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="w-52">
            <DropdownMenuCheckboxItem
              onSelect={(event) => event.preventDefault()}
              checked={searchParams.get(TEAM_ROLE_FILTER_NAME) === null}
              onCheckedChange={() =>
                setSearchParams(
                  (params) => {
                    params.delete(TEAM_ROLE_FILTER_NAME)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }
            >
              <FormattedMessage
                id="label.selectAll"
                defaultMessage="Select all"
              />
            </DropdownMenuCheckboxItem>

            {Object.values(Role).map((role) => (
              <DropdownMenuCheckboxItem
                key={role}
                onSelect={(event) => event.preventDefault()}
                checked={searchParams
                  .get(TEAM_ROLE_FILTER_NAME)
                  ?.includes(role)}
                onCheckedChange={() => handleSearchParams(role)}
              >
                <FormattedMessage
                  id="team.role.label"
                  defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                  values={{ role }}
                />
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
