import { RouteObject } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'

import { Cards } from './Cards'
import { CardTransactions } from './CardTransactions'
import { CreateCard } from './CreateCard'

export const cardsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Cards,
    element: <Cards />,
  },
]

export const createCardRoutes: RouteObject[] = [
  {
    path: BusinessRoute.CreateCard,
    element: <CreateCard />,
  },
]

export const cardTransactionsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.CardsTransactions,
    element: <CardTransactions />,
  },
]
