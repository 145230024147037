import * as React from 'react'
import { useState } from 'react'
import {
  format,
  getMonth,
  getYear,
  isValid,
  parse,
  setMonth,
  setYear,
} from 'date-fns'
import { useIntl } from 'react-intl'
import { useRifm } from 'rifm'

import { getMonthsNames } from '@/lib/date'
import { cn } from '@/lib/utils'

import { Calendar as CalendarIcon } from '../icons/outline'

import {
  AnimatedFormLabel,
  Button,
  Calendar,
  FormControl,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '.'

const YEAR_RANGE = 20

const START_YEAR = 1900
const END_YEAR = new Date().getFullYear() + YEAR_RANGE

export interface DatePickerInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  onClear?: () => void
  onChange: (value: string) => void
  value: string
}

const formatDate = (string: string) => {
  const digits = (string.match(/\d/g) || []).join('')
  const chars = digits.split('')

  return chars
    .reduce(
      (r, v, index) => (index === 1 || index === 3 ? `${r}${v}/` : `${r}${v}`),
      '',
    )
    .substring(0, 10)
}

export const DatePickerInput = React.forwardRef<
  HTMLInputElement,
  DatePickerInputProps
>(({ className, ...props }, ref) => {
  const intl = useIntl()
  const [date, setDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

  const [openCalendar, setOpenCalendar] = useState(false)

  const rifm = useRifm({
    accept: /[\d]/g,
    value: props.value,
    mask: 10 < props.value.length,
    format: formatDate,
    onChange: props.onChange,
  })

  const months = getMonthsNames(intl.locale)

  const years = Array.from(
    { length: END_YEAR - START_YEAR + 1 },
    (_, i) => START_YEAR + i,
  )

  const handleMonthChange = (month: string) => {
    const newDate = setMonth(date, months.indexOf(month))
    setDate(newDate)
  }

  const handleYearChange = (year: string) => {
    const newDate = setYear(date, parseInt(year))
    setDate(newDate)
  }

  return (
    <div className="relative">
      <FormControl>
        <Input
          className={cn('', className)}
          ref={ref}
          {...props}
          value={rifm.value}
          onChange={(e) => {
            rifm.onChange(e)

            const parsedDate = parse(e.target.value, 'dd/MM/yyyy', new Date())

            if (isValid(parsedDate)) {
              setSelectedDate(parsedDate)
              setDate(parsedDate)
            } else {
              setSelectedDate(undefined)
              setDate(new Date())
            }
          }}
        />
      </FormControl>

      <AnimatedFormLabel>{props.placeholder}</AnimatedFormLabel>

      <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
        <PopoverTrigger asChild>
          <Button
            size="inline"
            variant="ghost"
            className={cn(
              'absolute right-[18px] top-[18px] text-left font-normal',
            )}
          >
            <CalendarIcon className="size-4 text-neutral-gray-600" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div className="flex justify-between gap-3 p-2 pb-0">
            <Select
              onValueChange={handleMonthChange}
              value={months[getMonth(date)]}
            >
              <SelectTrigger className="w-full" variant="outline" size="medium">
                <SelectValue
                  placeholder={intl.formatMessage({
                    id: 'label.month',
                    defaultMessage: 'Month',
                  })}
                />
              </SelectTrigger>
              <SelectContent>
                {months.map((month) => (
                  <SelectItem key={month} value={month}>
                    {month}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select
              onValueChange={handleYearChange}
              value={getYear(date).toString()}
            >
              <SelectTrigger
                className="w-fit shrink-0"
                variant="outline"
                size="medium"
              >
                <SelectValue
                  placeholder={intl.formatMessage({
                    id: 'label.year',
                    defaultMessage: 'Year',
                  })}
                />
              </SelectTrigger>
              <SelectContent>
                {years.map((year) => (
                  <SelectItem key={year} value={year.toString()}>
                    {year}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <Calendar
            mode="single"
            selected={selectedDate}
            month={date}
            onMonthChange={setDate}
            onSelect={(value) => {
              if (!value) {
                return
              }

              const parsedDate = format(value, 'dd/MM/yyyy')

              props.onChange(formatDate(parsedDate))

              setSelectedDate(value)

              setOpenCalendar(false)
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  )
})

DatePickerInput.displayName = 'DatePickerInput'
