import { defineMessages, FormattedMessage } from 'react-intl'
import { generatePath, Link } from 'react-router-dom'

import { DefineMessages } from '@/constants/messages'
import { BusinessRoute } from '@/constants/paths'
import { cn } from '@/lib/utils'
import { Check } from '@/shared/icons/outline'
import { Typography } from '@/shared/ui'

import {
  OnboardingStateEnum,
  OnboardingStep,
  OnboardingStepName,
} from '../types'

const onboardingStepsMessages: DefineMessages<OnboardingStepName> =
  defineMessages({
    BUSINESS_ADDRESS: {
      id: 'onboarding.step.businessAddress',
      defaultMessage: 'Business address',
    },
    COMPANY_ACTIVITY: {
      id: 'onboarding.step.companyActivity',
      defaultMessage: 'Company activity',
    },
    COMPANY_FORMATION: {
      id: 'onboarding.step.companyFormation',
      defaultMessage: 'Company formation',
    },
    LEGAL_REPRESENTATIVE: {
      id: 'onboarding.step.legalRepresentative',
      defaultMessage: 'Legal representative',
    },
    COMPANY_OWNERSHIP: {
      id: 'onboarding.step.ownership',
      defaultMessage: 'Ownership',
    },
    IDENTITY_VALIDATION: {
      id: 'onboarding.step.identityValidation',
      defaultMessage: 'Identity validation',
    },
    REVIEW_AND_SUBMIT: {
      id: 'onboarding.step.reviewAndSubmit',
      defaultMessage: 'Review and submit',
    },
  })

export const OnboardingSidebarStep = ({
  active,
  step,
  currentNotStartedStep,
}: {
  active: boolean
  step: OnboardingStep
  currentNotStartedStep: boolean
}) => {
  if (currentNotStartedStep) {
    return (
      <Link
        to={generatePath(BusinessRoute.OnboardingStep, { step: step.name })}
        className="group flex h-10 items-center gap-3"
      >
        <div
          className={cn(
            'h-5 w-5 shrink-0 rounded-full border-2 border-neutral-gray-800 ',
            active && 'border-white',
          )}
        />

        <Typography
          bold={active}
          className={cn(
            'hidden text-neutral-gray-800 group-hover:text-white/80 md:block',
            active && 'text-white',
          )}
        >
          <FormattedMessage {...onboardingStepsMessages[step.name]} />
        </Typography>
      </Link>
    )
  }

  if (step.state === OnboardingStateEnum.VERIFICATION) {
    return (
      <div className="flex h-10 items-center gap-3">
        <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-neutral-gray-200 transition-colors group-hover:bg-neutral-gray-200/80">
          <Check className="h-4 w-4" />
        </span>
        <Typography
          bold={active}
          className="hidden text-white transition-colors group-hover:text-white/80 md:block"
        >
          <FormattedMessage {...onboardingStepsMessages[step.name]} />
        </Typography>
      </div>
    )
  }

  if (step.state === OnboardingStateEnum.PROGRESS_SAVED) {
    return (
      <Link
        to={generatePath(BusinessRoute.OnboardingStep, { step: step.name })}
        className="group flex h-10 items-center gap-3"
      >
        <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-neutral-gray-200 transition-colors group-hover:bg-neutral-gray-200/80">
          <Check className="h-4 w-4" />
        </span>
        <Typography
          bold={active}
          className="hidden text-white transition-colors group-hover:text-white/80 md:block"
        >
          <FormattedMessage {...onboardingStepsMessages[step.name]} />
        </Typography>
      </Link>
    )
  }

  return (
    <div className="flex h-10 items-center gap-3">
      <div
        className={cn(
          'h-5 w-5 shrink-0 rounded-full border-2 border-neutral-gray-800',
          active && 'border-white',
        )}
      />

      <Typography
        bold={active}
        className={cn(
          'hidden text-neutral-gray-800 md:block',
          active && 'text-white',
        )}
      >
        <FormattedMessage {...onboardingStepsMessages[step.name]} />
      </Typography>
    </div>
  )
}
