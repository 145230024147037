import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

import { blockTeamMember } from '../../features/Team/api'
import { TeamMember } from '../../features/Team/types'

type Props = {
  isOpen: boolean
  member?: TeamMember
  onBlock: () => void
  onOpenChange: (isOpen: boolean) => void
}

export const BlockTeamMemberDialog = ({
  isOpen,
  member,
  onBlock,
  onOpenChange,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending, isSuccess, reset } = useMutation({
    mutationFn: blockTeamMember,
  })

  const onBlockTeamMember = async () => {
    if (!member?.id) {
      toast.error('Missing team member ID')

      return
    }

    try {
      await mutateAsync({ id: member.id })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMembers],
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMember, member?.id],
      })

      onOpenChange(false)

      toast.success(
        intl.formatMessage({
          id: 'team.member.details.blocked',
          defaultMessage: 'Team member blocked',
        }),
      )

      onBlock()

      reset()
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <FormattedMessage
                defaultMessage="Block {name}?"
                id="team.member.block.dialog.title"
                values={{
                  name: member?.firstName,
                }}
              />
            </DialogTitle>

            <div className="flex flex-col">
              <DialogDescription>
                <FormattedMessage
                  defaultMessage="Are you sure you want to block {fullName}?"
                  id="team.member.block.dialog.blockUser"
                  values={{
                    fullName: getFullName(member),
                  }}
                />
              </DialogDescription>

              <DialogDescription>
                <FormattedMessage
                  defaultMessage="All of the user's products will be blocked and they will not be able to log into the account"
                  id="team.member.block.dialog.blockUserProducts"
                />
              </DialogDescription>
            </div>
          </DialogHeader>

          <div className="p-4" />

          <DialogActions>
            <Button
              disabled={isPending || isSuccess}
              width="full"
              onClick={() => onOpenChange(false)}
              variant="secondary"
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              disabled={isPending || isSuccess}
              loading={isPending || isSuccess}
              width="full"
              onClick={() => onBlockTeamMember()}
            >
              <FormattedMessage id="action.block" defaultMessage="Block" />
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
