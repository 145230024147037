import { contractorApi } from '@/lib/api'
import { TwoFactorMethod } from '@/types/auth'

export type AuthenticateResponse = {
  identityId: string
  twoFactorAuthentication: { otpMethod: TwoFactorMethod; initialSetup: boolean }
}

export type AuthenticateRequest = {
  email: string
  password: string
  otpMethod?: TwoFactorMethod
}

export function authenticateContractor(data: AuthenticateRequest) {
  return contractorApi.post<AuthenticateResponse>('/v1/authentication', data)
}
