import { contractorApi } from '@/lib/api'

import { Current2FAResponse } from './getContractor2FAMethod'

type Setup2FARequest = {
  method: 'AUTHENTICATOR_APP'
  code: string
}

export function setupContractor2FA(data: Setup2FARequest) {
  return contractorApi.post<Current2FAResponse>(
    `/v1/me/one-time-passwords/2FA/setup`,
    data,
  )
}
