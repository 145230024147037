import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import { Card, CardState } from '../../features/Cards/types'

const badgeVariantByCardState: Record<CardState, StatusVariant> = {
  [CardState.ACTIVE]: 'active',
  [CardState.CREATED]: 'neutral',
  [CardState.BLOCKED]: 'neutral',
  [CardState.TERMINATED]: 'failed',
}

type Props = {
  card: Card
}

export const CardStateCell = ({ card }: Props) => {
  return (
    <div className="flex items-center">
      <StatusBadge
        variant={badgeVariantByCardState[card.state]}
        title={
          <FormattedMessage
            id="card.state.label"
            defaultMessage="{state, select, ACTIVE {Active} CREATED {Inactive} BLOCKED {Frozen} TERMINATED {Terminated} other {}}"
            values={{ state: card.state }}
          />
        }
      />
    </div>
  )
}
