import { Locale } from '@/constants/locales'

export const BASE_API_DEV = `https://api.business.dolarapp.dev`
export const BASE_API_PROD = `https://api.business.dolarapp.com`

export const BASE_CONTRACTOR_API_DEV = `https://contractor-api.business.dolarapp.dev`
export const BASE_CONTRACTOR_API_PROD = `https://contractor-api.business.dolarapp.com`

export const CALENDLY_URL = 'https://calendly.com/juan_dolarapp/onboarding'
export const BRIDGE_LEGAL_URL = 'https://bridge.xyz/legal'
export const DOLAR_APP_TERMS_URL =
  'https://cdn.dolarapp.com/legal/Terminos+y+Condiciones+-+DolarApp+Business.pdf'
export const DOLAR_APP_PRIVACY_URL = `https://cdn.dolarapp.com/legal/privacy-policy-mexico.pdf`

export const BULK_PAYMENT_TEMPLATE_EN = `https://cdn.dolarapp.com/business/dab-bulk-payment.csv`
export const BULK_PAYMENT_TEMPLATE_ES = `https://cdn.dolarapp.com/business/dab-pago-masivo.csv`

export const BULK_PAYMENT_GUIDE_EN = `https://cdn.dolarapp.com/business/dab-bp-template-guide.pdf`
export const BULK_PAYMENT_GUIDE_ES = `https://cdn.dolarapp.com/business/dab-pm-guia-plantilla.pdf`

export const templateUrlByLocale: Record<Locale, string> = {
  [Locale.ES]: BULK_PAYMENT_TEMPLATE_ES,
  [Locale.EN]: BULK_PAYMENT_TEMPLATE_EN,
}

export const guideUrlByLocale: Record<Locale, string> = {
  [Locale.ES]: BULK_PAYMENT_GUIDE_ES,
  [Locale.EN]: BULK_PAYMENT_GUIDE_EN,
}
