import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { CardState } from '@/domains/Business/features/Cards/types'

import { STATUS_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const CardStateBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(STATUS_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(STATUS_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.status',
        defaultMessage: 'Status',
      })}
    >
      {searchParams
        .get(STATUS_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          const value = Object.values(CardState).find((o) => o === option)

          return intl.formatMessage(
            {
              id: 'card.state.label',
              defaultMessage:
                '{state, select, ACTIVE {Active} CREATED {Inactive} BLOCKED {Frozen} TERMINATED {Terminated} other {}}',
            },
            { state: value },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
