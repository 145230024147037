import { Card } from '@/domains/Business/features/Cards/types'

export function formatLastFour(cardNumber?: string | null, repeat = 4) {
  if (!cardNumber) {
    return ''
  }

  return '• '.repeat(repeat) + cardNumber
}

export function getCardTitle(card?: Card) {
  return (card?.nickname + ' ' + formatLastFour(card?.lastFourDigits)).trim()
}

export function formatCardNumber(cardNumber?: string): string {
  if (!cardNumber) {
    return ''
  }

  const cleaned = cardNumber.replace(/\D/g, '')

  const formatted = cleaned.replace(/(.{4})/g, '$1 ')

  return formatted.trim()
}
