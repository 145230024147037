import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { TransactionType } from '@/domains/Business/features/Transactions/types'

import { TYPE_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const TypeBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(TYPE_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(TYPE_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.type',
        defaultMessage: 'Type',
      })}
    >
      {searchParams
        .get(TYPE_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          const value = Object.values(TransactionType).find((o) => o === option)

          return intl.formatMessage(
            {
              id: 'filter.transaction.types.label',
              defaultMessage:
                '{type, select, DEPOSIT {Deposit} WITHDRAW {Withdrawal} INTERNAL_SWAP_DEPOSIT {Internal} CARD_PAYMENT {Card} CARD_PAYMENT_REFUND {Card refund} REWARD {Refund} other {}}',
            },
            { type: value },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
