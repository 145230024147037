import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'
import { motion } from 'framer-motion'
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
} from 'react-hook-form'
import { useIntl } from 'react-intl'

import { errorMessages } from '@/constants/messages'
import { cn } from '@/lib/utils'
import { Input, Label } from '@/shared/ui'

const Form = FormProvider

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName
}

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
)

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  )
}

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext)
  const itemContext = React.useContext(FormItemContext)
  const { getFieldState, formState } = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>')
  }

  const { id } = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

type FormItemContextValue = {
  id: string
}

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue,
)

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { className?: string }
>(({ className, ...props }, ref) => {
  const id = React.useId()

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('relative z-0', className)} {...props} />
    </FormItemContext.Provider>
  )
})
FormItem.displayName = 'FormItem'

const MotionFormItem = motion(FormItem)

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    className?: string
  }
>(({ children, className, ...props }, ref) => {
  const intl = useIntl()
  const { error, formItemId } = useFormField()

  const getChildren = () => {
    if (error?.message && errorMessages[String(error.message)]) {
      try {
        return intl.formatMessage(errorMessages[String(error.message)])
      } catch (e) {
        console.error('unhandled validation message', e)
        return String(error.message)
      }
    }

    return children
  }

  return (
    <Label
      ref={ref}
      className={cn(error && 'text-primary-error', className)}
      htmlFor={formItemId}
      {...props}
    >
      {getChildren()}
    </Label>
  )
})

FormLabel.displayName = 'FormLabel'

const FormInput = React.forwardRef<
  React.ElementRef<typeof Input>,
  React.ComponentPropsWithoutRef<typeof Input> & {
    className?: string
  }
>(({ className, ...props }, ref) => {
  const { error } = useFormField()

  return (
    <Input
      ref={ref}
      className={cn(error && 'border-primary-red', className)}
      aria-invalid={!!error}
      {...props}
    />
  )
})

FormInput.displayName = 'FormInput'

const animatedFormVariants = cva(
  'absolute peer-focus:w-64 md:w-64 top-4 z-10 transform text-[0.625rem] text-neutral-gray-600 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-sm peer-focus:text-[0.625rem] peer-placeholder-shown:w-auto',
  {
    variants: {
      align: {
        start: 'start-4 left-0 text-left origin-[0]',
        end: '-end-1 right-4 text-right peer-focus:right-4 peer-placeholder-shown:right-4',
      },
      error: {
        true: 'text-primary-error peer-focus:text-primary-error',
      },

      size: {
        default:
          'top-5 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:top-4 peer-focus:top-5',
        textarea:
          'top-3 pt-2 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:top-2 peer-focus:top-5 peer-focus:top-3 peer-focus:pt-2 peer-focus:w-[calc(100%-1rem)] md:w-[calc(100%-1rem)] bg-white',
        '2fa':
          'top-4 -translate-y-2.5 peer-focus:-translate-y-3.5 peer-placeholder-shown:top-3 peer-focus:top-5',
      },
    },
    defaultVariants: {
      align: 'start',
      size: 'default',
    },
  },
)

const AnimatedFormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    className?: string
  } & VariantProps<typeof animatedFormVariants>
>(({ align, children, className, size, ...props }, ref) => {
  const intl = useIntl()
  const { error, formItemId } = useFormField()

  const getChildren = () => {
    if (error?.message && errorMessages[String(error.message)]) {
      try {
        return intl.formatMessage(errorMessages[String(error.message)])
      } catch (e) {
        console.error('unhandled validation message', e)
        return String(error.message)
      }
    }

    return children
  }

  return (
    <Label
      ref={ref}
      className={cn(
        animatedFormVariants({
          align,
          className,
          size,
          error: !!error,
        }),
      )}
      htmlFor={formItemId}
      {...props}
    >
      {getChildren()}
    </Label>
  )
})

AnimatedFormLabel.displayName = 'AnimatedFormLabel'

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  )
})
FormControl.displayName = 'FormControl'

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> & { className?: string }
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField()

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn('text-sm text-neutral-gray-600', className)}
      {...props}
    />
  )
})
FormDescription.displayName = 'FormDescription'

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> & { className?: string }
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField()
  const intl = useIntl()

  const getChildren = () => {
    if (error?.message && errorMessages[String(error.message)]) {
      try {
        return intl.formatMessage(errorMessages[String(error.message)])
      } catch (e) {
        console.error('unhandled validation message', e)
        return String(error.message)
      }
    }

    return children
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn(
        'pl-2 pt-2 text-sm font-medium text-primary-error',
        className,
      )}
      {...props}
    >
      {getChildren()}
    </p>
  )
})
FormMessage.displayName = 'FormMessage'

export {
  AnimatedFormLabel,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MotionFormItem,
}
