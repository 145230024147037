import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { getBankByReference } from '@/domains/Business/api'
import { CountryCode } from '@/types/country'

type Props = {
  country: CountryCode
  bankReference: string
  enabled: boolean
}

export function useGetBankName({ country, bankReference, enabled }: Props) {
  const bankNameQuery = useQuery({
    queryKey: [queryKeys.getBankByReference, country, bankReference],
    queryFn: () => getBankByReference({ country, bankReference }),
    select: (data) => data.data,
    enabled,
    retry: 1,
  })

  return {
    name: bankNameQuery.data?.name,
    isPending: bankNameQuery.isFetching,
  }
}
