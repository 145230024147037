import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { Wallet } from '../../types'

import { BalanceCell } from './BalanceCell'
import { DetailsButtonCell } from './DetailsButtonCell'
import { LabelCell } from './LabelCell'
import { MoneyInOutCell } from './MoneyInOutCell'

export const useAccountsColumns = (): ColumnDef<Wallet>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Wallet>[] = useMemo(
    () => [
      {
        accessorKey: 'label',
        cell: ({ row }) => <LabelCell wallet={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
      },
      {
        id: 'details',
        cell: ({ row }) => <DetailsButtonCell wallet={row.original} />,
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<Wallet>[] = useMemo(
    () => [
      {
        accessorKey: 'label',
        cell: ({ row }) => <LabelCell wallet={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
      },
      {
        accessorKey: 'balance',
        cell: ({ row }) => <BalanceCell wallet={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.balance',
              defaultMessage: 'Balance',
            })}
          />
        ),
        size: 200,
      },
      {
        accessorKey: 'monthlyTotalMoneyIn',
        cell: ({ row }) => <MoneyInOutCell type="in" wallet={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.moneyInThirtyDays',
              defaultMessage: 'Money in last 30 days',
            })}
          />
        ),
        size: 180,
      },
      {
        accessorKey: 'monthlyTotalMoneyOut',
        cell: ({ row }) => <MoneyInOutCell type="out" wallet={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.moneyOutThirtyDays',
              defaultMessage: 'Money out last 30 days',
            })}
          />
        ),
        size: 180,
      },
      {
        id: 'details',
        cell: ({ row }) => <DetailsButtonCell wallet={row.original} />,
        size: 120,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
