import { TinyColor } from '@ctrl/tinycolor'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isProduction() {
  return import.meta.env.VITE_ENV === 'production'
}

export function isDevelopment() {
  return import.meta.env.VITE_ENV === 'development'
}

export function generateColorFromString(str?: string) {
  if (!str) {
    return '#000'
  }

  let hash = 0

  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += value.toString(16).padStart(2, '0')
  }

  return new TinyColor(color).desaturate(80).toString()
}

export function parseAdditionalDetails<T>(details?: string): T | null {
  if (!details) {
    return null
  }

  const parsedDetails = JSON.parse(details) as T

  return parsedDetails
}

type NestedObject = {
  [key: string]: string | boolean | null | undefined | NestedObject | unknown[]
}

export function removeEmptyFormFields(data: NestedObject): void {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] === null || data[key] === undefined) {
      delete data[key]
    } else if (typeof data[key] === 'object') {
      removeEmptyFormFields(data[key] as NestedObject)

      if (Object.keys(data[key] as NestedObject).length === 0) {
        delete data[key]
      }
    }
  })
}

export function downloadFile(file: BlobPart, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([file]))

  const link = document.createElement('a')
  link.href = url
  link.download = fileName

  document.body.appendChild(link)

  link.click()

  link.parentNode?.removeChild(link)
}

export function handleURLSearchParams(
  params: URLSearchParams,
  key: string,
  value: string,
) {
  const query = params.get(key)

  if (!query) {
    params.set(key, value)
    return params
  }

  if (query === value) {
    params.delete(key)
    return params
  }

  if (!query.split(',').includes(value)) {
    const newType = `${query},${value}`
    params.set(key, newType)
    return params
  }

  const newType = query
    .split(',')
    .filter((type) => type !== value)
    .join(',')

  params.set(key, newType)

  return params
}

export function partition<T>(
  array: T[],
  predicate: (value: T) => boolean,
): [T[], T[]] {
  return [
    array.filter((value) => predicate(value)),
    array.filter((value) => !predicate(value)),
  ]
}
