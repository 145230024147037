import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { SCOPE_OF_WORK_DESCRIPTION_PER_ROLE } from '@/constants/messages'
import { GoBackButton } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  DatePickerInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Textarea,
  Typography,
} from '@/shared/ui'

import { ScopeOfWorkField } from '../ScopeOfWorkField'

const PAY_AS_YOU_GO_CONTRACT_FORM = 'pay-as-you-go-contract-form-id'

const payAsYouGoContractSchema = z.object({
  contractorRole: z.string().min(1, {
    message: 'validation.role.required',
  }),
  scopeOfWorkRole: z.string().optional(),
  scopeOfWork: z.string().min(1, {
    message: 'validation.scopeOfWork.required',
  }),
  startDate: z.string().min(1, {
    message: 'validation.startDate.required',
  }),
})

export type PayAsYouGoContractSchema = z.infer<typeof payAsYouGoContractSchema>

type Props = {
  onBack: () => void
  onContinue: (data: PayAsYouGoContractSchema) => void
  payAsYouGoContract?: PayAsYouGoContractSchema
}

export const PayAsYouGoContractStep = ({
  onBack,
  onContinue,
  payAsYouGoContract,
}: Props) => {
  const intl = useIntl()

  const form = useForm<PayAsYouGoContractSchema>({
    mode: 'onChange',
    resolver: zodResolver(payAsYouGoContractSchema),
    values: {
      contractorRole: payAsYouGoContract?.contractorRole ?? '',
      scopeOfWork: payAsYouGoContract?.scopeOfWork ?? '',
      scopeOfWorkRole: payAsYouGoContract?.scopeOfWorkRole ?? '',
      startDate: payAsYouGoContract?.startDate ?? '',
    },
  })

  const onSubmit: SubmitHandler<PayAsYouGoContractSchema> = (data) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.create.contact.title"
            defaultMessage="Contract details"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.create.contact.payAsYouGo.subtitle"
            defaultMessage="Specify what the contractor's job will be"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={PAY_AS_YOU_GO_CONTRACT_FORM}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3">
              <FormField
                control={form.control}
                name="contractorRole"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Role',
                          id: 'label.role',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage defaultMessage="Role" id="label.role" />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="scopeOfWorkRole"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ScopeOfWorkField
                        value={field.value}
                        onSelect={(value) => {
                          field.onChange(value)

                          if (SCOPE_OF_WORK_DESCRIPTION_PER_ROLE[value]) {
                            const scopeOfWork = (
                              SCOPE_OF_WORK_DESCRIPTION_PER_ROLE[value]
                                .defaultMessage ?? ''
                            ).toString()

                            form.setValue('scopeOfWork', scopeOfWork)
                          }
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="scopeOfWork"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        className="relative"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Scope of work',
                          id: 'label.scopeOfWork',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel size="textarea">
                      <FormattedMessage
                        defaultMessage="Scope of work"
                        id="label.scopeOfWork"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem>
                    <DatePickerInput
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Start date',
                        id: 'label.startDate',
                      })}
                      {...field}
                    />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={PAY_AS_YOU_GO_CONTRACT_FORM}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
