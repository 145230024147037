import { api } from '@/lib/api'

import { Transaction, TransactionSearchParams } from '../types'
import { handleTransactionQueryParams } from '../utils'

export function getTransactions(params: TransactionSearchParams = {}) {
  const searchQuery = handleTransactionQueryParams(params)

  return api.get<Transaction[]>(`/v2/me/transactions/search${searchQuery}`)
}
