import { useMemo } from 'react'

import { getFullName } from '@/lib/typography'
import { cn } from '@/lib/utils'
import { Skeleton, Typography } from '@/shared/ui'

import { Card } from '../../features/Cards/types'
import { useBusinessRole, useBusinessUser } from '../../hooks'
import { Role } from '../../types'

type Props = {
  card: Card
}

export const CardholderCell = ({ card }: Props) => {
  const { role, isPending } = useBusinessRole()
  const businessUser = useBusinessUser()

  const cardUserName = card.printedUserName
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase())

  const fullName = useMemo(() => {
    switch (role) {
      case Role.ADMIN:
      case Role.READ_ONLY:
        return cardUserName

      case Role.PAYMENT_OPS:
      case Role.CARD_USER:
        return getFullName(businessUser)

      default:
        return ''
    }
  }, [cardUserName, businessUser, role])

  return (
    <div className="flex min-w-28 items-center">
      {isPending ? (
        <Skeleton className="h-[21px] w-36" />
      ) : (
        <Typography className={cn('line-clamp-1')}>{fullName}</Typography>
      )}
    </div>
  )
}
