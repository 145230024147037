import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Card, MotionDiv, Typography } from '@/shared/ui'

import { TeamMember, TeamState } from '../../features/Team/types'

import { UnblockTeamMemberDialog } from './UnblockTeamMemberDialog'

type Props = {
  member?: TeamMember
}

export const TeamMemberUnblockSection = ({ member }: Props) => {
  const [showUnblockDialog, setShowUnblockDialog] = useState(false)

  if (member?.state !== TeamState.BLOCKED) {
    return null
  }

  return (
    <>
      <MotionDiv>
        <Card size="upload" className="flex items-center justify-between">
          <div className="flex flex-col">
            <Typography>
              <FormattedMessage
                id="team.details.blocked.title"
                defaultMessage="{name} is currently blocked"
                values={{ name: member?.firstName }}
              />
            </Typography>
            <Typography
              className="pr-4 text-neutral-gray-600"
              variant="body-small"
            >
              <FormattedMessage
                id="team.details.blocked.description"
                defaultMessage="Clicking Unblock will resume all the user's products and their ability to log into the account"
              />
            </Typography>
          </div>

          <Button onClick={() => setShowUnblockDialog(true)} size="xs">
            <FormattedMessage id="action.unblock" defaultMessage="Unblock" />
          </Button>
        </Card>
      </MotionDiv>

      <UnblockTeamMemberDialog
        member={member}
        isOpen={showUnblockDialog}
        onOpenChange={(isOpen) => setShowUnblockDialog(isOpen)}
      />
    </>
  )
}
