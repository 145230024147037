import { Currency } from '@/constants/currency'
import { api } from '@/lib/api'

import {
  Contractor,
  ContractorAgreementType,
  ContractorFirstCycleMethod,
  ContractorPaymentType,
  ContractPaymentDayType,
} from '../types'

type CreateContractorRequest = {
  contractorDetails: {
    firstName: string
    lastName: string
    secondLastName?: string
    email: string
    internationalPhonePrefix: string
    localPhoneNumber: string
  }
  contractDetails: {
    contractorRole: string
    scopeOfWork: string
    startDate: string
    paymentType: ContractorPaymentType
    agreementType: ContractorAgreementType
    paymentDetails?: {
      currency: Currency
      amount: string
      frequency: string
      dayType: ContractPaymentDayType
      day?: string
      firstCycleMethod: ContractorFirstCycleMethod
      firstCycleAmount?: string
    }
  }
  uploadedContractDocumentIds?: string[]
}

export function createContractor(data: CreateContractorRequest) {
  return api.post<Contractor>(`/v1/me/contractors`, data)
}
