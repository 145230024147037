import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import { Contractor, ContractorState } from '../types'

function getBadgeVariant(
  state: ContractorState,
  variant: 'sidebar' | 'default',
) {
  return {
    [ContractorState.ACTION_NEEDED]: 'warning',
    [ContractorState.WAITING_FOR_CONTRACTOR]:
      variant === 'sidebar' ? 'neutral-dark' : 'neutral',
    [ContractorState.ACTIVE]: 'active',
  }[state] as StatusVariant
}

type Props = {
  state?: Contractor['state']
  variant?: 'sidebar' | 'default'
}

export const ContractorStateBadge = ({ state, variant = 'default' }: Props) => {
  if (!state) {
    return null
  }

  const badgeVariant = getBadgeVariant(state, variant)

  return (
    <StatusBadge
      variant={badgeVariant}
      title={
        <FormattedMessage
          id="contractor.state.label"
          defaultMessage="{state, select, ACTION_NEEDED {Action needed} WAITING_FOR_CONTRACTOR {Waiting for contractor} ACTIVE {Active} other {}}"
          values={{ state }}
        />
      }
    />
  )
}
