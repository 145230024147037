import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { Widget } from '@/shared/components'

import { getAllContractDocuments } from '../../api'
import { Contract } from '../../types'

import { UploadDocumentToExistingContract } from './UploadDocumentToExistingContract'

type Props = {
  contract?: Contract
}

export const ContractDocuments = ({ contract }: Props) => {
  const contractDocumentsQuery = useQuery({
    queryKey: [queryKeys.getAllContractDocuments, contract?.id],
    queryFn: () => getAllContractDocuments({ id: contract?.id ?? '' }),
    select: (data) => data.data,
    enabled: !!contract?.id,
  })

  return (
    <Widget
      variant="form"
      title={
        <FormattedMessage id="label.documents" defaultMessage="Documents" />
      }
    >
      <UploadDocumentToExistingContract
        contractId={contract?.id}
        isLoadingFiles={contractDocumentsQuery.isPending}
        files={contractDocumentsQuery.data ?? []}
      />
    </Widget>
  )
}
