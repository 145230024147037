import { Currency } from '@/constants/currency'
import { CountryCode } from '@/types/country'

export enum ContractorUserState {
  ACCEPTED_INVITE = 'ACCEPTED_INVITE',
  ONBOARDING = 'ONBOARDING',
  ACTIVE = 'ACTIVE',
}

export enum ContractorAgreementType {
  STANDARD = 'STANDARD',
  CUSTOM = 'CUSTOM',
}

export enum ContractorPaymentType {
  FIXED_RATE = 'FIXED_RATE',
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
}

export enum ContractorState {
  WAITING_FOR_CONTRACTOR = 'WAITING_FOR_CONTRACTOR',
  ACTION_NEEDED = 'ACTION_NEEDED',
  ACTIVE = 'ACTIVE',
}

export enum ContractState {
  ACTION_NEEDED = 'ACTION_NEEDED',
  WAITING_FOR_CONTRACTOR = 'WAITING_FOR_CONTRACTOR',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  TERMINATED = 'TERMINATED',
  TERMINATION_PENDING = 'TERMINATION_PENDING',
}

export enum ContractorPaymentMethodState {
  LINKED = 'LINKED',
  NOT_LINKED = 'NOT_LINKED',
}

export enum ContractPaymentDayType {
  LAST_BUSINESS_DAY_OF_PERIOD = 'LAST_BUSINESS_DAY_OF_PERIOD',
  LAST_FRIDAY_OF_PERIOD = 'LAST_FRIDAY_OF_PERIOD',
  CUSTOM_DAY_OF_MONTH = 'CUSTOM_DAY_OF_MONTH',
  CUSTOM_DAY_OF_WEEK = 'CUSTOM_DAY_OF_WEEK',
}

export enum ContractorFirstCycleMethod {
  FULL_AMOUNT = 'FULL_AMOUNT',
  CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
  NO_PAYMENT = 'NO_PAYMENT',
  PRO_RATA = 'PRO_RATA',
}

export enum ContractorPaymentFrequency {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  MONTHLY = 'MONTHLY',
}

export enum ContractorPaymentMethodType {
  DOLAR_TAG = 'DOLAR_TAG',
  US_BANK_ACCOUNT = 'US_BANK_ACCOUNT',
}

export type Address = {
  addressStreetNumber: string
  addressStreet: string
  addressDistrict?: string
  addressCity: string
  addressState: string
  addressPostCode: string
  addressCountry: CountryCode
}

export type DolarTagDetails = {
  type: ContractorPaymentMethodType.DOLAR_TAG
  paymentMethodInformation: { dolarTag: string }
}

export type USBankAccountDetails = {
  type: ContractorPaymentMethodType.US_BANK_ACCOUNT
  paymentMethodInformation: {
    accountNumber: string
    routingNumber: string
    address: Address
  }
}

export type ContractorPaymentMethodDetails =
  | DolarTagDetails
  | USBankAccountDetails

export type ContractorPaymentInformation = {
  currency: Currency
  amount: number
  frequency: ContractorPaymentFrequency
  dayType: ContractPaymentDayType
  day?: number
  firstCycleMethod: ContractorFirstCycleMethod
  firstCycleAmount?: number
}

export type Contract = {
  id: string
  state: ContractState
  paymentType: ContractorPaymentType
  agreementType: ContractorAgreementType
  contractorRole: string
  scopeOfWork: string
  startDate: string
  endDate: string | null
  createdByBusinessUserId: string
  paymentDetails?: ContractorPaymentInformation
}

export type ContractorPersonalDetails = {
  dateOfBirth: string
  countryOfCitizenship: CountryCode
  residentialAddress: Required<Address>
  countryOfTaxResidence: CountryCode | null
  taxId: string | null
  taxIdType: string | null
}

export type TaxDetails = {
  countryOfTaxResidence?: string
  taxId?: string
  taxIdType?: string
  taxForm?: string
}

export type PaymentMethod = {
  state: ContractorPaymentMethodState
  paymentMethodDetails?: ContractorPaymentMethodDetails
}

export type PersonalDetails = {
  firstName: string
  lastName: string
  secondLastName?: string
  email: string
  internationalPhonePrefix: string
  localPhoneNumber: string
  dateOfBirth?: string
  countryOfCitizenship?: string
  residentialAddress?: Address
}

export type ContractorDetails = {
  identityId: string
  id: string
  state: ContractorState
  taxDetails: TaxDetails
  paymentMethod: PaymentMethod
  personalDetails: PersonalDetails
  contracts: Contract[]
}

export type ContractDocument = {
  id: string
  fileName: string
  uploadedOn: string
}

export type ContractorUser = {
  businessId: string
  email: string
  firstName: string
  id: string
  lastName: string
  secondLastName: string | null
  state: ContractorUserState
}

export type ContractorCompany = {
  id: string
  legalName: string
}
