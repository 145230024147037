import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { WithPermissions } from '@/domains/Business/components'
import { Permission } from '@/domains/Business/types'
import { Button } from '@/shared/ui'

import { Recipient } from '../../types'

type Props = {
  recipient: Recipient
}

export const SendCell = ({ recipient }: Props) => {
  return (
    <WithPermissions permissions={[Permission.TRANSFERS]}>
      <div className="flex items-center justify-end">
        <Button
          onClick={(e) => e.stopPropagation()}
          asChild
          size="xs"
          variant="secondary"
        >
          <Link to={`${BusinessRoute.Send}?recipient=${recipient.id}`}>
            <FormattedMessage id="action.send" defaultMessage="Send" />
          </Link>
        </Button>
      </div>
    </WithPermissions>
  )
}
