import { FormattedMessage } from 'react-intl'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

type Props = {
  description: React.ReactNode
  descriptionAdditional?: React.ReactNode
  isOpen: boolean
  isPending: boolean
  onDelete: () => void
  onOpenChange: (isOpen: boolean) => void
  title: React.ReactNode
}

export const DeleteDialog = ({
  description,
  descriptionAdditional,
  isOpen,
  isPending,
  onDelete,
  onOpenChange,
  title,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>

          <div className="flex flex-col">
            <DialogDescription>{description}</DialogDescription>

            {descriptionAdditional ? (
              <DialogDescription>{descriptionAdditional}</DialogDescription>
            ) : null}
          </div>
        </DialogHeader>

        <div className="p-4" />

        <DialogActions>
          <Button
            disabled={isPending}
            width="full"
            onClick={() => onOpenChange(false)}
            variant="secondary"
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            disabled={isPending}
            loading={isPending}
            width="full"
            onClick={onDelete}
          >
            <FormattedMessage id="action.delete" defaultMessage="Delete" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
