import { getFullName } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { Contractor } from '../../../types'

type Props = {
  contractor: Contractor
}

export const ContractorCell = ({ contractor }: Props) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="flex gap-1">
        <Typography bold>{getFullName(contractor)}</Typography>
      </div>
      <Typography className="text-neutral-gray-600">
        {contractor.contractorRole}
      </Typography>
    </div>
  )
}
