import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { useErrorToast } from '@/hooks/useErrorToast'
import { cn } from '@/lib/utils'
import { DragAndDropContainer, UploadedFile } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Label, Typography } from '@/shared/ui'

import { uploadCustomContract } from '../../api'
import { ContractDocument } from '../../types'

function typeValidator(file: File) {
  if (
    ['image/jpeg', 'image/png', 'application/pdf', 'image/jpg'].includes(
      file.type,
    )
  ) {
    return null
  }

  return {
    code: 'invalid-type',
    message: 'Invalid file type',
  }
}

type Props = {
  existingFiles: ContractDocument[]
  name: string
  onDrop: (files: ContractDocument[]) => void
}

export const UploadCustomContactField = ({
  existingFiles,
  name,
  onDrop,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: uploadCustomContract,
  })

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({
      multiple: true,
      validator: typeValidator,
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'application/pdf': [],
        'image/jpg': [],
      },
      noClick: existingFiles.length > 0,
      onDrop: async (acceptedFiles) => {
        if (!acceptedFiles.length) {
          return
        }

        const files = await Promise.allSettled(
          acceptedFiles.map(async (file) => {
            const { data } = await mutateAsync({ file })

            return data
          }),
        )

        const uploadedFiles = files
          .map((file) => {
            if (file.status === 'fulfilled') {
              return file.value
            } else {
              notifyError(file.reason)
              return null
            }
          })
          .filter(Boolean)

        const newFiles = [
          ...existingFiles,
          ...uploadedFiles,
        ] as ContractDocument[]

        onDrop(newFiles)
      },
    })

  const deleteFile = (id: string) => {
    const updatedFiles = existingFiles.filter((file) => file.id !== id)

    onDrop(updatedFiles)
  }

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.forEach(({ file }) => {
        toast.error(
          intl.formatMessage(
            {
              defaultMessage: 'This file format is not supported',
              id: 'validation.file.wrongFormat',
            },
            { name: file.name },
          ),
        )
      })
    }
  }, [fileRejections, intl])

  return (
    <Card size="upload">
      <div className="flex flex-col gap-2">
        <Typography bold>
          <FormattedMessage
            id="contractor.documents.agreement.title"
            defaultMessage="Use my own agreement"
          />
        </Typography>

        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="contractor.documents.agreement.subtitle"
            defaultMessage="Upload my own agreement, previously signed by client and contractor"
          />
        </Typography>
      </div>

      <div className="p-2" />
      <div className={cn('flex w-full gap-6')} {...getRootProps()}>
        <input
          {...getInputProps({
            id: name,
            name: name,
          })}
        />

        <Label className="sr-only" htmlFor={name}>
          <FormattedMessage
            id="uploadFileField.dragOrClick"
            defaultMessage="Drag and drop or click to upload"
          />
        </Label>

        {existingFiles.length ? (
          <div className="flex w-full flex-col justify-center gap-2">
            {existingFiles.map((file) => (
              <UploadedFile
                key={file.id}
                file={file.fileName}
                onClick={() => deleteFile(file.id)}
                isPending={isPending}
              />
            ))}

            <div className="p-1" />

            <Button
              leftIcon={<Plus className="size-5" />}
              loading={isPending}
              disabled={isPending}
              size="md"
              onClick={open}
              type="button"
              variant="secondary"
            >
              <FormattedMessage
                id="uploadFile.field.addAnother"
                defaultMessage="Add another file"
              />
            </Button>
          </div>
        ) : (
          <DragAndDropContainer
            files={existingFiles.map((file) => file.fileName)}
            isDragActive={isDragActive}
            isPending={isPending}
          />
        )}
      </div>
    </Card>
  )
}
