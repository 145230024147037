import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'

import { ContractorForgetPasswordEmail } from './components/ContractorForgetPasswordEmail'
import { ContractorForgetPasswordEmailSent } from './components/ContractorForgetPasswordEmailSent'
import { ContractorResetPassword } from './components/ContractorResetPassword'

enum Step {
  Email = 'email',
  Verify = 'verify',
  Password = 'password',
}

type StepState =
  | { type: Step.Email }
  | { type: Step.Verify }
  | { type: Step.Password; identityId: string; otp: string }

export const ContractorForgetPassword = () => {
  const [identityId, otp] = useSearchParamsValue(['id', 'otp'])

  const [activeStep, setActiveStep] = useState<StepState>(() =>
    identityId && otp
      ? { type: Step.Password, identityId, otp }
      : { type: Step.Email },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  return (
    <AnimatePresence mode="wait">
      {activeStep.type === Step.Email ? (
        <ContractorForgetPasswordEmail
          onContinue={() => setActiveStep({ type: Step.Verify })}
        />
      ) : null}

      {activeStep.type === Step.Verify ? (
        <ContractorForgetPasswordEmailSent />
      ) : null}

      {activeStep.type === Step.Password ? (
        <ContractorResetPassword
          identityId={activeStep.identityId}
          otp={activeStep.otp}
        />
      ) : null}
    </AnimatePresence>
  )
}
