export function getFirstLetter(name?: string) {
  return name?.charAt(0).toUpperCase() ?? ''
}

export function getFirstLetters(name?: string) {
  return (
    name
      ?.split(' ')
      .map((n) => n.charAt(0).toUpperCase())
      .join('') ?? ''
  )
}

export function getFullName({
  firstName,
  lastName,
  secondLastName,
}: {
  firstName?: string
  lastName?: string
  secondLastName?: string | null
} = {}) {
  const initials = [firstName, lastName, secondLastName].filter(Boolean)

  return initials.join(' ')
}

export function maskValue(value: string, repeat = 4) {
  const lastFour = value.slice(-4)

  return '• '.repeat(repeat) + lastFour
}

export function intersperse(arr: (string | React.ReactNode)[], sep = ', ') {
  const filteredArr = arr.filter(
    (item) => item !== null && item !== undefined && item !== '',
  )

  if (filteredArr.length === 0) {
    return []
  }

  return filteredArr.slice(1).reduce(
    function (xs, x) {
      if (Array.isArray(xs)) {
        return xs.concat([sep, x])
      }

      return [xs, sep, x]
    },
    [filteredArr[0]],
  )
}

export function slugify(str?: string) {
  if (!str) {
    return ''
  }

  return str
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}

export function nonEmptyString(str?: string | null) {
  if (str && str !== '') {
    return str
  }

  return null
}
