import { PaymentLimitType } from '@/domains/Business/types'
import { api } from '@/lib/api'

import { Card } from '../types'

type CreatePhysicalCardRequest = {
  requestId: string
  walletId: string
  identityId: string
  nickname: string
  localPhoneNumber: string
  internationalPhonePrefix: string
  cardLimit: { limitAmount: string; limitType: PaymentLimitType }
  deliveryAddress: {
    street: string
    streetNumber: string
    apartment?: string
    references?: string
    neighborhood: string
    city: string
    state: string
    country: string
    postcode: string
  }
}

export function createPhysicalCard(data: CreatePhysicalCardRequest) {
  return api.post<Card>(`/v1/me/physical-cards`, data)
}
