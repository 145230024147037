import {
  DisplayableType,
  Transaction,
} from '@/domains/Business/features/Transactions/types'
import { formatCurrency } from '@/lib/money'

import { Typography } from '../../ui'

type Props = {
  transaction: Transaction
}

export const CurrencyCell = ({ transaction }: Props) => {
  switch (transaction.displayableType) {
    case DisplayableType.SINGLE:
      return (
        <div className="flex items-center">
          <Typography>{formatCurrency(transaction.localCurrency)}</Typography>
        </div>
      )

    case DisplayableType.BULK:
      return (
        <div className="flex items-center">
          <Typography>
            {transaction.localCurrencies
              .map((localCurrency) => formatCurrency(localCurrency))
              .join('/')}
          </Typography>
        </div>
      )

    default:
      return null
  }
}
