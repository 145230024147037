import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
} from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { useAuth } from '@/providers/AuthProvider'

export const AuthChecker = () => {
  const { accessToken } = useAuth()
  const location = useLocation()

  if (!accessToken) {
    return <Navigate to={BusinessRoute.SignIn} state={{ from: location }} />
  }

  return (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  )
}
