import { http, HttpResponse } from 'msw'

import { BASE_CONTRACTOR_API_DEV } from '@/constants/urls'
import { TwoFactorMethod } from '@/types/auth'

export const contractorSignInHandlers = [
  http.post(`${BASE_CONTRACTOR_API_DEV}/v1/authentication`, async () => {
    return HttpResponse.json({
      identityId: 'baa98df2-0831-4ac9-954f-346de5b66f41',
      twoFactorAuthentication: {
        otpMethod: TwoFactorMethod.EMAIL,
        initialSetup: false,
      },
    })
  }),

  http.post(`${BASE_CONTRACTOR_API_DEV}/v1/invitation-verifications`, () => {
    return HttpResponse.json({
      refreshToken: '1234567890',
    })
  }),

  http.post(`${BASE_CONTRACTOR_API_DEV}/v1/password-recovery`, () => {
    return HttpResponse.json()
  }),

  http.post(`${BASE_CONTRACTOR_API_DEV}/v1/signin`, () => {
    return HttpResponse.json({
      refreshToken: '1234567890',
    })
  }),

  http.post(`${BASE_CONTRACTOR_API_DEV}/v1/password-change`, () => {
    return HttpResponse.json()
  }),
]
