import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type TwoFactorResetStorage = {
  reset2FA: boolean
  enable2FAReset: () => void
  disable2FAReset: () => void
}

export const use2FAResetStorage = create<
  TwoFactorResetStorage,
  [['zustand/persist', TwoFactorResetStorage]]
>(
  persist(
    (set) => ({
      reset2FA: false,
      enable2FAReset: () => set({ reset2FA: true }),
      disable2FAReset: () => set({ reset2FA: false }),
    }),
    {
      name: '2fa-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)
