import { http, HttpResponse } from 'msw'

import { BASE_CONTRACTOR_API_DEV } from '@/constants/urls'

export const contractorOverviewHandlers = [
  http.get(
    `${BASE_CONTRACTOR_API_DEV}/v1/my/contract-agreements/contract/:id`,
    async () => {
      return HttpResponse.json([
        {
          id: '11512fdc-5cb5-456a-9b92-69ce8036bb07',
          fileName: 'test.jpg',
          uploadedOn: '2024-12-12T16:05:51',
        },
      ])
    },
  ),
]
