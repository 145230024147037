import { contractorApi } from '@/lib/api'

type ValidateDolarTagRequest = {
  dolarTag: string
  internationalPhonePrefix: string
  localPhoneNumber: string
}

export function validateDolarTag(data: ValidateDolarTagRequest) {
  return contractorApi.post<void>(
    '/v1/contractor-onboarding/validate-dolartag',
    data,
  )
}
