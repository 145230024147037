import { CountryName } from '@/shared/components'

import { Contractor } from '../../../types'

type Props = {
  contractor: Contractor
}

export const CitizenshipCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <CountryName countryCode={contractor.countryOfCitizenship} />
    </div>
  )
}
