import { useMutation, useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { GoBackButton, Loader } from '@/shared/components'
import { Button, SlideInScreen, StickyContainer, Typography } from '@/shared/ui'

import {
  completeContractorOnboarding,
  getContractorOnboardingState,
} from '../api'

import { PaymentInformationReview } from './PaymentInformationReview'
import { PersonalInformationReview } from './PersonalInformationReview'

export const ContractorsOnboardingSubmitStep = () => {
  const notifyError = useErrorToast()
  const navigate = useNavigate()
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: completeContractorOnboarding,
  })

  const onboardingStateQuery = useQuery({
    queryKey: [queryKeys.getContractorOnboardingState],
    queryFn: getContractorOnboardingState,
    select: (data) => data.data,
  })

  console.log(onboardingStateQuery.data)

  const previousStep = onboardingStateQuery.data?.steps.at(-1)

  const onSubmit = async () => {
    try {
      await mutateAsync()

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getContractorOnboardingState],
      })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Onboarding completed successfully',
          id: 'contractors.onboarding.completed.success',
        }),
      )

      navigate(ContractorRoute.Overview)
    } catch (error) {
      notifyError(error)
    }
  }

  if (onboardingStateQuery.isPending) {
    return <Loader />
  }

  return (
    <>
      <GoBackButton
        className="hidden md:left-80 md:flex"
        to={generatePath(ContractorRoute.OnboardingStep, {
          step: previousStep?.name,
        })}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Review and submit"
            id="onboarding.reviewAndSubmit.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Review your application, edit any of the previous steps and hit submit when you are ready!"
            id="onboarding.reviewAndSubmit.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-8">
          <PersonalInformationReview
            contractorState={onboardingStateQuery.data}
          />

          <PaymentInformationReview
            contractorState={onboardingStateQuery.data}
          />
        </div>

        <StickyContainer>
          <Button
            width="full"
            disabled={isPending}
            loading={isPending}
            onClick={onSubmit}
          >
            <FormattedMessage defaultMessage="Submit" id="action.submit" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
