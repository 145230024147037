import { defineMessage, FormattedMessage } from 'react-intl'

import { GoBackButton } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, SlideInScreen, Typography } from '@/shared/ui'

import { ContractorAgreementType } from '../../../types'

const CONTRACT_TYPES = [
  {
    title: defineMessage({
      id: 'contractor.create.documentType.standard',
      defaultMessage: 'Use DolarApp standard agreement',
    }),
    description: defineMessage({
      id: 'contractor.create.documentType.standard.description',
      defaultMessage: "DolarApp's locally compliant contract",
    }),
    agreementType: ContractorAgreementType.STANDARD,
  },
  {
    title: defineMessage({
      id: 'contractor.create.documentType.custom',
      defaultMessage: 'Upload my own signed agreement',
    }),
    description: defineMessage({
      id: 'contractor.create.documentType.custom.description',
      defaultMessage:
        'In case you already have a signed contract with the contractor',
    }),
    agreementType: ContractorAgreementType.CUSTOM,
  },
]

type Props = {
  onBack: () => void
  onContinue: (value: ContractorAgreementType) => void
}

export const ContractDocumentTypeStep = ({ onBack, onContinue }: Props) => {
  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.create.documentType.title"
            defaultMessage="Contract documentation"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.create.documentType.description"
            defaultMessage="Go for our standard agreement or upload your own"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-4">
          {CONTRACT_TYPES.map((contract) => {
            return (
              <Card
                key={contract.title.id}
                size="upload"
                className="flex w-full items-center justify-between"
                asChild
                scalable
              >
                <Button
                  size="inline"
                  variant="ghost"
                  onClick={() => {
                    onContinue(contract.agreementType)
                  }}
                >
                  <div className="flex items-center gap-4">
                    <div className="flex flex-col">
                      <div className="flex gap-2">
                        <Typography bold>
                          <FormattedMessage {...contract.title} />
                        </Typography>
                      </div>
                      <Typography className="whitespace-break-spaces text-neutral-gray-600">
                        <FormattedMessage {...contract.description} />
                      </Typography>
                    </div>
                  </div>

                  <ChevronRight className="size-8 shrink-0 text-neutral-gray-600" />
                </Button>
              </Card>
            )
          })}
        </div>
      </SlideInScreen>
    </>
  )
}
