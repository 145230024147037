import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { CardFields } from '@/domains/Business/components'
import { PaymentLimitType } from '@/domains/Business/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { createVirtualCard } from '../../api'

const CARD_INFO_FORM_ID = 'card-info-form-id'

const cardInfoSchema = z.object({
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  requestId: z.string(),
  identityId: z.string(),
  walletId: z.string().min(1),
  limitAmount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
    message: 'validation.balance.minAmount',
  }),
  limitType: z.nativeEnum(PaymentLimitType),
})

type CardInfoSchema = z.infer<typeof cardInfoSchema>

const DEFAULT_VALUES: CardInfoSchema = {
  nickname: '',
  walletId: '',
  requestId: '',
  identityId: '',
  limitAmount: '',
  limitType: '' as PaymentLimitType,
}

type Props = {
  onBack: () => void
}

export const CreateVirtualCardStep = ({ onBack }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const form = useForm<CardInfoSchema>({
    mode: 'onChange',
    resolver: zodResolver(cardInfoSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createVirtualCard,
  })

  const navigate = useNavigate()

  const onSubmit: SubmitHandler<CardInfoSchema> = async (data) => {
    try {
      const { limitAmount, limitType, ...rest } = data

      if (!rest.identityId) {
        toast.error(
          intl.formatMessage({
            id: 'cards.create.noIdentityId',
            defaultMessage: 'No identity ID found',
          }),
        )
        return
      }

      const response = await mutateAsync({
        ...rest,
        requestId: crypto.randomUUID(),
        cardLimit: { limitAmount, limitType },
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getAllCards],
      })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Virtual card created!',
          id: 'cards.create.virtualCardCreated',
        }),
      )

      navigate(`${BusinessRoute.Cards}?id=${response.data.id}`)
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="cards.create.infoStep.virtual.title"
            defaultMessage="Create a virtual card"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="cards.create.infoStep.virtual.description"
            defaultMessage="Customize the card to fit your needs. You can have as many as you want"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CARD_INFO_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <CardFields form={form} />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={CARD_INFO_FORM_ID}
            disabled={!form.formState.isValid || isPending}
            onClick={form.handleSubmit(onSubmit)}
            loading={isPending}
            type="submit"
          >
            <FormattedMessage
              id="action.createCard"
              defaultMessage="Create card"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
