import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

type DeleteRecipientRequest = {
  id: string
} & OTPCode

export function deleteRecipient({ id, otp }: DeleteRecipientRequest) {
  return api.delete<void>(`/v1/me/beneficiaries/${id}`, { data: { otp } })
}
