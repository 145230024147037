import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { parseDateFromBE, parseToFormat } from '@/lib/date'
import { queryClient } from '@/lib/queryClient'
import {
  CountryCombobox,
  OptionalTag,
  StateSelectOrInput,
  Widget,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  DatePickerInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  MotionDiv,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { submitPersonalInformation } from '../api'
import { ContractorOnboardingState } from '../types'

const PERSONAL_INFORMATION_FORM_ID = 'personal-information-form-id'

const personalInformationSchema = z.object({
  dateOfBirth: z
    .string()
    .min(1, { message: 'validation.dateOfBirth.required' }),
  countryOfCitizenship: z.string(),
  residentialAddress: z.object({
    addressStreet: z
      .string()
      .min(1, { message: 'validation.addressStreet.required' }),
    addressStreetNumber: z
      .string()
      .min(1, { message: 'validation.addressStreetNumber.required' }),
    addressDistrict: z.string().optional(),
    addressCity: z
      .string()
      .min(1, { message: 'validation.addressCity.required' }),
    addressState: z
      .string()
      .min(1, { message: 'validation.addressState.required' }),
    addressPostCode: z
      .string()
      .min(1, { message: 'validation.addressZipCode.required' }),
    addressCountry: z
      .string()
      .min(1, { message: 'validation.addressCountry.required' }),
  }),
  countryOfTaxResidence: z.string(),
  taxId: z
    .string()
    .min(1, { message: 'validation.taxIdentificationNumber.required' }),
})

type PersonalInformationSchema = z.infer<typeof personalInformationSchema>

type Props = {
  step?: string
  contractorState?: ContractorOnboardingState
}

export const PersonalInformationStep = ({ step, contractorState }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const navigate = useNavigate()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: submitPersonalInformation,
  })

  const form = useForm<PersonalInformationSchema>({
    mode: 'onChange',
    resolver: zodResolver(personalInformationSchema),
    values: {
      dateOfBirth: contractorState?.personalDetails?.dateOfBirth
        ? parseDateFromBE(contractorState?.personalDetails?.dateOfBirth)
        : '',
      countryOfCitizenship:
        contractorState?.personalDetails?.countryOfCitizenship ??
        ('' as CountryCode),
      residentialAddress: {
        addressStreet:
          contractorState?.personalDetails?.residentialAddress?.addressStreet ??
          '',
        addressStreetNumber:
          contractorState?.personalDetails?.residentialAddress
            ?.addressStreetNumber ?? '',
        addressDistrict:
          contractorState?.personalDetails?.residentialAddress
            ?.addressDistrict ?? '',
        addressCity:
          contractorState?.personalDetails?.residentialAddress?.addressCity ??
          '',
        addressState:
          contractorState?.personalDetails?.residentialAddress?.addressState ??
          '',
        addressPostCode:
          contractorState?.personalDetails?.residentialAddress
            ?.addressPostCode ?? '',
        addressCountry:
          contractorState?.personalDetails?.residentialAddress
            ?.addressCountry ?? '',
      },
      countryOfTaxResidence:
        contractorState?.personalDetails?.countryOfTaxResidence ??
        ('' as CountryCode),
      taxId: contractorState?.personalDetails?.taxId ?? '',
    },
  })

  const onSubmit: SubmitHandler<PersonalInformationSchema> = async (data) => {
    try {
      if (!contractorState) {
        return
      }

      await mutateAsync({
        ...data,
        countryOfCitizenship: data.countryOfCitizenship as CountryCode,
        countryOfTaxResidence: data.countryOfTaxResidence as CountryCode,
        dateOfBirth: parseToFormat(data.dateOfBirth),
      })

      const currentStepIndex = contractorState?.steps.findIndex(
        (s) => s.name === step,
      )

      const nextStep = contractorState?.steps[currentStepIndex + 1]

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getContractorOnboardingState],
      })

      navigate(
        generatePath(ContractorRoute.OnboardingStep, { step: nextStep.name }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          id="contractor.onboarding.personalInformation.title"
          defaultMessage="Complete personal information"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          id="contractor.onboarding.personalInformation.subtitle"
          defaultMessage="Enter your personal details to continue"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form
          id={PERSONAL_INFORMATION_FORM_ID}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-6">
            <Widget
              variant="form"
              title={
                <FormattedMessage
                  id="label.personalDetails"
                  defaultMessage="Personal details"
                />
              }
            >
              <div className="flex flex-col gap-3">
                <FormField
                  control={form.control}
                  name="dateOfBirth"
                  render={({ field }) => (
                    <FormItem>
                      <DatePickerInput
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Date of birth',
                          id: 'label.dateOfBirth',
                        })}
                        {...field}
                      />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="countryOfCitizenship"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <CountryCombobox
                          value={field.value}
                          onSelect={(value) => {
                            field.onChange(value.valueAsCode)
                          }}
                          placeholder={intl.formatMessage({
                            id: 'label.countryOfCitizenship',
                            defaultMessage: 'Country of citizenship',
                          })}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </Widget>

            <Widget
              variant="form"
              title={
                <FormattedMessage
                  id="label.residentialAddress"
                  defaultMessage="Residential address"
                />
              }
            >
              <div className="flex flex-col gap-3">
                <FormField
                  control={form.control}
                  name="residentialAddress.addressCountry"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <CountryCombobox
                          placeholder={intl.formatMessage({
                            id: 'label.country',
                            defaultMessage: 'Country',
                          })}
                          onSelect={(value) => {
                            field.onChange(value.valueAsCode)
                          }}
                          value={field.value}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                {form.watch('residentialAddress.addressCountry') !== '' ? (
                  <MotionDiv className="flex flex-col gap-3">
                    <div className="grid grid-cols-2 gap-3">
                      <FormField
                        control={form.control}
                        name="residentialAddress.addressStreet"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                autoComplete="address-line1"
                                placeholder={intl.formatMessage({
                                  id: 'label.street',
                                  defaultMessage: 'Street',
                                })}
                                {...field}
                              />
                            </FormControl>
                            <AnimatedFormLabel>
                              <FormattedMessage
                                defaultMessage="Street"
                                id="label.street"
                              />
                            </AnimatedFormLabel>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="residentialAddress.addressStreetNumber"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                autoComplete="address-line2"
                                placeholder={intl.formatMessage({
                                  id: 'label.streetNumber',
                                  defaultMessage: 'Street number',
                                })}
                                {...field}
                              />
                            </FormControl>
                            <AnimatedFormLabel>
                              <FormattedMessage
                                defaultMessage="Street number"
                                id="label.streetNumber"
                              />
                            </AnimatedFormLabel>
                          </FormItem>
                        )}
                      />
                    </div>

                    <FormField
                      control={form.control}
                      name="residentialAddress.addressDistrict"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              placeholder={intl.formatMessage({
                                id: 'label.neighborhoodOrDistrict',
                                defaultMessage: 'Neighborhood or district',
                              })}
                              {...field}
                            />
                          </FormControl>
                          <AnimatedFormLabel>
                            <FormattedMessage
                              id="label.neighborhoodOrDistrict"
                              defaultMessage="Neighborhood or district"
                            />
                          </AnimatedFormLabel>

                          {field.value === '' && <OptionalTag />}
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="residentialAddress.addressCity"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              key="addressCity"
                              autoComplete="address-level2"
                              placeholder={intl.formatMessage({
                                id: 'label.city',
                                defaultMessage: 'City',
                              })}
                              {...field}
                            />
                          </FormControl>
                          <AnimatedFormLabel>
                            <FormattedMessage
                              defaultMessage="City"
                              id="label.city"
                            />
                          </AnimatedFormLabel>
                        </FormItem>
                      )}
                    />

                    <div className="grid grid-cols-2 gap-3">
                      <FormField
                        control={form.control}
                        name="residentialAddress.addressState"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <StateSelectOrInput
                                value={field.value}
                                onChange={field.onChange}
                                variant={
                                  form.watch(
                                    'residentialAddress.addressCountry',
                                  ) === CountryCode.US
                                    ? 'select'
                                    : 'input'
                                }
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="residentialAddress.addressPostCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                autoComplete="postal-code"
                                placeholder={intl.formatMessage({
                                  id: 'label.postCode',
                                  defaultMessage: 'Post code',
                                })}
                                {...field}
                              />
                            </FormControl>
                            <AnimatedFormLabel>
                              <FormattedMessage
                                defaultMessage="Post code"
                                id="label.postCode"
                              />
                            </AnimatedFormLabel>
                          </FormItem>
                        )}
                      />
                    </div>
                  </MotionDiv>
                ) : null}
              </div>
            </Widget>

            <Widget
              title={intl.formatMessage({
                id: 'contractor.onboarding.personalInformation.fiscalDetails',
                defaultMessage: 'Fiscal details',
              })}
              variant="form"
            >
              <div className="flex flex-col gap-3">
                <FormField
                  control={form.control}
                  name="countryOfTaxResidence"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <CountryCombobox
                          subset="contractor-onboarding"
                          value={field.value}
                          onSelect={(value) => {
                            field.onChange(value.valueAsCode)
                          }}
                          placeholder={intl.formatMessage({
                            id: 'label.countryOfTaxResidence',
                            defaultMessage: 'Country of tax residence',
                          })}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="taxId"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Tax identification number',
                            id: 'label.taxIdentificationNumber',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Tax identification number"
                          id="label.taxIdentificationNumber"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
              </div>
            </Widget>
          </div>
        </form>
      </Form>

      <StickyContainer>
        <Button
          form={PERSONAL_INFORMATION_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          loading={isPending}
          disabled={!form.formState.isValid || isPending}
          type="submit"
          width="full"
        >
          <FormattedMessage id="action.continue" defaultMessage="Continue" />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
