import { contractorApi } from '@/lib/api'

export type UpdatePasswordRequest = {
  identityId: string
  otp: string
  newPassword: string
}

export function updateContractorPassword(data: UpdatePasswordRequest) {
  return contractorApi.post<void>('/v1/password-change', data)
}
