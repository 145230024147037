import { Currency } from '@/constants/currency'

import { Account, AccountDetails, AccountType } from '../../types'

export const ACCOUNT_MOCK_RESPONSE: Account = {
  id: '64eb0105-8484-4f38-ba20-91c27159ee2c',
  totalBalance: 1723.22,
  totalBalanceCurrency: Currency.USDC,
  wallets: [
    {
      id: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
      accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      currency: Currency.USDC,
      monthlyTotalMoneyIn: 0,
      monthlyTotalMoneyOut: 0,
      balance: 1023.22,
      label: 'Main account',
    },
    {
      accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      balance: 100,
      monthlyTotalMoneyIn: 100,
      monthlyTotalMoneyOut: 250,
      currency: Currency.USDC,
      id: 'cdc08881-1dc3-426e-b548-ca2255dfcb7f',
      label: 'Account for parties',
    },
    {
      accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      balance: 600,
      monthlyTotalMoneyIn: 300,
      monthlyTotalMoneyOut: 1000,
      currency: Currency.USDC,
      id: 'cdc08881-1dc3-426e-b548-ca2255dfcb7d',
      label: 'Test Account',
    },
  ],
}

export const ACCOUNT_SINGLE_MOCK_RESPONSE: Account = {
  id: '64eb0105-8484-4f38-ba20-91c27159ee2c',
  totalBalance: 1111,
  totalBalanceCurrency: Currency.USDC,
  wallets: [
    {
      id: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
      accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      monthlyTotalMoneyIn: 300,
      monthlyTotalMoneyOut: 1000,
      currency: Currency.USDC,
      balance: 1023.22,
      label: 'Main account',
    },
  ],
}

export const ACCOUNT_MOCK_RESPONSE_WITH_NEW_ENTRY: Account = {
  id: '64eb0105-8484-4f38-ba20-91c27159ee2c',
  totalBalance: 1111,
  totalBalanceCurrency: Currency.USDC,
  wallets: [
    {
      id: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
      accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      monthlyTotalMoneyIn: 300,
      monthlyTotalMoneyOut: 1000,
      currency: Currency.USDC,
      balance: 1023.22,
      label: 'Main account',
    },
    {
      accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      balance: 100,
      monthlyTotalMoneyIn: 300,
      monthlyTotalMoneyOut: 1000,
      currency: Currency.USDC,
      id: 'cdc08881-1dc3-426e-b548-ca2255dfcb7f',
      label: 'Account for parties',
    },
    {
      accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      balance: 0,
      monthlyTotalMoneyIn: 300,
      monthlyTotalMoneyOut: 1000,
      currency: Currency.USDC,
      id: 'cdc08881-1dc3-426e-b548-ca2255dfcb7f',
      label: 'Collections',
    },
  ],
}

export const MOCKED_ACCOUNT_DETAILS: AccountDetails = {
  bankAccounts: [
    {
      type: AccountType.LOCAL_MX,
      currency: Currency.MXN,
      state: 'ACTIVE',
      holderDetails: {
        bankName: 'Arcus',
        entityName: 'AC/DC Inc.',
      },
      accountDetails: {
        accountIdentifier: {
          type: 'CLABE',
          value: '706180208763050449',
        },
        accountEntityType: null,
        bankIdentifier: null,
      },
    },
    {
      type: AccountType.WIRE,
      currency: Currency.USD,
      state: 'ACTIVE',
      holderDetails: {
        bankName: 'Lead Bank',
        entityName: 'AC/DC Inc.',
        bankAddress: {
          street: '1801 Main St.',
          city: 'Kansas City',
          postcode: '64108',
          state: 'Missouri',
          countryCode: 'USA',
        },
      },
      accountDetails: {
        accountIdentifier: {
          type: 'ACCOUNT_NUMBER',
          value: '11223344556677',
        },
        accountEntityType: 'CHECKING',
        bankIdentifier: {
          type: 'ROUTING_NUMBER',
          value: '123456789',
        },
        additionalInformation: {
          DEPOSIT_MESSAGE: 'BRGUUDCK44P6CVHX768Q',
        },
      },
    },
    {
      type: AccountType.ACH,
      currency: Currency.USD,
      state: 'ACTIVE',
      holderDetails: {
        bankName: 'Lead Bank',
        entityName: 'AC/DC Inc.',
        bankAddress: {
          street: '1801 Main St.',
          city: 'Kansas City',
          postcode: '64108',
          state: 'Missouri',
          countryCode: 'USA',
        },
      },
      accountDetails: {
        accountIdentifier: {
          type: 'ACCOUNT_NUMBER',
          value: '11223344556677',
        },
        accountEntityType: 'CHECKING',
        bankIdentifier: {
          type: 'ROUTING_NUMBER',
          value: '123456789',
        },
        additionalInformation: null,
      },
    },
  ],
}
