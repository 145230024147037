import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { useErrorToast } from '@/hooks/useErrorToast'
import { formatDate } from '@/lib/date'
import { intersperse } from '@/lib/typography'
import { downloadFile } from '@/lib/utils'
import { CloudDownload, Trash } from '@/shared/icons/outline'
import { Document, Ellipsis } from '@/shared/icons/solid'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  MotionDiv,
  Typography,
} from '@/shared/ui'

import { downloadContractDocument } from '../../api'
import { ContractDocument } from '../../types'

type Props = {
  contractId?: string
  file: ContractDocument
  onDelete: (fileName: string) => void
}

export const UploadedDocument = ({ contractId, file, onDelete }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  // const { mutateAsync, isPending } = useMutation({
  //   mutationFn: deleteOnboardingDocument,
  // })

  const { mutateAsync: downloadMutateAsync, isPending: downloadIsPending } =
    useMutation({ mutationFn: downloadContractDocument })

  const deleteDocument = async () => {
    try {
      // await mutateAsync({
      //   step,
      //   document: name,
      //   file: fileName,
      // })

      onDelete(file.fileName)
    } catch (error) {
      console.error(error)

      toast.error(
        intl.formatMessage({
          defaultMessage: 'Failed to delete file',
          id: 'bulkPayment.uploadFile.errorDeletingFile',
        }),
      )
    }
  }

  const downloadDocument = async () => {
    if (!contractId) {
      return
    }

    try {
      const response = await downloadMutateAsync({
        fileId: file.id,
        contractId,
      })

      downloadFile(response.data, file.fileName)
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <MotionDiv className="flex w-full items-center justify-between">
      <div className="flex gap-2">
        <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
          <Document className="size-5 text-neutral-gray-900" />
        </div>

        <div className="flex flex-col">
          <Typography bold>{file.fileName}</Typography>
          <Typography variant="body-small" className="text-neutral-gray-600">
            {intersperse(
              [
                intl.formatMessage({
                  id: 'contractor.document.uploaded',
                  defaultMessage: 'Document uploaded ',
                }),
                formatDate(file.uploadedOn, 'dd MMM. yyyy, HH:mm'),
              ],
              ' • ',
            )}
          </Typography>
        </div>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label={intl.formatMessage({
              id: 'action.documentActions',
              defaultMessage: 'Delete file',
            })}
            leftIcon={<Ellipsis />}
            loaderPosition="left"
            loading={downloadIsPending}
            disabled={downloadIsPending}
            size="icon"
            variant="ghost"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem>
            <Button
              leftIcon={<Trash className="size-5 text-primary-error" />}
              onClick={deleteDocument}
              className="justify-start px-4 font-normal text-primary-error"
              width="full"
              variant="ghost"
            >
              <FormattedMessage
                id="label.deleteDocument"
                defaultMessage="Delete document"
              />
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={downloadDocument}>
            <Button
              leftIcon={<CloudDownload className="size-5" />}
              className="justify-start px-4 font-normal"
              width="full"
              variant="ghost"
            >
              <FormattedMessage
                id="label.downloadDocument"
                defaultMessage="Download document"
              />
            </Button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </MotionDiv>
  )
}
