import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

import { unblockTeamMember } from '../../features/Team/api'
import { TeamMember } from '../../features/Team/types'

type Props = {
  isOpen: boolean
  member?: TeamMember
  onOpenChange: (isOpen: boolean) => void
}

export const UnblockTeamMemberDialog = ({
  isOpen,
  member,
  onOpenChange,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: unblockTeamMember,
  })

  const onUnblockTeamMember = async () => {
    if (!member?.id) {
      toast.error(
        intl.formatMessage({
          id: 'team.member.details.error.missingId',
          defaultMessage: 'Missing team member ID',
        }),
      )
      return
    }

    try {
      await mutateAsync({ id: member.id })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMembers],
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMember, member?.id],
      })

      onOpenChange(false)

      toast.success(
        intl.formatMessage({
          id: 'team.member.details.unblocked',
          defaultMessage: 'Team member unblocked',
        }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <FormattedMessage
                defaultMessage="Unblock {name}?"
                id="team.member.unblock.dialog.title"
                values={{
                  name: member?.firstName,
                }}
              />
            </DialogTitle>

            <div className="flex flex-col items-center text-neutral-gray-600">
              <DialogDescription>
                <FormattedMessage
                  defaultMessage="Are you sure you want to unblock {fullName}?"
                  id="team.member.unblock.dialog.unblockUser"
                  values={{
                    fullName: getFullName(member),
                  }}
                />
              </DialogDescription>

              <DialogDescription>
                <FormattedMessage
                  defaultMessage="All of the user's products will be re-activated and they will have access to the account again"
                  id="team.member.unblock.dialog.unblockUserProducts"
                />
              </DialogDescription>
            </div>
          </DialogHeader>

          <div className="p-4" />

          <DialogActions>
            <Button
              width="full"
              onClick={() => onOpenChange(false)}
              disabled={isPending || isSuccess}
              variant="secondary"
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              disabled={isPending || isSuccess}
              loading={isPending || isSuccess}
              width="full"
              onClick={() => onUnblockTeamMember()}
            >
              <FormattedMessage id="action.unblock" defaultMessage="Unblock" />
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
