import { useEffect, useState } from 'react'
import { use2FAResetStorage } from '@/store'
import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { TwoFactorRequiredDialog } from '@/shared/components'

import { getContractor2FAMethod } from '../api'

type Props = {
  children: React.ReactNode
}

export const Contractor2FAChecker = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const reset2FA = use2FAResetStorage((state) => state.reset2FA)

  const { data: initialSetup } = useQuery({
    queryKey: [queryKeys.getContractor2FAMethod],
    queryFn: getContractor2FAMethod,
    select: (data) => data.data.initialSetup,
  })

  useEffect(() => {
    if (initialSetup || reset2FA) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [initialSetup, reset2FA])

  return (
    <>
      {children}

      <TwoFactorRequiredDialog flow="contractor" isOpen={isOpen} />
    </>
  )
}
