import { api } from '@/lib/api'

import { Current2FAResponse } from './getCurrent2FAMethod'

type Setup2FARequest = {
  method: 'AUTHENTICATOR_APP'
  code: string
}

export function setup2FAAuthenticator(data: Setup2FARequest) {
  return api.post<Current2FAResponse>(
    `/v1/me/one-time-passwords/2FA/setup`,
    data,
  )
}
