import { ContractorNavigationLink, ContractorRoute } from '@/constants/paths'
import { Home } from '@/shared/icons/solid'

export function useContractorLinks(): ContractorNavigationLink[] {
  return [
    {
      key: 'home',
      icon: Home,
      path: ContractorRoute.Overview,
    },
  ]
}
