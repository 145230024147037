import { motion } from 'framer-motion'

export const SlideInSpan = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <motion.span
      initial={{ opacity: 0, x: -30 }}
      animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
      exit={{ opacity: 0, x: -30 }}
      className={className}
    >
      {children}
    </motion.span>
  )
}
