import { ContractDocument } from '@/domains/Contractor/types'
import { cn } from '@/lib/utils'
import { Card } from '@/shared/ui'

import { UploadedContractDocument } from './UploadedContractDocument'

type Props = {
  contractId?: string
  files: ContractDocument[]
}

export const ContractorDocuments = ({ contractId, files }: Props) => {
  return (
    <Card size="upload">
      <div className={cn('flex w-full gap-6')}>
        <div className="flex w-full flex-col justify-center gap-2">
          {files.map((file) => (
            <UploadedContractDocument
              key={file.id}
              contractId={contractId}
              file={file}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}
