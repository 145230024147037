import { FormattedMessage } from 'react-intl'
import { QRCode } from 'react-qrcode-logo'

import { getImage } from '@/lib/images'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Card,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

const DOLAR_APP_DOWNLOAD_LINK = 'https://dolarapp.onelink.me/TKY0/vz9eea8k'

type Props = {
  onBack: () => void
  onContinue: () => void
}

export const DownloadAppQRScreen = ({ onBack, onContinue }: Props) => {
  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.onboarding.qrCode.title"
            defaultMessage="Download DolarApp and link your account"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.qrCode.subtitle"
            defaultMessage="Sign up at DolarApp to get your payment and enjoy the benefits of a global account"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col items-center gap-2">
          <Card className="w-fit rounded-2xl p-3">
            <QRCode
              eyeRadius={[
                [8, 8, 0, 8],
                [8, 8, 8, 0],
                [8, 0, 8, 8],
              ]}
              logoImage={getImage({ name: 'dolarapp-logo-black' })}
              logoWidth={75}
              logoPaddingStyle="square"
              removeQrCodeBehindLogo
              qrStyle="dots"
              quietZone={0}
              size={300}
              value={DOLAR_APP_DOWNLOAD_LINK}
            />
          </Card>

          <Typography
            className="text-neutral-gray-600"
            variant="body-small"
            text="center"
          >
            <FormattedMessage
              id="contractor.onboarding.qrCode.link"
              defaultMessage="Scan the QR code to download DolarApp on your device"
            />
          </Typography>
        </div>

        <StickyContainer>
          <Button onClick={onContinue} width="full">
            <FormattedMessage
              id="action.alreadyHaveMyDolarAppAccount"
              defaultMessage="I already have my DolarApp account!"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
