import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { ContractorRoute } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useReset } from '@/hooks/useReset'

import { contractorLogout } from '../api/contractorLogout'

export function useContractorLogout() {
  const reset = useReset()

  const navigate = useNavigate()
  const notifyError = useErrorToast()

  const { mutateAsync } = useMutation({
    mutationFn: contractorLogout,
  })

  const logout = useCallback(async () => {
    navigate(ContractorRoute.LoggingOut, { replace: true })

    try {
      await mutateAsync()
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }

    reset()
  }, [mutateAsync, navigate, notifyError, reset])

  return { logout }
}
