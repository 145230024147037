import { StatusVariant } from '@/shared/components'
import { Building, User } from '@/shared/icons/solid'
import { Icon } from '@/types/global'

import { EntityType, Recipient } from '../../Recipients/types'
import {
  BulkTransactionStatus,
  TransactionStatus,
  TransactionType,
} from '../types'

export const badgeVariantByTxStatus: Record<TransactionStatus, StatusVariant> =
  {
    [TransactionStatus.FAILED]: 'failed',
    [TransactionStatus.REVERTED]: 'failed',
    [TransactionStatus.PENDING]: 'neutral',
    [TransactionStatus.BLOCKED]: 'neutral',
    [TransactionStatus.COMPLETED]: 'active',
  }

export const badgeVariantByBulkTxStatus: Record<
  BulkTransactionStatus,
  StatusVariant
> = {
  [BulkTransactionStatus.INCOMPLETE]: 'failed',
  [BulkTransactionStatus.PENDING]: 'neutral',
  [BulkTransactionStatus.COMPLETED]: 'active',
}

export const iconByRecipientType: Record<Recipient['entityType'], Icon> = {
  [EntityType.BUSINESS]: Building,
  [EntityType.INDIVIDUAL]: User,
}

export const internalTransactionTypes: TransactionType[] = [
  TransactionType.INTERNAL_SWAP_DEPOSIT,
  TransactionType.INTERNAL_SWAP_WITHDRAW,
]

export const depositTransactionTypes: TransactionType[] = [
  TransactionType.DEPOSIT,
  TransactionType.INTERNAL_SWAP_DEPOSIT,
]

export const failedTransactionStatuses: TransactionStatus[] = [
  TransactionStatus.FAILED,
  TransactionStatus.REVERTED,
]
