import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { BaseRoute } from '@/constants/paths'
import { logout as logoutApi } from '@/domains/Business/api/logout'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useReset } from '@/hooks/useReset'

export function useLogout() {
  const reset = useReset()

  const navigate = useNavigate()
  const notifyError = useErrorToast()

  const { mutateAsync } = useMutation({
    mutationFn: logoutApi,
  })

  const logout = useCallback(async () => {
    navigate(BaseRoute.LoggingOut, { replace: true })

    try {
      await mutateAsync()
    } catch (error) {
      notifyError(error)
    }

    reset()
  }, [mutateAsync, navigate, notifyError, reset])

  return { logout }
}
