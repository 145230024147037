import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { generatePath, useNavigate } from 'react-router-dom'

import { ContractorRoute } from '@/constants/paths'

import { ContractorOnboardingState } from '../types'

import { DolarTagPaymentDetails } from './DolarTagPaymentDetails'
import { MXBankPaymentDetails } from './MXBankPaymentDetails'
import { SelectPaymentMethodScreen } from './SelectPaymentMethodScreen'
import { USBankPaymentDetails } from './USBankPaymentDetails'

type Step = 'select' | 'us-bank' | 'mx-bank' | 'dolarTag'

type Props = {
  step?: string
  contractorState?: ContractorOnboardingState
}

export const PaymentMethodStep = ({ step, contractorState }: Props) => {
  const [activeStep, setActiveStep] = useState<Step>('select')

  const navigate = useNavigate()

  const currentStepIndex = contractorState?.steps.findIndex(
    (s) => s.name === step,
  )

  const previousStepIndex = (currentStepIndex ?? 0) - 1

  const previousStep = contractorState?.steps[previousStepIndex]

  const onBack = () => {
    navigate(
      generatePath(ContractorRoute.OnboardingStep, {
        step: previousStep?.name ?? '',
      }),
    )
  }

  return (
    <AnimatePresence mode="wait">
      {activeStep === 'select' ? (
        <SelectPaymentMethodScreen
          onBack={onBack}
          onContinue={(value) => {
            setActiveStep(value as Step)
          }}
        />
      ) : null}

      {activeStep === 'us-bank' ? (
        <USBankPaymentDetails
          onBack={() => setActiveStep('select')}
          contractorState={contractorState}
        />
      ) : null}

      {activeStep === 'mx-bank' ? (
        <MXBankPaymentDetails
          onBack={() => setActiveStep('select')}
          contractorState={contractorState}
        />
      ) : null}

      {activeStep === 'dolarTag' ? (
        <DolarTagPaymentDetails
          onBack={() => setActiveStep('select')}
          contractorState={contractorState}
        />
      ) : null}
    </AnimatePresence>
  )
}
