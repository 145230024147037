import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, Link } from 'react-router-dom'
import { toast } from 'sonner'

import { BusinessRoute } from '@/constants/paths'
import { useBusinessRole } from '@/domains/Business/hooks'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Spinner } from '@/shared/ui'

import { useApproveTask } from '../../hooks/useApproveTask'
import { Task } from '../../types'
import { CancelTasksDialog } from '../CancelTaskDialog'
import { DeclineTasksDialog } from '../DeclineTasksDialog'

type Props = {
  task: Task
  onAction: (id: string) => void
}

export const TaskActionsCell = ({ onAction, task }: Props) => {
  const { isAdmin, isPaymentOps } = useBusinessRole()
  const intl = useIntl()

  const [showDeclineDialog, setShowDeclineDialog] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const { approveTask, isPending } = useApproveTask()

  const onContinue = (idx: string | string[]) => {
    if (Array.isArray(idx)) {
      const [id] = idx

      onAction(id)
      return
    }

    onAction(idx)
  }

  const onApprove = () => {
    if (!task?.id) {
      toast.error('Task ID not found')
      return
    }

    approveTask({ id: task.id })
  }

  return (
    <>
      <div className="flex items-center justify-end md:gap-6">
        {isAdmin && (
          <div className="hidden gap-2 md:flex">
            <Button
              size="xs"
              variant="secondary"
              onClick={() => setShowDeclineDialog(true)}
            >
              <FormattedMessage id="action.decline" defaultMessage="Decline" />
            </Button>
            <Button disabled={isPending} size="xs" onClick={onApprove}>
              <FormattedMessage id="action.approve" defaultMessage="Approve" />
            </Button>
          </div>
        )}

        {isPaymentOps && (
          <Button size="xs" onClick={() => setShowCancelDialog(true)}>
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>
        )}

        <Button
          asChild
          variant="ghost"
          size="inline"
          className="size-6 shrink-0 md:size-auto"
          aria-label={intl.formatMessage({
            id: 'action.openTaskReview',
            defaultMessage: 'Open task review',
          })}
        >
          <Link to={generatePath(BusinessRoute.ReviewTask, { id: task.id })}>
            {isPending ? (
              <Spinner className="size-5 shrink-0" />
            ) : (
              <ChevronRight className="size-5 shrink-0" />
            )}
          </Link>
        </Button>
      </div>

      <DeclineTasksDialog
        isOpen={showDeclineDialog}
        onOpenChange={setShowDeclineDialog}
        tasks={[task]}
        onContinue={(idx) => {
          onContinue(idx)

          setShowDeclineDialog(false)
        }}
      />

      <CancelTasksDialog
        tasks={[task]}
        isOpen={showCancelDialog}
        onOpenChange={setShowCancelDialog}
        onContinue={(idx) => {
          onContinue(idx)

          setShowCancelDialog(false)
        }}
      />
    </>
  )
}
