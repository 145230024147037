import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { InfoTriangle } from '@/shared/icons/outline'
import { Button, FullScreen, Typography } from '@/shared/ui'

import { useReset } from './hooks/useReset'

export const NotFound = () => {
  const resetAndLogout = useReset()
  const navigate = useNavigate()

  return (
    <FullScreen>
      <div className="text-center">
        <InfoTriangle className="mx-auto h-36 w-36" />
        <Typography text="center" variant="h1">
          404
        </Typography>

        <div className="p-3" />

        <Typography className="py-3 text-xl">
          <FormattedMessage
            id="page.notFound"
            defaultMessage="Sorry, this page doesn't exist"
          />
        </Typography>

        <div className="p-3" />

        <div className="flex justify-center gap-3">
          <Button onClick={() => navigate(-1)}>
            <FormattedMessage id="action.goBack" defaultMessage="Go back" />
          </Button>
          <Button variant="tertiary" onClick={resetAndLogout}>
            <FormattedMessage id="nav.logout" defaultMessage="Log out" />
          </Button>
        </div>
      </div>
    </FullScreen>
  )
}
