import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

type ScheduleBulkPaymentRequest = {
  bulkPaymentId: string
  walletId: string
  shouldNotifyRecipients: boolean
  label?: string
  selectedPayments: string[]
} & OTPCode

export function scheduleBulkPayment(data: ScheduleBulkPaymentRequest) {
  const { bulkPaymentId, ...rest } = data

  return api.post<void>(`/v1/me/bulk-payments/${bulkPaymentId}/schedule`, rest)
}
