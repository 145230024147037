import { Currency } from '@/constants/currency'
import { PaymentMethod } from '@/domains/Business/constants'
import { EntityType } from '@/domains/Business/features/Recipients/types'
import { CountryCode } from '@/types/country'

export enum TaskState {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
}

export enum TaskType {
  ADD_BENEFICIARY = 'ADD_BENEFICIARY',
  EXECUTE_WITHDRAWAL = 'EXECUTE_WITHDRAWAL',
  EXECUTE_BULK_PAYMENT = 'EXECUTE_BULK_PAYMENT',
}

export enum TaskEntityType {
  BENEFICIARY = 'BENEFICIARY',
  BULK_PAYMENT = 'BULK_PAYMENT',
  WITHDRAWAL = 'WITHDRAWAL',
}

type Amount = {
  amount: number
  currency: Currency
}

type PaymentQuoteDetail = {
  subtotalNumberOfPayments: number
  subtotalAmount: Amount
  fxRate: number
}

export type BulkPaymentTaskDetails = {
  id: string
  walletId: string
  paymentLabel: string
  totalNumberOfPayments: number
  totalAmount: Amount
  fee: Amount
  paymentQuoteDetails: PaymentQuoteDetail[]
  feeBeforeDiscount: Amount
}

export type WithdrawalTaskDetails = {
  additionalDetails?: {
    paymentMessage?: string
  }
  id: string
  businessId: string
  businessUserId: string
  walletId: string
  walletLabel: string
  beneficiaryId: string
  localAmount: string
  localCurrency: Currency
  baseAmount: string
  baseCurrency: Currency
  fxRate: string
  type: string
  createdOn: string
}

type SharedType = {
  id: string
  businessId: string
  businessUserId: string
  businessIdentityId: string
  createdByFirstName: string
  createdByLastName: string
  createdBySecondLastName?: null | string
  state: TaskState
  type: TaskType
  entityType: TaskEntityType
  entityId: string
  completedByBusinessUserId: null | string
  completedOn: null | string
  createdOn: string
}

type AddBeneficiaryTaskMX = SharedType & {
  type: TaskType.ADD_BENEFICIARY
  entityType: TaskEntityType.BENEFICIARY
  taskDetails: {
    beneficiaryName: string
    beneficiaryClabe: string
    beneficiaryEntityType: string
    beneficiaryEmail?: string
    beneficiaryPhoneNumber?: string
    beneficiaryBank?: string
    beneficiaryCountry: CountryCode.MX
  }
}

type AddBeneficiaryTaskUS = SharedType & {
  type: TaskType.ADD_BENEFICIARY
  entityType: TaskEntityType.BENEFICIARY
  taskDetails: {
    beneficiaryName: string
    beneficiaryAccountNumber: string
    beneficiaryRoutingNumber: string
    beneficiaryEntityType: EntityType
    beneficiaryPaymentMethod: PaymentMethod
    beneficiaryEmail?: string
    beneficiaryPhoneNumber?: string
    beneficiaryBank?: string
    beneficiaryCountry: CountryCode.US
  }
}

export type ExecuteWithdrawalTask = SharedType & {
  type: TaskType.EXECUTE_WITHDRAWAL
  entityType: TaskEntityType.WITHDRAWAL
  taskDetails: {
    localAmount: string
    localCurrency: Currency
    beneficiaryId: string
    beneficiaryName: string
    beneficiaryEntityType: EntityType
    walletLabel: string
  }
}

export type ExecuteBulkPaymentTask = SharedType & {
  type: TaskType.EXECUTE_BULK_PAYMENT
  entityType: TaskEntityType.BULK_PAYMENT
  taskDetails: {
    bulkPaymentLabel: string
    currency: Currency
    totalAmount: string
    totalNumberOfPayments: string
    walletLabel: string
  }
}

export type AddBeneficiaryTask = AddBeneficiaryTaskMX | AddBeneficiaryTaskUS

export type Task =
  | AddBeneficiaryTask
  | ExecuteWithdrawalTask
  | ExecuteBulkPaymentTask
