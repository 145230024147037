import { api } from '@/lib/api'

import { ContractDocument } from '../types'

type UploadDocumentParams = {
  file: File
}

export function uploadCustomContract({ file }: UploadDocumentParams) {
  const formData = new FormData()

  formData.append('file', file)

  return api.post<ContractDocument>(
    `v1/me/custom-contract-agreements`,
    formData,
  )
}
