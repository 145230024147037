import { FormattedMessage } from 'react-intl'

import { Widget } from '@/shared/components'

import { ContractorDetails, ContractorState } from '../../types'

import { SendReminderToDo } from './SendReminderToDo'

type Props = {
  contractor?: ContractorDetails
}

export const ContractorToDos = ({ contractor }: Props) => {
  switch (contractor?.state) {
    case ContractorState.ACTIVE:
      return null

    case ContractorState.ACTION_NEEDED:
    case ContractorState.WAITING_FOR_CONTRACTOR:
      return (
        <Widget
          title={<FormattedMessage defaultMessage="To-do's" id="label.toDos" />}
        >
          {contractor.state === ContractorState.WAITING_FOR_CONTRACTOR && (
            <SendReminderToDo contractor={contractor} />
          )}

          {contractor.state === ContractorState.ACTION_NEEDED && (
            <SendReminderToDo contractor={contractor} />
          )}
        </Widget>
      )

    default:
      return null
  }
}
