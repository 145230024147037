import { RouteObject } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'

import { CashbackTransactions } from './CashbackTransactions'
import { Transactions } from './Transactions'

export const transactionsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Transactions,
    element: <Transactions />,
  },
]

export const cashbackTransactionsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.CashbackTransactions,
    element: <CashbackTransactions />,
  },
]
