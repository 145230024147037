import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { ContractDocument } from '@/domains/Contractor/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatDate } from '@/lib/date'
import { intersperse } from '@/lib/typography'
import { downloadFile } from '@/lib/utils'
import { CloudDownload } from '@/shared/icons/outline'
import { Document, Ellipsis } from '@/shared/icons/solid'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  MotionDiv,
  Typography,
} from '@/shared/ui'

import { downloadContractorDocument } from '../api'

type Props = {
  contractId?: string
  file: ContractDocument
}

export const UploadedContractDocument = ({ contractId, file }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: downloadContractorDocument,
  })

  const downloadDocument = async () => {
    if (!contractId) {
      return
    }

    try {
      const response = await mutateAsync({
        agreementId: file.id,
        contractId,
      })

      downloadFile(response.data, file.fileName)
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <MotionDiv className="flex w-full items-center justify-between">
      <div className="flex gap-2">
        <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
          <Document className="size-5 text-neutral-gray-900" />
        </div>

        <div className="flex flex-col">
          <Typography bold>{file.fileName}</Typography>
          <Typography variant="body-small" className="text-neutral-gray-600">
            {intersperse(
              [
                intl.formatMessage({
                  id: 'contractor.document.uploaded',
                  defaultMessage: 'Document uploaded ',
                }),
                formatDate(file.uploadedOn, 'dd MMM. yyyy, HH:mm'),
              ],
              ' • ',
            )}
          </Typography>
        </div>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label={intl.formatMessage({
              id: 'action.documentActions',
              defaultMessage: 'Delete file',
            })}
            leftIcon={<Ellipsis />}
            loaderPosition="left"
            loading={isPending}
            disabled={isPending}
            size="icon"
            variant="ghost"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem>
            <Button
              onClick={downloadDocument}
              leftIcon={<CloudDownload className="size-5" />}
              className="justify-start px-4 font-normal"
              width="full"
              variant="ghost"
            >
              <FormattedMessage
                id="label.downloadDocument"
                defaultMessage="Download document"
              />
            </Button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </MotionDiv>
  )
}
