import { format, parse } from 'date-fns'

import { SPEI } from '@/domains/Business/constants'
import { parseAdditionalDetails } from '@/lib/utils'
import {
  DATE_FROM_FILTER_NAME,
  DATE_TO_FILTER_NAME,
  TYPE_FILTER_NAME,
} from '@/shared/components'

import {
  SingleTransaction,
  TransactionSearchParams,
  TransactionType,
} from '../types'

export function getTransactionPaymentMethod(
  transaction?: SingleTransaction,
): string | null {
  const additionalDetails = parseAdditionalDetails<{
    paymentMethod?: string
    beneficiaryPaymentMethod?: string
    beneficiaryClabe?: string
    payerClabe?: string
  }>(transaction?.additionalDetails)

  const userClabe =
    additionalDetails?.beneficiaryClabe ?? additionalDetails?.payerClabe

  const paymentMethod =
    additionalDetails?.beneficiaryPaymentMethod ??
    additionalDetails?.paymentMethod

  const MXDefaultPaymentMethod = userClabe ? SPEI : null

  return paymentMethod ?? MXDefaultPaymentMethod
}

export function handleTransactionQueryParams(
  params: TransactionSearchParams = {},
) {
  const searchParams = new URLSearchParams(params)

  if (params[DATE_FROM_FILTER_NAME]) {
    searchParams.set(
      DATE_FROM_FILTER_NAME,
      format(
        parse(
          params[DATE_FROM_FILTER_NAME],
          'dd/LL/yy',
          new Date().toUTCString(),
        ),
        'yyyy-MM-dd',
      ),
    )
  }

  if (params[DATE_TO_FILTER_NAME]) {
    searchParams.set(
      DATE_TO_FILTER_NAME,
      format(
        parse(
          params[DATE_TO_FILTER_NAME],
          'dd/LL/yy',
          new Date().toUTCString(),
        ),
        'yyyy-MM-dd',
      ),
    )
  }

  if (
    params[TYPE_FILTER_NAME]?.includes(TransactionType.INTERNAL_SWAP_DEPOSIT)
  ) {
    const existingStatuses = params[TYPE_FILTER_NAME].split(',')

    existingStatuses.push(TransactionType.INTERNAL_SWAP_WITHDRAW)

    searchParams.set(TYPE_FILTER_NAME, existingStatuses.join(','))
  }

  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return searchQuery
}
