import { contractorApi } from '@/lib/api'

export type Setup2FAInfoResponse = {
  identityId: string
  plainTextSecret: string
  qrCodeUrl: string
}

type Setup2FAInfoRequest = {
  reset?: boolean
}

export function getContractor2FASetupInfo({ reset }: Setup2FAInfoRequest) {
  const searchParams = new URLSearchParams()

  if (reset) {
    searchParams.set('reset', 'true')
  }

  return contractorApi.post<Setup2FAInfoResponse>(
    `/v1/me/one-time-passwords/2FA/secret?${searchParams.toString()}`,
  )
}
