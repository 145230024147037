import { useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useBusinessUser } from '@/domains/Business/hooks'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { Eye, EyeOff, PhonePad, Snow } from '@/shared/icons/outline'
import { Button } from '@/shared/ui'

import { freezeCard, getCardPin, unfreezeCard } from '../../api'
import { Card, CardState, CardType } from '../../types'

import { CardBack } from './CardBack'
import { CardFront } from './CardFront'

type Props = {
  card?: Card
  onShowPIN: (pin: string) => void
}

export const FlipCard = ({ card, onShowPIN }: Props) => {
  const intl = useIntl()
  const [isFlipped, setIsFlipped] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const businessUser = useBusinessUser()

  const notifyError = useErrorToast()

  const isTerminated = card?.state === CardState.TERMINATED

  const isCardOwner = useMemo(
    () => businessUser?.identityId === card?.identityId,
    [businessUser?.identityId, card?.identityId],
  )

  const { mutateAsync: unfreezeMutateAsync, isPending: unfreezeIsPending } =
    useMutation({
      mutationFn: unfreezeCard,
    })

  const { mutateAsync: freezeMutateAsync, isPending: freezeIsPending } =
    useMutation({
      mutationFn: freezeCard,
    })

  const {
    mutateAsync: getPinMutateAsync,
    isPending: getPinIsPending,
    isSuccess: getPinIsSuccess,
  } = useMutation({
    mutationFn: getCardPin,
  })

  const [isFrozen, setIsFrozen] = useState(card?.state === CardState.BLOCKED)

  const handleFlip = () => {
    if (isTerminated || !isCardOwner) {
      return
    }

    if (!isAnimating) {
      setIsFlipped(!isFlipped)
      setIsAnimating(true)
    }
  }

  const onShowPin = async () => {
    if (!card?.id) return

    try {
      const { data } = await getPinMutateAsync({ id: card.id })

      onShowPIN(data.pin)
    } catch (error) {
      notifyError(error)
    }
  }

  const handleFreezing = async () => {
    if (!card?.id) return

    if (isFrozen) {
      try {
        await unfreezeMutateAsync({ id: card.id })

        setIsFrozen(false)
        toast.success(
          intl.formatMessage({
            id: 'card.unfreeze.success',
            defaultMessage: 'Card has been unfrozen',
          }),
        )
      } catch (error: unknown) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    } else {
      try {
        await freezeMutateAsync({ id: card.id })

        setIsFrozen(true)
        toast.success(
          intl.formatMessage({
            id: 'card.freeze.success',
            defaultMessage: 'Card has been frozen',
          }),
        )
      } catch (error: unknown) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    }

    await queryClient.refetchQueries({
      queryKey: [queryKeys.getAllCards],
    })

    await queryClient.refetchQueries({
      queryKey: [queryKeys.getSingleCard, card.id],
    })
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex w-full justify-center">
        <div
          className="relative h-40 w-64 outline-none transition-all perspective-1000"
          onClick={handleFlip}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && handleFlip()}
        >
          <motion.div
            className="h-full w-full preserve-3d transform-duration-300"
            initial={false}
            animate={{ rotateY: isFlipped ? 180 : 360 }}
            transition={{ duration: 0.3, animationDirection: 'normal' }}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            <CardFront isFrozen={isFrozen} card={card} />
            <CardBack isFrozen={isFrozen} card={card} />
          </motion.div>
        </div>
      </div>

      <div className="p-2" />

      {isTerminated || !isCardOwner ? null : (
        <div className="flex gap-3">
          <Button
            leftIcon={
              isFlipped ? (
                <EyeOff className="size-4" />
              ) : (
                <Eye className="size-4" />
              )
            }
            onClick={handleFlip}
            size="md"
            variant="tertiary"
          >
            {isFlipped ? (
              <FormattedMessage
                id="action.hideDetails"
                defaultMessage="Hide details"
              />
            ) : (
              <FormattedMessage
                id="action.showDetails"
                defaultMessage="Show details"
              />
            )}
          </Button>

          <Button
            disabled={unfreezeIsPending || freezeIsPending}
            loading={unfreezeIsPending || freezeIsPending}
            onClick={handleFreezing}
            size="md"
            leftIcon={<Snow className="size-5" />}
            variant="tertiary"
          >
            {isFrozen ? (
              <FormattedMessage
                id="action.unfreeze"
                defaultMessage="Unfreeze"
              />
            ) : (
              <FormattedMessage id="action.freeze" defaultMessage="Freeze" />
            )}
          </Button>

          {card?.type === CardType.PHYSICAL && (
            <Button
              onClick={onShowPin}
              size="md"
              variant="tertiary"
              loading={getPinIsPending || getPinIsSuccess}
              loaderPosition="left"
              leftIcon={<PhonePad className="size-5" />}
            >
              <FormattedMessage id="action.showPIN" defaultMessage="Show PIN" />
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
