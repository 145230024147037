import { Currency } from '@/constants/currency'
import { CountryCode } from '@/types/country'

import {
  Contractor,
  ContractorAgreementType,
  ContractorDetails,
  ContractorFirstCycleMethod,
  ContractorPaymentFrequency,
  ContractorPaymentMethodState,
  ContractorPaymentMethodType,
  ContractorPaymentType,
  ContractorState,
  ContractPaymentDayType,
  ContractState,
} from '../../types'

export const MOCKED_CONTRACTORS: Contractor[] = [
  {
    id: '0a3a1024-5ac0-456b-8653-f61caa8e6400',
    firstName: 'Andrzej',
    lastName: 'Andrzej',
    secondLastName: 'Endrju',
    email: 'endrju@gov.pl',
    contractorRole: 'King of the World',
    countryOfCitizenship: CountryCode.US,
    paymentType: ContractorPaymentType.FIXED_RATE,
    startDate: '2025-09-06',
    lastPaymentDate: null,
    state: ContractorState.ACTIVE,
  },
  {
    id: '3c88add2-5485-414b-a786-1d1a0905f54d',
    firstName: 'Krzysztof',
    lastName: 'Broncel',
    secondLastName: 'Mega',
    email: 'krzysztof+contractor@dolarapp.com',
    contractorRole: 'BE Software Engineer',
    countryOfCitizenship: null,
    paymentType: ContractorPaymentType.PAY_AS_YOU_GO,
    startDate: '2024-11-13',
    lastPaymentDate: null,
    state: ContractorState.WAITING_FOR_CONTRACTOR,
  },
  {
    id: 'a794fc19-2574-4f9b-8a7c-aa942d9eda23',
    firstName: 'Maks',
    lastName: 'Akymenko',
    secondLastName: undefined,
    email: 'maks+contractor@dolarapp.com',
    contractorRole: 'Software Engineer',
    countryOfCitizenship: null,
    paymentType: ContractorPaymentType.FIXED_RATE,
    startDate: '2025-04-22',
    lastPaymentDate: null,
    state: ContractorState.WAITING_FOR_CONTRACTOR,
  },
]

export const MOCKED_CONTRACTOR_DETAILS: ContractorDetails = {
  businessUserId: 'dc64c142-7cbd-4867-b0f4-ce81dd2f8a5c',
  id: '0a3a1024-5ac0-456b-8653-f61caa8e6400',
  state: ContractorState.ACTIVE,
  personalDetails: {
    firstName: 'Andrzej',
    lastName: 'Andrzej',
    secondLastName: 'Endrju',
    email: 'endrju@gov.pl',
    internationalPhonePrefix: '+48',
    localPhoneNumber: '725725725',
    dateOfBirth: '1989-01-01',
    countryOfCitizenship: CountryCode.US,
    residentialAddress: {
      addressStreetNumber: '1235',
      addressStreet: 'Main St',
      addressDistrict: 'Downtown',
      addressCity: 'Springfield',
      addressState: 'IL',
      addressPostCode: '62701',
      addressCountry: CountryCode.US,
    },
  },
  taxDetails: {
    countryOfTaxResidence: CountryCode.US,
    taxId: '123456780',
    taxIdType: 'SSN',
    taxForm: 'W9',
  },
  paymentMethod: {
    state: ContractorPaymentMethodState.LINKED,
    paymentMethodDetails: {
      type: ContractorPaymentMethodType.DOLAR_TAG,
      paymentMethodInformation: {
        dolarTag: '$tomaszjangrot14',
      },
    },
  },
  contracts: [
    {
      id: '7bb1b6b9-8e8b-4260-b044-86684ebb8685',
      state: ContractState.ACTIVE,
      paymentType: ContractorPaymentType.FIXED_RATE,
      agreementType: ContractorAgreementType.CUSTOM,
      contractorRole: 'King of the World',
      scopeOfWork: 'His main mission is to make the world a better place',
      startDate: '2025-09-06',
      endDate: null,
      createdByBusinessUserId: 'dc64c142-7cbd-4867-b0f4-ce81dd2f8a5c',
      paymentDetails: {
        currency: Currency.USD,
        amount: 100000000,
        frequency: ContractorPaymentFrequency.MONTHLY,
        dayType: ContractPaymentDayType.CUSTOM_DAY_OF_MONTH,
        day: 23,
        firstCycleMethod: ContractorFirstCycleMethod.CUSTOM_AMOUNT,
        firstCycleAmount: 1000000,
      },
    },
  ],
}
