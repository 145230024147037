import { FormattedMessage } from 'react-intl'

import { passwordMessages } from '@/constants/messages'
import { cn } from '@/lib/utils'
import {
  hasLowerCaseCharacter,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCaseCharacter,
  PASSWORD_MIN_LENGTH,
} from '@/lib/validations'

import { Check } from '../icons/outline'
import { Typography } from '../ui'

export const PasswordRules = ({ password }: { password: string }) => {
  const rules = [
    {
      key: 'minLength',
      isValid: password.length >= PASSWORD_MIN_LENGTH,
    },
    {
      key: 'upperCase',
      isValid: hasUpperCaseCharacter(password),
    },
    {
      key: 'lowerCase',
      isValid: hasLowerCaseCharacter(password),
    },
    {
      key: 'hasNumber',
      isValid: hasNumber(password),
    },
    {
      key: 'hasSpecialCharacter',
      isValid: hasSpecialCharacter(password),
    },
  ] as const

  return (
    <ol className="flex list-inside flex-col pl-4 pt-4 text-sm text-neutral-gray-800 transition-all">
      {rules.map((rule) => (
        <li
          key={rule.key}
          className={cn(
            `flex h-6 items-center gap-1`,
            rule.isValid && 'font-bold text-primary',
          )}
        >
          <Typography>
            <FormattedMessage {...passwordMessages[rule.key]} />
          </Typography>
          {rule.isValid && <Check className="h-4 w-4" />}
        </li>
      ))}
    </ol>
  )
}
