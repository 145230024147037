import { Currency } from '@/constants/currency'
import { api } from '@/lib/api'

import { FixedRateContractSchema } from '../components'

type ProRataResponse = {
  paymentAmount: { amount: number; currency: Currency }
  paymentDate: string
}

type ProRataRequest = {
  startDate: FixedRateContractSchema['startDate']
  paymentDetails: FixedRateContractSchema['paymentDetails']
}

export function calculateProRata(data: ProRataRequest) {
  return api.post<ProRataResponse>(
    `/v1/me/contractor-payments/next-payment-quote`,
    data,
  )
}
