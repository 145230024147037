import { Fragment } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { sendTeamMemberInviteReminder } from '@/domains/Business/features/Team'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getImage } from '@/lib/images'
import { Button, Card, Typography } from '@/shared/ui'

import { ContractorDetails } from '../../types'

const Wrapper = ({
  children,
  variant,
}: {
  children: React.ReactNode
  variant: 'sidebar' | 'widget'
}) => {
  return variant === 'sidebar' ? (
    <Card size="medium">{children}</Card>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

type Props = {
  contractor?: ContractorDetails
  variant?: 'sidebar' | 'widget'
}

export const SendReminderToDo = ({ contractor, variant = 'widget' }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: sendTeamMemberInviteReminder,
  })

  const onSendReminder = async () => {
    if (!contractor?.businessUserId) {
      return
    }

    try {
      await mutateAsync({ id: contractor?.businessUserId })

      toast.success(
        intl.formatMessage({
          id: 'team.member.reminder.success',
          defaultMessage: 'Invitation reminder sent successfully',
        }),
      )
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <Wrapper variant={variant}>
      <div className="flex flex-wrap items-center justify-between gap-8 md:flex-nowrap md:gap-0">
        <div className="flex flex-wrap gap-8 md:gap-3">
          {variant === 'sidebar' ? null : (
            <img
              className="w-8 object-contain"
              src={getImage({ name: 'todo-list', category: 'contractors' })}
              alt=""
              aria-hidden={true}
            />
          )}
          <div className="flex flex-col justify-center">
            <Typography bold>
              <FormattedMessage
                id="contractors.toDo.invitation.title"
                defaultMessage="Invite {name} to add a payment method"
                values={{
                  name: contractor?.personalDetails.firstName,
                }}
              />
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.toDo.invitation.description"
                defaultMessage="Send {name} a friendly reminder via email to set up the account"
                values={{
                  name: contractor?.personalDetails.firstName,
                }}
              />
            </Typography>
          </div>
        </div>

        <Button
          onClick={onSendReminder}
          loading={isPending}
          disabled={isPending}
        >
          <FormattedMessage id="actions.remind" defaultMessage="Remind" />
        </Button>
      </div>
    </Wrapper>
  )
}
