import { http, HttpResponse } from 'msw'

import { BASE_CONTRACTOR_API_DEV } from '@/constants/urls'

import { MOCK_ONBOARDING_STATE } from './contractorOnboarding.mocks'

export const contractorOnboardingHandlers = [
  http.get(`${BASE_CONTRACTOR_API_DEV}/v1/contractor-onboarding`, () => {
    return HttpResponse.json(MOCK_ONBOARDING_STATE)
  }),

  http.post(`${BASE_CONTRACTOR_API_DEV}/v1/contractor-onboarding/start`, () => {
    return HttpResponse.json(MOCK_ONBOARDING_STATE)
  }),

  http.post(
    `${BASE_CONTRACTOR_API_DEV}/v1/contractor-onboarding/personal-information`,
    async () => {
      return HttpResponse.json(MOCK_ONBOARDING_STATE)
    },
  ),

  http.post(
    `${BASE_CONTRACTOR_API_DEV}/v1/contractor-onboarding/payment-method`,
    async () => {
      return HttpResponse.json(MOCK_ONBOARDING_STATE)
    },
  ),

  http.post(
    `${BASE_CONTRACTOR_API_DEV}/v1/contractor-onboarding/complete`,
    () => {
      return HttpResponse.json(MOCK_ONBOARDING_STATE)
    },
  ),
]
