import { RouteObject } from 'react-router-dom'

import { ContractorRoute } from '@/constants/paths'

import { ContractorOverview } from './ContractorOverview'
import { Layout } from './Layout'

export const contractorOverviewRoutes: RouteObject[] = [
  {
    path: ContractorRoute.Overview,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ContractorOverview />,
      },
    ],
  },
]
