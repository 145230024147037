import { FormattedMessage } from 'react-intl'

import { cardTypes } from '@/domains/Business/constants'
import { CardType } from '@/domains/Business/features/Cards/types'
import { getImage } from '@/lib/images'
import { GoBackButton } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Badge, Button, Card, SlideInScreen, Typography } from '@/shared/ui'

import { TeamMemberSchema } from './TeamMemberDetailsStep'

type Props = {
  onBack: () => void
  onContinue: (cardType: CardType) => void
  onSkip?: () => void
  teamMemberDetails?: TeamMemberSchema
}

export const TeamSelectCardStep = ({
  onBack,
  onContinue,
  onSkip,
  teamMemberDetails,
}: Props) => {
  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Create the card for {name}"
            id="team.create.card.title"
            values={{
              name: teamMemberDetails?.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Create a card for {name} now or skip and do it later"
            id="team.create.card.subtitle"
            values={{
              name: teamMemberDetails?.firstName,
            }}
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-4">
          {cardTypes.map((card) => {
            return (
              <Card
                key={card.title.id}
                size="upload"
                className="flex w-full items-center justify-between"
                asChild
                scalable
              >
                <Button
                  size="inline"
                  variant="ghost"
                  onClick={() => {
                    onContinue(card.type as CardType)
                  }}
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={getImage({
                        name: card.imageName,
                        category: 'cards',
                      })}
                      className="h-8 shrink-0 object-cover"
                      alt=""
                      aria-hidden
                    />

                    <div className="flex flex-col">
                      <div className="flex gap-2">
                        <Typography bold>
                          <FormattedMessage {...card.title} />
                        </Typography>
                        {card.label && (
                          <Badge variant="neutral">
                            <FormattedMessage
                              id="label.free"
                              defaultMessage="Free"
                            />
                          </Badge>
                        )}
                      </div>
                      <Typography className="whitespace-break-spaces text-neutral-gray-600">
                        <FormattedMessage {...card.description} />
                      </Typography>
                    </div>
                  </div>

                  <ChevronRight className="size-8 shrink-0 text-neutral-gray-600" />
                </Button>
              </Card>
            )
          })}
        </div>
        {onSkip ? (
          <Button
            width="full"
            className="mt-auto font-semibold no-underline"
            onClick={onSkip}
            variant="link"
            size="inline"
          >
            <FormattedMessage
              id="action.skipForNow"
              defaultMessage="Skip for now"
            />
          </Button>
        ) : null}

        <div className="p-6" />
      </SlideInScreen>
    </>
  )
}
