import { useEffect } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { generatePath, useNavigate } from 'react-router-dom'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'

import { getCurrentContractor } from '../../api'
import { ContractorUser, ContractorUserState } from '../../types'

import { getContractorOnboardingState } from './api'
import {
  ContractorOnboardingState,
  ContractorOnboardingStepState,
} from './types'

export const ContractorOnboarding = () => {
  const navigate = useNavigate()

  const [onboardingStateQuery, contractorUserQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getContractorOnboardingState],
        queryFn: getContractorOnboardingState,
        select: (data: AxiosResponse<ContractorOnboardingState>) => data.data,
      },
      {
        queryKey: [queryKeys.getCurrentContractor],
        queryFn: getCurrentContractor,
        select: (data: AxiosResponse<ContractorUser>) => data.data,
      },
    ],
  })

  useEffect(() => {
    if (!contractorUserQuery.data || !onboardingStateQuery.data) {
      return
    }

    switch (contractorUserQuery.data.state) {
      case ContractorUserState.ACCEPTED_INVITE:
        navigate(ContractorRoute.OnboardingStart, { replace: true })
        break

      case ContractorUserState.ONBOARDING:
        {
          const notStartedSteps = onboardingStateQuery.data.steps.filter(
            (step) => step.state === ContractorOnboardingStepState.NOT_STARTED,
          )

          const [firstNotStartedStep] = notStartedSteps

          if (firstNotStartedStep) {
            navigate(
              generatePath(ContractorRoute.OnboardingStep, {
                step: firstNotStartedStep.name,
              }),
            )

            return
          }

          navigate(
            generatePath(ContractorRoute.OnboardingStep, {
              step: onboardingStateQuery.data.steps[0].name,
            }),
            { replace: true },
          )
        }

        break

      case ContractorUserState.ACTIVE:
        navigate(ContractorRoute.Overview, { replace: true })
        break

      default:
        break
    }
  }, [contractorUserQuery.data, navigate, onboardingStateQuery.data])

  return null
}
