import { FormattedMessage, useIntl } from 'react-intl'

import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Widget } from '@/shared/components'
import {
  Details,
  Sheet,
  SheetContent,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { usePaymentDate } from '../../hooks'
import { ContractorDetails } from '../../types'
import { ContractStateBadge } from '../ContractStateBadge'

import { ContractDocuments } from './ContractDocuments'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  contractor?: ContractorDetails
}

export const ContractDetailsSidebar = ({
  isOpen,
  onOpenChange,
  contractor,
}: Props) => {
  const intl = useIntl()
  const latestContract = contractor?.contracts[0]

  const paymentDate = usePaymentDate({
    dayType: latestContract?.paymentDetails?.dayType,
    day: latestContract?.paymentDetails?.day,
  })

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <SlideInLeft className="flex h-full flex-col">
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              {latestContract?.paymentType ? (
                <Typography variant="h3">
                  <FormattedMessage
                    id="contractors.contract.type.label"
                    defaultMessage="{state, select, FIXED_RATE {Fixed rate contract} PAY_AS_YOU_GO {Pay-as-you-go contract} other {}}"
                    values={{ state: latestContract.paymentType }}
                  />
                </Typography>
              ) : (
                <Skeleton className="mb-0.5 h-[33px] w-36" />
              )}
            </div>
            {latestContract ? (
              latestContract?.paymentDetails ? (
                <Typography className="text-neutral-gray-600">
                  {intersperse(
                    [
                      formatAmount(latestContract.paymentDetails),
                      intersperse(
                        [
                          intl.formatMessage(
                            {
                              id: 'contractor.paymentFrequency.label',
                              defaultMessage:
                                '{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}',
                            },
                            {
                              paymentFrequency:
                                latestContract.paymentDetails.frequency,
                            },
                          ),
                          paymentDate,
                        ],
                        ', ',
                      ),
                    ],
                    ' • ',
                  )}
                </Typography>
              ) : (
                <Typography className="text-neutral-gray-600">
                  <FormattedMessage
                    id="contractor.paymentDetails.startDate"
                    defaultMessage="Start date on {startDate}"
                    values={{
                      startDate: intl.formatDate(latestContract?.startDate, {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                        timeZone: 'UTC',
                      }),
                    }}
                  />
                </Typography>
              )
            ) : (
              <Skeleton className="h-[18px] w-36" />
            )}

            <div className="p-1" />

            <ContractStateBadge
              variant="sidebar"
              state={latestContract?.state}
            />
          </div>

          <div className="p-4" />

          <div className="flex flex-col gap-8">
            <Widget
              title={
                <FormattedMessage
                  id="label.contractDetails"
                  defaultMessage="Contract details"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Contract type"
                    id="label.contractType"
                  />
                </Details.Label>
                <Details.Value>
                  <FormattedMessage
                    id="contract.type.label"
                    defaultMessage="{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}"
                    values={{ state: latestContract?.paymentType }}
                  />
                </Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage defaultMessage="Role" id="label.role" />
                </Details.Label>
                <Details.Value>{latestContract?.contractorRole}</Details.Value>
              </Details>

              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Start date"
                    id="label.startDate"
                  />
                </Details.Label>
                <Details.Value>
                  {intl.formatDate(latestContract?.startDate, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    timeZone: 'UTC',
                  })}
                </Details.Value>
              </Details>
            </Widget>

            {latestContract?.paymentDetails && (
              <Widget
                title={
                  <FormattedMessage
                    id="label.paymentDetails"
                    defaultMessage="Payment details"
                  />
                }
              >
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Payment amount"
                      id="label.paymentAmount"
                    />
                  </Details.Label>

                  <Details.Value>
                    {formatAmount(latestContract.paymentDetails)}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Payment frequency"
                      id="label.paymentFrequency"
                    />
                  </Details.Label>
                  <Details.Value>
                    {intersperse(
                      [
                        intl.formatMessage(
                          {
                            id: 'contractor.paymentFrequency.label',
                            defaultMessage:
                              '{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}',
                          },
                          {
                            paymentFrequency:
                              latestContract.paymentDetails.frequency,
                          },
                        ),
                        paymentDate,
                      ],
                      ', ',
                    )}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="First-month payment"
                      id="label.firstMonthPayment"
                    />
                  </Details.Label>

                  <Details.Value>
                    <FormattedMessage
                      id="contractor.firstCyclePayment.label"
                      defaultMessage="{firstCyclePayment, select, FULL_AMOUNT {Full amount} CUSTOM_AMOUNT {Custom amount} NO_PAYMENT {No payment} PRO_RATA {Pro-rata} other {}}"
                      values={{
                        firstCyclePayment:
                          latestContract.paymentDetails.firstCycleMethod,
                      }}
                    />
                  </Details.Value>
                </Details>
                {latestContract.paymentDetails.firstCycleAmount ? (
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        defaultMessage="Custom first cycle payment amount"
                        id="label.customFirstCyclePaymentAmount"
                      />
                    </Details.Label>

                    <Details.Value>
                      {formatAmount({
                        amount: latestContract.paymentDetails.firstCycleAmount,
                        currency: latestContract.paymentDetails.currency,
                      })}
                    </Details.Value>
                  </Details>
                ) : null}
              </Widget>
            )}

            <ContractDocuments contract={latestContract} />
          </div>

          <div className="p-4" />
        </SlideInLeft>
      </SheetContent>
    </Sheet>
  )
}
