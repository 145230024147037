import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useKeyPress } from '@/hooks/useKeyPress'
import { getOnboardingAddress } from '@/lib/address'
import { getCountryNameByISO3 } from '@/lib/country'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import { GoBackButton } from '@/shared/components'
import { Button, SlideInScreen, StickyContainer, Typography } from '@/shared/ui'

import { createBeneficialOwner, updateBeneficialOwner } from '../../api'
import { CompanyOwner, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'

type Props = {
  onBack: () => void
  data: Omit<CompanyOwner, 'id'>
  ownerId?: string
}

export const OwnerReviewStep = ({ onBack, ownerId, data }: Props) => {
  const notifyError = useErrorToast()
  const navigate = useNavigate()

  const intl = useIntl()

  const mappedKeys: Record<keyof typeof data, string | undefined> = {
    firstName: intl.formatMessage({
      id: 'onboarding.ownerDetails.firstName',
      defaultMessage: 'First name',
    }),
    lastName: intl.formatMessage({
      id: 'onboarding.ownerDetails.lastName',
      defaultMessage: 'Last name',
    }),
    secondLastName: intl.formatMessage({
      id: 'onboarding.ownerDetails.secondLastName',
      defaultMessage: 'Second last name',
    }),
    email: intl.formatMessage({
      id: 'onboarding.ownerDetails.workEmail',
      defaultMessage: 'Work email',
    }),
    internationalPhonePrefix: undefined,
    localPhoneNumber: intl.formatMessage({
      id: 'onboarding.ownerDetails.phoneNumber',
      defaultMessage: 'Phone number',
    }),
    taxResidenceCountry: intl.formatMessage({
      id: 'onboarding.ownerDetails.taxResidenceCountry',
      defaultMessage: 'Country of tax residence',
    }),
    taxId: intl.formatMessage({
      id: 'onboarding.ownerDetails.taxId',
      defaultMessage: 'Tax identification number',
    }),
    addressStreet: undefined,
    addressStreetNumber: undefined,
    addressDistrict: undefined,
    addressPostCode: undefined,
    addressCountry: undefined,
    addressState: undefined,
    addressCity: intl.formatMessage({
      id: 'onboarding.ownerDetails.residentialAddress',
      defaultMessage: 'Residential address',
    }),
  }

  const mappedValues: Record<keyof typeof data, React.ReactNode> =
    useMemo(() => {
      return {
        email: <Typography>{data.email}</Typography>,
        firstName: <Typography>{data.firstName}</Typography>,
        lastName: <Typography>{data.lastName}</Typography>,
        secondLastName: data.secondLastName ? (
          <Typography>{data.secondLastName}</Typography>
        ) : undefined,
        internationalPhonePrefix: undefined,
        localPhoneNumber: (
          <Typography>{`${data.internationalPhonePrefix} ${data.localPhoneNumber}`}</Typography>
        ),
        taxResidenceCountry: (
          <Typography>
            {getCountryNameByISO3(data.taxResidenceCountry, intl.locale)}
          </Typography>
        ),
        taxId: <Typography>{data.taxId}</Typography>,
        addressCountry: undefined,
        addressDistrict: undefined,
        addressPostCode: undefined,
        addressState: undefined,
        addressStreet: undefined,
        addressStreetNumber: undefined,
        addressCity: (
          <Typography className="whitespace-break-spaces text-right">
            {getOnboardingAddress(data, intl)}
          </Typography>
        ),
      }
    }, [data, intl])

  const details = Object.entries(data).map(([key]) => {
    return {
      key: mappedKeys[key as keyof typeof mappedKeys],
      value: mappedValues[key as keyof typeof mappedValues],
    }
  })

  const {
    mutateAsync: createBeneficialOwnerMutateAsync,
    isPending: createBeneficialOwnerIsPending,
    isSuccess: createBeneficialOwnerIsSuccess,
  } = useMutation({
    mutationFn: createBeneficialOwner,
  })

  const {
    mutateAsync: updateBeneficialOwnerMutateAsync,
    isPending: updateBeneficialOwnerIsPending,
    isSuccess: updateBeneficialOwnerIsSuccess,
  } = useMutation({
    mutationFn: updateBeneficialOwner,
  })

  const onSubmit = async () => {
    try {
      if (ownerId) {
        await updateBeneficialOwnerMutateAsync({
          id: ownerId,
          ...data,
        })
      } else {
        await createBeneficialOwnerMutateAsync(data)
      }

      await queryClient.refetchQueries({
        queryKey: [
          queryKeys.getOnboardingStepDetails,
          OnboardingStepName.COMPANY_OWNERSHIP,
        ],
      })

      navigate(
        generatePath(BusinessRoute.OnboardingStep, {
          step: OnboardingStepName.COMPANY_OWNERSHIP,
        }),
      )
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  useKeyPress('Enter', onSubmit)

  const isPending =
    createBeneficialOwnerIsPending ||
    updateBeneficialOwnerIsPending ||
    createBeneficialOwnerIsSuccess ||
    updateBeneficialOwnerIsSuccess

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="onboarding.companyOwnership.reviewStep.title"
            defaultMessage="Review and save beneficial owner"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="onboarding.companyOwnership.reviewStep.subtitle"
            defaultMessage="Make sure all of the information for {fullName} is correct"
            values={{
              fullName: getFullName(data),
            }}
          />
        </Typography>

        <div className="p-6" />

        <StepDetails
          step={OnboardingStepName.COMPANY_OWNERSHIP}
          details={[...details]}
        />

        <StickyContainer>
          <Button
            width="full"
            loading={isPending}
            disabled={isPending}
            className="w-full"
            onClick={onSubmit}
          >
            {ownerId ? (
              <FormattedMessage
                defaultMessage="Save changes"
                id="action.saveChanges"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Add {firstName} as an owner"
                id="action.addNewOwner"
                values={{
                  firstName: data.firstName,
                }}
              />
            )}
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
