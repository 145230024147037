import { useMutation } from '@tanstack/react-query'
import {  parse } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatDate, parseToFormat } from '@/lib/date'
import { formatAmount } from '@/lib/money'
import { parseFullPhoneNumber } from '@/lib/phone'
import { queryClient } from '@/lib/queryClient'
import { getFullName, intersperse } from '@/lib/typography'
import { removeEmptyFormFields } from '@/lib/utils'
import { GoBackButton, Widget } from '@/shared/components'
import { Document, User } from '@/shared/icons/solid'
import {
  Button,
  Card,
  Details,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { createContractor } from '../../../api'
import { usePaymentDate } from '../../../hooks'
import {
  ContractDocument,
  ContractorAgreementType,
  ContractorPaymentType,
} from '../../../types'

import { ContractorDetailsSchema } from './ContractorDetailsStep'
import { FixedRateContractSchema } from './FixedRateContractStep'
import { PayAsYouGoContractSchema } from './PayAsYouGoContractStep'

type Props = {
  agreementType?: ContractorAgreementType
  contractorDetails?: ContractorDetailsSchema
  fixedRateContract?: FixedRateContractSchema
  onBack: () => void
  payAsYouGoContract?: PayAsYouGoContractSchema
  paymentType?: ContractorPaymentType
  uploadedCustomContracts?: ContractDocument[]
}

export const ContractorReviewStep = ({
  agreementType,
  contractorDetails,
  fixedRateContract,
  onBack,
  payAsYouGoContract,
  paymentType,
  uploadedCustomContracts,
}: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const navigate = useNavigate()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: createContractor,
  })

  const sharedContractFields = {
    contractorRole:
      payAsYouGoContract?.contractorRole ?? fixedRateContract?.contractorRole,
    scopeOfWork:
      payAsYouGoContract?.scopeOfWork ?? fixedRateContract?.scopeOfWork,
    startDate: payAsYouGoContract?.startDate ?? fixedRateContract?.startDate,
  }

  const paymentDate = usePaymentDate({
    dayType: fixedRateContract?.paymentDetails?.dayType,
    day: fixedRateContract?.paymentDetails?.day,
  })

  const onSubmit = async () => {
    if (!contractorDetails) {
      toast.error('Missing contractor data. Please go back and fill it')
      return
    }

    if (!paymentType) {
      toast.error('Missing payment type. Please go back and fill it')
      return
    }

    if (!agreementType) {
      toast.error('Missing agreement type. Please go back and fill it')
      return
    }

    let contractorRole = ''
    let scopeOfWork = ''
    let startDate = ''

    if (payAsYouGoContract) {
      contractorRole = payAsYouGoContract.contractorRole
      scopeOfWork = payAsYouGoContract.scopeOfWork
      startDate = payAsYouGoContract.startDate
    }

    if (fixedRateContract) {
      contractorRole = fixedRateContract.contractorRole
      scopeOfWork = fixedRateContract.scopeOfWork
      startDate = fixedRateContract.startDate
    }

    const data = {
      contractorDetails: {
        firstName: contractorDetails.firstName,
        lastName: contractorDetails.lastName,
        secondLastName: contractorDetails.secondLastName ?? '',
        email: contractorDetails.email,
        internationalPhonePrefix: contractorDetails.internationalPhonePrefix,
        localPhoneNumber: parseFullPhoneNumber(contractorDetails),
      },
      contractDetails: {
        contractorRole,
        scopeOfWork,
        startDate: parseToFormat(startDate),
        paymentType,
        agreementType: agreementType,
        paymentDetails: fixedRateContract
          ? fixedRateContract.paymentDetails
          : undefined,
      },
      uploadedContractDocumentIds: uploadedCustomContracts?.map(
        (contract) => contract.id,
      ),
    }

    removeEmptyFormFields(data)

    try {
      const response = await mutateAsync(data)

      toast.success(
        intl.formatMessage({
          id: 'contractor.create.success',
          defaultMessage: 'Contractor created successfully',
        }),
      )

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getContractors],
      })

      navigate(
        generatePath(BusinessRoute.ContractorsDetails, {
          id: response.data.id,
        }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.create.review.title"
            defaultMessage="Review contractor details"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.create.review.subtitle"
            defaultMessage="Confirm that all the contract information and payment details are correct"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-6">
          <Card size="upload">
            <div className="flex gap-2">
              <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
                <User className="size-5 text-neutral-gray-900" />
              </div>

              <div className="flex flex-col">
                <Typography bold>{getFullName(contractorDetails)}</Typography>
                <Typography
                  variant="body-small"
                  className="text-neutral-gray-600"
                >
                  {contractorDetails?.email}
                </Typography>
              </div>
            </div>
          </Card>

          <Widget
            title={
              <FormattedMessage
                defaultMessage="Contract details"
                id="label.contractDetails"
              />
            }
          >
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Contract type"
                  id="label.contractType"
                />
              </Details.Label>
              <Details.Value>
                <FormattedMessage
                  id="contract.type.label"
                  defaultMessage="{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}"
                  values={{ state: paymentType }}
                />
              </Details.Value>
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Role" id="label.role" />
              </Details.Label>
              <Details.Value>
                {sharedContractFields?.contractorRole}
              </Details.Value>
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Start date"
                  id="label.startDate"
                />
              </Details.Label>
              <Details.Value>
                {intl.formatDate(
                  parse(
                    sharedContractFields?.startDate ?? '',
                    'dd/MM/yyyy',
                    new Date(),
                  ),
                  {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    timeZone: 'UTC',
                  },
                )}
              </Details.Value>
            </Details>
          </Widget>

          {fixedRateContract?.paymentDetails ? (
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Payment details"
                  id="label.paymentDetails"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Payment amount"
                    id="label.paymentAmount"
                  />
                </Details.Label>
                <Details.Value>
                  {formatAmount(fixedRateContract.paymentDetails)}
                </Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Payment frequency"
                    id="label.paymentFrequency"
                  />
                </Details.Label>
                <Details.Value>
                  {intersperse(
                    [
                      intl.formatMessage(
                        {
                          id: 'contractor.paymentFrequency.label',
                          defaultMessage:
                            '{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}',
                        },
                        {
                          paymentFrequency:
                            fixedRateContract.paymentDetails.frequency,
                        },
                      ),
                      paymentDate,
                    ],
                    ', ',
                  )}
                </Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="First-month payment"
                    id="label.firstMonthPayment"
                  />
                </Details.Label>

                <Details.Value>
                  <FormattedMessage
                    id="contractor.firstCyclePayment.label"
                    defaultMessage="{firstCyclePayment, select, FULL_AMOUNT {Full amount} CUSTOM_AMOUNT {Custom amount} NO_PAYMENT {No payment} PRO_RATA {Pro-rata} other {}}"
                    values={{
                      firstCyclePayment:
                        fixedRateContract.paymentDetails.firstCycleMethod,
                    }}
                  />
                </Details.Value>
              </Details>
              {fixedRateContract.paymentDetails.firstCycleAmount ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Custom first cycle payment amount"
                      id="label.customFirstCyclePaymentAmount"
                    />
                  </Details.Label>

                  <Details.Value>
                    {formatAmount({
                      amount: fixedRateContract.paymentDetails.firstCycleAmount,
                      currency: fixedRateContract.paymentDetails.currency,
                    })}
                  </Details.Value>
                </Details>
              ) : null}
            </Widget>
          ) : null}

          {uploadedCustomContracts?.length ? (
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Documents"
                  id="label.documents"
                />
              }
            >
              {uploadedCustomContracts.map((contract) => (
                <div key={contract.id} className="flex gap-2">
                  <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
                    <Document className="size-5 text-neutral-gray-900" />
                  </div>

                  <div className="flex flex-col">
                    <Typography bold>{contract.fileName}</Typography>
                    <Typography
                      variant="body-small"
                      className="text-neutral-gray-600"
                    >
                      {intersperse(
                        [
                          intl.formatMessage({
                            id: 'contractor.document.uploaded',
                            defaultMessage: 'Document uploaded',
                          }),
                          formatDate(
                            contract.uploadedOn,
                            'dd MMM. yyyy, HH:mm',
                          ),
                        ],
                        ' • ',
                      )}
                    </Typography>
                  </div>
                </div>
              ))}
            </Widget>
          ) : null}
        </div>

        <StickyContainer>
          <Button
            disabled={isPending || isSuccess}
            loading={isPending || isSuccess}
            width="full"
            onClick={onSubmit}
          >
            <FormattedMessage
              defaultMessage="Create new contractor"
              id="action.createNewContractor"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
