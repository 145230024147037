import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import {
  CardAddressStep,
  CardReviewStep,
  CardTypeStep,
  CreatePhysicalCardStep,
  CreateVirtualCardStep,
  PhysicalCardAddressSchema,
  PhysicalCardInfoSchema,
} from './components/CreateCardSteps'
import { CardType } from './types'

enum FormStep {
  Type = 'type',
  VirtualCardDetails = 'virtualCardDetails',
  PhysicalCardDetails = 'physicalCardDetails',
  Address = 'address',
  Review = 'review',
}

type FormState =
  | { type: FormStep.Type }
  | { type: FormStep.VirtualCardDetails }
  | { type: FormStep.PhysicalCardDetails }
  | { type: FormStep.Address }
  | { type: FormStep.Review }

export const CreateCard = () => {
  const location = useLocation()

  const [physicalCardDetails, setPhysicalCardDetails] =
    useState<PhysicalCardInfoSchema>()

  const [physicalCardAddress, setPhysicalCardAddress] =
    useState<PhysicalCardAddressSchema>()

  const [activeStep, setActiveStep] = useState<FormState>({
    type: FormStep.Type,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case FormStep.Type:
        return 25
      case FormStep.VirtualCardDetails:
        return 75
      case FormStep.PhysicalCardDetails:
        return 50
      case FormStep.Address:
        return 75
      case FormStep.Review:
        return 100
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Cards}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === FormStep.Type ? (
          <CardTypeStep
            onContinue={(cardType) => {
              switch (cardType) {
                case CardType.VIRTUAL:
                  setActiveStep({ type: FormStep.VirtualCardDetails })
                  break

                case CardType.PHYSICAL:
                  setActiveStep({ type: FormStep.PhysicalCardDetails })
                  break

                default:
                  break
              }
            }}
          />
        ) : null}

        {activeStep.type === FormStep.VirtualCardDetails ? (
          <CreateVirtualCardStep
            onBack={() => setActiveStep({ type: FormStep.Type })}
          />
        ) : null}

        {activeStep.type === FormStep.PhysicalCardDetails ? (
          <CreatePhysicalCardStep
            physicalCardDetails={physicalCardDetails}
            onBack={() => {
              setPhysicalCardDetails(undefined)
              setActiveStep({ type: FormStep.Type })
            }}
            onContinue={(cardDetails) => {
              setPhysicalCardDetails(cardDetails)
              setActiveStep({ type: FormStep.Address })
            }}
          />
        ) : null}

        {activeStep.type === FormStep.Address ? (
          <CardAddressStep
            physicalCardAddress={physicalCardAddress}
            onBack={() => {
              setPhysicalCardAddress(undefined)
              setActiveStep({ type: FormStep.PhysicalCardDetails })
            }}
            onContinue={(cardAddress) => {
              setPhysicalCardAddress(cardAddress)
              setActiveStep({ type: FormStep.Review })
            }}
          />
        ) : null}
        {activeStep.type === FormStep.Review ? (
          <CardReviewStep
            physicalCardDetails={physicalCardDetails}
            physicalCardAddress={physicalCardAddress}
            onBack={() => setActiveStep({ type: FormStep.Address })}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
