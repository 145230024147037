import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { Transaction } from '@/domains/Business/features/Transactions/types'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import {
  AmountCell,
  CurrencyCell,
  DateCell,
  MethodCell,
  StateCell,
  TransactionCell,
} from '@/shared/components'
import { DataTableColumnHeader } from '@/shared/ui'

export const useAccountsTransactionColumns = (): ColumnDef<Transaction>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Transaction>[] = [
    {
      accessorKey: 'name',
      cell: ({ row }) => <TransactionCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.description',
            defaultMessage: 'Description',
          })}
        />
      ),
    },
    {
      accessorKey: 'baseAmount',
      cell: ({ row }) => <AmountCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.amount',
            defaultMessage: 'Amount',
          })}
        />
      ),
    },
  ]

  const desktopColumns: ColumnDef<Transaction>[] = [
    {
      accessorKey: 'name',
      cell: ({ row }) => <TransactionCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.description',
            defaultMessage: 'Description',
          })}
        />
      ),
    },
    {
      accessorKey: 'transactionType',
      cell: ({ row }) => <MethodCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.method',
            defaultMessage: 'Method',
          })}
        />
      ),
      size: 140,
    },
    {
      accessorKey: 'createdOn',
      cell: ({ row }) => <DateCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.date',
            defaultMessage: 'Date',
          })}
        />
      ),
      size: 140,
    },
    {
      accessorKey: 'transactionStatus',
      cell: ({ row }) => <StateCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.status',
            defaultMessage: 'Status',
          })}
        />
      ),
      size: 140,
    },
    {
      accessorKey: 'localCurrency',
      cell: ({ row }) => <CurrencyCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.currency',
            defaultMessage: 'Currency',
          })}
        />
      ),
      size: 120,
    },
    {
      accessorKey: 'baseAmount',
      cell: ({ row }) => <AmountCell transaction={row.original} />,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={intl.formatMessage({
            id: 'label.amount',
            defaultMessage: 'Amount',
          })}
        />
      ),
      size: 120,
    },
  ]

  return isMobile ? mobileColumns : desktopColumns
}
