import { Currency } from '@/constants/currency'
import { PaymentLimitType } from '@/domains/Business/types'

export enum CardCategory {
  GROCERIES = 'GROCERIES',
  SHOPPING = 'SHOPPING',
  RESTAURANTS = 'RESTAURANTS',
  TRANSPORT = 'TRANSPORT',
  TRAVEL = 'TRAVEL',
  ENTERTAINMENT = 'ENTERTAINMENT',
  UTILITIES = 'UTILITIES',
  HEALTH = 'HEALTH',
  SERVICES = 'SERVICES',
  FINANCE_AND_INVESTMENTS = 'FINANCE_AND_INVESTMENTS',
  OTHER = 'OTHER',
}

export type CardLimit = {
  limitAmount: number
  limitCurrency: Currency
  limitType: PaymentLimitType
  periodResetDate: string
  usedAmount: number
}

export enum CardState {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  TERMINATED = 'TERMINATED',
}

export enum CardType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

export type Card = {
  businessId: string
  createdAt: string
  id: string
  identityId: string
  lastFourDigits?: string | null
  nickname: string
  printedUserName: string
  state: CardState
  type: CardType
  validDate?: string | null
  validToMMYY?: string | null
  walletId: string
  cardLimit?: CardLimit
  cardDeliveryDetails?: CardDeliveryDetails
}

export type UnmaskedCard = Card & {
  cvv2: string
  cardNumber: string
}

export enum CardDeliveryState {
  CREATED = 'CREATED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
}

export type CardDeliveryAddress = {
  street: string
  streetNumber: string
  apartment?: string
  references?: string
  neighborhood: string
  city: string
  state: string
  country: string
  postcode: string
}

export type CardDeliveryDetails = {
  id: '7e6c7550-301d-4b91-9519-945ad04bf46b'
  state: CardDeliveryState
  address: CardDeliveryAddress
  internationalPhonePrefix?: string
  localPhoneNumber?: string
  createdAt: string
  updatedAt: string
}

export type CardDelivery = {
  id: string
  cardId: string
  street: string
  streetNumber: string
  neighborhood: string
  city: string
  country: string
  postcode: string
  state: string
  status: CardDeliveryState
  createdAt: string
  shippedAt?: string
  deliveredAt?: string
  updatedAt: string
  courierCompany: string
  courierTrackingId: string
  courierLocation: string
  courierLocationType: string
  courierType: string
  externalCourierTrackingId: string
  externalCourierTrackingUrl: string
}
