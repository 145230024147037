import { Table } from '@tanstack/react-table'

import { cn } from '@/lib/utils'

import { Checkbox, Skeleton, TableCell, TableRow } from '.'

type Props<TData> = {
  table: Table<TData>
  rows?: number
  selectable?: boolean
}

export const TableLoader = <TData,>({
  selectable,
  table,
  rows = 4,
}: Props<TData>) => {
  const columns = table.getAllColumns().length
  const columnSizes = table.getAllColumns().map((c) => c.columnDef.size)

  return Array.from({ length: Number(rows) }).map((_, rowIndex) => (
    <TableRow key={rowIndex}>
      {Array.from({ length: Number(columns) }).map((_, columnIndex) => {
        const firstItem = columnIndex === 0

        if (selectable && firstItem) {
          return (
            <TableCell key={columnIndex}>
              <Checkbox onClick={(e) => e.stopPropagation()} checked={false} />
            </TableCell>
          )
        }

        return (
          <TableCell
            style={{
              width:
                columnSizes[columnIndex] === 150
                  ? 'auto'
                  : columnSizes[columnIndex],
            }}
            key={columnIndex}
          >
            <div className="flex items-center gap-3">
              <Skeleton
                className={cn('h-8 w-[80%]', {
                  'h-8 w-1/2': firstItem,
                })}
              />
            </div>
          </TableCell>
        )
      })}
    </TableRow>
  ))
}
