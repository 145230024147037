import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { scopeOfWorkMessages } from '@/constants/messages'
import { cn } from '@/lib/utils'
import { Check, ChevronDown } from '@/shared/icons/outline'
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from '@/shared/ui'

const SCOPE_OF_WORK = ['SOFTWARE_ENGINEER', 'DESIGNER', 'PRODUCT_MANAGER']

type Props = {
  onSelect: (value: string) => void
  placeholder?: string
  value?: string
}

export const ScopeOfWorkField = ({ onSelect, value }: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const selected = useMemo(
    () => SCOPE_OF_WORK?.find((scope) => scope === value),
    [value],
  )

  return (
    <FormItem className="flex flex-col">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              width="full"
              type="button"
              variant="outline"
              role="combobox"
              className={cn(
                'h-auto min-h-[54px] justify-between rounded-lg px-4 font-normal hover:bg-white md:h-[54px]',
              )}
            >
              {selected ? (
                <div className="flex flex-col items-start">
                  <Typography
                    className="text-neutral-gray-600"
                    variant="body-tiny"
                  >
                    <FormattedMessage
                      id="label.scopeOfWorkOptional"
                      defaultMessage="Scope of work template (Optional)"
                    />
                  </Typography>
                  <Typography className="whitespace-break-spaces">
                    <FormattedMessage {...scopeOfWorkMessages[selected]} />
                  </Typography>
                </div>
              ) : (
                <Typography className="text-neutral-gray-600">
                  <FormattedMessage
                    id="label.scopeOfWorkOptional"
                    defaultMessage="Scope of work template (Optional)"
                  />
                </Typography>
              )}

              <ChevronDown className="shrink-0 text-neutral-gray-600" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-full p-0 md:w-[600px]">
          <Command
            filter={(value, search) => {
              const searchValue = search.toLowerCase().trim()

              const originalValue = value.toLocaleUpperCase()

              const category = scopeOfWorkMessages[originalValue]

              if (!category) {
                return 0
              }

              const intlValue = intl.formatMessage(
                scopeOfWorkMessages[originalValue],
              )

              if (
                intlValue
                  .toLowerCase()
                  .includes(searchValue.trim().toLowerCase())
              ) {
                return 1
              }

              return 0
            }}
          >
            <CommandList>
              <CommandInput
                placeholder={intl.formatMessage({
                  id: 'placeholder.searchScopeOfWork',
                  defaultMessage: 'Search scope of work',
                })}
              />
              <CommandEmpty>
                <FormattedMessage
                  id="placeholder.search.noScopeOfWork"
                  defaultMessage="No scope of work found"
                />
              </CommandEmpty>
              <CommandGroup>
                {SCOPE_OF_WORK?.map((scope) => (
                  <CommandItem
                    value={scope}
                    key={scope}
                    onSelect={() => {
                      onSelect(scope)
                      setOpen((open) => !open)
                    }}
                  >
                    <Typography className="whitespace-break-spaces">
                      <FormattedMessage {...scopeOfWorkMessages[scope]} />
                    </Typography>

                    <Check
                      className={cn(
                        'ml-2 h-4 w-4',
                        scope === value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
  )
}
