import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { BusinessRoute, NavigationLink } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getTasksCount } from '@/domains/Business/features/Tasks'
import { Feature, useBusinessRole, useFeatures } from '@/domains/Business/hooks'
import { Playlist } from '@/shared/icons/outline'
import {
  Bank,
  Card,
  FileUser,
  Home,
  Navigation,
  SquareCheck,
  Users,
} from '@/shared/icons/solid'

export function useNavigationLinks(): NavigationLink[] {
  const { isAdmin } = useBusinessRole()

  const tasksCountQuery = useQuery({
    queryKey: [queryKeys.getTasksCount],
    queryFn: getTasksCount,
    select: (data) => data.data.pendingTasksCount,
    enabled: isAdmin,
  })

  const [
    homeEnabled,
    tasksEnabled,
    accountsEnabled,
    cardsEnabled,
    recipientsEnabled,
    transactionsEnabled,
    teamEnabled,
    contractorsEnabled,
  ] = useFeatures([
    Feature.HOME,
    Feature.TASKS,
    Feature.ACCOUNTS,
    Feature.CARDS,
    Feature.RECIPIENTS,
    Feature.TRANSACTIONS,
    Feature.TEAM,
    Feature.CONTRACTORS,
  ])

  return useMemo(
    () => [
      {
        key: 'home',
        icon: Home,
        path: BusinessRoute.Dashboard,
        enabled: homeEnabled,
      },
      {
        key: 'tasks',
        icon: SquareCheck,
        path: BusinessRoute.Tasks,
        enabled: tasksEnabled,
        showDot: !!(tasksCountQuery.data && tasksCountQuery.data > 0),
      },
      {
        key: 'accounts',
        icon: Bank,
        path: BusinessRoute.Accounts,
        enabled: accountsEnabled,
      },
      {
        key: 'cards',
        icon: Card,
        path: BusinessRoute.Cards,
        enabled: cardsEnabled,
      },
      {
        key: 'contractors',
        icon: FileUser,
        path: BusinessRoute.Contractors,
        enabled: contractorsEnabled,
      },
      {
        key: 'recipients',
        icon: Navigation,
        path: BusinessRoute.Recipients,
        enabled: recipientsEnabled,
      },
      {
        key: 'transactions',
        icon: Playlist,
        path: BusinessRoute.Transactions,
        enabled: transactionsEnabled,
      },
      {
        key: 'team',
        icon: Users,
        path: BusinessRoute.Team,
        enabled: teamEnabled,
      },
    ],
    [
      accountsEnabled,
      cardsEnabled,
      contractorsEnabled,
      homeEnabled,
      recipientsEnabled,
      tasksCountQuery.data,
      tasksEnabled,
      teamEnabled,
      transactionsEnabled,
    ],
  )
}
