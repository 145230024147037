import { forwardRef, Ref, type SVGProps } from 'react'

const SvgMobile = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 11 16"
    role="img"
    ref={ref}
    {...props}
  >
    <rect
      width={9}
      height={13.5}
      x={1}
      y={1.25}
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.125}
      rx={2.25}
    />
    <path
      fill={props.color || `currentColor`}
      d="M5.5 12.875a.373.373 0 0 1-.375-.375.374.374 0 0 1 .64-.265.374.374 0 0 1-.265.64"
    />
    <path
      fill={props.color || `currentColor`}
      d="M5.5 13.25a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgMobile)
export default ForwardRef
