import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getBusinessIdentity } from '@/domains/Business/api'
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { getBusinessAddress } from '@/lib/address'
import { getCountryNameByISO3 } from '@/lib/country'
import { Widget } from '@/shared/components'
import { Button, Details } from '@/shared/ui'

export const CardBillingAddress = () => {
  const intl = useIntl()
  const copyToClipboard = useCopyToClipboard()

  const businessQuery = useQuery({
    queryKey: [queryKeys.getBusinessIdentity],
    queryFn: getBusinessIdentity,
    select: (data) => data.data,
  })

  const fullBusinessAddress = getBusinessAddress(businessQuery.data)

  return (
    <Widget
      title={
        <FormattedMessage
          id="card.sidebar.billingAddress"
          defaultMessage="Billing address"
        />
      }
    >
      <Details>
        <Details.Label>
          <FormattedMessage
            id="label.streetAddress"
            defaultMessage="Street address"
          />
        </Details.Label>

        <Details.Value copyable>
          {businessQuery.data?.addressStreet +
            ' ' +
            businessQuery.data?.addressStreetNumber}
        </Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage
            id="label.neighborhoodOrDistrict"
            defaultMessage="Neighborhood or district"
          />
        </Details.Label>

        <Details.Value copyable>
          {businessQuery.data?.addressNeighborhood}
        </Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage id="label.city" defaultMessage="City" />
        </Details.Label>

        <Details.Value copyable>
          {businessQuery.data?.addressCity}
        </Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="State or province"
            id="label.stateOrProvince"
          />
        </Details.Label>

        <Details.Value copyable>
          {businessQuery.data?.addressState}
        </Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Post code" id="label.postCode" />
        </Details.Label>

        <Details.Value copyable>
          {businessQuery.data?.addressPostalCode}
        </Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Country" id="label.country" />
        </Details.Label>

        <Details.Value copyable>
          {getCountryNameByISO3(
            businessQuery.data?.countryOfIncorporation,
            intl.locale,
          )}
        </Details.Value>
      </Details>

      <Button
        onClick={() => copyToClipboard(fullBusinessAddress ?? '')}
        size="inline"
        width="full"
        className="font-semibold"
        variant="link"
      >
        <FormattedMessage
          defaultMessage="Copy full address"
          id="action.copyFullAddress"
        />
      </Button>
    </Widget>
  )
}
