import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { useErrorToast } from '@/hooks/useErrorToast'
import { Button, Card, MotionDiv, Typography } from '@/shared/ui'

import { sendTeamMemberInviteReminder } from '../../features/Team/api'
import { TeamMember, TeamState } from '../../features/Team/types'

type Props = {
  member?: TeamMember
}

export const TeamMemberInviteSection = ({ member }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: sendTeamMemberInviteReminder,
  })

  if (member?.state !== TeamState.INVITED) {
    return null
  }

  const onSendReminder = async () => {
    try {
      await mutateAsync({
        id: member.id,
      })

      toast.success(
        intl.formatMessage({
          id: 'team.member.reminder.success',
          defaultMessage: 'Invitation reminder sent successfully',
        }),
      )
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <MotionDiv>
      <Card size="upload" className="flex items-center justify-between">
        <div className="flex flex-col">
          <Typography>
            <FormattedMessage
              id="team.details.invite.title"
              defaultMessage="{name} has been invited to join the team"
              values={{ name: member?.firstName }}
            />
          </Typography>
          <Typography
            className="pr-4 text-neutral-gray-600"
            variant="body-small"
          >
            <FormattedMessage
              id="team.details.invite.description"
              defaultMessage="Send them a friendly reminder via email to set up their account"
            />
          </Typography>
        </div>

        <Button
          disabled={isPending}
          loading={isPending}
          onClick={onSendReminder}
          size="xs"
        >
          <FormattedMessage id="action.remind" defaultMessage="Remind" />
        </Button>
      </Card>
    </MotionDiv>
  )
}
