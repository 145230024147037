export enum PaymentMethod {
  WIRE = 'WIRE',
  ACH = 'ACH',
}

export const SPEI = 'SPEI'

export const ACH_FEE = 3
export const WIRE_FEE = 10
export const PHYSICAL_CARD_FEE = 5
