import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@/shared/ui'

import { MoveMoneyWidget } from '../../components'

enum Tab {
  OVERVIEW = 'overview',
  ALL_CONTRACTORS = 'all-contractors',
  HISTORICAL_PAYMENTS = 'historical-payments',
}

export const Contractors = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const defaultTab = useMemo(() => {
    if (pathname.includes(BusinessRoute.ContractorsOverview)) {
      return Tab.OVERVIEW
    }

    if (pathname.includes(BusinessRoute.ContractorsHistoricalPayments)) {
      return Tab.HISTORICAL_PAYMENTS
    }

    if (pathname.includes(BusinessRoute.ContractorsAll)) {
      return Tab.ALL_CONTRACTORS
    }

    return Tab.OVERVIEW
  }, [pathname])

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-start justify-between">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.contractors.title"
              defaultMessage="Contractors"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.contractors.subtitle"
              defaultMessage="Pay and manage your company contractors"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <Tabs
        onValueChange={(tab) => {
          switch (tab) {
            case Tab.OVERVIEW:
              navigate(BusinessRoute.ContractorsOverview)
              break
            case Tab.ALL_CONTRACTORS:
              navigate(BusinessRoute.ContractorsAll)
              break
            case Tab.HISTORICAL_PAYMENTS:
              navigate(BusinessRoute.ContractorsHistoricalPayments)
              break
          }
        }}
        defaultValue={defaultTab}
      >
        <TabsList variant="navigation" className="mb-8 gap-4">
          <TabsTrigger size="inline" variant="navigation" value={Tab.OVERVIEW}>
            <FormattedMessage
              id="contractors.tabs.overview"
              defaultMessage="Overview"
            />
          </TabsTrigger>
          <TabsTrigger
            size="inline"
            variant="navigation"
            value={Tab.ALL_CONTRACTORS}
          >
            <FormattedMessage
              id="contractors.tabs.allContractors"
              defaultMessage="All Contractors"
            />
          </TabsTrigger>
          <TabsTrigger
            size="inline"
            variant="navigation"
            value={Tab.HISTORICAL_PAYMENTS}
          >
            <FormattedMessage
              id="contractors.tabs.historicalPayments"
              defaultMessage="Historical Payments"
            />
          </TabsTrigger>
        </TabsList>

        <TabsContent value={Tab.OVERVIEW}>
          <Outlet />
        </TabsContent>
        <TabsContent value={Tab.ALL_CONTRACTORS}>
          <Outlet />
        </TabsContent>
        <TabsContent value={Tab.HISTORICAL_PAYMENTS}>
          <Outlet />
        </TabsContent>
      </Tabs>
    </div>
  )
}
