import { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { calculateProgress, formatAmount, formatMoney } from '@/lib/money'
import { cn } from '@/lib/utils'
import { Widget } from '@/shared/components'
import { MotionDiv, Progress, Typography } from '@/shared/ui'

import {
  getPaymentLimit,
  getTeamMemberPaymentLimit,
} from '../../features/Team/api'
import {
  TeamMember,
  TeamMemberPaymentLimit,
  TeamState,
} from '../../features/Team/types'
import { useBusinessRole } from '../../hooks'

type Props = {
  member?: TeamMember
}

export const TeamMoneyLimit = ({ member }: Props) => {
  const isBlockedOrTerminated =
    member?.state === TeamState.BLOCKED ||
    member?.state === TeamState.TERMINATED

  const { isAdmin } = useBusinessRole()
  const [teamMemberPaymentLimitQuery, userPaymentLimitQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTeamMemberPaymentLimit, member?.id],
        queryFn: () => getTeamMemberPaymentLimit({ id: member?.id ?? '' }),
        select: (data: AxiosResponse<TeamMemberPaymentLimit>) => data.data,
        enabled: isAdmin && !!member?.id,
      },
      {
        queryKey: [queryKeys.getPaymentLimit],
        queryFn: () => getPaymentLimit(),
        select: (data: AxiosResponse<TeamMemberPaymentLimit>) => data.data,
        enabled: !isAdmin && !!member?.id,
      },
    ],
  })

  const userPaymentLimit = useMemo(() => {
    if (teamMemberPaymentLimitQuery.data) {
      return teamMemberPaymentLimitQuery.data
    }

    if (userPaymentLimitQuery.data) {
      return userPaymentLimitQuery.data
    }

    return null
  }, [teamMemberPaymentLimitQuery.data, userPaymentLimitQuery.data])

  if (!userPaymentLimit?.periodTransferLimit || !member) {
    return null
  }

  return (
    <MotionDiv className="flex flex-col">
      <Widget
        title={
          <FormattedMessage
            id="team.details.moneyMovementLimit"
            defaultMessage="USDc money movement limit"
          />
        }
        className={cn(
          'flex flex-col gap-1',
          isBlockedOrTerminated && 'pointer-events-none bg-neutral-gray-200',
        )}
      >
        <div className="flex justify-between">
          <Typography className="font-semibold">
            {formatMoney(userPaymentLimit.periodTransferLimit.usedAmount)}
          </Typography>
          <Typography className="font-semibold">
            {formatMoney(userPaymentLimit.periodTransferLimit.limitAmount)} /{' '}
            <FormattedMessage
              id="card.table.limit.duration"
              defaultMessage="{limitType, select, DAILY {Day} WEEKLY {Week} MONTHLY {Month} YEARLY {Year} other {}}"
              values={{
                limitType: userPaymentLimit.periodTransferLimit.limitType,
              }}
            />
          </Typography>
        </div>
        <Progress
          value={calculateProgress({
            value: userPaymentLimit.periodTransferLimit.usedAmount,
            total: userPaymentLimit.periodTransferLimit.limitAmount,
          })}
        />

        <div className="flex justify-between">
          {userPaymentLimit.singleTransferLimit ? (
            <Typography className="text-neutral-gray-600" variant="body-small">
              <FormattedMessage
                id="team.moneyLimit.singleTransactionLimit"
                defaultMessage="Single transaction limit: {amount}"
                values={{
                  amount: formatAmount({
                    amount: userPaymentLimit.singleTransferLimit?.limitAmount,
                    currency:
                      userPaymentLimit.singleTransferLimit?.limitCurrency,
                  }),
                }}
              />
            </Typography>
          ) : null}
          <Typography
            className="text-neutral-gray-600"
            variant="body-small"
            text="right"
          >
            <FormattedMessage
              id="card.limit.resetDate"
              defaultMessage="Limit resets on {resetDate}"
              values={{
                resetDate: (
                  <FormattedDate
                    value={
                      new Date(
                        userPaymentLimit.periodTransferLimit.periodResetDate,
                      )
                    }
                    year="numeric"
                    month="long"
                    day="2-digit"
                    hour="2-digit"
                    minute="2-digit"
                  />
                ),
              }}
            />
          </Typography>
        </div>
      </Widget>
    </MotionDiv>
  )
}
