import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  ContractorPaymentMethodType,
  DolarTagDetails,
} from '@/domains/Contractor/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { parseFullPhoneNumber } from '@/lib/phone'
import { queryClient } from '@/lib/queryClient'
import { CountryCodeField, GoBackButton } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { submitPaymentDetails, validateDolarTag } from '../api'
import { ContractorOnboardingState } from '../types'

import { VerifiedDialog } from './VerifiedDialog'

const DOLAR_TAG_FORM_ID = 'dolar-tag-form-id'
const DOLAR_TAG_LENGTH_MIN = 5
const DOLAR_TAG_LENGTH_MAX = 26

const dolarTagSchema = z.object({
  dolarTag: z
    .string()
    .min(DOLAR_TAG_LENGTH_MIN, { message: 'validation.dolarTag.minLength' })
    .max(DOLAR_TAG_LENGTH_MAX, { message: 'validation.dolarTag.maxLength' })
    .refine(
      (v) => {
        return v.startsWith('$')
      },
      { message: 'validation.dolarTag.startsWith' },
    ),
  internationalPhonePrefix: z.string().min(1, {
    message: 'validation.phoneCode.required',
  }),
  localPhoneNumber: z
    .string()
    .min(1, { message: 'validation.phoneNumber.required' })
    .refine(isValidPhoneNumber, { message: 'validation.phoneNumber.invalid' }),
})

type DolarTagSchema = z.infer<typeof dolarTagSchema>

type Props = {
  onBack: () => void
  contractorState?: ContractorOnboardingState
}

export const AddDolarTagScreen = ({ onBack, contractorState }: Props) => {
  const { step } = useParams<{ step: string }>()
  const navigate = useNavigate()
  const intl = useIntl()
  const notifyError = useErrorToast()
  const [isDolarTagValid, setIsDolarTagValid] = useState(false)

  const paymentDetails =
    contractorState?.paymentMethodDetails as DolarTagDetails

  const form = useForm<DolarTagSchema>({
    mode: 'onChange',
    resolver: zodResolver(dolarTagSchema),
    values: {
      dolarTag: paymentDetails?.paymentMethodInformation.dolarTag ?? '',
      internationalPhonePrefix: '+52',
      localPhoneNumber: '',
    },
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: validateDolarTag,
  })

  const {
    mutateAsync: submitPaymentDetailsMutate,
    isPending: isSubmitPaymentDetailsPending,
  } = useMutation({
    mutationFn: submitPaymentDetails,
  })

  const onContinue = async (data: DolarTagSchema) => {
    if (!contractorState) {
      return
    }

    await submitPaymentDetailsMutate({
      type: ContractorPaymentMethodType.DOLAR_TAG,
      paymentMethodDetails: {
        ...data,
        localPhoneNumber: parseFullPhoneNumber(data),
      },
    })

    const currentStepIndex = contractorState.steps.findIndex(
      (s) => s.name === step,
    )

    const nextStep = contractorState?.steps[currentStepIndex + 1]

    await queryClient.invalidateQueries({
      queryKey: [queryKeys.getContractorOnboardingState],
    })

    navigate(
      generatePath(ContractorRoute.OnboardingStep, {
        step: nextStep.name,
      }),
    )
  }

  const onSubmit: SubmitHandler<DolarTagSchema> = async (data) => {
    try {
      await mutateAsync({
        ...data,
        localPhoneNumber: parseFullPhoneNumber(data),
      })

      setIsDolarTagValid(true)
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.onboarding.dolarTag.title"
            defaultMessage="Link your DolarApp account using the DolarTag"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.dolarTag.subtitle"
            defaultMessage="Introduce your DolarApp unique identifier, DolarTag, below to link your personal account"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.dolarTag.info"
            defaultMessage="DolarApp allows you to instantly get paid by your employer, and to use your salary however you want"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={DOLAR_TAG_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3">
              <FormField
                control={form.control}
                name="dolarTag"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'label.dolarTag',
                          defaultMessage: 'DolarTag',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="DolarTag"
                        id="label.dolarTag"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <div className="flex gap-3">
                <FormField
                  control={form.control}
                  name="internationalPhonePrefix"
                  render={({ field }) => {
                    return (
                      <CountryCodeField
                        value={field.value}
                        onSelect={(value) => {
                          form.setValue('internationalPhonePrefix', value)
                        }}
                      />
                    )
                  }}
                />

                <FormField
                  control={form.control}
                  name="localPhoneNumber"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        <PhoneNumberInput
                          phonePrefix={form.watch('internationalPhonePrefix')}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Phone number',
                            id: 'label.phoneNumber',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Phone number"
                          id="label.phoneNumber"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            form={DOLAR_TAG_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            loading={isSubmitPaymentDetailsPending || isPending}
            disabled={
              !form.formState.isValid ||
              isSubmitPaymentDetailsPending ||
              isPending
            }
            type="submit"
            width="full"
          >
            <FormattedMessage
              id="action.linkAccount"
              defaultMessage="Link account"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>

      <VerifiedDialog
        isOpen={isDolarTagValid}
        isPending={isSubmitPaymentDetailsPending}
        onContinue={() => onContinue(form.getValues())}
        onOpenChange={setIsDolarTagValid}
      />
    </>
  )
}
