import { useIntl } from 'react-intl'

import { Typography } from '@/shared/ui'

import { Contractor } from '../../../types'

type Props = {
  contractor: Contractor
}

export const ContractorStartDateCell = ({ contractor }: Props) => {
  const intl = useIntl()

  return (
    <div className="flex items-center">
      <Typography>
        {intl.formatDate(contractor.startDate, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          timeZone: 'UTC',
        })}
      </Typography>
    </div>
  )
}
