import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { useGetBankName } from '@/domains/Business/hooks'
import { isValidClabe } from '@/lib/validations'
import { BankName, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

type MXPaymentFieldsFormPart = {
  clabe: string
}

type Props<T extends MXPaymentFieldsFormPart> = {
  layout?: 'create' | 'edit'
  form: T extends MXPaymentFieldsFormPart ? UseFormReturn<T> : never
}

export const MXPaymentFields = <T extends MXPaymentFieldsFormPart>({
  layout,
  form,
}: Props<T>) => {
  const intl = useIntl()

  const { name, isPending } = useGetBankName({
    country: CountryCode.MX,
    bankReference: form.watch('clabe'),
    enabled: layout === 'create' && isValidClabe(form.watch('clabe')),
  })

  return (
    <Widget
      title={
        <FormattedMessage
          id="recipient.paymentDetails"
          defaultMessage="Payment details"
        />
      }
      variant="form"
    >
      <FormField
        control={form.control}
        name="clabe"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                disabled={layout === 'edit'}
                placeholder={intl.formatMessage({
                  id: 'label.clabe',
                  defaultMessage: 'CLABE',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
            </AnimatedFormLabel>

            <BankName name={name} isPending={isPending} />
          </FormItem>
        )}
      />
    </Widget>
  )
}
