import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { QRCode } from 'react-qrcode-logo'
import { generatePath, useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { getImage } from '@/lib/images'
import { Link } from '@/shared/icons/outline'
import { Button, Card, SlideInScreen, Typography } from '@/shared/ui'

import { OnboardingStepName } from '../types'

type Props = {
  link: string
}

export const QRScreen = ({ link }: Props) => {
  const navigate = useNavigate()

  const copyTextToClipboard = useCopyToClipboard()

  useEffect(() => {
    if (!link) {
      navigate(
        generatePath(BusinessRoute.OnboardingStep, {
          step: OnboardingStepName.IDENTITY_VALIDATION,
        }),
      )
    }
  }, [link, navigate])

  return (
    <SlideInScreen className="h-screen">
      <Typography variant="h3" text="center">
        <FormattedMessage
          defaultMessage="Validate your identity"
          id="onboarding.identityValidationScreen.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="Scan the QR code or copy the link to do your identity validation"
          id="onboarding.identityValidationScreen.subtitle"
        />
      </Typography>

      <div className="p-6" />

      <div className="flex flex-col items-center gap-5">
        <Card className="w-fit rounded-2xl p-3">
          <QRCode
            eyeRadius={[
              [8, 8, 0, 8],
              [8, 8, 8, 0],
              [8, 0, 8, 8],
            ]}
            logoImage={getImage({ name: 'dolarapp-logo-black' })}
            logoWidth={75}
            logoPaddingStyle="square"
            removeQrCodeBehindLogo
            qrStyle="dots"
            quietZone={0}
            size={300}
            value={link}
          />
        </Card>

        <div className="flex h-10 gap-3">
          <Card
            size="upload"
            className="flex max-w-52 items-center bg-neutral-gray-200"
          >
            <Typography className="truncate">{link}</Typography>
          </Card>
          <Button
            leftIcon={<Link className="size-5" />}
            size="sm"
            onClick={() => copyTextToClipboard(link)}
          >
            <FormattedMessage id="action.copyLink" defaultMessage="Copy link" />
          </Button>
        </div>
      </div>
    </SlideInScreen>
  )
}
