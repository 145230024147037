import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { ProgressButton } from '@/shared/components'

import { SignInForm } from './components/SignInForm'

export const SignIn = () => {
  const [from] = useSearchParamsValue(['from'])

  return (
    <>
      {from ? (
        <ProgressButton className="hidden md:flex" progress={50} to={from} />
      ) : null}

      <SignInForm />
    </>
  )
}
