import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

import { TeamMember } from '../types'

type DeleteTeamMemberRequest = {
  id: string
} & OTPCode

export function deleteTeamMember({ id, otp }: DeleteTeamMemberRequest) {
  return api.post<TeamMember>(`/v1/me/business-users/${id}/terminate`, { otp })
}
