import { formatAmount } from '@/lib/money'
import { AccountIcon } from '@/shared/components'
import { Typography } from '@/shared/ui'

import { Wallet } from '../features/Accounts/types'

type Props = {
  accountIconVariant?: 'large' | 'middle'
  balance?: boolean
  boldText?: boolean
  wallet: Wallet
}

export const WalletItem = ({
  accountIconVariant = 'middle',
  balance = true,
  boldText = false,
  wallet,
}: Props) => {
  return (
    <div className="flex items-center gap-2">
      <AccountIcon variant={accountIconVariant} id={wallet.id} />

      <div className="flex gap-1">
        <Typography className="text-neutral-gray-900" bold={boldText}>
          {wallet.label}
        </Typography>

        {balance ? (
          <>
            <Typography className="text-neutral-gray-900" bold={boldText}>
              •
            </Typography>
            <Typography className="text-neutral-gray-900" bold={boldText}>
              {formatAmount({
                amount: wallet.balance,
                currency: wallet.currency,
              })}
            </Typography>
          </>
        ) : null}
      </div>
    </div>
  )
}
