import { FormattedMessage } from 'react-intl'

import { ContractState } from '@/domains/Contractor/types'
import { StatusBadge, StatusVariant } from '@/shared/components'

function getBadgeVariant(state: ContractState, variant: 'sidebar' | 'default') {
  return {
    [ContractState.ACTION_NEEDED]: 'warning',
    [ContractState.WAITING_FOR_CONTRACTOR]: 'neutral',
    [ContractState.ACTIVE]: 'active',
    [ContractState.PAUSED]: variant === 'sidebar' ? 'neutral-dark' : 'neutral',
    [ContractState.TERMINATED]: 'failed',
    [ContractState.TERMINATION_PENDING]: 'warning',
  }[state] as StatusVariant
}

type Props = {
  state?: ContractState
  variant?: 'sidebar' | 'default'
}

export const ContractStateBadge = ({ state, variant = 'default' }: Props) => {
  if (!state) {
    return null
  }

  const badgeVariant = getBadgeVariant(state, variant)

  return (
    <StatusBadge
      variant={badgeVariant}
      title={
        <FormattedMessage
          id="contract.state.label"
          defaultMessage="{state, select, ACTION_NEEDED {Action needed} WAITING_FOR_CONTRACTOR {Waiting for contractor} ACTIVE {Active} PAUSED {Paused} TERMINATED {Terminated} TERMINATION_PENDING {Termination pending} other {}}"
          values={{ state }}
        />
      }
    />
  )
}
