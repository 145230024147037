import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { DataTableColumnHeader } from '@/shared/ui'

import { Card } from '../../features/Cards/types'

import { CardCell } from './CardCell'
import { CardNicknameCell } from './CardNicknameCell'
import { CardStateCell } from './CardStateCell'

export const useTeamCardsColumns = (): ColumnDef<Card>[] => {
  const intl = useIntl()

  const desktopColumns: ColumnDef<Card>[] = useMemo(
    () => [
      {
        accessorKey: 'nickname',
        cell: ({ row }) => <CardNicknameCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.nickname',
              defaultMessage: 'Nickname',
            })}
          />
        ),
      },
      {
        accessorKey: 'lastFourDigits',
        cell: ({ row }) => <CardCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.card',
              defaultMessage: 'Card',
            })}
          />
        ),
        size: 120,
      },
      {
        accessorKey: 'state',
        cell: ({ row }) => <CardStateCell card={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
        size: 100,
      },
    ],
    [intl],
  )

  return desktopColumns
}
