import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Link, useLocation } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { WithPermissions } from '@/domains/Business/components'
import { useBusinessRole } from '@/domains/Business/hooks'
import { Permission } from '@/domains/Business/types'
import { getRecipientAddress } from '@/lib/address'
import { CountryWithFlag, Widget } from '@/shared/components'
import { Pencil } from '@/shared/icons/outline'
import {
  Button,
  Details,
  Skeleton,
  SlideInLeft,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { Recipient } from '../types'
import { getRecipientTitle } from '../utils'

import { PaymentDetails } from './PaymentDetails'
import { ScreenType } from './RecipientDetailsSidebar'

type Props = {
  recipient?: Recipient
  setScreen: (screen: ScreenType) => void
}

export const BaseRecipientScreen = ({ setScreen, recipient }: Props) => {
  const location = useLocation()
  const intl = useIntl()
  const { isAdmin } = useBusinessRole()

  return (
    <SlideInLeft className="flex h-full flex-col">
      {recipient ? (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col">
            <Typography variant="h3">{recipient.nickname}</Typography>
            <Typography className="text-neutral-gray-600">
              {getRecipientTitle(recipient)}
            </Typography>
          </div>

          {isAdmin && (
            <Button
              leftIcon={<Pencil className="size-4" />}
              onClick={() => setScreen('edit')}
              variant="tertiary"
              size="icon"
              aria-label={intl.formatMessage({
                id: 'recipients.details.edit',
                defaultMessage: 'Edit recipient',
              })}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          <Skeleton className="h-[33px] w-36" />
          <Skeleton className="h-[18px] w-36" />
        </div>
      )}

      <div className="p-4" />

      <div className="flex flex-col gap-8">
        <Widget
          title={
            <FormattedMessage
              id="recipient.contactDetails"
              defaultMessage="Contact details"
            />
          }
        >
          {recipient ? (
            <>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="recipient.recipientCountry"
                    defaultMessage="Recipient country"
                  />
                </Details.Label>
                <CountryWithFlag country={recipient.country} />
              </Details>

              {recipient.email ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage id="label.email" defaultMessage="Email" />
                  </Details.Label>
                  <Details.Value>{recipient.email}</Details.Value>
                </Details>
              ) : null}

              {recipient.internationalPhonePrefix &&
              recipient.localPhoneNumber ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      id="label.phoneNumber"
                      defaultMessage="Phone number"
                    />
                  </Details.Label>
                  <Details.Value>
                    {formatPhoneNumberIntl(
                      recipient.internationalPhonePrefix +
                        recipient.localPhoneNumber,
                    )}
                  </Details.Value>
                </Details>
              ) : null}

              {recipient.addressCity ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      id="label.address"
                      defaultMessage="Address"
                    />
                  </Details.Label>
                  <Details.Value className="whitespace-break-spaces text-right">
                    {getRecipientAddress(recipient, intl)}
                  </Details.Value>
                </Details>
              ) : null}
            </>
          ) : (
            <>
              <div className="flex justify-between">
                <Details.Skeleton />
                <Details.Skeleton />
              </div>
              <div className="flex justify-between">
                <Details.Skeleton />
                <Details.Skeleton />
              </div>
            </>
          )}
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="recipient.paymentDetails"
              defaultMessage="Payment details"
            />
          }
        >
          <PaymentDetails recipient={recipient} />

          {recipient?.bankName ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.bank" defaultMessage="Bank" />
              </Details.Label>
              <Details.Value>{recipient.bankName}</Details.Value>
            </Details>
          ) : null}
        </Widget>

        {recipient?.creationDetails ? (
          <Widget
            title={
              <FormattedMessage
                id="recipient.recipientCreation"
                defaultMessage="Recipient creation"
              />
            }
          >
            {recipient.creationDetails.requestedBy ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.requestedBy"
                    defaultMessage="Requested by"
                  />
                </Details.Label>
                <Details.Value>
                  {recipient.creationDetails.requestedBy}
                </Details.Value>
              </Details>
            ) : null}
            {recipient.creationDetails.approvedBy ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.approvedBy"
                    defaultMessage="Approved by"
                  />
                </Details.Label>
                <Details.Value>
                  {recipient.creationDetails.approvedBy}
                </Details.Value>
              </Details>
            ) : null}
          </Widget>
        ) : null}
      </div>

      <WithPermissions permissions={[Permission.TRANSFERS]}>
        <StickyContainer className="pb-0">
          <Button width="full" asChild>
            <Link
              state={{ from: location }}
              to={`${BusinessRoute.Send}?recipient=${recipient?.id}`}
            >
              <FormattedMessage
                id="action.sendPayment"
                defaultMessage="Send a payment"
              />
            </Link>
          </Button>
        </StickyContainer>
      </WithPermissions>
    </SlideInLeft>
  )
}
