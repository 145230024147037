import { formatAmount } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { Wallet } from '../../types'

type Props = {
  wallet: Wallet
}

export const BalanceCell = ({ wallet }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>
        {formatAmount({
          amount: wallet.balance,
          currency: wallet.currency,
        })}
      </Typography>
    </div>
  )
}
