import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Transaction } from '@/domains/Business/features/Transactions/types'
import { useTransactionUtils } from '@/domains/Business/hooks'
import {
  EmptyTransactionsCard,
  TransactionDetailsSidebar,
  TransactionsTable,
  useTransactionColumns,
} from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card } from '@/shared/ui'

import { getTransactionByType, getTransactions } from '../../Transactions/api'

export const TransactionsWidget = () => {
  const columns = useTransactionColumns()
  const {
    closeSidebar,
    openSidebar,
    isSidebarOpen,
    transactionIdFromQuery,
    transactionTypeFromQuery,
  } = useTransactionUtils()

  const [allTransactions, singleTransaction] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTransactions],
        queryFn: () => getTransactions(),
        select: (data: AxiosResponse<Transaction[]>) => data?.data,
        staleTime: 0,
      },
      {
        queryKey: [
          queryKeys.getTransaction,
          transactionIdFromQuery,
          transactionTypeFromQuery,
        ],
        queryFn: () =>
          getTransactionByType({
            type: transactionTypeFromQuery,
            id: transactionIdFromQuery,
          }),
        select: (data: AxiosResponse<Transaction>) => data?.data,
        enabled: !!transactionIdFromQuery,
      },
    ],
  })

  if (allTransactions.data?.length === 0) {
    return <EmptyTransactionsCard />
  }

  return (
    <>
      <TransactionsTable
        isLoading={allTransactions.isPending}
        data={allTransactions.data?.slice(0, 4) ?? []}
        columns={columns}
        onRowClick={openSidebar}
        loaderOptions={{ rows: 4 }}
      />

      <Card className="relative -top-5" size="large">
        <Button
          rightIcon={<ChevronRight className="size-5" />}
          size="md"
          variant="secondary"
          asChild
        >
          <Link to={BusinessRoute.Transactions}>
            <FormattedMessage id="action.seeAll" defaultMessage="See all" />
          </Link>
        </Button>
      </Card>

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </>
  )
}
