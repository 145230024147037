import { ContractorDetails } from '@/domains/Contractor/types'
import { Sheet, SheetContent, SlideInLeft, Typography } from '@/shared/ui'

import { useContractorPaymentMethod } from '../hooks'

import { ContractorPaymentBadge } from './ContractorPaymentBadge'
import { ContractorPaymentInformation } from './ContractorPaymentInformation'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  contractor?: ContractorDetails
}

export const ContractorPaymentDetailsSidebar = ({
  isOpen,
  onOpenChange,
  contractor,
}: Props) => {
  const activePaymentMethod = useContractorPaymentMethod(contractor)

  const { title, subtitle } = activePaymentMethod

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <SlideInLeft className="flex h-full flex-col">
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              <Typography variant="h3">{title}</Typography>
            </div>
            <Typography className="text-neutral-gray-600">
              {subtitle}
            </Typography>

            <div className="p-1" />

            <ContractorPaymentBadge
              variant="sidebar"
              state={contractor?.paymentMethod.state}
            />
          </div>

          <div className="p-4" />

          <div className="flex flex-col gap-8">
            <ContractorPaymentInformation contractor={contractor} />
          </div>

          <div className="p-4" />
        </SlideInLeft>
      </SheetContent>
    </Sheet>
  )
}
