import { ContractorPaymentMethodType } from '@/domains/Contractor/types'
import { contractorApi } from '@/lib/api'

import { ContractorOnboardingState } from '../types'

type PersonalInformationRequest =
  | {
      type: ContractorPaymentMethodType.US_BANK_ACCOUNT
      paymentMethodDetails: {
        accountNumber: string
        routingNumber: string
        address: {
          addressStreetNumber: string
          addressStreet: string
          addressDistrict?: string
          addressCity: string
          addressState: string
          addressPostCode: string
          addressCountry: string
        }
      }
    }
  | {
      type: ContractorPaymentMethodType.DOLAR_TAG
      paymentMethodDetails: {
        dolarTag: string
        internationalPhonePrefix: string
        localPhoneNumber: string
      }
    }

export const submitPaymentDetails = (data: PersonalInformationRequest) => {
  return contractorApi.post<ContractorOnboardingState>(
    '/v1/contractor-onboarding/payment-method',
    data,
  )
}
