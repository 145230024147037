import { AccountIcon } from '@/shared/components'
import { Typography } from '@/shared/ui'

import { Wallet } from '../../types'

type Props = {
  wallet: Wallet
}

export const LabelCell = ({ wallet }: Props) => {
  return (
    <div className="flex items-center gap-2">
      <AccountIcon id={wallet.id} />
      <Typography>{wallet.label}</Typography>
    </div>
  )
}
