import { useCallback, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { FXRatesResponse, getFXRates } from '@/domains/Business/api'
import { AccountSelect } from '@/domains/Business/components'
import { getAccount } from '@/domains/Business/features/Accounts/api'
import { Account } from '@/domains/Business/features/Accounts/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useKeyPress } from '@/hooks/useKeyPress'
import { formatAmount, formatCurrency, formatRate } from '@/lib/money'
import { partition } from '@/lib/utils'
import { GoBackButton } from '@/shared/components'
import { ArrowTrendUp } from '@/shared/icons/outline'
import {
  Button,
  Form,
  FormField,
  Skeleton,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getCSVTransactions, quoteBulkPayment } from '../../api'
import { BulkPaymentQuote, TransactionRows } from '../../types'
import { ReviewSummaryList } from '../ReviewSummaryList'

const REVIEW_TX_FORM_ID = 'review-transactions-form'

const reviewSchema = z.object({
  fromBalance: z.string(),
  walletId: z.string().min(1),
})

type ReviewSchema = z.infer<typeof reviewSchema>

type Props = {
  onBack: () => void
  selectedIdx: string[]
  onContinue: (
    data: BulkPaymentQuote & { walletId: string; selectedPayments: string[] },
  ) => void
}

export const ReviewTransactions = ({
  onBack,
  onContinue,
  selectedIdx,
}: Props) => {
  const [searchParams] = useSearchParams()
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: quoteBulkPayment,
  })

  const bulkPaymentId = searchParams.get('id')

  const [csvValidRowsQuery, fxRateQuery, accountQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getCSVTransactions, bulkPaymentId],
        queryFn: () => getCSVTransactions({ id: bulkPaymentId ?? '' }),
        select: (data: AxiosResponse<TransactionRows>) =>
          data.data.validRows.filter((row) => {
            return selectedIdx.includes(row.id)
          }),
        staleTime: 0,
        enabled: !!bulkPaymentId,
      },
      {
        queryKey: [queryKeys.getFXRates],
        queryFn: () =>
          getFXRates({
            from: Currency.USDC,
            to: Currency.MXN,
          }),
        select: (data: AxiosResponse<FXRatesResponse>) => data.data,
        refetchInterval: 10 * 1000,
      },
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data?.data,
      },
    ],
  })

  const form = useForm<ReviewSchema>({
    mode: 'onChange',
    resolver: zodResolver(reviewSchema),

    values: {
      fromBalance: accountQuery.data?.wallets
        ? Big(accountQuery.data?.wallets[0].balance).toFixed()
        : Big(0).toFixed(),
      walletId: accountQuery.data?.wallets
        ? accountQuery.data.wallets[0].id
        : '',
    },
  })

  const totalAmount = useMemo(() => {
    const USDAmount = csvValidRowsQuery.data
      ?.filter((row) => row.paymentCurrency === Currency.USD)
      .reduce((acc, row) => {
        return acc.add(row.paymentAmount)
      }, Big(0))

    const MXNAmount = csvValidRowsQuery.data
      ?.filter((row) => row.paymentCurrency === Currency.MXN)
      .reduce((acc, row) => {
        return acc.add(row.paymentAmount)
      }, Big(0))

    const MXNAmountInUSD = MXNAmount?.div(fxRateQuery.data?.bidRate ?? Big(1))

    return USDAmount?.add(MXNAmountInUSD ?? Big(0)).toFixed(2) ?? '0'
  }, [csvValidRowsQuery.data, fxRateQuery.data?.bidRate])

  const onSubmit: SubmitHandler<ReviewSchema> = useCallback(
    async (data) => {
      if (!bulkPaymentId) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Bulk payment ID is missing',
            id: 'bulkPayment.uploadFile.missingPaymentId',
          }),
        )

        return
      }

      try {
        const response = await mutateAsync({
          bulkPaymentId,
          data: {
            walletId: data.walletId,
            selectedPayments: selectedIdx,
          },
        })

        onContinue({
          ...response.data,
          walletId: data.walletId,
          selectedPayments: selectedIdx,
        })
      } catch (error) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    },
    [bulkPaymentId, intl, notifyError, onContinue, mutateAsync, selectedIdx],
  )

  const [USDTransactions, MXNTransactions] = useMemo(() => {
    return partition(
      csvValidRowsQuery.data ?? [],
      (row) => row.paymentCurrency === Currency.USD,
    )
  }, [csvValidRowsQuery.data])

  useKeyPress('Enter', form.handleSubmit(onSubmit))

  const notEnoughBalance = Big(form.watch('fromBalance')).lt(Big(totalAmount))

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Bulk payment: {amount}"
            id="bulkPayment.reviewTransactions.title"
            values={{
              amount: formatAmount({
                amount: totalAmount,
                currency: Currency.USDC,
              }),
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="bulkPayment.reviewTransactions.subtitle"
            defaultMessage="Review your bulk payment details"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={REVIEW_TX_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="walletId"
              render={({ field }) => {
                return (
                  <AccountSelect
                    value={field.value}
                    accounts={accountQuery.data?.wallets}
                    label={
                      notEnoughBalance && !accountQuery.isPending
                        ? intl.formatMessage({
                            defaultMessage: 'Insufficient balance',
                            id: 'validation.balance.insufficient',
                          })
                        : intl.formatMessage({
                            defaultMessage: 'Paying from',
                            id: 'label.payingFrom',
                          })
                    }
                    showLabel
                    hasError={notEnoughBalance && !accountQuery.isPending}
                    onChange={(v) => {
                      field.onChange(v)

                      const selected = accountQuery.data?.wallets.find(
                        (wallet) => wallet.id === v,
                      )

                      if (selected) {
                        form.setValue(
                          'fromBalance',
                          Big(selected.balance).toFixed(),
                        )

                        form.trigger()
                      }
                    }}
                  />
                )
              }}
            />

            <div className="p-3" />

            <div className="flex flex-col gap-5">
              {USDTransactions.length > 0 ? (
                <ReviewSummaryList
                  currency={Currency.USD}
                  transactions={USDTransactions}
                />
              ) : null}

              {MXNTransactions.length > 0 ? (
                <>
                  <ReviewSummaryList
                    currency={Currency.MXN}
                    transactions={MXNTransactions}
                  />

                  <div className="flex w-full items-center gap-1 text-neutral-gray-600">
                    <ArrowTrendUp />
                    <div className="flex items-center gap-1">
                      <Typography>
                        1 {formatCurrency(Currency.USDC)} =
                      </Typography>
                      {fxRateQuery.isPending ? (
                        <Skeleton className="h-6 w-24 rounded-md" />
                      ) : (
                        <Typography>
                          {formatRate(fxRateQuery.data?.bidRate)}{' '}
                          {formatCurrency(Currency.MXN)}
                        </Typography>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={notEnoughBalance || isPending}
            form={REVIEW_TX_FORM_ID}
            loading={isPending}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage defaultMessage="Continue" id="action.continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
