import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import { useBusinessRole } from '@/domains/Business/hooks'
import { Role } from '@/domains/Business/types/roles'
import { getFullName } from '@/lib/typography'
import { CountryWithFlag, Widget } from '@/shared/components'
import { Details, Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { AddBeneficiaryTask } from '../types'

export const TaskPaymentDetails = ({ task }: { task: AddBeneficiaryTask }) => {
  switch (task.taskDetails.beneficiaryCountry) {
    case CountryCode.MX:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
            </Details.Label>

            <Details.Value masked>
              {task.taskDetails.beneficiaryClabe}
            </Details.Value>
          </Details>
          {task.taskDetails.beneficiaryBank ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Bank" id="label.bank" />
              </Details.Label>

              <Details.Value>{task.taskDetails.beneficiaryBank}</Details.Value>
            </Details>
          ) : null}
        </>
      )

    case CountryCode.US:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method: task.taskDetails.beneficiaryPaymentMethod }}
              />
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="ABA / Routing number"
                id="label.routingNumber"
              />
            </Details.Label>

            <Details.Value>
              {task.taskDetails.beneficiaryRoutingNumber}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Account number"
                id="label.accountNumber"
              />
            </Details.Label>
            <Details.Value masked>
              {task.taskDetails.beneficiaryAccountNumber}
            </Details.Value>
          </Details>
          {task.taskDetails.beneficiaryBank ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Bank" id="label.bank" />
              </Details.Label>

              <Details.Value masked>
                {task.taskDetails.beneficiaryBank}
              </Details.Value>
            </Details>
          ) : null}
        </>
      )

    default:
      return null
  }
}

type Props = {
  task: AddBeneficiaryTask
}

export const AddRecipientTaskReview = ({ task }: Props) => {
  const { role } = useBusinessRole()

  const description = useMemo(() => {
    if (task) {
      switch (role) {
        case Role.ADMIN:
          return (
            <FormattedMessage
              id="tasks.review.addBeneficiary.admin.description"
              defaultMessage="{fullName} has made a request to add a new recipient. Review and decide to approve or decline"
              values={{
                fullName: getFullName({
                  firstName: task.createdByFirstName,
                  lastName: task.createdByLastName,
                  secondLastName: task.createdBySecondLastName,
                }),
              }}
            />
          )

        case Role.PAYMENT_OPS:
          return (
            <FormattedMessage
              id="tasks.review.paymentOps.description"
              defaultMessage="Approval of this request is pending. Contact your account Admin so they can review it!"
            />
          )

        default:
          return ''
      }
    }

    return ''
  }, [role, task])

  return (
    <>
      <Typography text="center" variant="h3">
        <FormattedMessage
          id="tasks.review.addBeneficiary.title"
          defaultMessage="Add {recipient} as recipient"
          values={{
            recipient: task.taskDetails.beneficiaryName,
          }}
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">{description}</Typography>

      <div className="p-6" />

      <div className="flex flex-col gap-4">
        <Widget
          title={
            <FormattedMessage
              id="tasks.review.recipientDetails"
              defaultMessage="Recipient details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                id="task.review.recipientBankCountry"
                defaultMessage="Recipient bank country"
              />
            </Details.Label>
            <CountryWithFlag country={task.taskDetails.beneficiaryCountry} />
          </Details>

          <Details>
            <Details.Label>
              <FormattedMessage
                id="task.review.recipientType"
                defaultMessage="Recipient type"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                defaultMessage="{entity, select, BUSINESS {Business} INDIVIDUAL {Individual} other {}}"
                id="recipients.entity"
                values={{ entity: task.taskDetails.beneficiaryEntityType }}
              />
            </Details.Value>
          </Details>
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="tasks.review.contactDetails"
              defaultMessage="Contact details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                id="task.review.legalName"
                defaultMessage="Legal name"
              />
            </Details.Label>
            <Details.Value>{task.taskDetails.beneficiaryName}</Details.Value>
          </Details>

          {task.taskDetails.beneficiaryEmail ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="task.review.contactEmail"
                  defaultMessage="Contact email"
                />
              </Details.Label>
              <Details.Value>{task.taskDetails.beneficiaryEmail}</Details.Value>
            </Details>
          ) : null}

          {task.taskDetails.beneficiaryPhoneNumber ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="task.review.contactPhoneNumber"
                  defaultMessage="Contact phone number"
                />
              </Details.Label>
              <Details.Value>
                {formatPhoneNumberIntl(task.taskDetails.beneficiaryPhoneNumber)}
              </Details.Value>
            </Details>
          ) : null}
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="tasks.review.bankAccountDetails"
              defaultMessage="Bank account details"
            />
          }
        >
          <TaskPaymentDetails task={task} />
        </Widget>
      </div>
    </>
  )
}
