import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import {
  ContactorDetailsStep,
  ContractDocumentTypeStep,
  ContractorDetailsSchema,
  ContractorReviewStep,
  ContractTypeStep,
  FixedRateContractSchema,
  FixedRateContractStep,
  PayAsYouGoContractSchema,
  PayAsYouGoContractStep,
  UploadCustomContractStep,
} from '../components/CreateContractor'
import {
  ContractDocument,
  ContractorAgreementType,
  ContractorPaymentType,
} from '../types'

enum FormStep {
  Details = 'details',
  ContractType = 'contract-type',
  FixedRateContact = 'fixed-rate-contact',
  PayAsYouGoContract = 'pay-as-you-go-contract',
  ContractDocumentType = 'contract-document-type',
  UploadCustomContract = 'upload-custom-contract',
  Review = 'review',
}

type FormState =
  | { type: FormStep.Details }
  | { type: FormStep.ContractType }
  | { type: FormStep.FixedRateContact }
  | { type: FormStep.PayAsYouGoContract }
  | { type: FormStep.ContractDocumentType }
  | { type: FormStep.UploadCustomContract }
  | { type: FormStep.Review }

export const CreateContractor = () => {
  const location = useLocation()

  const [activeStep, setActiveStep] = useState<FormState>({
    type: FormStep.Details,
  })

  const [contractorDetails, setContractorDetails] =
    useState<ContractorDetailsSchema>()

  const [payAsYouGoContract, setPayAsYouGoContract] =
    useState<PayAsYouGoContractSchema>()

  const [fixedRateContract, setFixedRateContract] =
    useState<FixedRateContractSchema>()

  const [agreementType, setAgreementType] = useState<ContractorAgreementType>()
  const [paymentType, setPaymentType] = useState<ContractorPaymentType>()
  const [uploadedCustomContracts, setUploadedCustomContracts] =
    useState<ContractDocument[]>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case FormStep.Details:
        return 0
      case FormStep.ContractType:
        return 25
      case FormStep.FixedRateContact:
        return 50
      case FormStep.PayAsYouGoContract:
        return 50
      case FormStep.ContractDocumentType:
        return 75
      case FormStep.UploadCustomContract:
        return 75
      case FormStep.Review:
        return 100
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.ContractorsAll}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === FormStep.Details ? (
          <ContactorDetailsStep
            contractorDetails={contractorDetails}
            onContinue={(details) => {
              setContractorDetails(details)

              setActiveStep({ type: FormStep.ContractType })
            }}
          />
        ) : null}

        {activeStep.type === FormStep.ContractType ? (
          <ContractTypeStep
            contractorDetails={contractorDetails}
            onBack={() => {
              setActiveStep({ type: FormStep.Details })
              setPaymentType(undefined)
            }}
            onContinue={(paymentType) => {
              setPaymentType(paymentType)

              switch (paymentType) {
                case ContractorPaymentType.FIXED_RATE:
                  setActiveStep({ type: FormStep.FixedRateContact })
                  break

                case ContractorPaymentType.PAY_AS_YOU_GO:
                  setActiveStep({ type: FormStep.PayAsYouGoContract })
                  break

                default:
                  break
              }
            }}
          />
        ) : null}

        {activeStep.type === FormStep.FixedRateContact ? (
          <FixedRateContractStep
            fixedRateContract={fixedRateContract}
            onBack={() => {
              setActiveStep({ type: FormStep.ContractType })
              setFixedRateContract(undefined)
            }}
            onContinue={(data) => {
              setFixedRateContract(data)

              setActiveStep({ type: FormStep.ContractDocumentType })
            }}
          />
        ) : null}

        {activeStep.type === FormStep.PayAsYouGoContract ? (
          <PayAsYouGoContractStep
            payAsYouGoContract={payAsYouGoContract}
            onBack={() => {
              setActiveStep({ type: FormStep.ContractType })
              setPayAsYouGoContract(undefined)
            }}
            onContinue={(details) => {
              setPayAsYouGoContract(details)

              setActiveStep({ type: FormStep.ContractDocumentType })
            }}
          />
        ) : null}

        {activeStep.type === FormStep.ContractDocumentType ? (
          <ContractDocumentTypeStep
            onBack={() => {
              switch (paymentType) {
                case ContractorPaymentType.FIXED_RATE:
                  setActiveStep({ type: FormStep.FixedRateContact })
                  break
                case ContractorPaymentType.PAY_AS_YOU_GO:
                  setActiveStep({ type: FormStep.PayAsYouGoContract })
                  break
                default:
                  break
              }
            }}
            onContinue={(agreementType) => {
              setAgreementType(agreementType)

              switch (agreementType) {
                case ContractorAgreementType.STANDARD:
                  setActiveStep({ type: FormStep.Review })
                  break

                case ContractorAgreementType.CUSTOM:
                  setActiveStep({ type: FormStep.UploadCustomContract })
                  break

                default:
                  break
              }
            }}
          />
        ) : null}

        {activeStep.type === FormStep.UploadCustomContract ? (
          <UploadCustomContractStep
            onBack={() => {
              setActiveStep({ type: FormStep.ContractDocumentType })
              setUploadedCustomContracts(undefined)
            }}
            onContinue={(data) => {
              setUploadedCustomContracts(data)
              setActiveStep({ type: FormStep.Review })
            }}
            onSkip={() => {
              setUploadedCustomContracts(undefined)
              setActiveStep({ type: FormStep.Review })
            }}
            uploadedContractDocuments={uploadedCustomContracts}
          />
        ) : null}

        {activeStep.type === FormStep.Review ? (
          <ContractorReviewStep
            contractorDetails={contractorDetails}
            payAsYouGoContract={payAsYouGoContract}
            fixedRateContract={fixedRateContract}
            uploadedCustomContracts={uploadedCustomContracts}
            agreementType={agreementType}
            paymentType={paymentType}
            onBack={() => {
              switch (agreementType) {
                case ContractorAgreementType.STANDARD:
                  setActiveStep({ type: FormStep.ContractDocumentType })
                  break

                case ContractorAgreementType.CUSTOM:
                  setActiveStep({ type: FormStep.UploadCustomContract })
                  break

                default:
                  break
              }
            }}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
