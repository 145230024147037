import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Navigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getBusinessIdentity } from '@/domains/Business/api'
import { useBusinessRole, useLogout } from '@/domains/Business/hooks'
import { isAPIError } from '@/lib/error'
import { Loader } from '@/shared/components'

import { BusinessState } from '../types'

type Props = {
  children: React.ReactNode
}

export const BusinessStateChecker = ({ children }: Props) => {
  const { logout } = useLogout()
  const { isPending } = useBusinessRole()

  const businessQuery = useQuery({
    queryKey: [queryKeys.getBusinessIdentity],
    queryFn: getBusinessIdentity,
    select: (data) => data.data,
    retry: 1,
    staleTime: 0,
  })

  useEffect(() => {
    if (
      businessQuery.error &&
      isAPIError(businessQuery.error) &&
      businessQuery.error.response?.status === 401
    ) {
      logout()
    }
  }, [businessQuery.error, logout])

  if (businessQuery.isPending || businessQuery.isError || isPending) {
    return <Loader />
  }

  switch (businessQuery.data?.state) {
    case BusinessState.ACTIVE:
      return <>{children}</>

    case BusinessState.ONBOARDING:
      return <Navigate to={BusinessRoute.Onboarding} />

    default:
      console.error('Unknown business state')
      return null
  }
}
