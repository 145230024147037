import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

import { sessionStorageKeys } from '@/constants/keys'
import {
  BASE_API_DEV,
  BASE_API_PROD,
  BASE_CONTRACTOR_API_DEV,
  BASE_CONTRACTOR_API_PROD,
} from '@/constants/urls'
import { refreshToken } from '@/domains/Business/api'
import { refreshContractorToken } from '@/domains/Contractor/api'
import { isDevelopment } from '@/lib/utils'

import { generateFingerprint } from './fingerprint'

const AUTH_HEADER = 'authorization'

const FIVE_MINUTES = 5 * 60 * 1000

export const api = axios.create({
  baseURL: isDevelopment() ? BASE_API_DEV : BASE_API_PROD,
})

// same as api but without authorization
export const publicApi = axios.create({
  baseURL: isDevelopment() ? BASE_API_DEV : BASE_API_PROD,
})

api.interceptors.request.use(async (config) => {
  const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken)

  const deviceFingerprint = await generateFingerprint()

  config.headers['Device-Fingerprint'] = deviceFingerprint

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`

    const decoded = jwtDecode(accessToken)

    const expires = decoded.exp ? decoded.exp * 1000 : 0

    // If the token expires in less than 5 minutes, refresh it
    if (expires - Date.now() < FIVE_MINUTES) {
      refreshToken().then((response) => {
        const auth = response.headers.get(AUTH_HEADER)

        if (auth) {
          const [, token] = auth.split(' ')

          sessionStorage.setItem(sessionStorageKeys.accessToken, token)
        }
      })
    }
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    const auth = response.headers[AUTH_HEADER]

    if (auth) {
      const [, token] = auth.split(' ')

      sessionStorage.setItem(sessionStorageKeys.accessToken, token)
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export const contractorApi = axios.create({
  baseURL: isDevelopment() ? BASE_CONTRACTOR_API_DEV : BASE_CONTRACTOR_API_PROD,
})

contractorApi.interceptors.request.use(async (config) => {
  const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken)

  const deviceFingerprint = await generateFingerprint()

  config.headers['Device-Fingerprint'] = deviceFingerprint

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`

    const decoded = jwtDecode(accessToken)

    const expires = decoded.exp ? decoded.exp * 1000 : 0

    // If the token expires in less than 5 minutes, refresh it
    if (expires - Date.now() < FIVE_MINUTES) {
      refreshContractorToken().then((response) => {
        const auth = response.headers.get(AUTH_HEADER)

        if (auth) {
          const [, token] = auth.split(' ')

          sessionStorage.setItem(sessionStorageKeys.accessToken, token)
        }
      })
    }
  }

  return config
})

contractorApi.interceptors.response.use(
  (response) => {
    const auth = response.headers[AUTH_HEADER]

    if (auth) {
      const [, token] = auth.split(' ')

      sessionStorage.setItem(sessionStorageKeys.accessToken, token)
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
