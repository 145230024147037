import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { DEV_OTP } from '@/constants/otp'
import { BusinessRoute } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { isProduction } from '@/lib/utils'
import { SlideInScreen, Typography } from '@/shared/ui'

type Props = {
  id: string
  email: string
}

export const VerifyEmail = ({ id, email }: Props) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isProduction()) {
      return
    }

    /* email verification doesn't work on dev, so we manually proceed */
    const timeoutId = setTimeout(() => {
      navigate(
        `${BusinessRoute.SignUp}?id=${id}&email=${encodeURIComponent(email)}&otp=${DEV_OTP}`,
      )

      window.location.reload()
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [email, id, navigate])

  return (
    <SlideInScreen className="-mt-24">
      <div className="my-auto flex flex-col">
        <img
          className="mx-auto w-36 object-contain"
          src={getImage({ name: 'email-2fa' })}
          alt=""
          aria-hidden
        />
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Verify your email"
            id="signIn.verifyEmail"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center" className="text-neutral-gray-600">
          <FormattedMessage
            defaultMessage="We have sent you a verification email to {email}. Click on the link to create your account and continue your application"
            id="signIn.verifyEmail.linkSent"
            values={{ email }}
          />
        </Typography>
      </div>
    </SlideInScreen>
  )
}
