import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { BusinessRoute } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { passwordValidation } from '@/lib/validations'
import { EyeOnOffButton, PasswordRules } from '@/shared/components'
import { Eye, EyeOff } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

import { updateContractorPassword } from '../api'

const FORGET_PASSWORD_CREATE_FORM_ID = 'forget-password-create-form'

export type ForgetPasswordSchema = z.infer<typeof passwordValidation>

type Props = {
  identityId: string | null
  otp: string | null
}

export const ContractorResetPassword = ({ otp, identityId }: Props) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)
  const notifyError = useErrorToast()

  const navigate = useNavigate()

  const form = useForm<ForgetPasswordSchema>({
    mode: 'onChange',
    resolver: zodResolver(passwordValidation),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateContractorPassword,
  })

  const onSubmit: SubmitHandler<ForgetPasswordSchema> = async (data) => {
    if (!otp || !identityId) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Invalid code',
          id: 'auth.error.invalidCode',
        }),
      )

      return
    }

    try {
      await mutateAsync({
        newPassword: data.confirmPassword,
        identityId,
        otp,
      })
      navigate(BusinessRoute.SignIn, { replace: true })
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Create a new password"
          id="auth.createPassword"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form
          id={FORGET_PASSWORD_CREATE_FORM_ID}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-3">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="new-password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Password',
                        id: 'label.password',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Password"
                      id="label.password"
                    />
                  </AnimatedFormLabel>

                  <EyeOnOffButton
                    show={showPassword}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="new-password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Confirm password',
                        id: 'label.confirmPassword',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Confirm password"
                      id="label.confirmPassword"
                    />
                  </AnimatedFormLabel>

                  <PasswordRules password={form.getValues('password')} />

                  <Button
                    variant="ghost"
                    size="inline"
                    onClick={() => setShowPassword((prev) => !prev)}
                    type="button"
                    className="absolute right-4 top-5"
                    aria-label={
                      showPassword
                        ? intl.formatMessage({
                            defaultMessage: 'Show password',
                            id: 'action.showPassword',
                          })
                        : intl.formatMessage({
                            defaultMessage: 'Hide password',
                            id: 'action.hidePassword',
                          })
                    }
                  >
                    {showPassword ? (
                      <Eye className="size-4 text-neutral-gray-400" />
                    ) : (
                      <EyeOff className="size-4 text-neutral-gray-400" />
                    )}
                  </Button>
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>

      <div className="p-8" />

      <Button
        width="full"
        form={FORGET_PASSWORD_CREATE_FORM_ID}
        loading={isPending}
        disabled={isPending || !form.formState.isValid}
        onClick={form.handleSubmit(onSubmit)}
        className="mt-auto"
        type="submit"
      >
        <FormattedMessage defaultMessage="Save" id="action.save" />
      </Button>

      <div className="p-6" />
    </SlideInScreen>
  )
}
