import { api } from '@/lib/api'
import { TwoFactorMethod } from '@/types/auth'

export type Current2FAResponse = {
  identityId: string
  otpMethod: TwoFactorMethod
  initialSetup: boolean
}

export function getCurrent2FAMethod() {
  return api.get<Current2FAResponse>(`/v1/me/one-time-passwords/2FA`)
}
