import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { z } from 'zod'

import { BusinessRoute } from '@/constants/paths'
import { getCountryISO3byISO2, isCountryInISO3Format } from '@/lib/country'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import {
  CompanyOwner,
  OnboardingStepConfig,
  OnboardingStepName,
  OwnerType,
} from '../../types'
import { OwnerAddressFields } from '../OwnerAddressFields'

const createOwnerAddressSchema = z.object({
  GOOGLE_PLACES_ADDRESS: z.string(),

  ADDRESS_STREET: z.string(),
  ADDRESS_STREET_NUMBER: z.string(),
  ADDRESS_DISTRICT: z.string(),
  ADDRESS_CITY: z.string(),
  ADDRESS_STATE: z.string(),
  ADDRESS_POST_CODE: z.string(),
  ADDRESS_COUNTRY: z.string(),
})

export type CreateOwnerAddressSchema = z.infer<typeof createOwnerAddressSchema>

const CREATE_OWNER_ADDRESS_FORM_ID = 'create-owner-address-form-id'

type Props = {
  data: Omit<CompanyOwner, 'id'>
  config?: OnboardingStepConfig
  owner?: CompanyOwner
  ownerType?: OwnerType
  onBack: () => void
  onContinue: (data: Omit<CompanyOwner, 'id'>) => void
}

export const OwnerAddressStep = ({
  config,
  data,
  onBack,
  onContinue,
  owner,
  ownerType,
}: Props) => {
  const [inputView, setInputView] = useState<'auto' | 'manual'>('auto')

  const form = useForm<CreateOwnerAddressSchema>({
    mode: 'onChange',
    resolver: zodResolver(createOwnerAddressSchema),
    values: {
      GOOGLE_PLACES_ADDRESS: '',
      ADDRESS_STREET: data.addressStreet
        ? data.addressStreet
        : owner?.addressStreet
          ? owner.addressStreet
          : '',
      ADDRESS_STREET_NUMBER: data.addressStreetNumber
        ? data.addressStreetNumber
        : owner?.addressStreetNumber
          ? owner.addressStreetNumber
          : '',
      ADDRESS_DISTRICT: data.addressDistrict
        ? data.addressDistrict
        : owner?.addressDistrict
          ? owner.addressDistrict
          : '',
      ADDRESS_CITY: data.addressCity
        ? data.addressCity
        : owner?.addressCity
          ? owner.addressCity
          : '',
      ADDRESS_STATE: data.addressState
        ? data.addressState
        : owner?.addressState
          ? owner.addressState
          : '',
      ADDRESS_POST_CODE: data.addressPostCode
        ? data.addressPostCode
        : owner?.addressPostCode
          ? owner.addressPostCode
          : '',
      ADDRESS_COUNTRY: data.addressCountry
        ? data.addressCountry
        : owner?.addressCountry
          ? owner.addressCountry
          : '',
    },
  })

  useEffect(() => {
    if (data.addressStreet || owner?.addressStreet) {
      setInputView('manual')
    }
  }, [data.addressStreet, owner?.addressStreet])

  const onSubmit: SubmitHandler<CreateOwnerAddressSchema> = useCallback(
    (formData) => {
      const {
        ADDRESS_STREET,
        ADDRESS_STREET_NUMBER,
        ADDRESS_DISTRICT,
        ADDRESS_CITY,
        ADDRESS_STATE,
        ADDRESS_POST_CODE,
        ADDRESS_COUNTRY,
      } = formData

      const requestData = {
        ...data,
        addressStreet: ADDRESS_STREET,
        addressStreetNumber: ADDRESS_STREET_NUMBER,
        addressDistrict: ADDRESS_DISTRICT,
        addressCity: ADDRESS_CITY,
        addressState: ADDRESS_STATE,
        addressPostCode: ADDRESS_POST_CODE,
        addressCountry: getCountryISO3byISO2(ADDRESS_COUNTRY),
      }

      onContinue(requestData)
    },
    [data, onContinue],
  )

  const fields = form.watch()

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const stepFields = [
      'ADDRESS_STREET',
      'ADDRESS_STREET_NUMBER',
      'ADDRESS_DISTRICT',
      'ADDRESS_CITY',
      'ADDRESS_STATE',
      'ADDRESS_POST_CODE',
      'ADDRESS_COUNTRY',
    ]

    const filteredRequiredFields = config.fields.filter(
      (field) => stepFields.includes(field.name) && field.required,
    )

    return (
      filteredRequiredFields.every(
        (field) => fields[field.name as keyof typeof fields],
      ) && isCountryInISO3Format(fields.ADDRESS_COUNTRY)
    )
  }, [fields, config?.fields])

  const shouldRedirect = useMemo(() => {
    return (
      (ownerType === OwnerType.OTHER_LEGAL_REPRESENTATIVE_AND_OWNER ||
        ownerType === OwnerType.USER_LEGAL_REPRESENTATIVE_AND_OWNER) &&
      !!owner
    )
  }, [owner, ownerType])

  return (
    <>
      <GoBackButton
        to={
          shouldRedirect
            ? generatePath(BusinessRoute.OnboardingStep, {
                step: OnboardingStepName.COMPANY_OWNERSHIP,
              })
            : undefined
        }
        onClick={shouldRedirect ? undefined : onBack}
      />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="onboarding.ownership.addressStep.title"
            defaultMessage="Provide {name}'s residential address"
            values={{
              name: data.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="onboarding.ownership.addressStep.subtitle"
            defaultMessage="Search for the address or enter the address manually"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form id={CREATE_OWNER_ADDRESS_FORM_ID} className="w-full">
            <OwnerAddressFields
              config={config}
              inputView={inputView}
              onInputViewChange={setInputView}
              form={form}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            disabled={!hasAllRequiredFields}
            width="full"
            form={CREATE_OWNER_ADDRESS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>

          {inputView === 'manual' && (
            <>
              <div className="p-1" />
              <Button
                type="button"
                onClick={() => {
                  setInputView('auto')
                  form.setValue('GOOGLE_PLACES_ADDRESS', '')
                }}
                width="full"
                className="text-center font-semibold text-primary"
                variant="ghost"
              >
                <FormattedMessage
                  defaultMessage="Search for my address"
                  id="onboarding.businessAddress.searchForAddress"
                />
              </Button>
            </>
          )}
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
