import { FormattedMessage } from 'react-intl'

import { Check } from '@/shared/icons/outline'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

type Props = {
  isOpen: boolean
  isPending: boolean
  onOpenChange: (isOpen: boolean) => void
  onContinue: () => void
}

export const VerifiedDialog = ({
  onContinue,
  isOpen,
  isPending,
  onOpenChange,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="flex flex-col items-center">
        <span className="flex size-16 items-center justify-center rounded-full bg-primary">
          <Check className="size-12 text-white" />
        </span>

        <div className="p-2" />

        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              id="contractor.onboarding.dolarTag.verified.title"
              defaultMessage="Your DolarApp account was linked successfully"
            />
          </DialogTitle>

          <DialogDescription>
            <FormattedMessage
              id="contractor.onboarding.dolarTag.verified.description"
              defaultMessage="You are now ready to get your payment and enjoy the benefits of a global account"
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <Button
          disabled={isPending}
          loading={isPending}
          width="full"
          onClick={onContinue}
        >
          <FormattedMessage id="action.continue" defaultMessage="Continue" />
        </Button>
      </DialogContent>
    </Dialog>
  )
}
