import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useGetBankName } from '@/domains/Business/hooks'
import {
  ContractorPaymentMethodType,
  USBankAccountDetails,
} from '@/domains/Contractor/types'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import { isValidRoutingNumber } from '@/lib/validations'
import {
  BankName,
  GoBackButton,
  OptionalTag,
  StateSelectOrInput,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { submitPaymentDetails } from '../api'
import { ContractorOnboardingState } from '../types'

const US_BANK_PAYMENT_DETAILS_FORM_ID = 'us-bank-payment-details-id'

const bankPaymentDetailsSchema = z.object({
  accountNumber: z
    .string()
    .min(1, { message: 'validation.accountNumber.required' }),
  routingNumber: z
    .string()
    .min(1, { message: 'validation.routingNumber.required' }),
  address: z.object({
    addressStreet: z
      .string()
      .min(1, { message: 'validation.addressStreet.required' }),
    addressStreetNumber: z
      .string()
      .min(1, { message: 'validation.addressStreetNumber.required' }),
    addressDistrict: z.string().optional(),
    addressCity: z
      .string()
      .min(1, { message: 'validation.addressCity.required' }),
    addressState: z
      .string()
      .min(1, { message: 'validation.addressState.required' }),
    addressPostCode: z
      .string()
      .min(1, { message: 'validation.addressZipCode.required' }),
    addressCountry: z
      .string()
      .min(1, { message: 'validation.addressCountry.required' }),
  }),
})

type BankPaymentDetailsSchema = z.infer<typeof bankPaymentDetailsSchema>

type Props = {
  onBack: () => void
  contractorState?: ContractorOnboardingState
}

export const USBankPaymentDetails = ({ onBack, contractorState }: Props) => {
  const intl = useIntl()
  const { step } = useParams<{ step?: string }>()
  const navigate = useNavigate()

  const paymentDetails = contractorState?.paymentMethodDetails as
    | USBankAccountDetails
    | undefined

  const form = useForm<BankPaymentDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(bankPaymentDetailsSchema),
    values: {
      accountNumber:
        paymentDetails?.paymentMethodInformation.accountNumber ?? '',
      routingNumber:
        paymentDetails?.paymentMethodInformation.routingNumber ?? '',
      address: {
        addressStreet:
          paymentDetails?.paymentMethodInformation.address?.addressStreet ?? '',
        addressStreetNumber:
          paymentDetails?.paymentMethodInformation.address
            ?.addressStreetNumber ?? '',
        addressDistrict:
          paymentDetails?.paymentMethodInformation.address?.addressDistrict ??
          '',
        addressCity:
          paymentDetails?.paymentMethodInformation.address?.addressCity ?? '',
        addressState:
          paymentDetails?.paymentMethodInformation.address?.addressState ?? '',
        addressPostCode:
          paymentDetails?.paymentMethodInformation.address?.addressPostCode ??
          '',
        addressCountry: CountryCode.US,
      },
    },
  })

  const { name, isPending } = useGetBankName({
    country: CountryCode.US,
    bankReference: form.watch('routingNumber'),
    enabled: isValidRoutingNumber(form.watch('routingNumber')),
  })

  const {
    mutateAsync: submitPaymentDetailsMutate,
    isPending: isSubmitPaymentDetailsPending,
  } = useMutation({
    mutationFn: submitPaymentDetails,
  })

  const onSubmit: SubmitHandler<BankPaymentDetailsSchema> = async (data) => {
    if (!contractorState) {
      return
    }

    removeEmptyFormFields(data)

    await submitPaymentDetailsMutate({
      type: ContractorPaymentMethodType.US_BANK_ACCOUNT,
      paymentMethodDetails: data,
    })

    const currentStepIndex = contractorState.steps.findIndex(
      (s) => s.name === step,
    )

    const nextStep = contractorState?.steps[currentStepIndex + 1]

    await queryClient.invalidateQueries({
      queryKey: [queryKeys.getContractorOnboardingState],
    })

    navigate(
      generatePath(ContractorRoute.OnboardingStep, { step: nextStep.name }),
    )
  }

  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.onboarding.bankDetails.title"
            defaultMessage="Complete your payment details"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.bankDetails.subtitle"
            defaultMessage="Enter you payment methods details. You'll get your payments in this account"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={US_BANK_PAYMENT_DETAILS_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3">
              <FormField
                control={form.control}
                name="accountNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'label.accountNumber',
                          defaultMessage: 'Account number',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.accountNumber"
                        defaultMessage="Account number"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="routingNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'label.routingNumber',
                          defaultMessage: 'ABA / Routing number',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.routingNumber"
                        defaultMessage="ABA / Routing number"
                      />
                    </AnimatedFormLabel>

                    <BankName name={name} isPending={isPending} />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="address.addressStreet"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          autoComplete="address-line1"
                          placeholder={intl.formatMessage({
                            id: 'label.street',
                            defaultMessage: 'Street',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Street"
                          id="label.street"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="address.addressStreetNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          autoComplete="address-line2"
                          placeholder={intl.formatMessage({
                            id: 'label.streetNumber',
                            defaultMessage: 'Street number',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Street number"
                          id="label.streetNumber"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="address.addressDistrict"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'label.neighborhoodOrDistrict',
                          defaultMessage: 'Neighborhood or district',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.neighborhoodOrDistrict"
                        defaultMessage="Neighborhood or district"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="address.addressCity"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        key="addressCity"
                        autoComplete="address-level2"
                        placeholder={intl.formatMessage({
                          id: 'label.city',
                          defaultMessage: 'City',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage defaultMessage="City" id="label.city" />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="address.addressState"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <StateSelectOrInput
                          value={field.value}
                          onChange={field.onChange}
                          variant="select"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="address.addressPostCode"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          autoComplete="postal-code"
                          placeholder={intl.formatMessage({
                            id: 'label.postCode',
                            defaultMessage: 'Post code',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Post code"
                          id="label.postCode"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            form={US_BANK_PAYMENT_DETAILS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            loading={isSubmitPaymentDetailsPending}
            disabled={!form.formState.isValid || isSubmitPaymentDetailsPending}
            type="submit"
            width="full"
          >
            <FormattedMessage id="action.continue" defaultMessage="Continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
