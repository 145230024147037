import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getBusinessIdentity } from '@/domains/Business/api'
import { useLogout } from '@/domains/Business/hooks'
import { BusinessState } from '@/domains/Business/types'
import { isAPIError } from '@/lib/error'

export function useBusinessStateCheck() {
  const navigate = useNavigate()

  const { logout } = useLogout()

  const businessQuery = useQuery({
    queryKey: [queryKeys.getBusinessIdentity],
    queryFn: getBusinessIdentity,
    select: (data) => data.data,
    staleTime: 0,
    retry: 1,
  })

  useEffect(() => {
    if (
      businessQuery.error &&
      isAPIError(businessQuery.error) &&
      businessQuery.error.response?.status === 401
    ) {
      logout()
    }
  }, [businessQuery.error, logout])

  useEffect(() => {
    if (businessQuery.data?.state === BusinessState.ACTIVE) {
      navigate(BusinessRoute.Dashboard, { replace: true })
    }
  }, [businessQuery.data?.state, navigate])
}
