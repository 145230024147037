import { useCallback } from 'react'
import { Big } from 'big.js'
import { useIntl } from 'react-intl'
import { toast } from 'sonner'

import { backendErrorMessages, errorMessages } from '@/constants/messages'
import { ApiError } from '@/types/global'

function handleErrorMessageWithAdditionalData(error: ApiError) {
  const code = error.response?.data?.error

  switch (code) {
    case 'VERIFY_OTP_FORBIDDEN': {
      return {
        attempt: Big(error.response?.data?.details?.maxAttempts ?? 0)
          .minus(Big(error.response?.data?.details?.currentAttempt ?? 0))
          .toNumber(),
      }
    }

    default:
      return {}
  }
}

export function useErrorToast() {
  const intl = useIntl()

  return useCallback(
    (err: unknown) => {
      if (err instanceof Error) {
        const error = err as ApiError

        const errorMessage = intl.formatMessage(
          errorMessages['validation.generic.error'],
        )

        const errorCode = error.response?.data?.error

        if (errorCode) {
          if (backendErrorMessages[errorCode] === undefined) {
            if (error.response?.data?.description) {
              return toast.error(error.response.data.description)
            }

            return toast.error(errorMessage)
          }

          const backendErrorMessage = intl.formatMessage(
            backendErrorMessages[errorCode],
            {
              ...handleErrorMessageWithAdditionalData(error),
            },
          )

          return toast.error(backendErrorMessage)
        }

        return toast.error(errorMessage)
      }
    },
    [intl],
  )
}
