import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

import { TeamMember } from '../types'

type BlockTeamMemberRequest = {
  id: string
} & OTPCode

export function blockTeamMember({ id, otp }: BlockTeamMemberRequest) {
  return api.post<TeamMember>(`/v1/me/business-users/${id}/block`, { otp })
}
