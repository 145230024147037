import { useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/domains/Business/hooks'
import { useSearchInput } from '@/hooks/useSearchInput'
import {
  ActiveFilters,
  FiltersWidget,
  TasksTypeBadge,
  TasksTypeFilter,
} from '@/shared/components'
import { DataTable, SearchInput, Typography } from '@/shared/ui'

import { MoveMoneyWidget } from '../../components'

import { getAdminTasks, getUserTasks } from './api'
import { EmptyTasks } from './components/EmptyTasks'
import { FloatingTaskButton } from './components/FloatingTaskButton'
import { useTasksColumns } from './components/TasksTable'
import { Task } from './types'

function getSelectableIds(tasks: Task[], selected: Task[]) {
  if (selected.length === 0 || tasks.length === 0) {
    return {}
  }

  const selectedIndices = selected.map((t) =>
    tasks.findIndex((task) => task.id === t.id),
  )

  const filteredIndices = selectedIndices.filter((i) => i >= 0)

  return (filteredIndices ?? []).reduce(
    (acc, curr) => ({ ...acc, [curr]: true }),
    {},
  )
}

export const Tasks = () => {
  const intl = useIntl()
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([])
  const { isAdmin } = useBusinessRole()

  const columns = useTasksColumns({
    onAction: (id) => {
      setSelectedTasks((tasks) => tasks.filter((t) => t.id !== id))
    },
  })

  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const [searchParams] = useSearchParams()

  const params = Object.fromEntries([...searchParams])

  const columnsByRole = isAdmin
    ? columns
    : columns.filter((column) => column.id !== 'createdByFirstName')

  const [adminTasksQuery, userTasksQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAdminTasks, params],
        queryFn: () => getAdminTasks(params),
        select: (data: AxiosResponse<Task[]>) => data.data,
        enabled: isAdmin,
      },
      {
        queryKey: [queryKeys.getUserTasks, params],
        queryFn: () => getUserTasks(params),
        select: (data: AxiosResponse<Task[]>) => data.data,
        enabled: !isAdmin,
      },
    ],
  })

  const tasks = adminTasksQuery.data ?? userTasksQuery.data
  const isPending = adminTasksQuery.isFetching || userTasksQuery.isFetching

  const selectedRows = getSelectableIds(tasks ?? [], selectedTasks)

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.tasks.title"
              defaultMessage="Tasks"
            />
          </Typography>

          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.tasks.subtitle"
              defaultMessage="Review and manage requests"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <TasksTypeFilter />
        </FiltersWidget>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <TasksTypeBadge />
      </ActiveFilters>

      {!tasks?.length && !isPending && Object.keys(params).length === 0 ? (
        <EmptyTasks />
      ) : (
        <DataTable
          selectable
          onRowSelect={setSelectedTasks}
          columns={columnsByRole}
          key={Object.keys(selectedRows).length}
          initialRows={selectedRows}
          data={tasks ?? []}
          isLoading={isPending}
          loaderOptions={{ rows: 2 }}
          emptyState={{
            title: intl.formatMessage({
              id: 'tasks.table.empty.title',
              defaultMessage: 'No tasks found!',
            }),
            description: intl.formatMessage({
              id: 'tasks.table.empty.description',
              defaultMessage:
                'There were no tasks that matched your search criteria',
            }),
          }}
        />
      )}

      <FloatingTaskButton
        onActionCallback={(idx) => {
          setSelectedTasks((tasks) => tasks.filter((t) => !idx.includes(t.id)))
        }}
        tasks={selectedTasks}
      />
    </div>
  )
}
