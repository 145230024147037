import { z } from 'zod'

export function isValidRoutingNumber(routingNumber: string) {
  return routingNumber.length === 9
}

export function isValidClabe(clabe: string) {
  return clabe.length === 18
}

export function hasUpperCaseCharacter(value: string) {
  return /[A-Z]/.test(value)
}

export function hasLowerCaseCharacter(value: string) {
  return /[a-z]/.test(value)
}

export function hasNumber(value: string) {
  return /[0-9]/.test(value)
}

export function hasSpecialCharacter(value: string) {
  return /[^A-Za-z0-9]/.test(value)
}

export const PASSWORD_MIN_LENGTH = 12

export const passwordValidation = z
  .object({
    password: z
      .string()
      .min(PASSWORD_MIN_LENGTH, {
        message: 'validation.password.minLength',
      })
      .refine(hasUpperCaseCharacter, {
        message: 'validation.password.upperCase',
      })
      .refine(hasLowerCaseCharacter, {
        message: 'validation.password.lowerCase',
      })
      .refine(hasNumber, {
        message: 'validation.password.hasNumber',
      })
      .refine(hasSpecialCharacter, {
        message: 'validation.password.hasSpecialCharacter',
      }),
    confirmPassword: z.string().min(1, {
      message: 'validation.confirmPassword.required',
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'validation.password.match',
    path: ['confirmPassword'],
  })
