import { useEffect } from 'react'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getBusinessIdentity, getIdentity } from '@/domains/Business/api'
import { BusinessIdentity, Identity } from '@/domains/Business/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { Button, SlideInScreen, StickyContainer, Typography } from '@/shared/ui'

import { getOnboardingState, startOnboarding } from './api'
import { DocumentsList } from './components/DocumentsList'
import { OnboardingState } from './types'

export const OnboardingStart = () => {
  const notifyError = useErrorToast()
  const navigate = useNavigate()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: startOnboarding,
  })

  const [businessQuery, userQuery, onboardingStateQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getBusinessIdentity],
        queryFn: getBusinessIdentity,
        select: (data: AxiosResponse<BusinessIdentity>) => data.data,
        staleTime: 0,
      },
      {
        queryKey: [queryKeys.getIdentity],
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data.data,
      },
      {
        queryKey: [queryKeys.getOnboardingState],
        queryFn: getOnboardingState,
        select: (data: AxiosResponse<OnboardingState>) => data.data,
      },
    ],
  })

  useEffect(() => {
    if (businessQuery.data?.state === 'ACTIVE') {
      navigate(BusinessRoute.Dashboard, { replace: true })
    }
  }, [businessQuery.data?.state, navigate])

  const onStartOnboarding = async () => {
    try {
      const response = await mutateAsync()

      const [firstStep] = response.data.steps

      navigate(`${BusinessRoute.Onboarding}/${firstStep.name}`)

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getOnboardingState],
      })
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          id="onboarding.welcomeToApp"
          defaultMessage="Welcome to DolarApp, {name}"
          values={{
            name: userQuery.data?.firstName,
          }}
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          id="onboarding.infoNeeded"
          defaultMessage="We need the following information to get your account setup"
        />
      </Typography>

      <div className="p-6" />

      <DocumentsList steps={onboardingStateQuery.data?.steps} />

      <StickyContainer>
        <Button
          loading={isPending}
          disabled={isPending}
          width="full"
          onClick={onStartOnboarding}
        >
          <FormattedMessage
            id="onboarding.startApplication"
            defaultMessage="Start application"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
