import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { countryCodeByLocale, flagByLocale } from '@/constants/countries'
import { AVAILABLE_LANGUAGES, Locale } from '@/constants/locales'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { getFlagUrl } from '@/lib/images'
import { useLocale } from '@/providers/LocaleProvider'

import { Check } from '../icons/outline'
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '../ui'

type Props = {
  isOpen: boolean
  onOpenChange: (value: (prev: boolean) => boolean) => void
}

export const SwitchLanguageTooltip = ({ isOpen, onOpenChange }: Props) => {
  const intl = useIntl()
  const { locale, changeLocale } = useLocale()
  const isMobile = useMediaQuery(isSmallScreen)

  const getUserLocale = (lang: string) => {
    return AVAILABLE_LANGUAGES.find((l) => l.code === lang)?.lang
  }

  return (
    <Tooltip
      open={isOpen}
      onOpenChange={isMobile ? undefined : () => onOpenChange((o) => !o)}
      delayDuration={0}
    >
      <TooltipTrigger
        onClick={isMobile ? () => onOpenChange((o) => !o) : undefined}
        asChild
      >
        <Button
          className="w-full justify-start gap-2.5 font-normal focus-visible:ring-transparent"
          variant="ghost"
        >
          <img
            src={getFlagUrl(flagByLocale[locale])}
            alt={intl.formatMessage(
              {
                id: `country.flag.alt`,
                defaultMessage:
                  '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
              },
              { country: countryCodeByLocale[locale] },
            )}
            className="ml-0.5 size-4"
          />

          <span>
            <Typography>{getUserLocale(locale)}</Typography>
          </span>
        </Button>
      </TooltipTrigger>
      <TooltipContent align="start" side="right">
        {Object.values(Locale).map((lang) => (
          <Button
            disabled={lang === locale}
            key={lang}
            variant="ghost"
            className="w-full cursor-pointer justify-start gap-2.5 font-normal focus-visible:ring-transparent"
            onClick={() => changeLocale(lang)}
          >
            <span className="flex items-center gap-2">
              <img
                src={getFlagUrl(flagByLocale[lang])}
                alt={intl.formatMessage(
                  {
                    id: `country.flag.alt`,
                    defaultMessage:
                      '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                  },
                  { country: countryCodeByLocale[lang] },
                )}
                className="size-4"
              />
              <Typography>
                {AVAILABLE_LANGUAGES.find((l) => l.code === lang)?.lang}
              </Typography>
            </span>

            {lang === locale && (
              <Check className="size-4 shrink-0 text-neutral-gray-600" />
            )}
          </Button>
        ))}
      </TooltipContent>
    </Tooltip>
  )
}
