import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'

import { isSmallScreen } from '@/constants/breakpoints'
import {
  BusinessRoute,
  ContractorNavigationLink,
  navMessages,
} from '@/constants/paths'
import { useContractorLinks } from '@/domains/Contractor/hooks'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { cn } from '@/lib/utils'
import { LogoIcon, SlideInSpan } from '@/shared/components'
import { Typography } from '@/shared/ui'

import { ContractorProfileDropdown } from '../../components'

type NavigationLinksProps = {
  expanded?: boolean
  links: ContractorNavigationLink[]
}

const NavigationLinks = ({ expanded, links }: NavigationLinksProps) => {
  const intl = useIntl()

  return (
    <ul className="flex w-full flex-col items-start gap-4 text-neutral-gray-100">
      {links.map(({ key, path, icon: Icon }) => {
        return (
          <li className="w-full" key={key}>
            <NavLink
              end={path === BusinessRoute.Dashboard}
              to={path}
              className={cn(
                'relative z-10 flex w-full items-center justify-start gap-3 whitespace-nowrap rounded-xl py-2 outline-none transition-colors aria-[current=page]:font-bold md:h-10 md:p-2 md:hover:bg-neutral-gray-900 md:focus:bg-neutral-gray-900 aria-[current=page]:md:bg-neutral-gray-900',
              )}
            >
              <span className="relative size-6 shrink-0">
                <Icon
                  className={cn('size-6 shrink-0 justify-self-center', {
                    'ml-0.5 mt-0.5 size-5': key === 'cards',
                  })}
                  aria-label={intl.formatMessage(navMessages[key])}
                />
              </span>

              <AnimatePresence mode="wait">
                {expanded && (
                  <SlideInSpan>
                    <Typography>
                      {intl.formatMessage(navMessages[key])}
                    </Typography>
                  </SlideInSpan>
                )}
              </AnimatePresence>
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}

export const ContractorSidebar = () => {
  const isMobile = useMediaQuery(isSmallScreen)

  const links = useContractorLinks()

  const [openPopover, setOpenPopover] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const onMouseOver = () => {
    if (isMobile) return

    setExpanded(true)
  }

  const onMouseOut = () => {
    if (isMobile) return

    setExpanded(false)
    setOpenPopover(false)
  }

  return (
    <nav
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseOut}
      className={cn(
        'fixed z-50 min-h-screen w-12 bg-neutral-gray-1000 px-3 py-6 transition-all duration-300 md:w-16',
        expanded && 'md:w-60',
      )}
    >
      <div className="hide-scrollbar flex h-screen flex-col overflow-y-auto overflow-x-hidden">
        <ContractorProfileDropdown
          open={openPopover}
          onOpenChange={setOpenPopover}
          expanded={expanded}
        />

        <div className="p-6" />

        <NavigationLinks expanded={expanded} links={links} />

        <div className="mb-16 mt-auto flex w-full md:pl-2.5">
          <LogoIcon aria-label="DolarApp Business logo" className="size-5" />
        </div>
      </div>
    </nav>
  )
}
