import * as React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cva, VariantProps } from 'class-variance-authority'
import { FormattedMessage } from 'react-intl'

import { cn } from '@/lib/utils'

import { X } from '../icons/outline'

import { Typography } from '.'

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const dialogContentVariants = cva(
  'fixed left-[50%] top-[50%] z-50 grid w-[90%] max-w-lg translate-x-[-50%] translate-y-[-50%] rounded-xl bg-white p-6 shadow-lg sm:rounded-lg',
  {
    variants: {
      variant: {
        default:
          'duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:max-w-[425px]',
        fullscreen:
          'bg-neutral-gray-100 max-w-screen overflow-y-auto h-screen w-screen duration-200 px-6 pt-28 data-[state=open]:fade-in-0 data-[state=closed]:animate-out data-[state=closed]:zoom-out data-[state=closed]:fade-out-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-neutral-gray-900/30 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
    VariantProps<typeof dialogContentVariants> & {
      className?: string
      hideCloseButton?: boolean
    }
>(
  (
    {
      hideCloseButton,
      className,
      children,
      variant,

      ...props
    },
    ref,
  ) => {
    return (
      <DialogPortal>
        <DialogOverlay />

        <DialogPrimitive.Content
          ref={ref}
          className={cn(
            dialogContentVariants({
              variant,
              className,
            }),
          )}
          {...props}
        >
          {hideCloseButton ? null : (
            <>
              <DialogPrimitive.Close className="ml-auto w-fit rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-1 focus:ring-neutral-gray-600 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-neutral-100 data-[state=open]:text-neutral-gray-600 sm:hidden">
                <X className="scale-125" />
                <span className="sr-only">
                  <FormattedMessage defaultMessage="Close" id="action.close" />
                </span>
              </DialogPrimitive.Close>
              <div className="p-2" />
            </>
          )}
          {children}
        </DialogPrimitive.Content>
      </DialogPortal>
    )
  },
)
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  className?: string
}) => (
  <div
    className={cn('flex flex-col gap-3 text-center sm:text-left', className)}
    {...props}
  />
)
DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  className?: string
}) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  />
)
DialogFooter.displayName = 'DialogFooter'

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & {
    className?: string
  }
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Title
    asChild
    ref={ref}
    className={cn('text-center', className)}
    {...props}
  >
    <Typography variant="h4">{children}</Typography>
  </DialogPrimitive.Title>
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description> & {
    className?: string
  }
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Description
    asChild
    ref={ref}
    className={cn('text-center text-neutral-gray-600', className)}
    {...props}
  >
    <Typography>{children}</Typography>
  </DialogPrimitive.Description>
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

const DialogActions = ({
  className,
  children,
}: {
  children: React.ReactNode
  className?: string
}) => <div className={cn('grid grid-cols-2 gap-3', className)}>{children}</div>
DialogActions.displayName = 'DialogActions'

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogActions,
}
