import { Role } from '@/domains/Business/types/roles'
import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

import {
  InvitedTeamMember,
  LimitPeriodType,
  MovementPermission,
} from '../types'

type InviteBusinessUserRequest = {
  role: Role
  firstName: string
  lastName: string
  secondLastName?: string
  userTitle?: string
  email: string
  transferPermissions?: {
    type: MovementPermission
    limitPeriodType?: LimitPeriodType
    periodTransferLimitAmount?: string
    singleTransferLimitAmount?: string
  }
} & OTPCode

export function inviteBusinessUser(data: InviteBusinessUserRequest) {
  return api.post<InvitedTeamMember>(`/v1/me/business-user-invitations`, data)
}
