import { failedTransactionStatuses } from '@/domains/Business/features/Transactions/constants'
import {
  SingleTransaction,
  TransactionType,
} from '@/domains/Business/features/Transactions/types'
import { formatAmount } from '@/lib/money'
import { cn } from '@/lib/utils'

import { Typography } from '../../ui'

type Props = {
  transaction: SingleTransaction
}

export const SingleAmountCell = ({ transaction }: Props) => {
  const isFailed = failedTransactionStatuses.includes(
    transaction.transactionStatus,
  )

  return (
    <div className="flex flex-col justify-center">
      <Typography
        className={cn('whitespace-nowrap', isFailed && 'line-through')}
      >
        {formatAmount({
          amount: transaction.baseAmount,
          currency: transaction.baseCurrency,
        })}
      </Typography>
      {![TransactionType.FEE, TransactionType.CASHBACK].includes(
        transaction.transactionType,
      ) && (
        <Typography
          className={cn(
            'whitespace-nowrap text-neutral-gray-400',
            isFailed && 'line-through',
          )}
        >
          {formatAmount({
            amount: transaction.localAmount,
            currency: transaction.localCurrency,
          })}
        </Typography>
      )}
    </div>
  )
}
