import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const typographyVariants = cva('', {
  variants: {
    variant: {
      h1: 'text-7xl font-bold',
      h2: '',
      h3: 'text-2xl md:text-2.5xl font-bold',
      h4: 'text-2xl font-bold',
      h5: '',
      h6: '',
      body: 'text-sm',
      'body-small': 'text-xs',
      'body-tiny': 'text-[0.625rem]',
      'body-base': 'text-base',
      button: 'text-base font-bold',
    },
    bold: {
      true: 'font-bold',
    },
    text: {
      right: 'text-right',
      left: 'text-left',
      center: 'text-center',
    },
  },
  defaultVariants: {
    variant: 'body',
    bold: false,
    text: 'left',
  },
})

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body'
  | 'button'
  | 'body-small'
  | 'body-base'
  | 'body-tiny'

const variantsMapping: Record<TypographyVariant, React.ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body: 'p',
  'body-small': 'p',
  'body-base': 'p',
  'body-tiny': 'p',
  button: 'p',
}

export interface TypographyProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof typographyVariants> {
  bold?: boolean
}

export const Typography = React.forwardRef<HTMLButtonElement, TypographyProps>(
  ({ bold, className, text, variant, ...props }, ref) => {
    const Comp: React.ElementType = variant ? variantsMapping[variant] : 'p'

    return (
      <Comp
        className={cn(
          typographyVariants({
            variant,
            bold,
            text,
            className,
          }),
        )}
        ref={ref}
        {...props}
      />
    )
  },
)

Typography.displayName = 'Button'
