import { FormattedMessage, useIntl } from 'react-intl'

import { ContractorDetails } from '@/domains/Contractor/types'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Widget } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Document } from '@/shared/icons/solid'
import { Skeleton, Typography } from '@/shared/ui'

import { useContractorPaymentDate } from '../hooks'

import { ContractStateBadge } from './ContractStateBadge'

type Props = {
  onClick: () => void
  contractor?: ContractorDetails
}

export const ContractsSection = ({ onClick, contractor }: Props) => {
  const intl = useIntl()

  const latestContract = contractor?.contracts[0]

  const paymentDate = useContractorPaymentDate({
    dayType: latestContract?.paymentDetails?.dayType,
    day: latestContract?.paymentDetails?.day,
  })

  if (!latestContract) {
    return (
      <Widget
        title={
          <FormattedMessage defaultMessage="Contracts" id="label.contracts" />
        }
      >
        <div className="flex items-center justify-between">
          <Skeleton className="h-[39px] w-36" />
          <Skeleton className="h-[21px] w-36" />
        </div>
      </Widget>
    )
  }

  return (
    <Widget
      onClick={onClick}
      className="group"
      title={
        <FormattedMessage defaultMessage="Contracts" id="label.contracts" />
      }
    >
      <div className="md:flex-no-wrap flex w-full flex-wrap items-center justify-between gap-3 md:gap-0">
        <div className="flex items-center gap-2">
          <div className="flex size-9 shrink-0 items-center justify-center rounded-lg bg-neutral-gray-100">
            <Document className="size-5 text-neutral-gray-900" />
          </div>

          <div className="flex flex-col">
            <Typography bold>
              <FormattedMessage
                id="contract.type.label"
                defaultMessage="{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}"
                values={{ state: latestContract.paymentType }}
              />
            </Typography>
            <Typography
              variant="body-small"
              className="md:whitespace-no-wrap whitespace-break-spaces text-neutral-gray-600"
            >
              {latestContract.paymentDetails ? (
                intersperse(
                  [
                    latestContract.contractorRole,
                    formatAmount(latestContract.paymentDetails),
                    intersperse(
                      [
                        intl.formatMessage(
                          {
                            id: 'contractor.paymentFrequency.label',
                            defaultMessage:
                              '{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}',
                          },
                          {
                            paymentFrequency:
                              latestContract.paymentDetails.frequency,
                          },
                        ),
                        paymentDate,
                      ],
                      ', ',
                    ),
                  ],
                  ' • ',
                )
              ) : (
                <FormattedMessage
                  id="contractor.paymentDetails.startDate"
                  defaultMessage="Start date on {startDate}"
                  values={{
                    startDate: intl.formatDate(latestContract.startDate, {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                      timeZone: 'UTC',
                    }),
                  }}
                />
              )}
            </Typography>
          </div>
        </div>

        <div className="flex items-center">
          <ContractStateBadge state={latestContract.state} />

          <ChevronRight className="size-8 shrink-0 text-neutral-gray-600 group-hover:translate-x-1 group-hover:transition-all" />
        </div>
      </div>
    </Widget>
  )
}
