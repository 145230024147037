import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

type GetPinRequest = {
  id: string
} & OTPCode

export async function getCardPin({ id, otp }: GetPinRequest) {
  return api.post<{ pin: string }>(`/v1/me/physical-cards/${id}/pin`, { otp })
}
