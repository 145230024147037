import { Navigate, RouteObject } from 'react-router-dom'

import { ContractorRoute } from '@/constants/paths'
import { Loader } from '@/shared/components'

import { Contractor2FAChecker } from './Contractor2FAChecker'
import { ContractorActivityChecker } from './ContractorActivityChecker'
import { contractorOnboardingRoutes } from './ContractorOnboarding'
import { contractorOverviewRoutes } from './ContractorOverview'
import { ContractorSetup2FAScreen } from './ContractorSetup2FA'
import { ContractorStateChecker } from './ContractorStateChecker'

export const contractorRoutes: RouteObject[] = [
  {
    path: ContractorRoute.Root,
    element: <Navigate to={ContractorRoute.SigningIn} />,
  },
  {
    path: ContractorRoute.SigningIn,
    element: <ContractorStateChecker />,
  },
  {
    path: ContractorRoute.LoggingOut,
    element: <Loader />,
  },
  {
    path: ContractorRoute.ContractorSetup2fa,
    element: <ContractorSetup2FAScreen />,
  },
  {
    element: (
      <Contractor2FAChecker>
        <ContractorActivityChecker />
      </Contractor2FAChecker>
    ),
    children: [...contractorOverviewRoutes, ...contractorOnboardingRoutes],
  },
]
