import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import { MOCKED_BUSINESS_USER } from './businessUser.mock'
import { MOCK_COUNTRY_CODES } from './countryCodes.mocks'
import {
  BUSINESS_IDENTITY_MOCK_RESPONSE,
  IDENTITY_MOCK_RESPONSE,
} from './identity.mocks'
import { MOCK_PAYMENT_LIMITS } from './paymentLimits.mocks'
import { PRICE_CHART_RATES } from './priceRates.mocks'

export const apiHandlers = [
  http.get(`${BASE_API_DEV}/v2/signup/country-codes`, async () => {
    return HttpResponse.json(MOCK_COUNTRY_CODES, { status: 200 })
  }),

  http.get(
    `${BASE_API_DEV}/v1/me/withdrawal-quotes/from/:from/to/:to`,
    async () => {
      return HttpResponse.json({ fxRate: 17.022 }, { status: 200 })
    },
  ),

  http.get(`${BASE_API_DEV}/v1/me/fx-rate`, async () => {
    return HttpResponse.json(
      {
        symbol: 'usdc_mxn',
        bidRate: 17.022,
        askRate: 17.042,
        midRate: 17.0585,
        rateChangeToday: -0.001,
        rateChangeTodayPercentage: -0.00586339,
      },
      { status: 200 },
    )
  }),
  http.get(`${BASE_API_DEV}/v1/price-chart`, async () => {
    return HttpResponse.json(PRICE_CHART_RATES)
  }),

  http.get(`${BASE_API_DEV}/v1/me/identity/details`, async () => {
    return HttpResponse.json(IDENTITY_MOCK_RESPONSE)
  }),

  http.get(`${BASE_API_DEV}/v1/me/business/details`, async () => {
    return HttpResponse.json(BUSINESS_IDENTITY_MOCK_RESPONSE)
  }),

  http.get(`${BASE_API_DEV}/v1/my/business-users`, async () => {
    return HttpResponse.json(MOCKED_BUSINESS_USER)
  }),

  http.get(`${BASE_API_DEV}/v1/me/business/payment-limits`, async () => {
    return HttpResponse.json(MOCK_PAYMENT_LIMITS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/one-time-passwords/2FA`, async () => {
    return HttpResponse.json({
      identityId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
      otpMethod: 'EMAIL',
    })
  }),
]

export * from './countryCodes.mocks'
export * from './identity.mocks'
export * from './priceRates.mocks'
