import { FormattedMessage } from 'react-intl'

import { EntityType } from '@/domains/Business/features/Recipients/types'
import { SingleTransaction } from '@/domains/Business/features/Transactions/types'
import { parseAdditionalDetails } from '@/lib/utils'

import { TransactionIcon } from '../../../domains/Business/components/TransactionIcon'
import { Typography } from '../../ui'

type Props = {
  transaction: SingleTransaction
}

export const SingleTransactionCell = ({ transaction }: Props) => {
  const beneficiaryEntityType = parseAdditionalDetails<{
    beneficiaryEntityType?: EntityType
  }>(transaction.additionalDetails)?.beneficiaryEntityType

  return (
    <div className="flex items-center gap-3">
      <TransactionIcon
        additionalDetails={transaction.additionalDetails}
        recipientType={beneficiaryEntityType}
        transactionType={transaction.transactionType}
        transactionStatus={transaction.transactionStatus}
      />

      <div className="flex items-center gap-1">
        <Typography className="line-clamp-1">
          <FormattedMessage
            id="transaction.typeLabel"
            defaultMessage="{type, select, DEPOSIT {From:} WITHDRAW {To:} INTERNAL_SWAP_DEPOSIT {From:} INTERNAL_SWAP_WITHDRAW {To:} other {}}"
            values={{ type: transaction.transactionType }}
          />{' '}
          {transaction.name}
        </Typography>
      </div>
    </div>
  )
}
