import { useEffect, useMemo } from 'react'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { Feature, useBusinessRole, useFeatures } from '@/domains/Business/hooks'
import { Loader } from '@/shared/components'

type Props = {
  children: React.ReactNode
}

export const PermissionsChecker = ({ children }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isCardUser, isPending } = useBusinessRole()

  const [
    homeEnabled,
    tasksEnabled,
    accountsEnabled,
    cardsEnabled,
    recipientsEnabled,
    transactionsEnabled,
    teamEnabled,
    transferActionsEnabled,
    contractorsEnabled,
  ] = useFeatures([
    Feature.HOME,
    Feature.TASKS,
    Feature.ACCOUNTS,
    Feature.CARDS,
    Feature.RECIPIENTS,
    Feature.TRANSACTIONS,
    Feature.TEAM,
    Feature.ACTIONS,
    Feature.CONTRACTORS,
  ])

  const redirectRoute = useMemo(() => {
    if (isCardUser) {
      return BusinessRoute.Cards
    }

    return BusinessRoute.Dashboard
  }, [isCardUser])

  const pathToPermissionsMap = useMemo(
    () => [
      {
        paths: [
          BusinessRoute.Cards,
          BusinessRoute.CardsTransactions,
          BusinessRoute.CreateCard,
        ],
        disabled: !cardsEnabled,
      },
      {
        paths: [BusinessRoute.Team, BusinessRoute.AddTeamMember],
        disabled: !teamEnabled,
      },
      {
        paths: [BusinessRoute.Dashboard],
        disabled: !homeEnabled,
      },
      {
        paths: [BusinessRoute.Recipients, BusinessRoute.AddRecipients],
        disabled: !recipientsEnabled,
      },
      {
        paths: [
          BusinessRoute.Transactions,
          BusinessRoute.BulkPayment,
          BusinessRoute.BulkPaymentTransactions,
        ],
        disabled: !transactionsEnabled,
      },
      {
        paths: [BusinessRoute.Tasks, BusinessRoute.ReviewTask],
        disabled: !tasksEnabled,
      },
      {
        paths: [
          BusinessRoute.Accounts,
          BusinessRoute.CreateAccount,
          BusinessRoute.SingleAccount,
          BusinessRoute.AccountTransactions,
        ],
        disabled: !accountsEnabled,
      },
      {
        paths: [
          BusinessRoute.AddBalance,
          BusinessRoute.Transfer,
          BusinessRoute.Send,
        ],
        disabled: !transferActionsEnabled,
      },
      {
        paths: [
          BusinessRoute.Contractors,
          BusinessRoute.ContractorsDetails,
          BusinessRoute.ContractorsOverview,
          BusinessRoute.ContractorsAll,
          BusinessRoute.ContractorsHistoricalPayments,
          BusinessRoute.ContractorsCreate,
        ],
        disabled: !contractorsEnabled,
      },
    ],
    [
      accountsEnabled,
      cardsEnabled,
      contractorsEnabled,
      homeEnabled,
      recipientsEnabled,
      tasksEnabled,
      teamEnabled,
      transactionsEnabled,
      transferActionsEnabled,
    ],
  )

  useEffect(() => {
    const matchPermission = pathToPermissionsMap.find((permission) =>
      permission.paths.some((path) =>
        matchRoutes([{ path }], location.pathname),
      ),
    )

    if (matchPermission?.disabled) {
      navigate(redirectRoute, { replace: true })
    }
  }, [location.pathname, navigate, pathToPermissionsMap, redirectRoute])

  return isPending ? <Loader /> : <>{children}</>
}
