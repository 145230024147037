import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useCountryCodes } from '@/hooks/useCountryCodes'
import { getCountryNameByISO3 } from '@/lib/country'
import { cn } from '@/lib/utils'
import { Check, ChevronDown } from '@/shared/icons/outline'
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

type Value = {
  valueAsCode: string
  valueAsName: string
}

const SUBSET_COUNTRIES = {
  'sign-up': Object.values(CountryCode).filter(
    (country) => ![CountryCode.CL, CountryCode.PT].includes(country),
  ),
  'contractor-onboarding': [
    CountryCode.MX,
    CountryCode.AR,
    CountryCode.CO,
    CountryCode.BR,
    CountryCode.US,
  ],
}

type Props = {
  disabled?: boolean
  onSelect: (value: Value) => void
  placeholder?: string
  subset?: 'sign-up' | 'contractor-onboarding' | 'all'
  value?: string
}

export const CountryCombobox = ({
  disabled,
  onSelect,
  placeholder,
  subset = 'all',
  value,
}: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const countryCodesQuery = useCountryCodes()

  const selected = useMemo(
    () => countryCodesQuery.data?.find((country) => country.isoCode === value),
    [countryCodesQuery.data, value],
  )

  return (
    <FormItem className="flex flex-col">
      <Popover modal open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              disabled={disabled}
              width="full"
              type="button"
              variant="outline"
              role="combobox"
              className={cn(
                'h-[54px] justify-between rounded-lg px-4 font-normal hover:bg-white',
              )}
            >
              {selected ? (
                <div className="flex flex-col items-start">
                  <Typography
                    className="text-neutral-gray-600"
                    variant="body-tiny"
                  >
                    {placeholder}
                  </Typography>
                  <span className="flex items-center gap-1">
                    <span className="mt-0.5">{selected.flag}</span>
                    <Typography>
                      {getCountryNameByISO3(
                        selected.isoCode,
                        intl.locale,
                        selected.nameToShow,
                      )}
                    </Typography>
                  </span>
                </div>
              ) : (
                <Typography className="text-neutral-gray-600">
                  {placeholder}
                </Typography>
              )}

              <ChevronDown className="shrink-0 text-neutral-gray-600" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-full p-0 md:w-[600px]">
          <Command
            filter={(value, search) => {
              const searchValue = search.toLowerCase().trim()

              const [code, country] = value.split('-')

              if (
                country.includes(searchValue.trim().toLowerCase()) ||
                code.includes(searchValue.trim().toLowerCase())
              ) {
                return 1
              }

              return 0
            }}
          >
            <CommandList>
              <CommandInput
                placeholder={intl.formatMessage({
                  id: 'placeholder.searchCountry',
                  defaultMessage: 'Search country',
                })}
              />
              <CommandEmpty>
                <FormattedMessage
                  id="placeholder.search.noCountryFound"
                  defaultMessage="No country found"
                />
              </CommandEmpty>
              <CommandGroup>
                {countryCodesQuery.data
                  ?.filter((country) =>
                    subset === 'all'
                      ? true
                      : SUBSET_COUNTRIES[subset].includes(
                          country.isoCode as CountryCode,
                        ),
                  )
                  .map((country) => (
                    <CommandItem
                      value={`${country.isoCode}-${country.name}`}
                      key={country.isoCode}
                      disabled={country.isoCode === selected?.isoCode}
                      onSelect={() => {
                        onSelect({
                          valueAsCode: country.isoCode,
                          valueAsName: country.name,
                        })
                        setOpen((open) => !open)
                      }}
                    >
                      <Typography className="flex items-center gap-1">
                        <span className="mt-0.5">{country.flag}</span>
                        {getCountryNameByISO3(country.isoCode, intl.locale)}
                      </Typography>

                      <Check
                        className={cn(
                          'ml-2 h-4 w-4',
                          country.isoCode === value
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
  )
}
