import { Permission, Role } from '../../types'

export const MOCKED_BUSINESS_USER = {
  id: 'ab9890c1-8ed5-4f52-a1fd-1039ba5ddce4',
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
  email: 'maks@dolarapp.com',
  userTitle: undefined,
  firstName: 'Maks',
  lastName: 'Akymenko',
  secondLastName: '',
  state: 'ACTIVE',
  role: Role.ADMIN,
  permissions: [
    Permission.TEAM_MANAGEMENT,
    Permission.CARDS_MANAGEMENT,
    Permission.CARDS_ACCESS,
    Permission.TRANSFERS,
    Permission.READ_ACCESS,
    Permission.REQUEST_APPROVALS,
  ],
}

export const MOCKED_PAYMENT_OPS_BUSINESS_USER = {
  id: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  identityId: 'baa98df2-0831-4ac9-954f-346de5b66f41',
  email: 'john.doe@test.com',
  userTitle: undefined,
  firstName: 'John',
  lastName: 'Doe',
  secondLastName: undefined,
  state: 'ACTIVE',
  role: Role.PAYMENT_OPS,
  permissions: [
    Permission.TEAM_MANAGEMENT,
    Permission.TRANSFERS,
    Permission.READ_ACCESS,
    Permission.REQUEST_APPROVALS,
  ],
}
