import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { ContractorRoute } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { passwordValidation } from '@/lib/validations'
import { useAuth } from '@/providers/AuthProvider'
import { EyeOnOffButton, PasswordRules } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { acceptContractorInvitation } from './api'

const PASSWORD_FORM_ID = 'password-form'

export type PasswordSchema = z.infer<typeof passwordValidation>

export const ContractorAcceptInvite = () => {
  const [showPassword, setShowPassword] = useState(false)

  const { updateRefreshToken } = useAuth()
  const notifyError = useErrorToast()
  const navigate = useNavigate()
  const intl = useIntl()

  const [identityId, businessId, otp] = useSearchParamsValue([
    'identity',
    'business',
    'otp',
  ])

  const form = useForm<PasswordSchema>({
    mode: 'onChange',
    resolver: zodResolver(passwordValidation),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: acceptContractorInvitation,
  })

  const onSubmit: SubmitHandler<PasswordSchema> = async (data) => {
    if (!identityId || !businessId || !otp) {
      toast.error(
        intl.formatMessage({
          defaultMessage:
            'Required data is missing. Please ensure you used correct link',
          id: 'auth.error.missingData',
        }),
      )

      return
    }

    try {
      const response = await mutateAsync({
        rawPassword: data.password,
        identityId,
        businessId,
        otp,
      })

      updateRefreshToken(response.data.refreshToken)

      navigate(ContractorRoute.SigningIn, { replace: true })
    } catch (error: unknown) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Choose your password and get started!"
          id="choosePassword.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="This will be your password to log into the account. Make sure it is secure and don't share it with anyone"
          id="choosePassword.subtitle"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form id={PASSWORD_FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="new-password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Password',
                        id: 'label.password',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Password"
                      id="label.password"
                    />
                  </AnimatedFormLabel>

                  <EyeOnOffButton
                    show={showPassword}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="new-password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Confirm password',
                        id: 'label.confirmPassword',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Confirm password"
                      id="label.confirmPassword"
                    />
                  </AnimatedFormLabel>

                  <PasswordRules password={form.getValues('password')} />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          form={PASSWORD_FORM_ID}
          disabled={isPending || !form.formState.isValid}
          loading={isPending}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Create password"
            id="action.createPassword"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
