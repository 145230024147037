import { RouteObject } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'

import { AddRecipient } from './AddRecipient'
import { Recipients } from './Recipients'

export const recipientsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Recipients,
    element: <Recipients />,
  },
]

export const addRecipientRoutes: RouteObject[] = [
  {
    path: BusinessRoute.AddRecipients,
    element: <AddRecipient />,
  },
]
