import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import {
  ConfirmTransaction,
  ReviewTransactions,
  SelectTransactions,
  UploadCSV,
} from './components/steps'
import { BulkPaymentQuote } from './types'

enum Step {
  Upload = 'upload',
  Select = 'select',
  Review = 'review',
  Confirm = 'confirm',
}

type State =
  | { type: Step.Upload }
  | { type: Step.Select }
  | { type: Step.Review; selectedIdx: string[] }
  | {
      type: Step.Confirm
      quote: BulkPaymentQuote & {
        selectedPayments: string[]
        walletId: string
      }
    }

export const BulkPayments = () => {
  const location = useLocation()

  const [activeStep, setActiveStep] = useState<State>({ type: Step.Upload })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Upload:
        return 25
      case Step.Select:
        return 50
      case Step.Review:
        return 75
      case Step.Confirm:
        return 100
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Recipients}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Upload && (
          <UploadCSV
            onContinue={() =>
              setActiveStep({
                type: Step.Select,
              })
            }
          />
        )}
        {activeStep.type === Step.Select && (
          <SelectTransactions
            onBack={() => setActiveStep({ type: Step.Upload })}
            onContinue={(selectedIdx) =>
              setActiveStep({ type: Step.Review, selectedIdx })
            }
          />
        )}
        {activeStep.type === Step.Review && (
          <ReviewTransactions
            selectedIdx={activeStep.selectedIdx}
            onBack={() => setActiveStep({ type: Step.Select })}
            onContinue={(quote) => {
              setActiveStep({
                type: Step.Confirm,
                quote,
              })
            }}
          />
        )}
        {activeStep.type === Step.Confirm && (
          <ConfirmTransaction
            quote={activeStep.quote}
            onBack={() =>
              setActiveStep({
                type: Step.Review,
                selectedIdx: activeStep.quote.selectedPayments,
              })
            }
          />
        )}
      </AnimatePresence>
    </FullScreen>
  )
}
