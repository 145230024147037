import { Role } from '@/domains/Business/types/roles'
import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

import { LimitPeriodType, MovementPermission, TeamMember } from '../types'

type EditTeamMemberRequest = {
  id: string
  role: Role
  firstName: string
  lastName: string
  secondLastName?: string
  userTitle?: string
  transferPermissions?: {
    type: MovementPermission
    limitPeriodType?: LimitPeriodType
    periodTransferLimitAmount?: string
    singleTransferLimitAmount?: string
  }
} & OTPCode

export function editTeamMemberDetails(data: EditTeamMemberRequest) {
  const { id, ...rest } = data

  return api.put<TeamMember>(`/v1/me/business-users/${id}`, rest)
}
