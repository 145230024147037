import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { useBusinessRole } from '@/domains/Business/hooks'
import { Role } from '@/domains/Business/types/roles'
import { Loader } from '@/shared/components'

export const RoleChecker = () => {
  const location = useLocation()
  const { role } = useBusinessRole()
  const navigate = useNavigate()

  useEffect(() => {
    switch (role) {
      case Role.ADMIN:
      case Role.PAYMENT_OPS:
      case Role.READ_ONLY: {
        navigate(location.state?.from ?? BusinessRoute.Dashboard, {
          replace: true,
        })
        break
      }

      case Role.CARD_USER:
        navigate(BusinessRoute.Cards, { replace: true })
        break

      default:
        break
    }
  }, [role, navigate, location])

  return <Loader />
}
