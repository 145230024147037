import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import {
  CREATED_CARD,
  MOCKED_CARD_DELIVERY,
  MOCKED_CARDS,
  MOCKED_ENCRYPTED_CARD_DETAILS,
} from './cards.mocks'

export const cardsHandlers = [
  http.get(`${BASE_API_DEV}/v1/me/cards/search`, async () => {
    return HttpResponse.json(MOCKED_CARDS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/cards/:id`, async ({ params }) => {
    const { id } = params

    const card = MOCKED_CARDS.find((card) => card.id === id)

    if (!card) {
      return HttpResponse.json(MOCKED_CARDS[0])
    }

    return HttpResponse.json(card)
  }),

  http.post(`${BASE_API_DEV}/v1/me/virtual-cards`, async () => {
    return HttpResponse.json(MOCKED_CARDS[0])
  }),
  http.post(`${BASE_API_DEV}/v1/me/physical-cards`, async () => {
    return HttpResponse.json(CREATED_CARD)
  }),

  http.post(`${BASE_API_DEV}/v1/me/card-encryption-keys`, async () => {
    return HttpResponse.json({})
  }),

  http.get(`${BASE_API_DEV}/v1/me/cards/:id/details`, async () => {
    return HttpResponse.json(MOCKED_ENCRYPTED_CARD_DETAILS)
  }),

  http.post(`${BASE_API_DEV}/v1/me/cards/:id/unblock`, async () => {
    return HttpResponse.json(MOCKED_CARDS[0])
  }),

  http.post(`${BASE_API_DEV}/v1/me/cards/:id/block`, async () => {
    return HttpResponse.json(MOCKED_CARDS[0])
  }),

  http.post(`${BASE_API_DEV}/v1/me/cards/:id/terminate`, async () => {
    return HttpResponse.json(MOCKED_CARDS[0])
  }),

  http.get(`${BASE_API_DEV}/v1/me/card-deliveries/:id`, async () => {
    return HttpResponse.json(MOCKED_CARD_DELIVERY)
  }),

  http.post(`${BASE_API_DEV}/v1/me/physical-cards/:id/pin`, async () => {
    return HttpResponse.json({ pin: '1111' })
  }),
  http.post(`${BASE_API_DEV}/v1/me/physical-cards/:id/activate`, async () => {
    return HttpResponse.json(MOCKED_CARDS[0])
  }),
  http.patch(`${BASE_API_DEV}/v1/me/cards`, async () => {
    return HttpResponse.json(MOCKED_CARDS[0])
  }),
]

export * from './cards.mocks'
