import { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input/input'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import { termsMessages } from '@/constants/messages'
import { BusinessRoute } from '@/constants/paths'
import {
  BRIDGE_LEGAL_URL,
  DOLAR_APP_PRIVACY_URL,
  DOLAR_APP_TERMS_URL,
} from '@/constants/urls'
import { parseFullPhoneNumber } from '@/lib/phone'
import { cn } from '@/lib/utils'
import { CountryCodeField, OptionalTag } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  buttonVariants,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

const USER_STEP_FORM_ID = 'user-step-form-id'

const DEFAULT_VALUES: UserStepSchema = {
  firstName: '',
  lastName: '',
  secondLastName: '',
  email: '',
  userTitle: '',
  internationalPhonePrefix: '+52',
  localPhoneNumber: '',
}

const userStepSchema = z.object({
  firstName: z.string().min(1, {
    message: 'validation.firstName.required',
  }),
  lastName: z.string().min(1, {
    message: 'validation.lastName.required',
  }),
  secondLastName: z.string().optional(),
  userTitle: z.string().optional(),
  email: z
    .string()
    .min(1, {
      message: 'validation.email.required',
    })
    .email({
      message: 'validation.email.invalid',
    }),
  internationalPhonePrefix: z.string().min(1, {
    message: 'validation.phoneCode.required',
  }),
  localPhoneNumber: z
    .string()
    .min(1, {
      message: 'validation.phoneNumber.required',
    })
    .refine(isValidPhoneNumber, {
      message: 'validation.phoneNumber.invalid',
    }),
})

export type UserStepSchema = z.infer<typeof userStepSchema>

type Props = {
  onContinue: (data: UserStepSchema) => void
}

export const UserStep = ({ onContinue }: Props) => {
  const intl = useIntl()

  const form = useForm<UserStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(userStepSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const onSubmit: SubmitHandler<UserStepSchema> = useCallback(
    (data) => {
      onContinue({ ...data, localPhoneNumber: parseFullPhoneNumber(data) })
    },
    [onContinue],
  )

  const { firstName, lastName, email, localPhoneNumber } = form.watch()

  const hasRequiredFields = [
    firstName,
    lastName,
    email,
    localPhoneNumber,
  ].every(Boolean)

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Let's start with the basics"
          id="signUp.letsStart"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form id={USER_STEP_FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="given-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'First name',
                        id: 'label.firstName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="First name"
                      id="label.firstName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="family-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Last name',
                        id: 'label.lastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Last name"
                      id="label.lastName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="secondLastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="additional-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Second last name',
                        id: 'label.secondLastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Second last name"
                      id="label.secondLastName"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            {/* <FormField
              control={form.control}
              name="userTitle"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Title',
                        id: 'label.title',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage defaultMessage="Title" id="label.title" />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            /> */}

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Work email',
                        id: 'label.workEmail',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Work email"
                      id="label.workEmail"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <div className="flex items-stretch gap-3">
              <FormField
                control={form.control}
                name="internationalPhonePrefix"
                render={({ field }) => {
                  return (
                    <CountryCodeField
                      value={field.value}
                      onSelect={(value) =>
                        form.setValue('internationalPhonePrefix', value)
                      }
                    />
                  )
                }}
              />

              <FormField
                control={form.control}
                name="localPhoneNumber"
                render={({ field }) => (
                  <FormItem className="relative flex-1">
                    <FormControl>
                      <PhoneNumberInput
                        phonePrefix={form.watch('internationalPhonePrefix')}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Phone number',
                          id: 'label.phoneNumber',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Phone number"
                        id="label.phoneNumber"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>

      <StickyContainer className="flex flex-col gap-4">
        <Typography text="center">
          <FormattedMessage
            {...termsMessages.terms}
            values={{
              dolarAppTerms: (
                <a
                  href={DOLAR_APP_TERMS_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={cn(
                    buttonVariants({
                      variant: 'link',
                      size: 'inline',
                    }),
                  )}
                >
                  <FormattedMessage {...termsMessages.dolarAppTerms} />
                </a>
              ),
              dolarAppPrivacy: (
                <a
                  href={DOLAR_APP_PRIVACY_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={cn(
                    buttonVariants({
                      variant: 'link',
                      size: 'inline',
                    }),
                  )}
                >
                  <FormattedMessage {...termsMessages.dolarAppPrivacy} />
                </a>
              ),
              bridgeTerms: (
                <a
                  href={BRIDGE_LEGAL_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={cn(
                    buttonVariants({ variant: 'link', size: 'inline' }),
                  )}
                >
                  <FormattedMessage {...termsMessages.bridgeTerms} />
                </a>
              ),
            }}
          />
        </Typography>

        <Button
          width="full"
          form={USER_STEP_FORM_ID}
          disabled={!hasRequiredFields}
          type="submit"
          onClick={form.handleSubmit(onSubmit)}
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>

        <div className="flex flex-col items-center gap-3">
          <Typography>
            <FormattedMessage
              defaultMessage="Already have an account?"
              id="auth.alreadyHaveAccount"
            />{' '}
            <Link
              className={cn(
                buttonVariants({ variant: 'link', size: 'inline' }),
              )}
              to={BusinessRoute.SignIn}
            >
              <FormattedMessage defaultMessage="Sign in" id="action.signIn" />
            </Link>
          </Typography>
        </div>
      </StickyContainer>
    </SlideInScreen>
  )
}
