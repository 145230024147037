import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { queryKeys } from '@/constants/queryKeys'
import { getTransactions } from '@/domains/Business/features/Transactions/api'
import { DisplayableType } from '@/domains/Business/features/Transactions/types'
import { formatCurrency } from '@/lib/money'
import { handleURLSearchParams } from '@/lib/utils'

import { Coin } from '../../icons/outline'
import { Filter } from '../../icons/solid'
import {
  Button,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  Spinner,
} from '../../ui'

import { CURRENCY_FILTER_NAME } from './data'

export const CurrencyFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isPending } = useQuery({
    queryKey: [queryKeys.getTransactions],
    queryFn: () => getTransactions(),
    select: (data) =>
      data?.data
        .flatMap((tx) => {
          switch (tx.displayableType) {
            case DisplayableType.SINGLE:
              return tx.localCurrency
            case DisplayableType.BULK:
              return null
          }
        })
        .filter((val, index, self) => self.indexOf(val) === index)
        .filter(Boolean),
  })

  function handleSearchParams(value: string) {
    return (
      setSearchParams((params) =>
        handleURLSearchParams(params, CURRENCY_FILTER_NAME, value),
      ),
      { preventScrollReset: true }
    )
  }

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            disabled={isPending}
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              {isPending ? (
                <Spinner className="size-4" />
              ) : (
                <Coin className="size-4" />
              )}
              <FormattedMessage defaultMessage="Currency" id="label.currency" />
            </span>
            {searchParams.get(CURRENCY_FILTER_NAME) && (
              <Filter className="size-4" />
            )}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="w-52">
            <DropdownMenuCheckboxItem
              onSelect={(event) => event.preventDefault()}
              checked={searchParams.get(CURRENCY_FILTER_NAME) === null}
              onCheckedChange={() =>
                setSearchParams(
                  (params) => {
                    params.delete(CURRENCY_FILTER_NAME)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }
            >
              <FormattedMessage
                id="label.selectAll"
                defaultMessage="Select all"
              />
            </DropdownMenuCheckboxItem>

            {(data ?? []).map((currency) => {
              if (currency === null) {
                return null
              }

              return (
                <DropdownMenuCheckboxItem
                  key={currency}
                  onSelect={(event) => event.preventDefault()}
                  checked={searchParams
                    .get(CURRENCY_FILTER_NAME)
                    ?.split(',')
                    ?.includes(currency)}
                  onCheckedChange={() => handleSearchParams(currency)}
                >
                  {formatCurrency(currency)}
                </DropdownMenuCheckboxItem>
              )
            })}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
