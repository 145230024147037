import { FormattedMessage } from 'react-intl'

import {
  SingleTransaction,
  TransactionType,
} from '@/domains/Business/features/Transactions/types'
import { getTransactionPaymentMethod } from '@/domains/Business/features/Transactions/utils'
import { nonEmptyString } from '@/lib/typography'
import { parseAdditionalDetails } from '@/lib/utils'

import { Card, Details } from '../../ui'

type Props = {
  transaction?: SingleTransaction
}

export const PaymentDetails = ({ transaction }: Props) => {
  const additionalDetails = parseAdditionalDetails<{
    // MX fields
    beneficiaryClabe?: string | null
    payerClabe?: string | null
    // US fields
    paymentMessage?: string | null
    beneficiaryAccountNumber?: string | null
    beneficiaryRoutingNumber?: string | null
    beneficiaryPaymentMethod?: string | null
    payerRoutingNumber?: string | null
    paymentMethod?: string | null
    payerBankName?: string | null
  }>(transaction?.additionalDetails)

  const userClabe =
    additionalDetails?.beneficiaryClabe ?? additionalDetails?.payerClabe

  const routingNumber =
    additionalDetails?.beneficiaryRoutingNumber ??
    additionalDetails?.payerRoutingNumber

  const paymentMethod = getTransactionPaymentMethod(transaction)

  const paymentMessage =
    nonEmptyString(transaction?.description) ||
    nonEmptyString(additionalDetails?.paymentMessage)

  const getPaymentMessage = () => {
    switch (transaction?.transactionType) {
      case TransactionType.DEPOSIT:
      case TransactionType.WITHDRAW: {
        return paymentMessage ? (
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentReferenceConcepto"
                defaultMessage="Payment reference / Concepto"
              />
            </Details.Label>
            <Details.Value>{paymentMessage}</Details.Value>
          </Details>
        ) : null
      }

      default:
        return null
    }
  }

  const showAdditionalDetails =
    paymentMethod ||
    routingNumber ||
    userClabe ||
    additionalDetails?.paymentMessage ||
    additionalDetails?.beneficiaryAccountNumber ||
    additionalDetails?.payerBankName ||
    !!getPaymentMessage()

  if (showAdditionalDetails) {
    return (
      <Card className="flex flex-col gap-4" size="medium">
        {paymentMethod ? (
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>

            <Details.Value>{paymentMethod}</Details.Value>
          </Details>
        ) : null}

        {routingNumber ? (
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.routingNumber"
                defaultMessage="ABA / Routing number"
              />
            </Details.Label>
            <Details.Value>{routingNumber}</Details.Value>
          </Details>
        ) : null}

        {additionalDetails?.beneficiaryAccountNumber ? (
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.accountNumber"
                defaultMessage="Account number"
              />
            </Details.Label>
            <Details.Value>
              {additionalDetails.beneficiaryAccountNumber}
            </Details.Value>
          </Details>
        ) : null}
        {additionalDetails?.payerBankName ? (
          <Details>
            <Details.Label>
              <FormattedMessage id="label.bank" defaultMessage="Bank" />
            </Details.Label>
            <Details.Value>{additionalDetails.payerBankName}</Details.Value>
          </Details>
        ) : null}
        {userClabe ? (
          <Details>
            <Details.Label>
              <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
            </Details.Label>

            <Details.Value>{userClabe}</Details.Value>
          </Details>
        ) : null}

        {getPaymentMessage()}
      </Card>
    )
  }

  return null
}
