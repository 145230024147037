import { Fragment, useCallback, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useCheckUserPaymentLimit } from '@/domains/Business/hooks'
import { useErrorToast } from '@/hooks/useErrorToast'
import {
  formatAmount,
  formatCurrency,
  formatMoney,
  formatRate,
} from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import { GoBackButton, OptionalTag, Widget } from '@/shared/components'
import { InfoCircle } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Checkbox,
  Details,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  SlideInScreen,
  StickyContainer,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '@/shared/ui'

import { scheduleBulkPayment } from '../../api'
import { BulkPaymentQuote } from '../../types'

const CONFIRM_FORM_ID = 'confirm-transaction-form-id'

const confirmSchema = z.object({
  label: z.string().optional().or(z.literal('')),
  shouldNotifyRecipients: z.boolean(),
})

export type ConfirmSchema = z.infer<typeof confirmSchema>

type Props = {
  onBack: () => void
  quote: BulkPaymentQuote & {
    walletId: string
    selectedPayments: string[]
  }
}

export const ConfirmTransaction = ({ onBack, quote }: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const notifyError = useErrorToast()
  const [searchParams] = useSearchParams()

  const isOverLimit = useCheckUserPaymentLimit()

  const bulkPaymentId = searchParams.get('id')

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: scheduleBulkPayment,
  })

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(confirmSchema),
    defaultValues: {
      label: '',
      shouldNotifyRecipients: true,
    },
  })

  const onSubmit = useCallback(
    async (data: ConfirmSchema) => {
      removeEmptyFormFields(data)

      try {
        if (!bulkPaymentId) {
          toast.error(
            intl.formatMessage({
              defaultMessage: 'Bulk payment ID is missing',
              id: 'bulkPayment.uploadFile.missingPaymentId',
            }),
          )

          return
        }

        await mutateAsync({
          ...data,
          bulkPaymentId,
          walletId: quote.walletId,
          selectedPayments: quote.selectedPayments,
        })

        if (isOverLimit(quote.totalAmount.amount)) {
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.getUserTasks],
          })

          toast.success(
            intl.formatMessage({
              id: 'bulkPayment.task.submitted',
              defaultMessage:
                'Bulk payment request submitted and pending approval',
            }),
          )

          navigate(BusinessRoute.Tasks)

          return
        }

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getTransactions],
        })

        toast.success(
          intl.formatMessage({
            id: 'bulkPayment.sentSuccessfully',
            defaultMessage: 'Payment sent!',
          }),
        )

        navigate(BusinessRoute.Transactions)
      } catch (error) {
        notifyError(error)
      }
    },
    [
      bulkPaymentId,
      intl,
      isOverLimit,
      navigate,
      notifyError,
      quote.selectedPayments,
      quote.totalAmount.amount,
      quote.walletId,
      mutateAsync,
    ],
  )

  const exchangeRate = useMemo(() => {
    const MXNRate = quote.paymentQuoteDetails.find(
      (details) => details.subtotalAmount.currency === Currency.MXN,
    )

    if (!MXNRate) {
      return null
    }

    return `1 ${formatCurrency(Currency.USDC)} = ${formatRate(MXNRate.fxRate)} ${formatCurrency(MXNRate.subtotalAmount.currency)}`
  }, [quote.paymentQuoteDetails])

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Confirm bulk payment"
            id="bulkPayment.confirmTransactions.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="bulkPayment.confirmTransactions.subtitle"
            defaultMessage="Make a final check and send your bulk payment"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CONFIRM_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Total payment"
                  id="label.totalPayment"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Total recipients"
                    id="bulkPayments.confirmScreen.totalRecipients"
                  />
                </Details.Label>
                <Details.Value>{quote.totalNumberOfPayments}</Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Total payment amount"
                    id="bulkPayments.confirmScreen.totalPaymentAmount"
                  />
                </Details.Label>
                <Details.Value>
                  {formatMoney(quote.totalAmount.amount)}{' '}
                  {formatCurrency(quote.totalAmount.currency)}
                </Details.Value>
              </Details>

              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Your fee"
                    id="bulkPayments.confirmScreen.fee"
                  />
                </Details.Label>
                <div className="flex items-center gap-1">
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Button size="inline" className="size-4" variant="ghost">
                        <InfoCircle className="size-4" />
                      </Button>
                    </TooltipTrigger>
                    <div className="flex gap-1 text-right">
                      <Typography className="line-through">
                        {formatAmount(quote.feeBeforeDiscount)}
                      </Typography>
                      <Typography bold className="uppercase text-primary">
                        <FormattedMessage
                          defaultMessage="Free"
                          id="label.free"
                        />
                      </Typography>
                    </div>

                    <TooltipContent className="max-w-[300px]">
                      <Typography>
                        <FormattedMessage
                          id="bulkPayments.fee.tooltip"
                          defaultMessage="All fees are waived when sending to other DolarApp accounts"
                        />
                      </Typography>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </Details>
            </Widget>

            <div className="p-3" />

            <Widget
              title={
                <FormattedMessage
                  id="recipient.paymentDetails"
                  defaultMessage="Payment details"
                />
              }
            >
              {quote.paymentQuoteDetails.map((details) => (
                <Fragment
                  key={
                    details.subtotalAmount.amount +
                    details.subtotalAmount.currency
                  }
                >
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        defaultMessage="{currency} recipients"
                        id="bulkPayments.confirmScreen.recipients"
                        values={{
                          currency: details.subtotalAmount.currency,
                        }}
                      />
                    </Details.Label>
                    <Details.Value>
                      {details.subtotalNumberOfPayments}
                    </Details.Value>
                  </Details>
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        defaultMessage="{currency} payment total"
                        id="bulkPayments.confirmScreen.paymentTotal"
                        values={{
                          currency: details.subtotalAmount.currency,
                        }}
                      />
                    </Details.Label>
                    <Details.Value>
                      {formatMoney(details.subtotalAmount.amount)}{' '}
                      {formatCurrency(details.subtotalAmount.currency)}
                    </Details.Value>
                  </Details>
                </Fragment>
              ))}
              {exchangeRate ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage defaultMessage="Rate" id="label.rate" />
                  </Details.Label>
                  <Details.Value>{exchangeRate}</Details.Value>
                </Details>
              ) : null}
            </Widget>

            <div className="p-3" />

            <FormField
              control={form.control}
              name="label"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Payment label (e.g. March Payroll)',
                        id: 'bulkPayments.confirmScreen.paymentLabel',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Payment label (e.g. March Payroll)"
                      id="bulkPayments.confirmScreen.paymentLabel"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <div className="p-2" />

            <FormField
              control={form.control}
              name="shouldNotifyRecipients"
              render={({ field }) => (
                <FormItem className="flex items-center gap-3 px-2">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>

                  <FormLabel>
                    <Typography className="text-neutral-gray-800">
                      <FormattedMessage
                        defaultMessage="Notify recipients via email when this payment is sent"
                        id="bulkPayments.confirmScreen.notifyRecipient"
                      />
                    </Typography>
                  </FormLabel>
                </FormItem>
              )}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={CONFIRM_FORM_ID}
            disabled={isPending || isSuccess}
            onClick={form.handleSubmit(onSubmit)}
            loading={isPending || isSuccess}
            type="submit"
          >
            {isOverLimit(quote.totalAmount.amount) ? (
              <FormattedMessage
                defaultMessage="Send request for approval"
                id="action.sendRequestForApproval"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Send {value} {currency}"
                id="send.reviewScreen.sendAction"
                values={{
                  value: formatMoney(quote.totalAmount.amount),
                  currency: formatCurrency(quote.totalAmount.currency),
                }}
              />
            )}
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
