import { contractorApi } from '@/lib/api'
import { CountryCode } from '@/types/country'

import { ContractorOnboardingState } from '../types'

type PersonalInformationRequest = {
  dateOfBirth: string
  countryOfCitizenship: CountryCode
  residentialAddress: {
    addressStreetNumber: string
    addressStreet: string
    addressDistrict?: string
    addressCity: string
    addressState: string
    addressPostCode: string
    addressCountry: string
  }
  countryOfTaxResidence: CountryCode
  taxId: string
}

export const submitPersonalInformation = (data: PersonalInformationRequest) => {
  return contractorApi.post<ContractorOnboardingState>(
    '/v1/contractor-onboarding/personal-information',
    data,
  )
}
