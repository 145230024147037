import { cn } from '@/lib/utils'

type Props = {
  children: React.ReactNode
  className?: string
}

export const StickyContainer = ({ children, className }: Props) => {
  return (
    <div
      className={cn(
        'sticky bottom-0 mt-auto flex w-full flex-col gap-2 bg-gradient-to-b from-transparent from-5% to-neutral-gray-100 to-30% py-12',
        className,
      )}
    >
      {children}
    </div>
  )
}
