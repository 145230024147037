import { Currency } from '@/constants/currency'
import { PaymentLimitType } from '@/domains/Business/types'

import {
  Card,
  CardDelivery,
  CardDeliveryState,
  CardState,
  CardType,
} from '../../types'

export const CREATED_CARD: Card = {
  id: '0abd95c1-501e-4f88-92b4-3767b7f56db6',
  walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
  printedUserName: 'MAKS AKYMENKO',
  nickname: 'Physical DolarApp',
  state: CardState.CREATED,
  type: CardType.PHYSICAL,
  lastFourDigits: null,
  validToMMYY: null,
  validDate: null,
  cardLimit: {
    limitAmount: 1234,
    usedAmount: 0,
    limitCurrency: Currency.USDC,
    limitType: PaymentLimitType.WEEKLY,
    periodResetDate: '2024-08-19T05:00:00',
  },
  createdAt: '2024-08-12T12:49:20.16936',
}

export const MOCKED_CARDS: Card[] = [
  {
    id: 'ae461e3a-759a-4f03-90a8-c5e644a6adf6',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
    printedUserName: 'MAKS AKYMENKO',
    nickname: 'Card hehe',
    state: CardState.BLOCKED,
    type: CardType.VIRTUAL,
    lastFourDigits: '4231',
    validToMMYY: '06/27',
    validDate: '2027-06-30',
    createdAt: '2024-06-17T09:40:46.32225',
    cardLimit: {
      limitAmount: 1000,
      limitCurrency: Currency.USDC,
      limitType: PaymentLimitType.DAILY,
      periodResetDate: '2024-08-01T05:00:00',
      usedAmount: 500,
    },
  },
  {
    id: 'c6be52b9-1b0c-4d39-9ef2-cdf2bc30d54b',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
    printedUserName: 'MAKS AKYMENKO',
    nickname: 'Card 🤘',
    state: CardState.ACTIVE,
    type: CardType.VIRTUAL,
    lastFourDigits: '3924',
    validToMMYY: '06/27',
    validDate: '2027-06-30',
    createdAt: '2024-06-10T11:05:13.435637',
    cardLimit: {
      limitAmount: 1000,
      limitCurrency: Currency.USDC,
      limitType: PaymentLimitType.WEEKLY,
      periodResetDate: '2024-08-01T05:00:00',
      usedAmount: 500,
    },
  },
  {
    id: '20ab244e-fca0-4d6f-8e49-b7c7f32f4eba',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
    printedUserName: 'MAKS AKYMENKO',
    nickname: 'DolarApp Card',
    state: CardState.CREATED,
    type: CardType.VIRTUAL,
    lastFourDigits: '9860',
    validToMMYY: '06/27',
    validDate: '2027-06-30',
    createdAt: '2024-06-17T09:17:17.349145',
    cardLimit: {
      limitAmount: 1000,
      limitCurrency: Currency.USDC,
      limitType: PaymentLimitType.DAILY,
      periodResetDate: '2024-08-01T05:00:00',
      usedAmount: 500,
    },
  },
  {
    id: '4718f659-394c-42bd-b666-29248179d897',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
    printedUserName: 'MAKS AKYMENKO',
    nickname: 'First card',
    state: CardState.BLOCKED,
    type: CardType.VIRTUAL,
    lastFourDigits: '9761',
    validToMMYY: '06/27',
    validDate: '2027-06-30',
    createdAt: '2024-06-10T10:52:32.126804',
    cardLimit: {
      limitAmount: 1000,
      limitCurrency: Currency.USDC,
      limitType: PaymentLimitType.WEEKLY,
      periodResetDate: '2024-08-01T05:00:00',
      usedAmount: 500,
    },
  },
  {
    id: '86f50502-c77a-4386-aa90-04f6b950de26',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
    printedUserName: 'MAKS AKYMENKO',
    nickname: 'Second card',
    state: CardState.TERMINATED,
    type: CardType.VIRTUAL,
    lastFourDigits: '4491',
    validToMMYY: '06/27',
    validDate: '2027-06-30',
    createdAt: '2024-06-10T11:03:47.946764',
    cardLimit: {
      limitAmount: 1000,
      limitCurrency: Currency.USDC,
      limitType: PaymentLimitType.YEARLY,
      periodResetDate: '2024-08-01T05:00:00',
      usedAmount: 500,
    },
  },
  CREATED_CARD,
]

export const MOCKED_ENCRYPTED_CARD_DETAILS: {
  encryptedCardDetails: string
  encryptedKey: string
  iv: string
} = {
  encryptedCardDetails:
    '757e8ccb3b0498338e6ae4c339772b8d4a954902c0ec826ec7fed908f58b320b751c322969fcd73e0566c45a78fbef298db0a192882f6a1f572d12ceefff3e2d6ec8eed7a6e0beac8ad134a4500ed10b4eae6135c285a880622d8db20871c029f4e121d4df17e60a029b5d39e5bf470e7aa433588dfe5357d5c1908e161ce36642e8cf4a48da4e01b57694d143b5cd06d089222c4bc930955e689b82c153c49757e16fe873548c3fe2eb8ac9195930b9e07b1edf114c79db81ce9a2b32b5568ff287ceae2dee3b6ba0012945f0b3d54d0c415cf3235a94435e46dbcc189918e84206b824d270652d41bf6cac505f31841ce451e78483cc5847c5375732903e248e95603a95c5e3ec7866eb8747f39d0e9df6e42b5a41f52bdfddf3ca9ec4eeab8dedd692253a6e3bc93c2e3def1734c057cc30577e3dcf32dae2fb74234f7101fa056addc7fe74be783be9f8a66e711d137031beb1f173dd857cdd4721116888ccd82b9d2b40d287307a42ca5044ef8ca3f670c32e099a81c678c90c45f4807f0598540cb5043920bfe438b4810f59d2c2a0d4f52544dde567419e197f756f70d2c4d404e86cd9264424fd84c43020c2db91b10fce0a1e2342e49af27c3884426a17be09886ffeda4318b859ca823543eb3f901e50f570af9242fb6a1f19d536',
  encryptedKey:
    '4a214a8896e0a42f2a3324c9585ad7c8b2a10bde0613ae1413fe53b51d7bb7e2f13bf7f39946656d55bff72ef40f1f73ec66e1dc0146b8e63366144675d5fc694e16df1f0932814757cdb811553e9c03b37ab549524d5c34a07696ec60b51de8bb2aafd4e699b32cf1afd431f1dd703700c025ddbc5966b429e3913aaabffdd1f59c1400874ed729f03138555fdc3b156f6ea190d6284f886af0f4c48064e34fd141c5ed957b2ab2a414e64f1efb4cbad71c4dbfe9f901f0b082ac4273845cc77d18cdc0b926a3235064f37161aa055dbb33cba78a8da1f51f7bd4b3d831a881b37850135413986140ee0426fb97f17067c9ef84c6108387bfa4f607bb24d84f',
  iv: '21f6022389b648f4c349214a6fa7350f',
}

export const MOCKED_CARD_DELIVERY: CardDelivery = {
  id: '9b2a47af-bc78-40bc-bb5e-37e32d06e18b',
  cardId: 'd73b3637-799d-4daf-9992-ab6363a5b34a',
  street: 'Calle Reforma',
  streetNumber: '123',
  neighborhood: 'Centro',
  city: 'Ciudad de Mexico',
  country: 'Mexico',
  postcode: '01000',
  state: 'CDMX',
  status: CardDeliveryState.SHIPPED,
  createdAt: '2024-08-19T10:14:43.115284',
  updatedAt: '2024-08-19T10:14:43.11529',
  courierCompany: 'NINENTY_MINUTOS_COURIER_NAME',
  courierTrackingId: '8574745',
  courierLocation: '99.242',
  courierLocationType: '99 MINUTOS',
  courierType: 'Geo',
  externalCourierTrackingId: '12515',
  externalCourierTrackingUrl: 'http://example.com',
}

export const MOCKED_PUBLIC_RSA_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkRaVVBolfPbSyK9AhmyH
TpjopOv2BS1GFOPoxc+iP0u7WQ5NwuLvYPKjnYtK/3kNYblUvvObUh1xJqo6koSk
IhztRUAz3PwLdniVN4bx3WsMYm53y/RolvTiqERCE6xIPvKi874GOPl9iI6MsX98
YnSoLldjk3itMp7NhSqbzUPeeitVYGcN1DxvYdV7VaTqGd6jDbLUH83bmH0M93h4
polYYZnsmikNyO2guM+ntAzDO9vJAAxOLOsiL2OunKoQwBUFoNHzYVp0KyrRX7Jy
BR8W4iZXDX9RdBpoUTKff267heN9gWV+wUBEPoxkc9UqB/bg+fVh4q35MX4lY0wH
cwIDAQAB
-----END PUBLIC KEY-----`

export const MOCKED_PRIVATE_RSA_KEY = `-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEAkRaVVBolfPbSyK9AhmyHTpjopOv2BS1GFOPoxc+iP0u7WQ5N
wuLvYPKjnYtK/3kNYblUvvObUh1xJqo6koSkIhztRUAz3PwLdniVN4bx3WsMYm53
y/RolvTiqERCE6xIPvKi874GOPl9iI6MsX98YnSoLldjk3itMp7NhSqbzUPeeitV
YGcN1DxvYdV7VaTqGd6jDbLUH83bmH0M93h4polYYZnsmikNyO2guM+ntAzDO9vJ
AAxOLOsiL2OunKoQwBUFoNHzYVp0KyrRX7JyBR8W4iZXDX9RdBpoUTKff267heN9
gWV+wUBEPoxkc9UqB/bg+fVh4q35MX4lY0wHcwIDAQABAoIBAHqg+9X5w3W7qKy2
c3Mqh7KGyxuZ1HbLrVA60aNfu2KEP/UlgdQWuvzWGDQwZ1NGnWjbnCVJAR6Gg/yK
JLdyJMgwatr9SNier5GSmYw1Inhi4jTP6bEZAs2UbX6LMs4t72JE8jXRhrDhLpkc
4w2jEx6kOwHwGtmqRbljP5IuUUFLr+mBe6Vh3QK+0Kht/xzjHbylJKZ5+ZOtzbx3
kT+zjx4o/9fBKgc07H8U72FI3g99e6nyCUtYZxa+TCEBbfc4Haxq1+ONRrpzbGu0
eu6KwEAKGc+yMrortpKJOMsYiEzjCcOM94aESBJ95bGP2bvTDagx8E12PusZCFnG
2TPMaAECgYEA6hDXRjo98Q090/oAYSlN9BQCibK95Alv7WBleoXgcsZXNK6IXYGv
dOnylUfogiSMuTVDPUkQ/e5gQFAEs+AB6ZBwfVBVD2gFzdB5kctu4/dlFBpRt/uW
kIgllLywv1GVjeH/UsCuzTsC2Ac0WCP+HikrKcE4t2HNGN3EsOizDuECgYEAnq8z
WfNMJtAJBIXcUGYnqR7XEJN1KNCHL4oltMt9VYHgqRgS4bprwI+0SSgtGjVOl4YD
03RGW2n/aE8dPxjD3C8o4Bhpw9iGmvh9AxPeLlFpu/gismL1/Iev21MPK5QjBPCK
tyjZO4y4NWzH8x3LK9Y4TXtn3fajHZ1ztd8jRNMCgYEA192KPDlQkPQuVkXVfcYT
H4wovwd6e0l80UY6sKGxWwiyumJQXzRQNZng4G3977B3nAOULHwrUJU8bXOesqlf
+iD8rYWdvOSpnaOrypr8fP/XV4/iV7SOqmL1jdPribZbIAwSZiZPz/ShbcxPKQ6g
uP4s0YhinzVyaoF0eddR8+ECgYAf6aQuJtvdwmDN5A3WrXS0cltWHhGlz6ogSWwj
2/isxZ3k486ITvF0nHHpdIb9ilLv7ie+0teDcuHGnIKJOfXJxkI2LzK2DEmlyOt7
Pvej4QOepdx0e6p0yuT2+tFGNliEmcuL4k8txrMVK3da0VcWzdIs4vCUlxFe11d5
8BIruQKBgQDnJl/s8R4csKdjN8jwz8GtjBe8R5Jnnylcg5Iek1knjNuYFi60ry7z
wGn1DRMM+A3RBGXEcg+ht/T2pNcbQItO7pWUgFu344UzSs7AekUs0ozPzOgsnbxu
bCdc74HHx2P7Kzk6SqHecmP7KglcXaAqYEFAwO7H/BuCn5KXlABCJA==
-----END RSA PRIVATE KEY-----`
