import { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import { taxIdByCountry } from '@/constants/countries'
import { BusinessRoute } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { cn, removeEmptyFormFields } from '@/lib/utils'
import { CountryCombobox } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  buttonVariants,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { signUp } from '../api'

import { CompanyTypeCombobox } from './CompanyTypeCombobox'
import { UserStepSchema } from './UserStep'

const COMPANY_STEP_FORM_ID = 'company-step-form-id'

const DEFAULT_VALUES: CompanyStepSchema = {
  countryOfIncorporation: '',
  companyType: '',
  legalName: '',
  taxId: '',
  taxIdType: '',
}

const companyStepSchema = z.object({
  legalName: z.string().min(1, {
    message: 'validation.legalName.required',
  }),
  countryOfIncorporation: z.string().min(1, {
    message: 'validation.country.required',
  }),
  companyType: z.string().trim().min(1),
  taxId: z.string().min(1, {
    message: 'validation.taxId.required',
  }),
  taxIdType: z.string().min(1, {
    message: 'validation.taxId.type',
  }),
})

export type CompanyStepSchema = z.infer<typeof companyStepSchema>

type Props = {
  userData: UserStepSchema
  onContinue: ({ signupId, email }: { signupId: string; email: string }) => void
}

export const CompanyStep = ({ userData, onContinue }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const form = useForm<CompanyStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(companyStepSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: signUp,
  })

  const onSubmit: SubmitHandler<CompanyStepSchema> = useCallback(
    async (data) => {
      removeEmptyFormFields(data)

      try {
        const response = await mutateAsync({
          ...userData,
          ...data,
        })

        onContinue({
          signupId: response.data.signupId,
          email: userData.email,
        })
      } catch (error: unknown) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    },
    [notifyError, onContinue, mutateAsync, userData],
  )

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Tell us about your company"
          id="signUp.companyInformation"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form id={COMPANY_STEP_FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            <FormField
              control={form.control}
              name="countryOfIncorporation"
              render={({ field }) => (
                <FormItem>
                  <CountryCombobox
                    placeholder={intl.formatMessage({
                      id: 'label.countryOfIncorporation',
                      defaultMessage: 'Country of incorporation',
                    })}
                    subset="sign-up"
                    onSelect={(value) => {
                      field.onChange(value.valueAsCode)

                      if (form.getValues('companyType')) {
                        form.setValue('companyType', '')
                        form.trigger('companyType')
                      }

                      form.setValue(
                        'taxIdType',
                        taxIdByCountry[value.valueAsCode as CountryCode] ??
                          'OTHER',
                      )
                    }}
                    value={field.value}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="legalName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.legalName',
                        defaultMessage: 'Legal Name',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.legalName"
                      defaultMessage="Legal Name"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="companyType"
              render={({ field }) => (
                <FormItem>
                  <CompanyTypeCombobox
                    country={form.watch('countryOfIncorporation')}
                    placeholder={intl.formatMessage({
                      id: 'label.companyType',
                      defaultMessage: 'Company type',
                    })}
                    onSelect={(value) => {
                      field.onChange(value)
                    }}
                    value={field.value}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="taxId"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.taxId',
                        defaultMessage: 'Tax ID',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="auth.taxIdType"
                      defaultMessage="{taxIdType, select, CUIT {CUIT} EIN {EIN} NIT {NIT (include verification digit)} RFC {RFC} RUT_CHL {RUT} RUT_URY {RUT} CNPJ {CNPJ} NIP_ESP {NIP} NIPC {NIPC} other {Tax ID}}"
                      values={{ taxIdType: form.getValues('taxIdType') }}
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>

      <div className="p-8" />

      <div className="mt-auto flex flex-col gap-3">
        <Button
          width="full"
          form={COMPANY_STEP_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          disabled={isPending || !form.formState.isValid}
          loading={isPending}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>

        <div className="flex flex-col items-center gap-3">
          <Typography>
            <FormattedMessage
              defaultMessage="Already have an account?"
              id="auth.alreadyHaveAccount"
            />{' '}
            <Link
              className={cn(
                buttonVariants({ variant: 'link', size: 'inline' }),
              )}
              to={BusinessRoute.SignIn}
            >
              <FormattedMessage defaultMessage="Sign in" id="action.signIn" />
            </Link>
          </Typography>
        </div>
      </div>

      <div className="p-6" />
    </SlideInScreen>
  )
}
