import {
  BASE_CONTRACTOR_API_DEV,
  BASE_CONTRACTOR_API_PROD,
} from '@/constants/urls'
import { generateFingerprint } from '@/lib/fingerprint'
import { isProduction } from '@/lib/utils'

export type RefreshTokenRequest = {
  refreshToken: string
}

const BASE_URL = isProduction()
  ? BASE_CONTRACTOR_API_PROD
  : BASE_CONTRACTOR_API_DEV

// we use fetch cause we make this request inside axios interceptor
export async function refreshContractorToken() {
  const token = sessionStorage.getItem('access-token')
  const refreshToken = sessionStorage.getItem('refresh-token')

  const deviceFingerprint = await generateFingerprint()

  if (!token || !refreshToken) {
    return Promise.reject('No token or device id or refresh token')
  }

  return fetch(`${BASE_URL}/v1/authentication/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Device-Fingerprint': deviceFingerprint,
    },
    body: JSON.stringify({ refreshToken }),
  })
}
