import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

export const sendHandlers = [
  http.post(`${BASE_API_DEV}/v2/me/withdrawals`, async () => {
    return HttpResponse.json({})
  }),
  http.post(`${BASE_API_DEV}/v1/me/internal-swaps`, async () => {
    return HttpResponse.json({})
  }),
]
