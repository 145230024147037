import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

import { TaskState } from '../types'

type ApproveTaskRequest = {
  id: string
} & OTPCode

type ApproveTaskResponse = {
  id: string
  state: TaskState.APPROVED
}

export function approveTask({ id, otp }: ApproveTaskRequest) {
  return api.post<ApproveTaskResponse>(`/v1/me/business-tasks/${id}/approve`, {
    otp,
  })
}
