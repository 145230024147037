import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'
import { TwoFactorMethod } from '@/types/auth'

export const signInHandlers = [
  http.post(`${BASE_API_DEV}/v1/signup`, async () => {
    return HttpResponse.json({
      signupId: 'baa98df2-0831-4ac9-954f-346de5b66f41',
    })
  }),
  http.post(`${BASE_API_DEV}/v1/verification`, async () => {
    return HttpResponse.json({})
  }),
  http.post(`${BASE_API_DEV}/v1/authentication`, async () => {
    return HttpResponse.json({
      identityId: 'baa98df2-0831-4ac9-954f-346de5b66f41',
      twoFactorAuthentication: {
        otpMethod: TwoFactorMethod.EMAIL,
        initialSetup: false,
      },
    })
  }),
  http.post(`${BASE_API_DEV}/v1/signin`, async () => {
    return HttpResponse.json({})
  }),
  http.post(`${BASE_API_DEV}/v1/password-recovery`, async () => {
    return HttpResponse.json({})
  }),
  http.post(`${BASE_API_DEV}/v1/password-change`, async () => {
    return HttpResponse.json({})
  }),
  http.post(`${BASE_API_DEV}/v1/invitation-verifications`, async () => {
    return HttpResponse.json({})
  }),
  http.get(`${BASE_API_DEV}/v1/signup/company-types`, async () => {
    return HttpResponse.json([
      'Asociación Civil',
      'Sociedad Anónima',
      'Sociedad Anónima de Capital Variable',
      'Sociedad Anónima Promotora de Inversión',
      'Sociedad Anónima Promotora de Inversión de Capital Variable',
      'Sociedad Anónima Simplificada',
      'Sociedad Anónima Simplificada de Capital Variable',
      'Sociedad Cooperativa',
      'Sociedad de Responsabilidad Limitada',
      'Sociedad en Comandita por Acciones',
      'Sociedad en Comandita Simple',
      'Sociedad en Nombre Colectivo',
    ])
  }),
]

export * from './auth.mocks'
