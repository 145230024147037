import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import {
  RecipientCountryStep,
  RecipientDetailsStep,
  RecipientEntityStep,
  RecipientReviewStep,
} from './components/AddRecipientSteps'
import { CreatedRecipient, EntityType } from './types'

enum Step {
  Country = 'country',
  Entity = 'entity',
  Details = 'Details',
  Review = 'review',
}

type FormState =
  | { type: Step.Country }
  | { type: Step.Entity }
  | { type: Step.Details }
  | { type: Step.Review }

export const AddRecipient = () => {
  const location = useLocation()
  const [entityType, setEntityType] = useState<EntityType>(EntityType.BUSINESS)
  const [country, setCountry] = useState<CountryCode>(CountryCode.MX)
  const [recipient, setRecipient] = useState<CreatedRecipient>()

  const [activeStep, setActiveStep] = useState<FormState>({
    type: Step.Country,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Country:
        return 0
      case Step.Entity:
        return 25
      case Step.Details:
        return 50
      case Step.Review:
        return 75
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Recipients}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Country ? (
          <RecipientCountryStep
            onContinue={(v) => {
              setCountry(v)

              setActiveStep({ type: Step.Entity })
            }}
          />
        ) : null}

        {activeStep.type === Step.Entity ? (
          <RecipientEntityStep
            onBack={() => setActiveStep({ type: Step.Country })}
            onContinue={(v) => {
              setEntityType(v)

              setActiveStep({ type: Step.Details })
            }}
          />
        ) : null}

        {activeStep.type === Step.Details ? (
          <RecipientDetailsStep
            country={country}
            entityType={entityType}
            recipient={recipient}
            onBack={() => {
              setRecipient(undefined)
              setActiveStep({ type: Step.Entity })
            }}
            onContinue={(recipient) => {
              setRecipient(recipient)

              setActiveStep({ type: Step.Review })
            }}
          />
        ) : null}

        {activeStep.type === Step.Review ? (
          <RecipientReviewStep
            recipient={recipient}
            onBack={() => setActiveStep({ type: Step.Details })}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
