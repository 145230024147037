import * as React from 'react'
import { useIntl } from 'react-intl'

import { Input } from '../ui'

const MAX_INPUT_LENGTH = 6

export interface CodeInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: string) => void
}

export const CodeInput = React.forwardRef<HTMLInputElement, CodeInputProps>(
  ({ className, ...props }, ref) => {
    const intl = useIntl()
    return (
      <Input
        inputSize="medium"
        variant="2fa"
        type="text"
        maxLength={MAX_INPUT_LENGTH}
        placeholder={
          props.placeholder ??
          intl.formatMessage({
            defaultMessage: '6-digit code',
            id: 'label.sixDigitCode',
          })
        }
        {...props}
        onChange={(e) => {
          if (e.target.value.match(/\D/g)) {
            return
          }

          props.onChange?.(e.target.value)
        }}
        ref={ref}
      />
    )
  },
)

CodeInput.displayName = 'CodeInput'
