import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { BusinessRoute, ContractorRoute } from '@/constants/paths'
import { queryClient } from '@/lib/queryClient'
import { useAuth } from '@/providers/AuthProvider'

export function useReset() {
  const { hide } = useIntercom()
  const { updateRefreshToken, setAuthToken } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isContractorPath = pathname.includes(ContractorRoute.Root)

  const reset = useCallback(() => {
    hide()

    setAuthToken(null)
    updateRefreshToken(null)
    sessionStorage.clear()
    Sentry.setUser(null)

    queryClient.invalidateQueries()
    queryClient.resetQueries()

    if (isContractorPath) {
      navigate(ContractorRoute.Root, { replace: true })
    } else {
      navigate(BusinessRoute.SignIn, { replace: true })
    }
  }, [hide, setAuthToken, updateRefreshToken, isContractorPath, navigate])

  return reset
}
