import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { generatePath, Link } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getIdentity } from '@/domains/Business/api'
import { Button } from '@/shared/ui'

import {
  OnboardingMember,
  OnboardingMemberRole,
  OnboardingMemberState,
} from '../../types'
import { IdentityEmailDialog } from '../IdentityEmailDialog'

type Props = {
  member: OnboardingMember
}

export const IdentityActionCell = ({ member }: Props) => {
  const [showDialog, setShowDialog] = useState(false)

  const userQuery = useQuery({
    queryKey: [queryKeys.getIdentity],
    queryFn: getIdentity,
    select: (data) => data.data,
  })

  const isLegalRepresentative = member.roles.includes(
    OnboardingMemberRole.LEGAL_REPRESENTATIVE,
  )

  const isUser = useMemo(
    () => member.email === userQuery.data?.email,
    [member.email, userQuery.data?.email],
  )

  const shouldShowDialog = isLegalRepresentative && !isUser

  if (member.state === OnboardingMemberState.COMPLETED) {
    return null
  }

  const isRejected = member.state === OnboardingMemberState.REJECTED
  const inProgress = member.state === OnboardingMemberState.IN_PROGRESS

  const validationPath = `${generatePath(
    BusinessRoute.OnboardingValidateIdentity,
    {
      type: isUser ? 'qr' : 'email',
    },
  )}?email=${encodeURIComponent(member.email)}&status=${member.state}`

  return (
    <div className="flex items-center justify-end">
      {shouldShowDialog ? (
        <Button
          onClick={() => setShowDialog(true)}
          variant={isRejected || inProgress ? 'secondary' : 'default'}
          size="xs"
        >
          {isRejected ? (
            <FormattedMessage id="action.retry" defaultMessage="Retry" />
          ) : inProgress ? (
            <FormattedMessage id="action.review" defaultMessage="Review" />
          ) : (
            <FormattedMessage id="action.start" defaultMessage="Start" />
          )}
        </Button>
      ) : (
        <Button
          onClick={(e) => e.stopPropagation()}
          asChild
          variant={isRejected || inProgress ? 'secondary' : 'default'}
          size="xs"
        >
          <Link to={validationPath}>
            {isRejected ? (
              <FormattedMessage id="action.retry" defaultMessage="Retry" />
            ) : inProgress ? (
              <FormattedMessage id="action.review" defaultMessage="Review" />
            ) : (
              <FormattedMessage id="action.start" defaultMessage="Start" />
            )}
          </Link>
        </Button>
      )}

      <IdentityEmailDialog
        member={member}
        isOpen={showDialog}
        onOpenChange={setShowDialog}
      />
    </div>
  )
}
