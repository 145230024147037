import {
  DisplayableType,
  Transaction,
} from '@/domains/Business/features/Transactions/types'

import { BulkTransactionCell } from './BulkTransactionCell'
import { SingleTransactionCell } from './SingleTransactionCell'

type Props = {
  transaction: Transaction
}

export const TransactionCell = ({ transaction }: Props) => {
  switch (transaction.displayableType) {
    case DisplayableType.SINGLE:
      return <SingleTransactionCell transaction={transaction} />
    case DisplayableType.BULK:
      return <BulkTransactionCell transaction={transaction} />

    default:
      return null
  }
}
