import { FormattedMessage } from 'react-intl'

import { SPEI } from '@/domains/Business/constants'
import { intersperse } from '@/lib/typography'
import { Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { CSVTransaction } from '../types'

type Props = {
  transaction: CSVTransaction
}

export const CSVTransactionDetailsRow = ({ transaction }: Props) => {
  return (
    <div className="flex items-center gap-1 text-neutral-gray-600 ">
      {intersperse(
        [
          {
            key: 'paymentMethod',
            value: transaction.paymentDetails.paymentMethod,
          },
          {
            key: 'SPEI',
            value:
              !transaction.paymentDetails.paymentMethod &&
              transaction.paymentDetails.accountNumber &&
              transaction.beneficiaryCountry === CountryCode.MX
                ? SPEI
                : null,
          },
          {
            key: 'accountNumber',
            value: transaction.paymentDetails.accountNumber,
          },
          {
            key: 'routingNumber',
            value: transaction.paymentDetails.routingNumber,
          },
        ]
          .filter(({ value }) => value)
          .map(({ key, value }) => (
            <Typography key={key}>
              {key === 'paymentMethod' ? (
                <FormattedMessage
                  id="paymentMethod.label"
                  defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                  values={{ method: value }}
                />
              ) : (
                value
              )}
            </Typography>
          )),
        '•',
      )}
    </div>
  )
}
