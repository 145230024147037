import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { matchSorter } from 'match-sorter'
import { FormattedMessage } from 'react-intl'
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Permission } from '@/domains/Business/types'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { useSidebar } from '@/hooks/useSidebar'
import { AccountBalance } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, DataTable, SearchInput, Typography } from '@/shared/ui'

import { MoveMoneyWidget, WithPermissions } from '../../components'

import { getAccount } from './api'
import { AccountDetailsSidebar } from './components/AccountDetailsSidebar'
import { useAccountsColumns } from './components/AccountsTable'

export const Accounts = () => {
  const columns = useAccountsColumns()
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState('')

  const [accountId] = useSearchParamsValue(['id'])
  const [showDetails, setShowDetails] = useSidebar({ key: 'id' })

  const accountsQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data.data,
    staleTime: 0,
  })

  const filteredData = matchSorter(
    accountsQuery.data?.wallets ?? [],
    search.trim().toLowerCase(),
    {
      keys: [
        'label',
        'currency',
        'balance',
        'monthlyTotalMoneyIn',
        'monthlyTotalMoneyOut',
      ],
      sorter: (rankedItems) => rankedItems,
    },
  )

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6 md:flex-nowrap md:gap-0">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.accounts.title"
              defaultMessage="My accounts"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.accounts.subtitle"
              defaultMessage="Create accounts for different purposes and easily move your funds between them"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput value={search} onChange={setSearch} />

        <WithPermissions permissions={[Permission.TRANSFERS]}>
          <Button
            size="md"
            leftIcon={<Plus className="size-5" />}
            variant="tertiary"
            asChild
          >
            <Link to={BusinessRoute.CreateAccount} state={{ from: location }}>
              <FormattedMessage
                id="action.newAccount"
                defaultMessage="New account"
              />
            </Link>
          </Button>
        </WithPermissions>
      </div>

      <div className="p-3" />

      <AccountBalance />

      <div className="p-3" />

      <DataTable
        isLoading={accountsQuery.isPending}
        data={filteredData ?? []}
        columns={columns}
        onRowClick={(account) => {
          navigate(
            generatePath(BusinessRoute.SingleAccount, { id: account.id }),
          )
        }}
        loaderOptions={{ rows: 3 }}
      />

      <AccountDetailsSidebar
        wallet={accountsQuery.data?.wallets.find((w) => w.id === accountId)}
        isOpen={showDetails}
        onOpenChange={() => {
          setShowDetails((d) => !d)
          navigate(BusinessRoute.Accounts, { preventScrollReset: true })
        }}
      />
    </div>
  )
}
