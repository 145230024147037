import { api } from '@/lib/api'

type DownloadDocumentParams = {
  fileId: string
  contractId: string
}

export function downloadContractDocument({
  fileId,
  contractId,
}: DownloadDocumentParams) {
  return api.get<BlobPart>(
    `/v1/me/contract-agreements/${fileId}/contract/${contractId}`,
    {
      responseType: 'blob',
    },
  )
}
