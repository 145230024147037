import { format, FormatOptions, parse } from 'date-fns'
import { enGB, es } from 'date-fns/locale'

import { localStorageKeys } from '@/constants/keys'
import { Locale } from '@/constants/locales'

import { getUserLocale } from './localization'

const MAX_DATE_LENGTH = 10

export const DATE_LOCALE = {
  [Locale.EN]: enGB,
  [Locale.ES]: es,
}

/**
 * formats UTC date to local date
 *
 * @param date - date to format
 * @param formatStr - format string e.g. 'dd/MM/yyyy'
 * @param options - format options from date-fns
 * @returns formatted value
 */

export const formatDate = (
  date: string | number | Date,
  formatStr: string,
  options?: FormatOptions | undefined,
) => {
  const locale =
    (window.localStorage.getItem(localStorageKeys.locale) as Locale) ||
    getUserLocale()

  const parsedDate = new Date(date)
  const offsetMinutes = parsedDate.getTimezoneOffset()
  const localTime = new Date(parsedDate.getTime() - offsetMinutes * 60 * 1000)

  return format(localTime, formatStr, {
    locale: DATE_LOCALE[locale],
    ...options,
  })
}

export function parseDigits(string: string) {
  return (string.match(/[0-9/]/g) || []).join('')
}

export function parseInputDate(date: string) {
  const digits = parseDigits(date)
  const chars = digits.split('')

  return chars.reduce((r, v) => `${r}${v}`, '').substring(0, MAX_DATE_LENGTH)
}

export function getBrowserTimeZone() {
  const locale = getUserLocale()

  const timeZoneName = new Intl.DateTimeFormat(locale, {
    timeZoneName: 'short',
    timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
    .formatToParts()
    .find((i) => i.type === 'timeZoneName')?.value

  return timeZoneName
}

export function daysOfWeek(locale = 'en-US', length = 7) {
  return Array.from({ length }, (_, i) =>
    new Date(0, 0, i + 1).toLocaleString(locale, {
      weekday: 'long',
    }),
  )
}

export function getDayOfWeek(day: number | string | undefined, locale: string) {
  const week = daysOfWeek(locale)

  return week.find((_, index) => {
    // we assume days are always 1 to 7
    const dayOfWeek = index + 1

    return dayOfWeek.toString() === day?.toString()
  })
}

export function getMonthsNames(locale: string) {
  return Array.from({ length: 12 }, (_, i) =>
    new Date(0, i, 1).toLocaleString(locale, { month: 'long' }),
  )
}

export function parseToFormat(date: string, formatStr: string = 'yyyy-MM-dd') {
  return format(parse(date, 'dd/MM/yyyy', new Date().toUTCString()), formatStr)
}

export function parseDateFromBE(date: string) {
  return format(
    parse(date, 'yyyy-MM-dd', new Date().toUTCString()),
    'dd/MM/yyyy',
  )
}
