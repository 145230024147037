import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useSearchParams } from 'react-router-dom'

import { queryKeys } from '@/constants/queryKeys'
import { getOnboardingAddress } from '@/lib/address'
import { getFullName } from '@/lib/typography'
import { CountryName, Widget } from '@/shared/components'
import { Document } from '@/shared/icons/solid'
import { Details, MotionDiv, Skeleton, Typography } from '@/shared/ui'

import { getContractorDetails } from '../../api'

import { ContractDetailsSidebar } from './components/ContractDetailsSidebar'
import { ContractorPaymentDetails } from './components/ContractorPaymentDetails'
import { ContractorPaymentDetailsSidebar } from './components/ContractorPaymentDetailsSidebar'
import { ContractorPayments } from './components/ContractorPayments'
import { ContractsSection } from './components/ContractsSection'

export const ContractorOverview = () => {
  const intl = useIntl()

  const [searchParams, setSearchParams] = useSearchParams()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getContractorDetails],
    queryFn: getContractorDetails,
    select: (data) => data?.data,
  })

  const latestContract = useMemo(() => {
    if (contractorQuery.data) {
      return contractorQuery.data.contracts[0]
    }

    return null
  }, [contractorQuery.data])

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            {contractorQuery.isPending ? (
              <Skeleton className="h-[33px] w-36" />
            ) : (
              <Typography variant="h3">
                {getFullName(contractorQuery.data?.personalDetails)}
              </Typography>
            )}
            {contractorQuery.isPending ? (
              <Skeleton className="h-[21px] w-36" />
            ) : (
              <Typography className="text-neutral-gray-600">
                {latestContract?.contractorRole}
              </Typography>
            )}
          </div>
        </div>

        <div className="p-4" />

        <MotionDiv className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="flex flex-col gap-8">
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Personal information"
                  id="label.personalInformation"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage defaultMessage="Email" id="label.email" />
                </Details.Label>
                {contractorQuery.data ? (
                  <Details.Value>
                    {contractorQuery.data.personalDetails.email}
                  </Details.Value>
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Phone number"
                    id="label.phoneNumber"
                  />
                </Details.Label>
                {contractorQuery.data ? (
                  <Details.Value>
                    {formatPhoneNumberIntl(
                      contractorQuery.data.personalDetails
                        .internationalPhonePrefix +
                        contractorQuery.data.personalDetails.localPhoneNumber,
                    )}
                  </Details.Value>
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Birthday"
                    id="label.birthday"
                  />
                </Details.Label>
                {contractorQuery.data ? (
                  <Details.Value>
                    {contractorQuery.data.personalDetails.dateOfBirth
                      ? intl.formatDate(
                          new Date(
                            contractorQuery.data.personalDetails.dateOfBirth,
                          ),
                          {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            timeZone: 'UTC',
                          },
                        )
                      : '-'}
                  </Details.Value>
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Country of citizenship"
                    id="label.countryOfCitizenship"
                  />
                </Details.Label>
                {contractorQuery.data ? (
                  <CountryName
                    countryCode={
                      contractorQuery.data.personalDetails.countryOfCitizenship
                    }
                  />
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.address"
                    defaultMessage="Address"
                  />
                </Details.Label>
                {contractorQuery.data ? (
                  <Details.Value className="whitespace-break-spaces text-right">
                    {contractorQuery.data.personalDetails.residentialAddress
                      ? getOnboardingAddress(
                          contractorQuery.data.personalDetails
                            .residentialAddress,
                        )
                      : '-'}
                  </Details.Value>
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
            </Widget>
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Tax information"
                  id="label.taxInformation"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Tax residence"
                    id="label.taxResidence"
                  />
                </Details.Label>

                {contractorQuery.data ? (
                  <CountryName
                    countryCode={
                      contractorQuery.data.taxDetails.countryOfTaxResidence
                    }
                  />
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Tax identification number"
                    id="label.taxIdentificationNumber"
                  />
                </Details.Label>
                {contractorQuery.data ? (
                  <Details.Value>
                    {contractorQuery.data.taxDetails.taxId ?? '-'}
                  </Details.Value>
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Tax form"
                    id="label.taxForm"
                  />
                </Details.Label>
                {contractorQuery.data ? (
                  <div className="flex items-center gap-1">
                    {contractorQuery.data.taxDetails.taxForm && (
                      <Document className="size-4" />
                    )}
                    <Typography className="text-right">
                      {contractorQuery.data.taxDetails.taxForm ?? '-'}
                    </Typography>
                  </div>
                ) : (
                  <Details.Skeleton />
                )}
              </Details>
            </Widget>
          </div>
          <div className="col-span-2 flex flex-col gap-8">
            <ContractsSection
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('contract', latestContract?.id ?? '')
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
              contractor={contractorQuery.data}
            />

            <ContractorPaymentDetails
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('payment-details', 'true')
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
              contractor={contractorQuery.data}
            />

            <ContractorPayments contractor={contractorQuery.data} />
          </div>
        </MotionDiv>
      </div>

      <ContractDetailsSidebar
        contractor={contractorQuery.data}
        isOpen={!!searchParams.get('contract')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('contract')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />

      <ContractorPaymentDetailsSidebar
        contractor={contractorQuery.data}
        isOpen={!!searchParams.get('payment-details')}
        onOpenChange={() => {
          setSearchParams(
            (params) => {
              params.delete('payment-details')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </>
  )
}
