import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/domains/Business/features/Accounts'
import { formatCurrency, formatMoney } from '@/lib/money'
import { Button, Card, Skeleton, Typography } from '@/shared/ui'

import { ChartMixed } from '../icons/outline'

import { EmptyChart } from './EmptyChart'

export const AccountBalance = () => {
  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data.data,
    staleTime: 0,
  })

  const [showChart, setShowChart] = useState(false)

  return (
    <Card
      size="widget"
      className="flex w-full flex-col items-center justify-between"
    >
      <div className="flex w-full flex-wrap items-center justify-between gap-3 md:flex-nowrap md:gap-0">
        <div className="flex flex-col">
          <div className="flex gap-1">
            <Typography variant="body-small">
              <FormattedMessage
                id="dashboard.totalBalance"
                defaultMessage="Total balance"
              />
            </Typography>
            <Typography variant="body-small">
              <FormattedMessage
                id="dashboard.accountsCount"
                defaultMessage="{count, plural, =0 {} one {(# account)} other {(# accounts)}}"
                values={{ count: accountQuery.data?.wallets.length ?? 0 }}
              />
            </Typography>
          </div>

          <div className="p-1" />

          {!accountQuery.isPending ? (
            <Typography variant="h3">
              {formatMoney(accountQuery.data?.totalBalance?.toFixed(2) ?? 0)}{' '}
              {formatCurrency(accountQuery.data?.totalBalanceCurrency)}
            </Typography>
          ) : (
            <Skeleton className="h-[33px] w-36" />
          )}
        </div>
        <Button
          leftIcon={<ChartMixed className="size-5" />}
          onClick={() => setShowChart((c) => !c)}
          size="md"
          variant="secondary"
        >
          {showChart ? (
            <FormattedMessage
              id="action.hideChart"
              defaultMessage="Hide chart"
            />
          ) : (
            <FormattedMessage
              id="action.showChart"
              defaultMessage="Show chart"
            />
          )}
        </Button>
      </div>

      <EmptyChart show={showChart} />
    </Card>
  )
}
