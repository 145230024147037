import { Outlet, RouteObject } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'

import { accountActionsRoutes, accountsRoutes } from '../Accounts'
import { bulkPaymentsRoutes, bulkTransactionsRoutes } from '../BulkPayments'
import { BusinessStateChecker } from '../BusinessStateChecker'
import { cardsRoutes, cardTransactionsRoutes, createCardRoutes } from '../Cards'
import { contractorsRoutes, createContractorRoutes } from '../Contractors'
import { moveMoneyRoutes } from '../MoveMoney'
import { addRecipientRoutes, recipientsRoutes } from '../Recipients'
import { reviewTasksRoutes, tasksRoutes } from '../Tasks'
import { addTeamMemberRoutes, teamRoutes } from '../Team'
import { cashbackTransactionsRoutes, transactionsRoutes } from '../Transactions'
import { TwoFactorGuardChecker } from '../TwoFactorGuardChecker'

import { Home } from './Home'
import { Layout } from './Layout'
import { PermissionsChecker } from './PermissionsChecker'
import { Setup2FAScreen } from './Setup2FAScreen'

export const dashboardRoutes: RouteObject[] = [
  {
    path: BusinessRoute.DashboardSetup2fa,
    element: <Setup2FAScreen />,
  },
  {
    path: BusinessRoute.Dashboard,
    element: (
      <BusinessStateChecker>
        <PermissionsChecker>
          <TwoFactorGuardChecker>
            <Layout />
          </TwoFactorGuardChecker>
        </PermissionsChecker>
      </BusinessStateChecker>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      // /dashboard prefixed routes
      ...accountsRoutes,
      ...bulkTransactionsRoutes,
      ...cardsRoutes,
      ...cardTransactionsRoutes,
      ...cashbackTransactionsRoutes,
      ...contractorsRoutes,
      ...recipientsRoutes,
      ...tasksRoutes,
      ...teamRoutes,
      ...transactionsRoutes,
    ],
  },
  {
    element: (
      <BusinessStateChecker>
        <PermissionsChecker>
          <Outlet />
        </PermissionsChecker>
      </BusinessStateChecker>
    ),
    children: [
      ...accountActionsRoutes,
      ...addRecipientRoutes,
      ...addTeamMemberRoutes,
      ...bulkPaymentsRoutes,
      ...createCardRoutes,
      ...createContractorRoutes,
      ...moveMoneyRoutes,
      ...reviewTasksRoutes,
    ],
  },
]
