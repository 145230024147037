import { contractorApi } from '@/lib/api'

export type AcceptInvitationRequest = {
  identityId: string
  businessId: string
  otp: string
  rawPassword: string
}

export type AcceptInvitationResponse = {
  refreshToken: string
}

export function acceptContractorInvitation(data: AcceptInvitationRequest) {
  return contractorApi.post<AcceptInvitationResponse>(
    '/v1/invitation-verifications',
    data,
  )
}
