import { forwardRef, Ref, type SVGProps } from 'react'

const SvgDoubleTick = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 14 8"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M1.398 3.716a.563.563 0 0 0-.796.796zM4 7.114l-.398.398c.22.22.576.22.796 0zm6.398-5.602a.562.562 0 1 0-.796-.796zm-6 2.204a.563.563 0 0 0-.796.796zM7 7.114l-.398.398c.22.22.576.22.796 0zm6.398-5.602a.562.562 0 1 0-.796-.796zm-12.796 3 3 3 .796-.796-3-3zm3.796 3 6-6-.796-.796-6 6zm-.796-3 3 3 .796-.796-3-3zm3.796 3 6-6-.796-.796-6 6z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgDoubleTick)
export default ForwardRef
