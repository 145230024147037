import { FormattedMessage } from 'react-intl'
import { useIntercom } from 'react-use-intercom'

import { getImage } from '@/lib/images'
import { Button, SlideInScreen, Typography } from '@/shared/ui'

export const ContractorForgetPasswordEmailSent = () => {
  const { boot, showNewMessage } = useIntercom()

  return (
    <SlideInScreen>
      <img
        className="mx-auto w-36 object-contain"
        src={getImage({ name: 'email-2fa' })}
        alt=""
        aria-hidden
      />
      <div className="flex flex-col">
        <Typography variant="h3" text="center">
          <FormattedMessage
            id="auth.forgetPassword.emailSent"
            defaultMessage="Email sent"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="auth.forgetPassword.emailSent.receiveLink"
            defaultMessage="If the email you entered is associated with a DolarApp Business account, you should receive a link to create a new password shortly"
          />
        </Typography>

        <div className="p-4" />

        <div className="flex flex-col gap-4 rounded-2xl bg-neutral-gray-200 p-6">
          <Typography bold>
            <FormattedMessage
              id="auth.forgetPassword.noLink"
              defaultMessage="Didn't receive a link?"
            />
          </Typography>

          <Typography>
            <FormattedMessage
              id="auth.forgetPassword.contactManager"
              defaultMessage="Please contact your manager or admin to have a link directly sent to the email associated with your DolarApp Business account, or check your spam folder"
            />
          </Typography>
        </div>

        <div className="p-4" />

        <div className="flex flex-col gap-2 lg:items-center">
          <Typography className=" flex flex-wrap items-center justify-center gap-1">
            <FormattedMessage
              defaultMessage="Can't access your email?"
              id="auth.cantAccessEmail"
            />{' '}
            <Button
              type="button"
              variant="link"
              size="inline"
              onClick={() => {
                boot({
                  customAttributes: {
                    user_type: 'CONTRACTOR',
                  },
                })
                showNewMessage()
              }}
            >
              <FormattedMessage
                defaultMessage="Chat to support"
                id="action.chatSupport"
              />
            </Button>
          </Typography>
        </div>
      </div>
    </SlideInScreen>
  )
}
