import { useState } from 'react'

import { DolarTagDetails } from '@/domains/Contractor/types'

import { ContractorOnboardingState } from '../types'

import { AddDolarTagScreen } from './AddDolarTagScreen'
import { DownloadAppQRScreen } from './DownloadAppQRScreen'

type Step = 'qr-code' | 'details'

type Props = {
  onBack: () => void
  contractorState?: ContractorOnboardingState
}

export const DolarTagPaymentDetails = ({ contractorState, onBack }: Props) => {
  const paymentDetails = contractorState?.paymentMethodDetails as
    | DolarTagDetails
    | undefined

  const [step, setStep] = useState<Step>(() =>
    paymentDetails?.paymentMethodInformation?.dolarTag ? 'details' : 'qr-code',
  )

  const handleDolarTagScreenBack = () => {
    if (paymentDetails?.paymentMethodInformation?.dolarTag) {
      onBack()
    } else {
      setStep('qr-code')
    }
  }

  switch (step) {
    case 'qr-code':
      return (
        <DownloadAppQRScreen
          onBack={onBack}
          onContinue={() => setStep('details')}
        />
      )

    case 'details':
      return (
        <AddDolarTagScreen
          onBack={handleDolarTagScreenBack}
          contractorState={contractorState}
        />
      )
  }
}
