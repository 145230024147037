import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { getCodeByCountry } from '@/lib/country'
import { parseFullPhoneNumber } from '@/lib/phone'
import { Button, Form, StickyContainer } from '@/shared/ui'

import { BusinessUSRecipient, CreatedRecipientWithId } from '../types'

import { BusinessFields } from './BusinessFields'
import { ContactDetailsFields } from './ContactDetailsFields'
import { DeleteRecipientAction } from './DeleteRecipientAction'
import { SharedRecipientFormProps } from './RecipientForm'
import { USPaymentFields } from './USPaymentFields'
import { businessValidation, paymentValidation } from './validations'

const createUSBusinessSchema = businessValidation.and(paymentValidation)

const US_BUSINESS_FORM = 'us-business-form'

export type CreateUSBusinessSchema = z.infer<typeof createUSBusinessSchema>

type Props = {
  onContinue: (values: CreateUSBusinessSchema) => void
  recipient?: BusinessUSRecipient
} & SharedRecipientFormProps

export const USBusinessForm = ({
  country,
  layout,
  onDelete,
  onContinue,
  isPending,
  recipient,
}: Props) => {
  const form = useForm<CreateUSBusinessSchema>({
    mode: 'onChange',
    resolver: zodResolver(createUSBusinessSchema),
    values: {
      nickname: recipient?.nickname ?? '',
      legalName: recipient?.legalName ?? '',
      email: recipient?.email ?? '',
      localPhoneNumber:
        recipient?.internationalPhonePrefix && recipient?.localPhoneNumber
          ? recipient.internationalPhonePrefix + recipient.localPhoneNumber
          : '',
      internationalPhonePrefix: recipient?.internationalPhonePrefix
        ? recipient.internationalPhonePrefix
        : getCodeByCountry(country),

      paymentMethod: recipient?.localInformation.paymentMethod ?? '',
      accountNumber: recipient?.localInformation.accountNumber ?? '',
      routingNumber: recipient?.localInformation.routingNumber ?? '',

      addressStreet: recipient?.addressStreet ?? '',
      addressCity: recipient?.addressCity ?? '',
      addressState: recipient?.addressState ?? '',
      addressZipCode: recipient?.addressZipCode ?? '',
      addressCountry: recipient?.addressCountry ?? '',
    },
  })

  const onSubmit: SubmitHandler<CreateUSBusinessSchema> = (values) => {
    onContinue({
      ...values,
      localPhoneNumber: parseFullPhoneNumber(values),
    })
  }

  return (
    <>
      <Form {...form}>
        <form
          id={US_BUSINESS_FORM}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-8">
            <BusinessFields layout={layout} form={form} />

            <ContactDetailsFields form={form} />

            <USPaymentFields layout={layout} form={form} />
          </div>
        </form>
      </Form>

      <StickyContainer className={layout === 'edit' ? '-bottom-16' : undefined}>
        <Button
          width="full"
          form={US_BUSINESS_FORM}
          disabled={
            !form.formState.isValid ||
            isPending ||
            (layout === 'edit' && !form.formState.isDirty)
          }
          loading={isPending}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          {layout === 'edit' ? (
            <FormattedMessage
              defaultMessage="Save changes"
              id="action.saveChanges"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          )}
        </Button>

        {onDelete ? (
          <DeleteRecipientAction
            onDelete={onDelete}
            recipient={recipient as CreatedRecipientWithId}
          />
        ) : null}
      </StickyContainer>
    </>
  )
}
