import { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Map, Marker } from '@vis.gl/react-google-maps'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useBusinessUser } from '@/domains/Business/hooks'
import { useCoordinatesFromAddress } from '@/hooks/useCoordinatesFromAddress'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getCountryNameByISO3 } from '@/lib/country'
import { queryClient } from '@/lib/queryClient'
import {
  Button,
  Card,
  Details,
  Skeleton,
  SlideInLeft,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { activateCard, getCardDeliveryDetails } from '../api'
import { CardDeliveryState, Card as CardType } from '../types'

import { CardDeliveryProgress } from './CardDeliveryProgress'
import { ScreenType } from './CardDetailsSidebar'

type Props = {
  card?: CardType
  setScreen: (screen: ScreenType) => void
}

export const CardDelivery = ({ card, setScreen }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const businessUser = useBusinessUser()

  const cardDeliveryQuery = useQuery({
    queryKey: [queryKeys.getCardDeliveryDetails, card?.cardDeliveryDetails?.id],
    queryFn: () =>
      getCardDeliveryDetails({
        cardDeliveryId: card?.cardDeliveryDetails?.id ?? '',
      }),
    select: (data) => data?.data,
    enabled: !!card?.cardDeliveryDetails?.id,
  })

  const isCardOwner = useMemo(
    () => businessUser?.identityId === card?.identityId,
    [businessUser?.identityId, card?.identityId],
  )

  const fullAddress = useMemo(() => {
    if (!card?.cardDeliveryDetails) {
      return ''
    }

    const { address } = card.cardDeliveryDetails

    return [
      address.street,
      address.streetNumber,
      address.apartment,
      address.neighborhood,
      address.city,
      address.state,
      getCountryNameByISO3(address.country, intl.locale),
      address.postcode,
    ].join(', ')
  }, [card?.cardDeliveryDetails, intl.locale])

  const coordinates = useCoordinatesFromAddress(fullAddress)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: activateCard,
  })

  const onCardActivate = async () => {
    if (!card?.id) {
      toast.error(
        intl.formatMessage({
          id: 'cards.physical.delivery.missingCardId',
          defaultMessage: 'Missing card ID',
        }),
      )

      return
    }

    try {
      await mutateAsync({
        id: card.id,
      })

      toast.success(
        intl.formatMessage({
          id: 'cards.physical.delivery.cardActivated',
          defaultMessage: 'Card activated successfully!',
        }),
      )

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAllCards],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getSingleCard, card.id],
      })

      setScreen({ type: 'base' })
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  const showActivationButton = useMemo(() => {
    if (!cardDeliveryQuery.data?.status) {
      return false
    }

    return [CardDeliveryState.DELIVERED, CardDeliveryState.SHIPPED].includes(
      cardDeliveryQuery.data?.status,
    )
  }, [cardDeliveryQuery.data?.status])

  return (
    <SlideInLeft className="flex h-full flex-col">
      {card?.nickname ? (
        <Typography variant="h3">{card.nickname}</Typography>
      ) : (
        <Skeleton className="h-[33px] w-36" />
      )}

      <div className="p-4" />

      <CardDeliveryProgress deliveryDetails={cardDeliveryQuery.data} />

      <div className="p-4" />

      <Card size="medium" className="flex flex-col gap-6">
        {coordinates ? (
          <Map
            className="h-40 w-full [&>div]:rounded-xl"
            defaultZoom={16}
            defaultCenter={coordinates}
            gestureHandling="greedy"
            disableDefaultUI
          >
            <Marker position={coordinates} />
          </Map>
        ) : (
          <Skeleton className="h-40 w-full" />
        )}

        <Details align="start">
          <Details.Label>
            <FormattedMessage
              defaultMessage="Delivery address"
              id="label.deliveryAddress"
            />
          </Details.Label>
          <Details.Value className="max-w-72 whitespace-break-spaces text-right">
            {fullAddress}
          </Details.Value>
        </Details>

        {card?.cardDeliveryDetails?.localPhoneNumber ? (
          <Details align="start">
            <Details.Label>
              <FormattedMessage
                defaultMessage="Contact phone number"
                id="label.contactPhoneNumber"
              />
            </Details.Label>
            <Details.Value>
              {formatPhoneNumberIntl(
                card.cardDeliveryDetails.internationalPhonePrefix +
                  card.cardDeliveryDetails.localPhoneNumber,
              )}
            </Details.Value>
          </Details>
        ) : null}

        {card?.cardDeliveryDetails?.address.references ? (
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Address references"
                id="label.addressReferences"
              />
            </Details.Label>

            <Details.Value>
              {card.cardDeliveryDetails.address.references}
            </Details.Value>
          </Details>
        ) : null}

        {cardDeliveryQuery.data?.externalCourierTrackingUrl && (
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Tracking number"
                id="label.trackingNumber"
              />
            </Details.Label>
            <Button
              variant="link"
              asChild
              className="line-clamp-1 flex cursor-pointer items-center justify-end p-0 text-neutral-gray-900 underline"
            >
              <a
                href={cardDeliveryQuery.data?.externalCourierTrackingUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {cardDeliveryQuery.data?.courierTrackingId ??
                  cardDeliveryQuery.data?.externalCourierTrackingUrl}
              </a>
            </Button>
          </Details>
        )}
      </Card>

      {showActivationButton && isCardOwner ? (
        <StickyContainer>
          <Button
            loading={isPending}
            disabled={isPending}
            width="full"
            onClick={onCardActivate}
          >
            <FormattedMessage
              defaultMessage="Activate your card!"
              id="cards.physical.delivery.activateCard"
            />
          </Button>
        </StickyContainer>
      ) : null}
    </SlideInLeft>
  )
}
