import { publicApi } from '@/lib/api'
import { CountryData } from '@/types/country'

type GetCountryCodesParams = {
  showAllCountries?: boolean
}

export function getCountryCodes({
  showAllCountries = false,
}: GetCountryCodesParams) {
  const query = showAllCountries ? '' : '?acceptedPhoneNumbers=true'

  return publicApi.get<CountryData[]>(`/v2/signup/country-codes${query}`)
}
