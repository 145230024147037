import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'
import { z } from 'zod'

import { queryKeys } from '@/constants/queryKeys'
import { AccountSelect, LimitTypeSelect } from '@/domains/Business/components'
import { getAccount } from '@/domains/Business/features/Accounts/api'
import { Account } from '@/domains/Business/features/Accounts/types'
import { PaymentLimitType } from '@/domains/Business/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getCardTitle } from '@/lib/card'
import { queryClient } from '@/lib/queryClient'
import { AmountInput } from '@/shared/components'
import {
  AnimatedFormLabel,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  Skeleton,
  SlideInLeft,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { updateCard } from '../api'
import { Card as CardType } from '../types'

import { ScreenType } from './CardDetailsSidebar'
import { DeleteCardAction } from './DeleteCardAction'

const EDIT_CARD_FORM = `edit-card-form`

const editCardSchema = z.object({
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  walletId: z.string().min(1),
  limitAmount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
    message: 'validation.balance.minAmount',
  }),
  limitType: z.nativeEnum(PaymentLimitType),
})

type EditCardSchema = z.infer<typeof editCardSchema>

type Props = {
  card?: CardType
  setScreen: (screen: ScreenType) => void
}

export const EditCard = ({ card, setScreen }: Props) => {
  const notifyError = useErrorToast()

  const form = useForm<EditCardSchema>({
    mode: 'onChange',
    resolver: zodResolver(editCardSchema),
    values: {
      nickname: card?.nickname ?? '',
      walletId: card?.walletId ?? '',
      limitAmount: Big(card?.cardLimit?.limitAmount ?? 0).toString(),
      limitType: card?.cardLimit?.limitType as PaymentLimitType,
    },
  })

  const [accountQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data?.data,
      },
    ],
  })

  const {
    mutateAsync: updateCardMutation,
    isPending: updateCardIsPending,
    isSuccess: updateCardIsSuccess,
  } = useMutation({
    mutationFn: updateCard,
  })

  const intl = useIntl()

  const onSubmit: SubmitHandler<EditCardSchema> = async (data) => {
    if (!card?.id) {
      toast.error('Card ID not found')

      return
    }

    const { limitAmount, limitType, ...rest } = data

    try {
      await updateCardMutation({
        cardId: card.id,
        ...rest,
        cardLimit: { limitAmount, limitType },
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getSingleCard, card.id],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAllCards],
      })

      toast.success(
        intl.formatMessage({
          id: 'card.update.success',
          defaultMessage: 'Card has been updated successfully',
        }),
      )

      setScreen({ type: 'base' })
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInLeft className="flex h-full flex-col">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen({ type: 'base' })}
              variant="ghost"
              size="inline"
            >
              {card ? (
                <Typography>{getCardTitle(card)}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <Typography>
              <FormattedMessage
                id="card.sidebar.editCard"
                defaultMessage="Edit card"
              />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <Typography variant="h3">
        <FormattedMessage
          id="card.sidebar.edit.title"
          defaultMessage="Edit card"
        />
      </Typography>

      <div className="p-2" />

      <Typography>
        <FormattedMessage
          id="card.sidebar.edit.description"
          defaultMessage="Change the card's settings as many times as you want"
        />
      </Typography>

      <div className="p-4" />

      <Form {...form}>
        <form
          id={EDIT_CARD_FORM}
          className="h-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="nickname"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'card.field.nickname',
                        defaultMessage: 'Card nickname (i.e. Travel expenses)',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="card.field.nickname"
                      defaultMessage="Card nickname (i.e. Travel expenses)"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="walletId"
              render={({ field }) => {
                return (
                  <AccountSelect
                    value={field.value}
                    accounts={accountQuery.data?.wallets}
                    showLabel
                    label={intl.formatMessage({
                      id: 'card.field.sourceAccount',
                      defaultMessage: 'Source account',
                    })}
                    onChange={field.onChange}
                  />
                )
              }}
            />

            <FormField
              control={form.control}
              name="limitType"
              render={({ field }) => (
                <FormItem>
                  <LimitTypeSelect
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="limitAmount"
              render={({ field }) => (
                <FormItem>
                  <AmountInput
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Limit amount',
                      id: 'label.limitAmount',
                    })}
                    {...field}
                  />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>

      <div className="p-2" />

      <StickyContainer className="-bottom-16">
        <Button
          width="full"
          form={EDIT_CARD_FORM}
          onClick={form.handleSubmit(onSubmit)}
          loading={updateCardIsPending || updateCardIsSuccess}
          disabled={
            !form.formState.isValid ||
            updateCardIsPending ||
            !form.formState.isDirty ||
            updateCardIsSuccess
          }
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save changes"
            id="action.saveChanges"
          />
        </Button>

        <DeleteCardAction
          isPendingOutside={updateCardIsPending || updateCardIsSuccess}
          onDelete={() => setScreen({ type: 'base' })}
          card={card}
        />
      </StickyContainer>
    </SlideInLeft>
  )
}
