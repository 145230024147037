import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Permission } from '@/domains/Business/types'
import { formatCurrency, formatMoney } from '@/lib/money'
import { CountryBankDetails } from '@/shared/components'
import { CreditCard, Exchange, Invoice, Plus } from '@/shared/icons/outline'
import { Ellipsis, Navigation } from '@/shared/icons/solid'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Card,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Skeleton,
  Typography,
} from '@/shared/ui'

import { MoveMoneyWidget, WalletItem, WithPermissions } from '../../components'

import { getAccount, getAccountDetails } from './api'
import { AccountTransactionsList } from './components/AccountTransactionsList'
import { StatementsSidebar } from './components/StatementsSidebar'
import {
  Account,
  AccountDetails as AccountDetailsType,
  AccountType,
} from './types'

export const AccountDetails = () => {
  const location = useLocation()
  const intl = useIntl()
  const params = useParams()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const [accountQuery, accountDetailsQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data?.data,
      },
      {
        queryKey: [queryKeys.getAccountDetails, params.id],
        queryFn: () => getAccountDetails({ id: params.id ?? '' }),
        select: (data: AxiosResponse<AccountDetailsType>) =>
          data?.data.bankAccounts.sort((a) =>
            a.type === AccountType.ACH ? -1 : 1,
          ),
        enabled: !!params.id,
      },
    ],
  })

  const activeAccount = accountQuery.data?.wallets.find(
    (wallet) => wallet.id === params.id,
  )

  return (
    <div className="flex w-full flex-col">
      <div className="md:flex-no-wrap flex w-full flex-wrap items-center justify-between gap-6 md:gap-0">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link to={BusinessRoute.Accounts}>
                  <FormattedMessage
                    id="dashboard.accounts.title"
                    defaultMessage="My accounts"
                  />
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              {activeAccount?.label ? (
                <Typography>{activeAccount?.label}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <Card
        size="large"
        className="flex flex-wrap items-center justify-center gap-5 md:justify-between md:gap-0"
      >
        {activeAccount && accountQuery.data?.wallets.length ? (
          <div className="flex flex-col gap-3">
            <Select
              onValueChange={(id) => {
                if (id === 'new-account-action') {
                  navigate(BusinessRoute.CreateAccount, {
                    state: { from: location },
                  })
                  return
                }

                navigate(generatePath(BusinessRoute.Accounts, { id }))
              }}
            >
              <SelectTrigger className="h-auto w-fit p-0">
                <WalletItem
                  accountIconVariant="large"
                  balance={false}
                  boldText={false}
                  wallet={activeAccount}
                />
              </SelectTrigger>
              <SelectContent>
                <WithPermissions permissions={[Permission.TRANSFERS]}>
                  <SelectItem
                    value="new-account-action"
                    className="flex items-center gap-3 px-2 py-3"
                  >
                    <div className="flex items-center gap-1">
                      <Plus className="size-5 text-primary" />
                      <Typography className="font-semibold text-primary">
                        <FormattedMessage
                          id="action.newAccount"
                          defaultMessage="New account"
                        />
                      </Typography>
                    </div>
                  </SelectItem>
                </WithPermissions>
                {accountQuery.data?.wallets
                  .filter((wallet) => wallet.id !== activeAccount.id)
                  .map((wallet) => (
                    <SelectItem
                      key={wallet.id}
                      value={wallet.id}
                      className="flex items-center gap-3 px-2 py-3"
                    >
                      <WalletItem
                        balance={false}
                        boldText={false}
                        wallet={wallet}
                      />
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            <Typography variant="h3">
              {formatMoney(activeAccount.balance)}{' '}
              {formatCurrency(activeAccount.currency)}
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <Skeleton className="h-[24px] w-36" />
            <Skeleton className="h-[33px] w-24" />
          </div>
        )}

        <div className="flex flex-wrap justify-center gap-3 md:justify-start">
          <WithPermissions permissions={[Permission.TRANSFERS]}>
            <Button
              leftIcon={<Exchange className="size-5" />}
              size="md"
              variant="secondary"
              asChild
            >
              <Link
                to={`${BusinessRoute.Transfer}?from=${activeAccount?.id}`}
                state={{ from: location }}
              >
                <FormattedMessage id="action.transfer" defaultMessage="Move" />
              </Link>
            </Button>
          </WithPermissions>
          <WithPermissions permissions={[Permission.TRANSFERS]}>
            <Button
              leftIcon={<Navigation />}
              size="md"
              variant="secondary"
              asChild
            >
              <Link
                to={`${BusinessRoute.Send}?from=${activeAccount?.id}`}
                state={{ from: location }}
              >
                <FormattedMessage id="action.send" defaultMessage="Send" />
              </Link>
            </Button>
          </WithPermissions>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                aria-label={intl.formatMessage({
                  id: 'action.showStatements',
                  defaultMessage: 'Show statements',
                })}
                size="inline"
                className="size-10"
                variant="secondary"
              >
                <Ellipsis />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>
                <Button
                  leftIcon={<Invoice className="size-5" />}
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set('statements', 'ACCOUNT')
                      return params
                    })
                  }}
                  className="justify-start px-4 font-normal"
                  width="full"
                  variant="ghost"
                >
                  <FormattedMessage
                    id="label.accountStatements"
                    defaultMessage="Account statements"
                  />
                </Button>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Button
                  leftIcon={<CreditCard className="size-5" />}
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set('statements', 'CREDIT')
                      return params
                    })
                  }}
                  className="justify-start px-4 font-normal"
                  width="full"
                  variant="ghost"
                >
                  <FormattedMessage
                    id="label.creditStatements"
                    defaultMessage="Credit statements"
                  />
                </Button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </Card>

      <div className="p-4" />

      <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
        {accountDetailsQuery.data ? (
          <CountryBankDetails
            accountBanks={accountDetailsQuery.data}
            walletId={params.id}
          />
        ) : (
          <>
            <CountryBankDetails.Skeleton rows={6} />
            <CountryBankDetails.Skeleton />
          </>
        )}
      </div>

      <div className="p-5" />

      <AccountTransactionsList />

      <StatementsSidebar
        type={searchParams.get('statements')}
        isOpen={!!searchParams.get('statements')}
        onOpenChange={() => {
          setSearchParams((params) => {
            params.delete('statements')
            return params
          })
        }}
      />
    </div>
  )
}
