import { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getIdentity } from '@/domains/Business/api'
import { Identity } from '@/domains/Business/types'
import { GoBackButton } from '@/shared/components'
import { Check, ChevronRight } from '@/shared/icons/outline'
import { Button, Card, Skeleton, SlideInScreen, Typography } from '@/shared/ui'

import { getOnboardingStepDetails } from '../../api'
import {
  CompanyOwnershipStep,
  LegalRepresentativeStep,
  OnboardingStepDetails,
  OnboardingStepName,
  OwnerType,
} from '../../types'

type Props = {
  onContinue: (representativeType: OwnerType) => void
}

export const SelectOwnerTypeStep = ({ onContinue }: Props) => {
  const intl = useIntl()

  const [userQuery, ownershipStepQuery, legalRepresentativeStepQuery] =
    useQueries({
      queries: [
        {
          queryKey: [queryKeys.getIdentity],
          queryFn: getIdentity,
          select: (data: AxiosResponse<Identity>) => data?.data,
        },
        {
          queryKey: [
            queryKeys.getOnboardingStepDetails,
            OnboardingStepName.COMPANY_OWNERSHIP,
          ],
          queryFn: () =>
            getOnboardingStepDetails(OnboardingStepName.COMPANY_OWNERSHIP),
          select: (data: AxiosResponse<OnboardingStepDetails>) =>
            data.data as CompanyOwnershipStep,
        },
        {
          queryKey: [
            queryKeys.getOnboardingStepDetails,
            OnboardingStepName.LEGAL_REPRESENTATIVE,
          ],
          queryFn: () =>
            getOnboardingStepDetails(OnboardingStepName.LEGAL_REPRESENTATIVE),
          select: (data: AxiosResponse<OnboardingStepDetails>) =>
            data.data as LegalRepresentativeStep,
        },
      ],
    })

  const otherLegalRepresentativeAndOwnerOption = useMemo(() => {
    if (!legalRepresentativeStepQuery.data?.stepDetails?.legalRepresentative) {
      return null
    }

    const { legalRepresentative } =
      legalRepresentativeStepQuery.data.stepDetails

    return {
      title: intl.formatMessage(
        {
          id: 'onboarding.beneficialOwner.legalRepresentative.title',
          defaultMessage:
            'Add {fullName}, the legal representative, also as beneficial owner',
        },
        {
          fullName: [
            legalRepresentative.firstName,
            legalRepresentative.secondLastName,
            legalRepresentative.lastName,
          ]
            .filter(Boolean)
            .join(' '),
        },
      ),
      description: intl.formatMessage(
        {
          id: 'onboarding.ownership.legalRepresentative.description',
          defaultMessage: `Use {name}'s previously filled data to add him faster`,
        },
        {
          name: legalRepresentative.firstName,
        },
      ),
      completed: ownershipStepQuery.data?.stepDetails?.beneficialOwners?.some(
        (owner) => owner.email === legalRepresentative.email,
      ),
      type: OwnerType.OTHER_LEGAL_REPRESENTATIVE_AND_OWNER,
    }
  }, [
    intl,
    legalRepresentativeStepQuery.data?.stepDetails,
    ownershipStepQuery.data?.stepDetails?.beneficialOwners,
  ])

  const userOwnerOption = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'onboarding.beneficialOwnerOnly.user.title',
        defaultMessage: 'I am a beneficial owner',
      }),
      description: intl.formatMessage({
        id: 'onboarding.beneficialOwnerOnly.user.description',
        defaultMessage: 'Use my previously filled information to save time',
      }),
      completed: ownershipStepQuery.data?.stepDetails?.beneficialOwners?.some(
        (owner) => owner.email === userQuery.data?.email,
      ),
      type: OwnerType.USER_OWNER,
    }),
    [
      intl,
      ownershipStepQuery.data?.stepDetails?.beneficialOwners,
      userQuery.data?.email,
    ],
  )

  const userLegalRepresentativeAndOwnerOption = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'onboarding.beneficialOwner.user.title',
        defaultMessage: 'I the legal representative am also a beneficial owner',
      }),
      description: intl.formatMessage({
        id: 'onboarding.beneficialOwner.user.description',
        defaultMessage: 'Use my previously filled information to save time',
      }),
      completed: ownershipStepQuery.data?.stepDetails?.beneficialOwners?.some(
        (owner) => owner.email === userQuery.data?.email,
      ),
      type: OwnerType.USER_LEGAL_REPRESENTATIVE_AND_OWNER,
    }),
    [
      intl,
      ownershipStepQuery.data?.stepDetails?.beneficialOwners,
      userQuery.data?.email,
    ],
  )

  const newOwnerOption = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'onboarding.beneficialOwner.other.title',
        defaultMessage: 'Add new beneficial owner',
      }),
      description: intl.formatMessage({
        id: 'onboarding.beneficialOwner.other.description',
        defaultMessage: 'Add a different person as beneficial owner',
      }),
      completed: false,
      type: OwnerType.NEW_OWNER,
    }),
    [intl],
  )

  const beneficialOwnersOptions = useMemo(() => {
    const stepDetails = legalRepresentativeStepQuery.data?.stepDetails

    const isUserAlsoLegalRepresentative =
      stepDetails?.legalRepresentative?.email === userQuery.data?.email

    if (isUserAlsoLegalRepresentative) {
      return [newOwnerOption, userLegalRepresentativeAndOwnerOption]
    }

    return [
      newOwnerOption,
      userOwnerOption,
      otherLegalRepresentativeAndOwnerOption,
    ]
  }, [
    legalRepresentativeStepQuery.data?.stepDetails,
    newOwnerOption,
    otherLegalRepresentativeAndOwnerOption,
    userLegalRepresentativeAndOwnerOption,
    userOwnerOption,
    userQuery.data?.email,
  ])

  return (
    <>
      <GoBackButton
        to={generatePath(BusinessRoute.OnboardingStep, {
          step: OnboardingStepName.COMPANY_OWNERSHIP,
        })}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Add a new beneficial owner"
            id="onboarding.beneficialOwner.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="If you or the legal representative are one of the beneficial owners, save time with the prefilled option below"
            id="onboarding.beneficialOwner.description"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-4">
          {ownershipStepQuery.isPending ||
          legalRepresentativeStepQuery.isPending
            ? Array.from({ length: 2 }).map((_, index) => (
                <Card
                  key={index}
                  size="medium"
                  className="flex h-[78px] w-full flex-col gap-4"
                >
                  <Skeleton className="h-[21px] w-32" />
                  <Skeleton className="h-[21px] w-32" />
                </Card>
              ))
            : beneficialOwnersOptions
                .sort((a, b) => {
                  if (a?.completed && !b?.completed) {
                    return 1
                  }
                  if (!a?.completed && b?.completed) {
                    return -1
                  }
                  return 0
                })
                .map((ownerType) => {
                  if (!ownerType) {
                    return null
                  }

                  return (
                    <Card
                      key={ownerType.title}
                      size="medium"
                      className="flex w-full items-center justify-between"
                      asChild
                      scalable
                    >
                      <Button
                        variant="ghost"
                        size="inline"
                        disabled={ownerType.completed}
                        onClick={() => {
                          onContinue(ownerType.type)
                        }}
                      >
                        <div className="flex items-center gap-4">
                          <div className="flex flex-col gap-1">
                            <Typography
                              bold
                              className="whitespace-break-spaces"
                            >
                              {ownerType?.title}
                            </Typography>

                            <Typography className="whitespace-break-spaces text-neutral-gray-600">
                              {ownerType.description}
                            </Typography>
                          </div>
                        </div>
                        {ownerType.completed ? (
                          <span className="flex size-5 items-center justify-center rounded-full bg-primary">
                            <Check className="size-4 text-white" />
                          </span>
                        ) : (
                          <ChevronRight className="size-7 shrink-0 text-neutral-gray-600" />
                        )}
                      </Button>
                    </Card>
                  )
                })}
        </div>
      </SlideInScreen>
    </>
  )
}
