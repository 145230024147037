import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { EyeOnOffButton, PasswordRules } from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
} from '@/shared/ui'

import { PasswordSchema } from './PasswordForm'

type Props = {
  form: UseFormReturn<PasswordSchema>
}

export const PasswordFields = ({ form }: Props) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="flex flex-col gap-3">
      <FormField
        control={form.control}
        name="password"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Password',
                  id: 'label.password',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage defaultMessage="Password" id="label.password" />
            </AnimatedFormLabel>

            <EyeOnOffButton
              show={showPassword}
              onClick={() => setShowPassword((prev) => !prev)}
            />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="confirmPassword"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Confirm password',
                  id: 'label.confirmPassword',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                defaultMessage="Confirm password"
                id="label.confirmPassword"
              />
            </AnimatedFormLabel>

            <PasswordRules password={form.getValues('password')} />
          </FormItem>
        )}
      />
    </div>
  )
}
