import { formatAmount } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { Wallet } from '../../types'

type Props = {
  type: 'in' | 'out'
  wallet: Wallet
}

export const MoneyInOutCell = ({ type, wallet }: Props) => {
  const value =
    type === 'in' ? wallet.monthlyTotalMoneyIn : wallet.monthlyTotalMoneyOut

  return (
    <div className="flex items-center">
      <Typography>
        {type === 'in' ? '+' : ''}
        {formatAmount({
          amount: value,
          currency: wallet.currency,
        })}
      </Typography>
    </div>
  )
}
