import { Currency } from '@/constants/currency'

import { PaymentLimit, PaymentLimitType } from '../../types'

export const MOCK_PAYMENT_LIMITS: PaymentLimit = {
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  periodTransferLimit: {
    limitType: PaymentLimitType.WEEKLY,
    limitAmount: 1000,
    usedAmount: 500,
    limitCurrency: Currency.USD,
    periodStartDate: '2024-08-19T05:00:00',
    periodResetDate: '2024-08-19T05:00:00',
  },
}
