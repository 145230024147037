import { ContractorStateBadge } from '../../../components'
import { Contractor } from '../../../types'

type Props = {
  contractor: Contractor
}

export const ContractorStateCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <ContractorStateBadge state={contractor.state} />
    </div>
  )
}
