import { zodResolver } from '@hookform/resolvers/zod'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { LimitTypeSelect } from '@/domains/Business/components'
import { cn } from '@/lib/utils'
import { CurrencyFlag, GoBackButton, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  MoneyInput,
  RadioGroup,
  RadioGroupItem,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { LimitPeriodType, MovementPermission } from '../../types'

import { TeamMemberSchema } from './TeamMemberDetailsStep'

const MOVEMENT_RADIO_OPTIONS = [
  {
    title: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.aboveLimit',
      defaultMessage: 'One admin approval above a limit',
    }),
    subtitle: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.aboveLimit.subtitle',
      defaultMessage: `Requires and Admin's approval after reaching the limit`,
    }),
    value: MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT,
  },
  {
    title: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.oneAdmin',
      defaultMessage: 'One admin approval',
    }),
    subtitle: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.oneAdmin.subtitle',
      defaultMessage:
        'Can request a transfer and needs at least one Admin to approve it',
    }),
    value: MovementPermission.ALWAYS_REQUIRE_APPROVAL,
  },
  {
    title: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.noApproval',
      defaultMessage: 'No approval required',
    }),
    subtitle: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.noApproval.subtitle',
      defaultMessage: 'Can complete transfers without approval',
    }),
    value: MovementPermission.NO_APPROVAL_REQUIRED,
  },
]

const MONEY_MOVEMENT_FORM_ID = 'money-movement-form-id'

const moneyMovementSchema = z
  .object({
    movementPermission: z.nativeEnum(MovementPermission),
    limitPeriodType: z.nativeEnum(LimitPeriodType).optional(),
    periodTransferLimitAmount: z.string().optional(),
    singleTransferLimitAmount: z.string().optional(),
  })
  .refine(
    (data) => {
      const {
        periodTransferLimitAmount = '0',
        movementPermission,
        singleTransferLimitAmount = '0',
      } = data

      if (
        movementPermission !==
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return true
      }

      if (
        singleTransferLimitAmount === '0' &&
        periodTransferLimitAmount === '0'
      ) {
        return false
      }

      if (
        Big(
          singleTransferLimitAmount === '' ? 0 : singleTransferLimitAmount,
        ).gt(periodTransferLimitAmount === '' ? 0 : periodTransferLimitAmount)
      ) {
        return false
      }

      return true
    },
    {
      message: 'validation.singleTransactionLimit.greaterThanCycleLimit',
      path: ['singleTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const { movementPermission, singleTransferLimitAmount = '0' } = data

      if (
        movementPermission !==
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return true
      }

      return Big(
        singleTransferLimitAmount === '' ? 0 : singleTransferLimitAmount,
      ).gt(0)
    },
    {
      message: 'validation.balance.minAmount',
      path: ['singleTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const { movementPermission, periodTransferLimitAmount = '0' } = data

      if (
        movementPermission ===
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return Big(
          periodTransferLimitAmount === '' ? 0 : periodTransferLimitAmount,
        ).gt(0)
      }

      return true
    },
    {
      message: 'validation.balance.minAmount',
      path: ['periodTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const { movementPermission, limitPeriodType } = data

      if (
        movementPermission ===
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return !!limitPeriodType
      }

      return true
    },
    {
      message: 'validation.balance.minAmount',
      path: ['limitPeriodType'],
    },
  )

export type MoneyMovementSchema = z.infer<typeof moneyMovementSchema>

type Props = {
  moneyMovement?: MoneyMovementSchema
  onBack: () => void
  teamMemberDetails?: TeamMemberSchema
  onContinue: (data: MoneyMovementSchema) => void
}

export const MoneyMovementStep = ({
  moneyMovement,
  onBack,
  onContinue,
  teamMemberDetails,
}: Props) => {
  const intl = useIntl()

  const form = useForm<MoneyMovementSchema>({
    mode: 'onChange',
    resolver: zodResolver(moneyMovementSchema),
    values: {
      movementPermission:
        moneyMovement?.movementPermission ??
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT,
      limitPeriodType: moneyMovement?.limitPeriodType,
      periodTransferLimitAmount: moneyMovement?.periodTransferLimitAmount ?? '',
      singleTransferLimitAmount: moneyMovement?.singleTransferLimitAmount ?? '',
    },
  })

  const onSubmit: SubmitHandler<MoneyMovementSchema> = async (data) => {
    onContinue(data)
  }

  const hasDisabledFields =
    form.watch('movementPermission') !==
    MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="teamMember.moneyMovement.title"
            defaultMessage="Money movement permissions for {name}"
            values={{
              name: teamMemberDetails?.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="teamMember.moneyMovement.subtitle"
            defaultMessage="Choose permissions for transfers. Card spending limits will be defined separately"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={MONEY_MOVEMENT_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-6">
              <Widget
                title={
                  <FormattedMessage
                    id="teamMember.moneyMovement.permissions"
                    defaultMessage="Money movement permissions"
                  />
                }
                variant="form"
              >
                <FormField
                  control={form.control}
                  name="movementPermission"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <RadioGroup
                          onValueChange={(value) => {
                            if (
                              value !==
                              MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
                            ) {
                              form.setValue('limitPeriodType', undefined)
                              form.setValue(
                                'periodTransferLimitAmount',
                                undefined,
                              )
                              form.setValue(
                                'singleTransferLimitAmount',
                                undefined,
                              )

                              form.clearErrors([
                                'periodTransferLimitAmount',
                                'singleTransferLimitAmount',
                              ])
                            }

                            field.onChange(value)
                          }}
                          defaultValue={field.value}
                          value={field.value}
                          className="flex flex-col gap-2"
                        >
                          {MOVEMENT_RADIO_OPTIONS.map((option) => (
                            <FormItem key={option.value}>
                              <Card
                                scalable
                                size="upload"
                                className="flex w-full items-center justify-between"
                              >
                                <FormLabel className="flex w-full flex-col">
                                  <Typography bold>
                                    <FormattedMessage {...option.title} />
                                  </Typography>
                                  <Typography
                                    variant="body-small"
                                    className="text-neutral-gray-600"
                                  >
                                    <FormattedMessage {...option.subtitle} />
                                  </Typography>
                                </FormLabel>
                                <FormControl>
                                  <RadioGroupItem value={option.value} />
                                </FormControl>
                              </Card>
                            </FormItem>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </Widget>

              <Widget
                title={
                  <FormattedMessage
                    id="teamMember.perCycleLimit.permissions"
                    defaultMessage="Per cycle limit"
                  />
                }
                variant="form"
              >
                <FormField
                  control={form.control}
                  name="limitPeriodType"
                  render={({ field }) => (
                    <FormItem>
                      <LimitTypeSelect
                        disabled={hasDisabledFields}
                        value={field.value}
                        onChange={(v) => {
                          field.onChange(v)
                        }}
                        placeholder={intl.formatMessage({
                          id: 'teamMember.moneyMovement.cycleType',
                          defaultMessage: 'Cycle type',
                        })}
                      />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="periodTransferLimitAmount"
                  render={({ field }) => (
                    <FormItem>
                      <Card
                        size="input"
                        className={cn(
                          'flex',
                          hasDisabledFields && 'cursor-not-allowed opacity-50',
                        )}
                      >
                        <CurrencyFlag />
                        <FormControl>
                          <MoneyInput
                            disabled={hasDisabledFields}
                            currency={Currency.USD}
                            placeholder={intl.formatMessage({
                              defaultMessage: 'Aggregate amount',
                              id: 'label.aggregateAmount',
                            })}
                            className="text-right"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>

                        <AnimatedFormLabel align="end">
                          <FormattedMessage
                            defaultMessage="Aggregate amount"
                            id="label.aggregateAmount"
                          />
                        </AnimatedFormLabel>
                      </Card>
                    </FormItem>
                  )}
                />
              </Widget>

              <Widget
                title={
                  <FormattedMessage
                    id="teamMember.moneyMovement.singleTransactionLimit"
                    defaultMessage="Single transaction limit"
                  />
                }
                variant="form"
              >
                <FormField
                  control={form.control}
                  name="singleTransferLimitAmount"
                  render={({ field }) => (
                    <FormItem>
                      <Card
                        size="input"
                        className={cn(
                          'flex',
                          hasDisabledFields && 'cursor-not-allowed opacity-50',
                        )}
                      >
                        <CurrencyFlag />
                        <FormControl>
                          <MoneyInput
                            disabled={hasDisabledFields}
                            currency={Currency.USD}
                            placeholder={intl.formatMessage({
                              defaultMessage: 'Single transaction amount',
                              id: 'label.singleTransactionAmount',
                            })}
                            className="text-right"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>

                        <AnimatedFormLabel align="end">
                          <FormattedMessage
                            defaultMessage="Single transaction amount"
                            id="label.singleTransactionAmount"
                          />
                        </AnimatedFormLabel>
                      </Card>
                    </FormItem>
                  )}
                />
              </Widget>
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={MONEY_MOVEMENT_FORM_ID}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
