import { useMemo } from 'react'
import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'

import { Document, MoneyBag, PencilEdit, User } from '@/shared/icons/outline'
import { Card, Skeleton, Typography } from '@/shared/ui'
import { Icon } from '@/types/global'

import {
  ContractorOnboardingStep,
  ContractorOnboardingStepName,
} from '../types'

type StepItem = {
  stepName: ContractorOnboardingStepName
  title: MessageDescriptor
  description: MessageDescriptor
  icon: Icon
}

const STEPS_INFO: StepItem[] = [
  {
    stepName: ContractorOnboardingStepName.PERSONAL_INFORMATION,
    icon: User,
    title: defineMessage({
      id: 'contractor.onboarding.personalInformation',
      defaultMessage: 'Complete your personal information',
    }),
    description: defineMessage({
      id: 'contractor.onboarding.personalInformation.description',
      defaultMessage:
        'We need you to provide some personal and legal information',
    }),
  },
  {
    stepName: ContractorOnboardingStepName.PAYMENT_METHOD,
    icon: MoneyBag,
    title: defineMessage({
      id: 'contractor.onboarding.paymentMethod',
      defaultMessage: 'Set up your payment method',
    }),
    description: defineMessage({
      id: 'contractor.onboarding.paymentMethod.description',
      defaultMessage: 'You need to provide your personal account details',
    }),
  },
  {
    stepName: ContractorOnboardingStepName.TAX_FORMS,
    icon: Document,
    title: defineMessage({
      id: 'contractor.onboarding.taxForms',
      defaultMessage: 'Sign your tax form',
    }),
    description: defineMessage({
      id: 'contractor.onboarding.taxForms.description',
      defaultMessage:
        'Make sure all the information in the tax form is correct and sign it',
    }),
  },
  {
    stepName: ContractorOnboardingStepName.SIGN_CONTRACT,
    icon: PencilEdit,
    title: defineMessage({
      id: 'contractor.onboarding.signContract',
      defaultMessage: 'Review and sign your contract',
    }),
    description: defineMessage({
      id: 'contractor.onboarding.signContract.description',
      defaultMessage:
        'Make sure all the details are correct and virtually sign your contract',
    }),
  },
]

type Props = {
  steps?: ContractorOnboardingStep[]
}

export const ContractorStepsInfo = ({ steps }: Props) => {
  const stepNames = useMemo(
    () => steps?.map((step) => step.name) ?? [],
    [steps],
  )

  const orderListByStepNames = useMemo(() => {
    return STEPS_INFO.sort((a, b) => {
      const stepA = stepNames.indexOf(a.stepName)
      const stepB = stepNames.indexOf(b.stepName)

      if (stepA === -1) return 1
      if (stepB === -1) return -1

      return stepA - stepB
    }).filter((step) => stepNames.includes(step.stepName))
  }, [stepNames])

  if (!steps) {
    return (
      <Card className="flex flex-col gap-4" size="small">
        {[1, 2].map((index) => (
          <div key={index} className="flex flex-col gap-1">
            <div className="flex gap-3">
              <Skeleton className="size-4 rounded-full" />
              <div className="flex flex-1 flex-col gap-1">
                <Skeleton className="h-[18px] w-32" />
                <Skeleton className="h-[18px] w-56" />
              </div>
            </div>
          </div>
        ))}
      </Card>
    )
  }

  return (
    <Card className="flex flex-col gap-4" size="small">
      {orderListByStepNames.map(({ title, description, icon: Icon }, index) => (
        <div key={`${title.id}${index}`} className="flex flex-col gap-1">
          <div className="flex gap-3">
            <Icon className="mt-1 size-4" />

            <div className="flex flex-col">
              <Typography bold>
                <FormattedMessage {...title} />
              </Typography>

              <Typography className="text-neutral-gray-600">
                <FormattedMessage {...description} />
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}
