import { contractorApi } from '@/lib/api'

export type SignInRequest = {
  identityId: string
  otp?: string
}

type SignInResponse = {
  refreshToken: string
}

export function signInContractor(data: SignInRequest) {
  return contractorApi.post<SignInResponse>('/v1/signin', data)
}
