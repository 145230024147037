import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { DefineMessages } from '@/constants/messages'
import { BusinessRoute } from '@/constants/paths'
import { cn } from '@/lib/utils'
import { ChevronDown, Exchange, Plus } from '@/shared/icons/outline'
import { BulkArrows, Navigation } from '@/shared/icons/solid'
import { Button, Popover, PopoverContent, PopoverTrigger } from '@/shared/ui'
import { Icon } from '@/types/global'

import { Permission } from '../types'

import { WithPermissions } from './WithPermissions'

const actionMessages: DefineMessages<
  'send' | 'transfer' | 'addBalance' | 'bulkPayment'
> = defineMessages({
  send: {
    defaultMessage: 'Send',
    id: 'action.send',
  },
  transfer: {
    defaultMessage: 'Move',
    id: 'action.transfer',
  },
  addBalance: {
    defaultMessage: 'Add balance',
    id: 'action.addBalance',
  },
  bulkPayment: {
    defaultMessage: 'Bulk payment',
    id: 'action.bulkPayment',
  },
})

type Action = {
  key: 'send' | 'transfer' | 'addBalance' | 'bulkPayment'
  icon: Icon
  link: string
}

const ACTIONS: Action[] = [
  {
    key: 'send',
    icon: Navigation,
    link: BusinessRoute.Send,
  },
  {
    key: 'bulkPayment',
    icon: BulkArrows,
    link: BusinessRoute.BulkPayment,
  },
  {
    key: 'transfer',
    icon: Exchange,
    link: BusinessRoute.Transfer,
  },
  {
    key: 'addBalance',
    icon: Plus,
    link: BusinessRoute.AddBalance,
  },
]

export const MoveMoneyWidget = () => {
  const location = useLocation()
  const intl = useIntl()

  return (
    <WithPermissions permissions={[Permission.TRANSFERS]}>
      <Popover>
        <PopoverTrigger asChild>
          <Button size="md" className="self-start" leftIcon={<ChevronDown />}>
            <FormattedMessage
              id="action.moveMoney"
              defaultMessage="Move money"
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent sideOffset={10} className="w-48 p-0">
          <div className="flex flex-col gap-2 p-3">
            {ACTIONS.map(({ key, icon: Icon, link }) => (
              <Button
                asChild
                key={key}
                className="flex w-full items-center justify-start gap-2 font-normal"
                variant="ghost"
              >
                <Link to={link} state={{ from: location }}>
                  <Icon className={cn('size-5')} />

                  {intl.formatMessage(actionMessages[key])}
                </Link>
              </Button>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </WithPermissions>
  )
}
