import { useCallback, useEffect, useState } from 'react'
import { use2FAResetStorage } from '@/store'

import { getImage } from '@/lib/images'
import { Dialog, DialogContent } from '@/shared/ui'
import { TwoFactorMethod } from '@/types/auth'

import { AuthenticatorDialog } from './AuthenticatorDialog'
import { EmailDialog } from './EmailDialog'
import { MethodDialog } from './MethodDialog'
import { ResetAppDialog } from './ResetAppDialog'

export type Auth2FAResponse = {
  otp?: string
  method?: string
}

export type Configuration = 'resend-code' | 'use-another-method'

export type AuthProps = {
  email?: string
  password?: string
  identityId?: string
}

export type DialogScreen = 'APP' | 'EMAIL' | 'METHOD' | 'RESET'

type Props = {
  email?: string
  password?: string
  configurations?: Configuration[]
  defaultMethod: TwoFactorMethod
  isOpen: boolean
  onContinue: (data: Auth2FAResponse) => void
  onOpenChange: (isOpen: boolean) => void
  onMethodChange: (method: TwoFactorMethod) => void
  isPending: boolean
}

export const Auth2FADialog = ({
  email,
  password,
  configurations = [],
  defaultMethod,
  isOpen,
  isPending,
  onContinue,
  onMethodChange,
  onOpenChange,
}: Props) => {
  const enable2FAReset = use2FAResetStorage((state) => state.enable2FAReset)
  const disable2FAReset = use2FAResetStorage((state) => state.disable2FAReset)

  const [screen, setScreen] = useState<DialogScreen>()

  const getDefaultScreen = useCallback(() => {
    switch (defaultMethod) {
      case TwoFactorMethod.EMAIL:
        return 'EMAIL'
      case TwoFactorMethod.AUTHENTICATOR_APP:
        return 'APP'

      default:
        return 'EMAIL'
    }
  }, [defaultMethod])

  useEffect(() => {
    const defaultScreen = getDefaultScreen()

    setScreen(defaultScreen)
  }, [getDefaultScreen, isOpen])

  return (
    <>
      <link rel="preload" href={getImage({ name: 'email-2fa' })} as="image" />
      <link rel="preload" href={getImage({ name: '2fa' })} as="image" />

      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          onOpenChange(open)

          disable2FAReset()
        }}
      >
        <DialogContent>
          {(() => {
            switch (screen) {
              case 'APP':
                return (
                  <AuthenticatorDialog
                    configurations={configurations}
                    isPending={isPending}
                    onClose={() => onOpenChange(false)}
                    onContinue={onContinue}
                    onMethodChange={() => {
                      setScreen('METHOD')
                    }}
                  />
                )

              case 'EMAIL':
                return (
                  <EmailDialog
                    email={email}
                    password={password}
                    configurations={configurations}
                    isPending={isPending}
                    onClose={() => onOpenChange(false)}
                    onContinue={onContinue}
                    onMethodChange={() => {
                      setScreen('METHOD')
                    }}
                  />
                )

              case 'METHOD':
                return (
                  <MethodDialog
                    onMethodChange={setScreen}
                    onSelect={onMethodChange}
                    onReset={async () => {
                      onMethodChange(TwoFactorMethod.EMAIL)

                      enable2FAReset()

                      setScreen('RESET')
                    }}
                  />
                )

              case 'RESET':
                return (
                  <ResetAppDialog
                    isPending={isPending}
                    onReset={onContinue}
                    onClose={() => {
                      disable2FAReset()
                      onOpenChange(false)
                    }}
                  />
                )

              default:
                return null
            }
          })()}
        </DialogContent>
      </Dialog>
    </>
  )
}
