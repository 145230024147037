import { useCallback, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { motion } from 'framer-motion'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useIntercom } from 'react-use-intercom'

import { getImage } from '@/lib/images'
import {
  AnimatedFormLabel,
  Button,
  DialogActions,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  slideInFromBottomAnimation,
  Typography,
} from '@/shared/ui'
import { TwoFactorMethod } from '@/types/auth'

import { CodeInput } from '../CodeInput'
import { codeInputSchema, CodeInputSchema } from '../codeValidation'

import { Auth2FAResponse } from './Auth2FADialog'

type Props = {
  isPending: boolean
  onClose: () => void
  onReset: (data: Auth2FAResponse) => void
}

export const ResetAppDialog = ({ isPending, onClose, onReset }: Props) => {
  const { boot, showNewMessage } = useIntercom()

  const form = useForm<CodeInputSchema>({
    mode: 'onChange',
    resolver: zodResolver(codeInputSchema),
    defaultValues: { otp: '' },
  })

  const onSubmit: SubmitHandler<CodeInputSchema> = useCallback(
    async (data) => {
      onReset({
        otp: data.otp,
        method: TwoFactorMethod.EMAIL,
      })
    },
    [onReset],
  )

  useEffect(() => {
    if (isPending) {
      return
    }

    const subscription = form.watch(() => form.handleSubmit(onSubmit)())

    return () => subscription.unsubscribe()
  }, [form, isPending, onSubmit])

  return (
    <motion.div
      variants={slideInFromBottomAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <img
        className="mx-auto w-36 object-contain"
        src={getImage({ name: 'email-2fa' })}
        alt=""
        aria-hidden
      />
      <DialogHeader>
        <DialogTitle>
          <FormattedMessage
            defaultMessage="Reset Authentication App"
            id="2fa.dialog.resetApp.title"
          />
        </DialogTitle>

        <div className="flex flex-col gap-3">
          <DialogDescription>
            <FormattedMessage
              defaultMessage="Enter the verification code sent to your email"
              id="2fa.dialog.resetApp.subtitle"
            />
          </DialogDescription>
          <DialogDescription>
            <FormattedMessage
              defaultMessage="Setting up a new authenticator app will remove any existing connections"
              id="2fa.dialog.resetApp.removeConnections"
            />
          </DialogDescription>
        </div>
      </DialogHeader>

      <div className="p-3" />

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CodeInput autoFocus {...field} />
                </FormControl>
                <AnimatedFormLabel size="2fa">
                  <FormattedMessage
                    defaultMessage="6-digit code"
                    id="label.sixDigitCode"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />

          <div className="p-2" />

          <DialogActions>
            <Button
              type="button"
              onClick={onClose}
              disabled={isPending}
              width="full"
              variant="secondary"
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              type="submit"
              disabled={isPending}
              loading={isPending}
              width="full"
            >
              <FormattedMessage
                id="action.continue"
                defaultMessage="Continue"
              />
            </Button>
          </DialogActions>

          <div className="p-3" />

          <Typography
            variant="body-small"
            text="center"
            className="text-neutral-gray-600"
          >
            <FormattedMessage
              defaultMessage="Need help? {chatWithSupport}"
              id="2fa.dialog.needHelp.chatWithSupport"
              values={{
                chatWithSupport: (
                  <Button
                    variant="link"
                    size="inline"
                    className="text-xs"
                    onClick={() => {
                      boot({
                        customAttributes: { user_type: 'BUSINESS' },
                      })
                      showNewMessage()
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Chat with support"
                      id="2fa.dialog.action.chatWithSupport"
                    />
                  </Button>
                ),
              }}
            />
          </Typography>
        </form>
      </Form>
    </motion.div>
  )
}
