import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import {
  MOCKED_BULK_PAYMENT_QUOTE,
  MOCKED_TRANSACTIONS_ROWS,
} from './bulkPayments.mocks'

export const bulkPaymentsHandlers = [
  http.post(`${BASE_API_DEV}/v1/me/bulk-payments`, async () => {
    return HttpResponse.json({ id: '94c2ff72-2b45-435e-8553-951634fc7908' })
  }),
  http.get(`${BASE_API_DEV}/v1/me/bulk-payment-files/:id`, async () => {
    return HttpResponse.json([{ fileName: 'test-csv' }])
  }),
  http.get(`${BASE_API_DEV}/v1/me/bulk-payments/:id`, async () => {
    return HttpResponse.json(MOCKED_TRANSACTIONS_ROWS)
  }),
  http.post(`${BASE_API_DEV}/v1/me/bulk-payment-files/:id`, async () => {
    return HttpResponse.json([{ fileName: 'test-csv' }])
  }),
  http.post(`${BASE_API_DEV}/v1/me/bulk-payments/:id/quote`, async () => {
    return HttpResponse.json(MOCKED_BULK_PAYMENT_QUOTE)
  }),
  http.post(`${BASE_API_DEV}/v1/me/bulk-payments/:id/schedule`, async () => {
    return HttpResponse.json({})
  }),

  http.delete(
    `${BASE_API_DEV}/v1/me/bulk-payment-files/:id/name/:name`,
    async () => {
      return HttpResponse.json({})
    },
  ),
]

export * from './bulkPayments.mocks'
