import { api } from '@/lib/api'

import { ContractDocument } from '../types'

type UploadDocumentParams = {
  file: File
  contractId: string
}

export function uploadDocumentToExistingContact({
  file,
  contractId,
}: UploadDocumentParams) {
  const formData = new FormData()

  formData.append('file', file)

  return api.post<ContractDocument>(
    `/v1/me/custom-contract-agreements/contract/${contractId}`,
    formData,
  )
}
