import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getCardTitle } from '@/lib/card'
import { queryClient } from '@/lib/queryClient'
import { DeleteDialog } from '@/shared/components'
import { Button } from '@/shared/ui'

import { terminateCard } from '../api/terminateCard'
import { Card } from '../types'

type Props = {
  card?: Card
  onDelete: () => void
  isPendingOutside: boolean
}

export const DeleteCardAction = ({
  onDelete,
  card,
  isPendingOutside,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()
  const [isDeleting, setIsDeleting] = useState(false)

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: terminateCard,
  })

  const onDeleteCard = async () => {
    if (!card?.id) {
      toast.error('Card ID not found')

      return
    }

    try {
      await mutateAsync({ id: card.id })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getSingleCard, card.id],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAllCards],
      })

      setIsDeleting(false)

      toast.success(
        intl.formatMessage({
          id: 'card.delete.success',
          defaultMessage: 'Card has been deleted',
        }),
      )

      onDelete()
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <>
      <Button
        width="full"
        disabled={isPending || isPendingOutside}
        onClick={() => setIsDeleting(true)}
        loading={isPending}
        type="button"
        variant="ghost"
        className="text-primary-error"
      >
        <FormattedMessage defaultMessage="Delete card" id="action.deleteCard" />
      </Button>
      <DeleteDialog
        title={
          <FormattedMessage
            defaultMessage="Delete card?"
            id="card.delete.dialog.title"
          />
        }
        description={
          <FormattedMessage
            defaultMessage="Are you sure you want to delete {cardInfo}?"
            id="card.delete.dialog.deleteCard"
            values={{
              cardInfo: getCardTitle(card),
            }}
          />
        }
        descriptionAdditional={
          <FormattedMessage
            defaultMessage="The card will be terminated permanently"
            id="card.delete.dialog.cardTerminated"
          />
        }
        isOpen={isDeleting}
        onOpenChange={setIsDeleting}
        isPending={isPending || isSuccess}
        onDelete={onDeleteCard}
      />
    </>
  )
}
