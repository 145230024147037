import { contractorApi } from '@/lib/api'

type DownloadDocumentParams = {
  agreementId: string
  contractId: string
}

export function downloadContractorDocument({
  agreementId,
  contractId,
}: DownloadDocumentParams) {
  return contractorApi.get<BlobPart>(
    `/v1/my/contract-agreements/${agreementId}/contract/${contractId}`,
    { responseType: 'blob' },
  )
}
