import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { ContractDocument } from '../../../types'
import { UploadCustomContactField } from '../UploadCustomContactField'

const UPLOAD_CUSTOM_CONTRACT_FORM = 'upload-custom-contract-form-id'

const uploadCustomContractSchema = z.object({
  uploadedContractDocuments: z.array(
    z.object({
      id: z.string(),
      fileName: z.string(),
      uploadedOn: z.string(),
    }),
  ),
})

export type UploadCustomContractSchema = z.infer<
  typeof uploadCustomContractSchema
>

type Props = {
  onBack: () => void
  onContinue: (data: ContractDocument[]) => void
  onSkip: () => void
  uploadedContractDocuments?: ContractDocument[]
}

export const UploadCustomContractStep = ({
  onBack,
  onContinue,
  onSkip,
  uploadedContractDocuments,
}: Props) => {
  const form = useForm<UploadCustomContractSchema>({
    mode: 'onChange',
    resolver: zodResolver(uploadCustomContractSchema),
    values: {
      uploadedContractDocuments: uploadedContractDocuments ?? [],
    },
  })

  const onSubmit: SubmitHandler<UploadCustomContractSchema> = (data) => {
    onContinue(data.uploadedContractDocuments)
  }

  const hasAnyDocuments = form.watch('uploadedContractDocuments').length > 0

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.create.customContractUpload.title"
            defaultMessage="Upload your contractor's contract"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.create.customContractUpload.subtitle"
            defaultMessage="Use your already signed contract in the file as supporting document"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={UPLOAD_CUSTOM_CONTRACT_FORM}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3">
              <FormField
                control={form.control}
                name="uploadedContractDocuments"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <UploadCustomContactField
                        name={field.name}
                        existingFiles={field.value ?? []}
                        onDrop={(files) => {
                          form.setValue(field.name, files)
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>

        <StickyContainer className="flex flex-col gap-3">
          <Button
            width="full"
            form={UPLOAD_CUSTOM_CONTRACT_FORM}
            disabled={!hasAnyDocuments}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>

          <Button
            className="font-semibold no-underline"
            width="full"
            onClick={onSkip}
            variant="link"
            size="inline"
          >
            <FormattedMessage
              id="action.skipForNow"
              defaultMessage="Skip for now"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
