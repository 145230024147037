import {
  DisplayableType,
  Transaction,
} from '@/domains/Business/features/Transactions/types'

import { Details, Sheet, SheetContent, Skeleton } from '../../ui'

import { BulkTransactionDetails } from './BulkTransactionDetails'
import { SingleTransactionDetails } from './SingleTransactionDetails'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  transaction?: Transaction
}

export const TransactionDetailsSidebar = ({
  isOpen,
  onOpenChange,
  transaction,
}: Props) => {
  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent onOpenAutoFocus={(e) => e.preventDefault()}>
        {(() => {
          switch (transaction?.displayableType) {
            case DisplayableType.SINGLE: {
              return <SingleTransactionDetails transaction={transaction} />
            }

            case DisplayableType.BULK: {
              return <BulkTransactionDetails transaction={transaction} />
            }

            default: {
              return (
                <>
                  <Skeleton className="h-[33px] w-40" />
                  <div className="p-1.5" />
                  <Skeleton className="h-[21px] w-36" />
                  <div className="p-3" />
                  <div className="flex flex-col gap-4">
                    <Details.Loader />
                    <Details.Loader />
                  </div>
                </>
              )
            }
          }
        })()}
      </SheetContent>
    </Sheet>
  )
}
