import { PaymentMethod } from '@/domains/Business/constants'
import { CountryCode } from '@/types/country'

import { EntityType, Recipient } from '../../types'

export const MOCKED_RECIPIENTS: Recipient[] = [
  {
    nickname: 'Andrzej Duda',
    addressCity: null,
    addressState: null,
    addressStreet: null,
    addressZipCode: null,
    id: 'b3c2c6c6-ce4e-40ee-b2a9-88d8e3334a4f',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    country: CountryCode.MX,
    state: 'ACTIVE',
    entityType: EntityType.INDIVIDUAL,
    bankName: null,
    firstName: 'Andrzej',
    lastName: 'Duda',
    secondLastName: null,
    legalName: null,
    email: 'andrzej.duda@gov.pl',
    internationalPhonePrefix: null,
    localPhoneNumber: null,
    createdAt: '2023-12-19T16:33:26.023773',
    localInformation: {
      clabe: '1234567',
    },
  },
  {
    nickname: 'Krzysztof B',
    addressCity: null,
    addressState: null,
    addressStreet: null,
    addressZipCode: null,
    id: 'e95c763c-be31-4c58-8bc4-be2d71cded62',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    country: CountryCode.MX,
    state: 'ACTIVE',
    entityType: EntityType.INDIVIDUAL,
    bankName: null,
    firstName: 'Krzysztof',
    lastName: 'B',
    secondLastName: null,
    legalName: null,
    email: 'bronek@gmail.pl',
    internationalPhonePrefix: null,
    localPhoneNumber: null,
    createdAt: '2023-12-19T16:34:57.44998',
    localInformation: {
      clabe: '002010077777777771',
    },
  },
  {
    nickname: 'Test Inc.',
    addressCity: null,
    addressState: null,
    addressStreet: null,
    addressZipCode: null,
    id: '03ff1cb6-1596-40ad-bdff-d1027665b18f',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    country: CountryCode.MX,
    state: 'ACTIVE',
    entityType: EntityType.BUSINESS,
    bankName: null,
    firstName: null,
    lastName: null,
    secondLastName: null,
    legalName: 'Test Inc.',
    email: 'maks@maks.pl',
    internationalPhonePrefix: null,
    localPhoneNumber: null,
    createdAt: '2023-12-19T16:35:51.450611',
    localInformation: {
      clabe: '032180000118359719',
    },
  },
  {
    nickname: 'Test US',
    addressCity: 'New York',
    addressState: 'US-NY',
    addressStreet: '1 Groove St',
    addressZipCode: '10001',
    id: '03ff1cb6-1596-40ad-bdff-d1027665b18d',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2aa',
    country: CountryCode.US,
    state: 'ACTIVE',
    entityType: EntityType.BUSINESS,
    bankName: null,
    firstName: null,
    lastName: null,
    secondLastName: null,
    legalName: 'Test US',
    email: 'maks@maks.pl',
    internationalPhonePrefix: null,
    localPhoneNumber: null,
    createdAt: '2023-12-19T16:35:51.450611',
    localInformation: {
      accountNumber: '123456789',
      routingNumber: '123456789',
      paymentMethod: PaymentMethod.WIRE,
    },
  },
]
