export function parseFullPhoneNumber({
  localPhoneNumber,
  internationalPhonePrefix,
}: {
  localPhoneNumber?: string
  internationalPhonePrefix?: string
}) {
  if (!localPhoneNumber || !internationalPhonePrefix) {
    return ''
  }

  return localPhoneNumber.replace(internationalPhonePrefix, '')
}
