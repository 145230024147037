import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import { DeleteDialog } from '@/shared/components'
import { Button } from '@/shared/ui'

import { deleteTeamMember } from '../../features/Team/api'
import { TeamMember } from '../../features/Team/types'

type Props = {
  isPending?: boolean
  onDelete: () => void
  member?: TeamMember
}

export const DeleteTeamMemberAction = ({
  isPending,
  onDelete,
  member,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const notifyError = useErrorToast()
  const intl = useIntl()

  const {
    mutateAsync,
    isPending: deleteIsPending,
    isSuccess,
  } = useMutation({
    mutationFn: deleteTeamMember,
  })

  const onDeleteTeamMember = async () => {
    if (!member?.id) {
      toast.error(
        intl.formatMessage({
          id: 'team.member.details.error.missingId',
          defaultMessage: 'Missing team member ID',
        }),
      )
      return
    }

    try {
      await mutateAsync({ id: member.id })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMembers],
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMember, member?.id],
      })

      setIsDeleting(false)

      toast.success(
        intl.formatMessage({
          id: 'team.member.details.terminated',
          defaultMessage: 'Team member terminated',
        }),
      )

      onDelete()
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <>
      <Button
        width="full"
        disabled={isPending || deleteIsPending || isSuccess}
        onClick={() => setIsDeleting(true)}
        type="button"
        variant="ghost"
        className="text-primary-error"
      >
        <FormattedMessage
          defaultMessage="Delete team member"
          id="action.deleteTeamMember"
        />
      </Button>

      <DeleteDialog
        isOpen={isDeleting}
        onOpenChange={setIsDeleting}
        isPending={isPending || deleteIsPending || isSuccess}
        onDelete={() => onDeleteTeamMember()}
        title={
          <FormattedMessage
            defaultMessage="Delete {name}?"
            id="teamMember.delete.dialog.title"
            values={{
              name: member?.firstName,
            }}
          />
        }
        description={
          <FormattedMessage
            defaultMessage="Are you sure you want to delete {fullName}?"
            id="teamMember.delete.dialog.deleteCard"
            values={{
              fullName: getFullName(member),
            }}
          />
        }
        descriptionAdditional={
          <FormattedMessage
            defaultMessage="The user will be terminated permanently"
            id="teamMember.delete.dialog.userTerminated"
          />
        }
      />
    </>
  )
}
