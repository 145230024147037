import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { TransactionsShortList } from '@/domains/Business/components'
import { BulkTransaction } from '@/domains/Business/features/Transactions/types'
import { formatCurrency, formatMoney } from '@/lib/money'

import { InfoCircle } from '../../icons/outline'
import {
  Button,
  Card,
  Details,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '../../ui'

type Props = {
  transaction?: BulkTransaction
}

export const BulkTransactionDetails = ({ transaction }: Props) => {
  const allTransactions = [
    ...(transaction?.transactions ?? []),
    ...(transaction?.failedTransactions ?? []),
  ].sort(
    (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
  )

  return (
    <>
      <Typography className="text-neutral-gray-400">
        <FormattedMessage
          id="label.bulkPayment"
          defaultMessage="Bulk payment"
        />
      </Typography>

      <div className="p-3" />

      <Typography variant="h3">
        {formatMoney(transaction?.totalBaseAmount ?? 0)}{' '}
        {formatCurrency(transaction?.totalBaseCurrency)}
      </Typography>

      <div className="p-3" />

      <Card className="flex flex-col gap-4" size="medium">
        {transaction?.bulkTransactionLabel ? (
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentLabel"
                defaultMessage="Payment label"
              />
            </Details.Label>

            <Details.Value>{transaction?.bulkTransactionLabel}</Details.Value>
          </Details>
        ) : null}

        <Details>
          <Details.Label>
            <FormattedMessage
              id="label.numberOfRecipients"
              defaultMessage="No. of recipients"
            />
          </Details.Label>
          <Details.Value>
            {(transaction?.transactions.length ?? 0) +
              (transaction?.failedTransactions.length ?? 0)}
          </Details.Value>
        </Details>

        <Details>
          <Details.Label>
            <FormattedMessage
              id="label.totalPaymentAmount"
              defaultMessage="Total payment amount"
            />
          </Details.Label>
          <Details.Value>
            {formatMoney(transaction?.totalBaseAmount ?? 0).replace(/-/g, '')}{' '}
            {formatCurrency(transaction?.totalBaseCurrency)}
          </Details.Value>
        </Details>

        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Your fee"
              id="bulkPayments.confirmScreen.fee"
            />
          </Details.Label>

          {transaction ? (
            <Tooltip delayDuration={0}>
              <div className="flex items-center gap-1">
                <TooltipTrigger asChild>
                  <Button size="inline" variant="ghost">
                    <InfoCircle className="size-5" />
                  </Button>
                </TooltipTrigger>
                <Details.Value>
                  {formatMoney(transaction.totalFeeAmount)}{' '}
                  {formatCurrency(transaction.totalFeeCurrency)}
                </Details.Value>
              </div>

              <TooltipContent className="max-w-[300px]">
                <Typography>
                  <FormattedMessage
                    id="bulkPayments.fee.tooltip"
                    defaultMessage="All fees are waived when sending to other DolarApp accounts"
                  />
                </Typography>
              </TooltipContent>
            </Tooltip>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
      </Card>

      <div className="p-3" />

      <TransactionsShortList
        transactions={allTransactions}
        path={generatePath(BusinessRoute.BulkPaymentTransactions, {
          id: transaction?.groupId,
        })}
      />
    </>
  )
}
