import { z } from 'zod'

export const codeInputSchema = z.object({
  otp: z
    .string()
    .min(1, {
      message: 'validation.otp.required',
    })
    .length(6, {
      message: 'validation.otp.length',
    }),
})

export type CodeInputSchema = z.infer<typeof codeInputSchema>
