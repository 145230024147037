import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { getBusinessUser } from '@/domains/Business/api'

export function usePermissions() {
  const businessUserQuery = useQuery({
    queryKey: [queryKeys.getBusinessUser],
    queryFn: getBusinessUser,
    select: (data) => data.data,
  })

  return businessUserQuery.data?.permissions
}
