import { api } from '@/lib/api'
import { OTPCode } from '@/types/auth'

import { Card } from '../types'

type TerminateCardRequest = {
  id: string
} & OTPCode

export function terminateCard({ id, otp }: TerminateCardRequest) {
  return api.post<Card>(`/v1/me/cards/${id}/terminate`, { otp })
}
