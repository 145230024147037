import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

import { CountryCodeField, OptionalTag } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

const CONTRACTOR_DETAILS_FORM = 'contractor-details-form-id'

const contractorDetailsSchema = z.object({
  firstName: z.string().min(1, {
    message: 'validation.firstName.required',
  }),
  lastName: z.string().min(1, {
    message: 'validation.lastName.required',
  }),
  secondLastName: z.string().optional(),
  email: z.string().email({
    message: 'validation.email.invalid',
  }),
  internationalPhonePrefix: z.string().min(1, {
    message: 'validation.phoneCode.required',
  }),
  localPhoneNumber: z
    .string()
    .min(1, {
      message: 'validation.phoneNumber.required',
    })
    .refine(isValidPhoneNumber, {
      message: 'validation.phoneNumber.invalid',
    }),
})

export type ContractorDetailsSchema = z.infer<typeof contractorDetailsSchema>

type Props = {
  contractorDetails?: ContractorDetailsSchema
  onContinue: (data: ContractorDetailsSchema) => void
}

export const ContactorDetailsStep = ({
  contractorDetails,
  onContinue,
}: Props) => {
  const intl = useIntl()

  const form = useForm<ContractorDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(contractorDetailsSchema),
    values: {
      firstName: contractorDetails?.firstName ?? '',
      lastName: contractorDetails?.lastName ?? '',
      secondLastName: contractorDetails?.secondLastName ?? '',
      email: contractorDetails?.email ?? '',
      localPhoneNumber: contractorDetails?.localPhoneNumber ?? '',
      internationalPhonePrefix:
        contractorDetails?.internationalPhonePrefix ?? '+52',
    },
  })

  const onSubmit: SubmitHandler<ContractorDetailsSchema> = (data) => {
    onContinue(data)
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          id="contractor.create.details.title"
          defaultMessage="Add a new contractor"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          id="contractor.create.details.description"
          defaultMessage="Enter their personal details to get started"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form
          id={CONTRACTOR_DETAILS_FORM}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-3">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="given-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'First name',
                        id: 'label.firstName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="First name"
                      id="label.firstName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="family-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Last name',
                        id: 'label.lastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Last name"
                      id="label.lastName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="secondLastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="additional-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Second last name',
                        id: 'label.secondLastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Second last name"
                      id="label.secondLastName"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Email',
                        id: 'label.email',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage defaultMessage="Email" id="label.email" />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <div className="flex gap-3">
              <FormField
                control={form.control}
                name="internationalPhonePrefix"
                render={({ field }) => {
                  return (
                    <CountryCodeField
                      value={field.value}
                      onSelect={(value) =>
                        form.setValue('internationalPhonePrefix', value)
                      }
                    />
                  )
                }}
              />

              <FormField
                control={form.control}
                name="localPhoneNumber"
                render={({ field }) => (
                  <FormItem className="relative flex-1">
                    <FormControl>
                      <PhoneNumberInput
                        phonePrefix={form.watch('internationalPhonePrefix')}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Contact phone number',
                          id: 'label.contactPhoneNumber',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Contact phone number"
                        id="label.contactPhoneNumber"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          form={CONTRACTOR_DETAILS_FORM}
          disabled={!form.formState.isValid}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          <FormattedMessage
            id="action.saveAndContinue"
            defaultMessage="Save & Continue"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
