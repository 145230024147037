import { forwardRef } from 'react'

import { Currency } from '@/constants/currency'
import { cn } from '@/lib/utils'
import { CountryCode } from '@/types/country'

import { AnimatedFormLabel, Card, FormControl, MoneyInput } from '../ui'

import { CurrencyFlag } from './CurrencyFlag'

export interface AmountInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  currency?: Currency
  countryCode?: CountryCode
  disabled?: boolean
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
}

export const AmountInput = forwardRef<HTMLInputElement, AmountInputProps>(
  (
    { currency, countryCode, disabled, placeholder, value, onChange, ...props },
    ref,
  ) => {
    return (
      <Card
        size="input"
        className={cn('flex', disabled && 'cursor-not-allowed opacity-50')}
      >
        <CurrencyFlag countryCode={countryCode} currency={currency} />

        <FormControl>
          <MoneyInput
            disabled={disabled}
            currency={Currency.USD}
            placeholder={placeholder}
            className="text-right"
            value={value}
            onChange={onChange}
            {...props}
            ref={ref}
          />
        </FormControl>

        <AnimatedFormLabel align="end">{placeholder}</AnimatedFormLabel>
      </Card>
    )
  },
)

AmountInput.displayName = 'AmountInput'
