import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { generatePath, useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'

import { getOnboardingState } from './api'
import { useBusinessStateCheck } from './hooks/useBusinessStateCheck'
import { OnboardingStateEnum } from './types'

export const Onboarding = () => {
  const navigate = useNavigate()

  useBusinessStateCheck()

  const onboardingStateQuery = useQuery({
    queryKey: [queryKeys.getOnboardingState],
    queryFn: getOnboardingState,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (!onboardingStateQuery.data) {
      return
    }

    if (onboardingStateQuery.data.state === OnboardingStateEnum.NOT_STARTED) {
      navigate(`${BusinessRoute.OnboardingStart}`, { replace: true })
      return
    }

    const hasAnyCompletedOrVerificationStep =
      onboardingStateQuery.data.steps.some(
        (step) =>
          step.state === OnboardingStateEnum.VERIFICATION ||
          step.state === OnboardingStateEnum.COMPLETED,
      )

    if (hasAnyCompletedOrVerificationStep) {
      navigate(
        generatePath(BusinessRoute.OnboardingCompleted, { replace: true }),
      )

      return
    }

    const progressSavedSteps = onboardingStateQuery.data.steps.filter(
      (step) => step.state === OnboardingStateEnum.PROGRESS_SAVED,
    )

    if (progressSavedSteps.length === 0) {
      const [firstStep] = onboardingStateQuery.data.steps

      navigate(
        generatePath(BusinessRoute.OnboardingStep, { step: firstStep.name }),
        {
          replace: true,
        },
      )

      return
    }

    const [lastProgressSavedStep] = progressSavedSteps.slice(-1)

    const lastProgressStepIndex = onboardingStateQuery.data.steps.findIndex(
      (step) => step.name === lastProgressSavedStep.name,
    )

    const lastNotStartedStepIndex = lastProgressStepIndex + 1

    const lastNotStartedStep =
      onboardingStateQuery.data.steps[lastNotStartedStepIndex]

    if (lastNotStartedStep) {
      navigate(
        generatePath(BusinessRoute.OnboardingStep, {
          step: lastNotStartedStep.name,
        }),
        {
          replace: true,
        },
      )

      return
    }

    const [firstStep] = onboardingStateQuery.data.steps

    navigate(
      generatePath(BusinessRoute.OnboardingStep, { step: firstStep.name }),
      {
        replace: true,
      },
    )
  }, [navigate, onboardingStateQuery.data])

  return null
}
