import { forwardRef, Ref, type SVGProps } from 'react'

const SvgPayment = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 22 22"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M12.396 11.159q-.836 0-1.415-.58a1.93 1.93 0 0 1-.58-1.415q0-.836.58-1.415t1.415-.58 1.415.58.58 1.415-.58 1.415-1.415.58m-5.262 2.47q-.594 0-1.018-.424a1.4 1.4 0 0 1-.424-1.019V6.142q0-.595.424-1.018A1.4 1.4 0 0 1 7.134 4.7h10.524q.595 0 1.018.424.424.423.424 1.018v6.044q0 .595-.424 1.019a1.4 1.4 0 0 1-1.018.423zm1.197-1.197h8.13q0-.597.424-1.02a1.4 1.4 0 0 1 1.018-.422V7.339q-.596 0-1.019-.424a1.4 1.4 0 0 1-.423-1.018h-8.13q0 .596-.424 1.019a1.4 1.4 0 0 1-1.018.423v3.65q.596 0 1.019.424t.423 1.019m7.793 3.988H4.342q-.595 0-1.018-.423a1.4 1.4 0 0 1-.424-1.019V7.676q0-.255.172-.426a.58.58 0 0 1 .427-.172q.254 0 .426.172a.58.58 0 0 1 .172.426v7.302q0 .092.076.17a.23.23 0 0 0 .169.076h11.782q.255 0 .426.172a.58.58 0 0 1 .172.426q0 .255-.172.427a.58.58 0 0 1-.426.171m-8.99-3.988H6.89V5.897h.245a.24.24 0 0 0-.173.072.24.24 0 0 0-.072.173v6.044q0 .1.072.173a.24.24 0 0 0 .173.073"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgPayment)
export default ForwardRef
