import {
  ArrowLeftRight,
  CircleCancel,
  Dolarapp,
  InfoCircle,
} from '@/shared/icons/outline'

import { EntityType, Recipient } from '../features/Recipients/types'
import {
  failedTransactionStatuses,
  iconByRecipientType,
} from '../features/Transactions/constants'
import {
  SingleTransaction,
  TransactionType,
} from '../features/Transactions/types'

import { CardTransactionIcon } from './CardTransactionIcon'

const IconComponent = ({
  additionalDetails,
  recipientType = EntityType.INDIVIDUAL,
  transactionType,
  transactionStatus,
}: Props) => {
  const isFailed = transactionStatus
    ? failedTransactionStatuses.includes(transactionStatus)
    : false

  if (isFailed) {
    return (
      <>
        <CircleCancel className="text-neutral-gray-900" />
        <InfoCircle className="absolute -bottom-1 -right-1 size-3 text-primary-error" />
      </>
    )
  }

  switch (transactionType) {
    case TransactionType.INTERNAL_SWAP_DEPOSIT:
    case TransactionType.INTERNAL_SWAP_WITHDRAW:
      return <ArrowLeftRight className="text-neutral-gray-900" />
    case TransactionType.REWARD:
    case TransactionType.FEE:
    case TransactionType.CASHBACK:
      return <Dolarapp className="size-4 text-neutral-gray-900" />

    case TransactionType.CARD_PAYMENT: {
      return <CardTransactionIcon additionalDetails={additionalDetails} />
    }

    default: {
      const Icon = iconByRecipientType[recipientType]

      return <Icon className="text-neutral-gray-900" />
    }
  }
}

type Props = {
  additionalDetails?: SingleTransaction['additionalDetails']
  recipientType?: Recipient['entityType']
  transactionType: TransactionType
  transactionStatus?: SingleTransaction['transactionStatus']
}

export const TransactionIcon = ({
  additionalDetails,
  recipientType = EntityType.INDIVIDUAL,
  transactionType,
  transactionStatus,
}: Props) => {
  return (
    <div className="relative flex h-6 w-6 shrink-0 items-center justify-center rounded-md bg-neutral-gray-100 p-0.5">
      <IconComponent
        additionalDetails={additionalDetails}
        recipientType={recipientType}
        transactionType={transactionType}
        transactionStatus={transactionStatus}
      />
    </div>
  )
}
