import { forwardRef, Ref, type SVGProps } from 'react'

const SvgMoneyBag = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 12 14"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M6.02 10.326a.53.53 0 0 0-.425-.303 2.4 2.4 0 0 1-.66-.173l.098-.258q.103.045.212.082h.003l.003.002q.351.113.68.113c.22 0 .38-.03.502-.09l.007-.004.008-.004a.55.55 0 0 0 .291-.49c0-.23-.127-.42-.349-.524a2.7 2.7 0 0 0-.498-.158 4 4 0 0 1-.584-.182.76.76 0 0 1-.295-.24q-.114-.151-.114-.448c0-.238.07-.434.21-.6.126-.146.305-.245.545-.3a.53.53 0 0 0 .379-.325.53.53 0 0 0 .407.311q.235.037.428.116l-.09.253a2 2 0 0 0-.713-.143c-.769 0-.808.517-.808.62 0 .225.125.414.342.518l.006.003.006.002c.11.048.265.094.49.145q.348.077.577.175l.003.001.003.001a.7.7 0 0 1 .297.234l.001.002.001.002q.114.143.114.437a.88.88 0 0 1-.204.582.96.96 0 0 1-.51.307.54.54 0 0 0-.363.336"
    />
    <path
      fill={props.color || `currentColor`}
      d="M6.214 11h-.37a.15.15 0 0 1-.15-.15v-.301a.156.156 0 0 0-.14-.153 2.8 2.8 0 0 1-.545-.117 2 2 0 0 1-.442-.199.14.14 0 0 1-.055-.17l.254-.675c.034-.091.145-.126.23-.079q.172.096.37.163.294.095.566.095.233 0 .332-.05.1-.057.1-.162 0-.123-.133-.185a2.4 2.4 0 0 0-.428-.134 4.6 4.6 0 0 1-.642-.2 1.14 1.14 0 0 1-.447-.358q-.19-.252-.19-.676 0-.492.3-.843.27-.313.745-.422a.16.16 0 0 0 .125-.152V5.9a.15.15 0 0 1 .15-.15h.37a.15.15 0 0 1 .15.15v.313c0 .077.058.14.134.152q.417.065.732.245c.06.034.083.107.06.172l-.237.672a.16.16 0 0 1-.221.085 1.7 1.7 0 0 0-.767-.203q-.433 0-.433.246 0 .117.128.179.129.056.424.123.38.083.641.195.262.106.452.352.195.246.195.67 0 .469-.29.821a1.33 1.33 0 0 1-.7.43.16.16 0 0 0-.118.15v.348a.15.15 0 0 1-.15.15"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M4.379 1.25h3.247c.832 0 .375 1.248.038 2.01l-.383.872-.052.118a1.68 1.68 0 0 1 1.023.287 11.7 11.7 0 0 1 2.69 3.651c.254.51.355 1.084.29 1.65a2.815 2.815 0 0 1-2.68 2.662h-5.1A2.815 2.815 0 0 1 .77 9.841a2.96 2.96 0 0 1 .29-1.65 11.7 11.7 0 0 1 2.693-3.655 1.68 1.68 0 0 1 1.023-.286l-.06-.136-.375-.855c-.335-.761-.794-2.009.039-2.009"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M7.23 4.85a.6.6 0 0 0 0-1.2zm-2.454-1.2a.6.6 0 0 0 0 1.2zm4.52.373a.6.6 0 0 0-.587-1.047zm-2.015.109.113.589h.002zm-2.566-.018.122-.588H4.83zM3.287 2.97a.6.6 0 0 0-.57 1.057zm3.943.678H4.776v1.2H7.23zm1.479-.673c-.48.27-1.002.462-1.542.567l.229 1.178a6.4 6.4 0 0 0 1.9-.698zm-1.54.566a6 6 0 0 1-2.332-.016l-.243 1.175c.923.19 1.875.197 2.8.02zM4.83 3.525a5.5 5.5 0 0 1-1.544-.554l-.57 1.057c.59.318 1.225.545 1.883.674z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgMoneyBag)
export default ForwardRef
