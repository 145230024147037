import { motion } from 'framer-motion'
import { useIntl } from 'react-intl'

import { Logo } from '@/shared/components'
import { Spinner } from '@/shared/ui'

export const BusinessLoader = () => {
  const intl = useIntl()

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className="fixed inset-0 flex h-screen w-screen items-center justify-center"
      aria-label={intl.formatMessage({
        defaultMessage: 'Loading',
        id: 'label.loading',
      })}
    >
      <span className="absolute left-6 top-6 md:left-12 md:top-12">
        <Logo aria-label="DolarApp Business Logo" />
      </span>

      <Spinner className="size-16" />
    </motion.div>
  )
}
