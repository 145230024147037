import { FormattedMessage } from 'react-intl'

import { Typography } from '@/shared/ui'

import { Contractor } from '../../../types'

type Props = {
  contractor: Contractor
}

export const ContractTypeCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>
        <FormattedMessage
          id="contract.type.label"
          defaultMessage="{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}"
          values={{ state: contractor.paymentType }}
        />
      </Typography>
    </div>
  )
}
