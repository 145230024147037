import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

import { Button, Card, Typography } from '../ui'

const widgetVariants = cva('flex w-full max-w-3xl flex-col', {
  variants: {
    variant: {
      default: 'gap-6',
      form: 'gap-3 bg-transparent p-0',
    },
    gap: {
      none: 'gap-0',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

type Props = {
  scalable?: boolean
  children: React.ReactNode
  className?: string
  title: string | React.ReactNode
  onClick?: () => void
} & VariantProps<typeof widgetVariants>

export const Widget = ({
  scalable,
  children,
  className,
  title,
  onClick,
  variant = 'default',
  gap,
}: Props) => {
  return (
    <div className="flex flex-col">
      <Title>{title}</Title>

      <div className="p-1" />

      {onClick ? (
        <Card
          scalable={scalable}
          size="medium"
          className={cn(widgetVariants({ variant, gap }), className)}
          onClick={onClick}
          asChild
        >
          <Button size="inline" variant="ghost" width="full">
            {children}
          </Button>
        </Card>
      ) : (
        <Card
          size="medium"
          className={cn(widgetVariants({ variant, gap }), className)}
        >
          {children}
        </Card>
      )}
    </div>
  )
}

const Title = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography bold className="px-2">
      {children}
    </Typography>
  )
}

Widget.Title = Title
