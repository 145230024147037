import { FormattedMessage, useIntl } from 'react-intl'

import { COUNTRIES } from '@/constants/countries'
import { getCountryNameByISO3 } from '@/lib/country'
import { getFlagUrl } from '@/lib/images'
import { GoBackButton, Widget } from '@/shared/components'
import { ChevronRight, DolarappWhite } from '@/shared/icons/outline'
import { Badge, Button, Card, SlideInScreen, Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'

type Props = {
  onContinue: (screen: string) => void
  onBack: () => void
}

export const SelectPaymentMethodScreen = ({ onBack, onContinue }: Props) => {
  const intl = useIntl()

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Payment method"
            id="contractors.onboarding.paymentMethod.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="How do you want to get your salary paid?"
            id="contractors.onboarding.paymentMethod.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-6">
          <Widget
            scalable
            onClick={() => onContinue('dolarTag')}
            title={
              <FormattedMessage id="label.dolarapp" defaultMessage="DolarApp" />
            }
          >
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="flex size-9 items-center justify-center rounded-full bg-primary">
                  <DolarappWhite className="size-5 text-white" />
                </div>

                <div className="flex flex-col">
                  <div className="flex gap-2">
                    <Typography bold>
                      <FormattedMessage
                        id="label.dolartag"
                        defaultMessage="DolarTag"
                      />
                    </Typography>
                    <Badge
                      className="font-bold capitalize"
                      variant="dark-neutral"
                    >
                      <FormattedMessage
                        id="label.recommended"
                        defaultMessage="Recommended"
                      />
                    </Badge>
                  </div>
                  <Typography
                    variant="body-small"
                    className="text-neutral-gray-600"
                  >
                    <FormattedMessage
                      id="contractors.dolartag.description"
                      defaultMessage="Instantly and for free"
                    />
                  </Typography>
                </div>
              </div>

              <ChevronRight className="size-6 shrink-0 text-neutral-gray-600" />
            </div>
          </Widget>

          <Widget
            variant="form"
            title={
              <FormattedMessage
                id="label.bankAccounts"
                defaultMessage="Bank accounts"
              />
            }
          >
            {COUNTRIES.map((country) => {
              return (
                <Card
                  size="medium"
                  className="flex w-full items-center justify-between"
                  key={country.code}
                  asChild
                  scalable
                >
                  <Button
                    size="inline"
                    variant="ghost"
                    onClick={() => {
                      if (country.code === CountryCode.US) {
                        onContinue('us-bank')
                      }

                      if (country.code === CountryCode.MX) {
                        onContinue('mx-bank')
                      }
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <img
                        src={getFlagUrl(country.flagCode)}
                        className="size-9 shrink-0"
                        alt={intl.formatMessage(
                          {
                            id: 'country.flag.alt',
                            defaultMessage:
                              '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                          },
                          {
                            country: country.code,
                          },
                        )}
                      />

                      <div className="flex flex-col">
                        <Typography bold>
                          {getCountryNameByISO3(country.code, intl.locale)}
                        </Typography>
                        <Typography
                          variant="body-small"
                          className="text-neutral-gray-600"
                        >
                          {(() => {
                            switch (country.code) {
                              case CountryCode.US:
                                return (
                                  <FormattedMessage
                                    id="contractors.paymentMethodByCountry.description.US"
                                    defaultMessage="3-5 business days and 1% fee"
                                  />
                                )
                              case CountryCode.MX:
                                return (
                                  <FormattedMessage
                                    id="contractors.paymentMethodByCountry.description.MX"
                                    defaultMessage="Instantly and 1% fee"
                                  />
                                )

                              default:
                                return null
                            }
                          })()}
                        </Typography>
                      </div>
                    </div>

                    <ChevronRight className="size-6 shrink-0 text-neutral-gray-600" />
                  </Button>
                </Card>
              )
            })}
          </Widget>
        </div>
      </SlideInScreen>
    </>
  )
}
