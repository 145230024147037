import { useIntl } from 'react-intl'

import { useCountryCodes } from '@/hooks/useCountryCodes'
import { getCountryNameByISO3 } from '@/lib/country'

import { Skeleton, Typography } from '../ui'

export const CountryName = ({
  countryCode,
}: {
  countryCode?: string | null
}) => {
  const { data: codes, isPending } = useCountryCodes()
  const intl = useIntl()

  const country = codes?.find((code) => code.isoCode === countryCode)

  return (
    <div className="flex gap-1">
      {isPending ? (
        <Skeleton className="h-[23px] w-24" />
      ) : (
        <Typography className="flex items-center">
          {country ? (
            <>
              <span className="mr-1 mt-0.5">{country?.flag}</span>
              {getCountryNameByISO3(country.isoCode, intl.locale)}
            </>
          ) : (
            '-'
          )}
        </Typography>
      )}
    </div>
  )
}
