import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { IDENTITY_FILTER_NAME, Widget } from '@/shared/components'
import { DataTable, MotionDiv } from '@/shared/ui'

import { getAllCards } from '../../features/Cards'
import { TeamMember, TeamState } from '../../features/Team/types'
import { useTeamCardsColumns } from '../CardsTable'

export type Props = {
  member?: TeamMember
}

export const TeamCardsTable = ({ member }: Props) => {
  const navigate = useNavigate()
  const columns = useTeamCardsColumns()
  const isBlockedOrTerminated =
    member?.state === TeamState.BLOCKED ||
    member?.state === TeamState.TERMINATED

  const allCardsQuery = useQuery({
    queryKey: [queryKeys.getAllCards, member?.identityId],
    queryFn: () =>
      getAllCards({
        [IDENTITY_FILTER_NAME]: member?.identityId,
      }),
    select: (data) => data?.data,
    enabled: !!member?.identityId,
  })

  if (!allCardsQuery.data?.length) {
    return null
  }

  return (
    <MotionDiv className="flex flex-col">
      <Widget
        title={<FormattedMessage id="label.cards" defaultMessage="Cards" />}
        className="bg-transparent p-0"
      >
        <DataTable
          loaderOptions={{ rows: 2 }}
          disabled={isBlockedOrTerminated}
          columns={columns}
          data={allCardsQuery.data}
          isLoading={allCardsQuery.isPending}
          onRowClick={(card) => {
            navigate(`${BusinessRoute.Cards}?id=${card.id}`)
          }}
        />
      </Widget>
    </MotionDiv>
  )
}
