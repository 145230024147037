import { forwardRef, Ref, type SVGProps } from 'react'

const SvgScan = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 14 12"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M1.938 3.75a.563.563 0 0 0 1.125 0zM2.5 1.5V.938a.563.563 0 0 0-.562.562zm2.25.563a.563.563 0 0 0 0-1.126zm6.188 1.687a.562.562 0 1 0 1.124 0zM11.5 1.5h.563A.56.56 0 0 0 11.5.938zM9.25.938a.563.563 0 0 0 0 1.125zm2.813 7.312a.562.562 0 1 0-1.126 0zM11.5 10.5v.563c.31 0 .563-.252.563-.563zm-2.25-.562a.562.562 0 1 0 0 1.124zM3.063 8.25a.563.563 0 0 0-1.126 0zM2.5 10.5h-.562c0 .31.251.563.562.563zm2.25.563a.562.562 0 1 0 0-1.126zm8.25-4.5a.562.562 0 1 0 0-1.125zM1 5.438a.563.563 0 0 0 0 1.125zM3.063 3.75V1.5H1.937v2.25zM2.5 2.063h2.25V.937H2.5zm9.563 1.687V1.5h-1.126v2.25zM11.5.938H9.25v1.125h2.25zm-.562 7.312v2.25h1.124V8.25zm.562 1.688H9.25v1.124h2.25zM1.938 8.25v2.25h1.125V8.25zm.562 2.813h2.25V9.937H2.5zM13 5.437H1v1.125h12z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgScan)
export default ForwardRef
