import { Navigate, RouteObject } from 'react-router-dom'

import { ContractorRoute } from '@/constants/paths'

import { ContractorAcceptInvite } from './ContractorAcceptInvite'
import { ContractorForgetPassword } from './ContractorForgetPassword'
import { ContractorSignIn } from './ContractorSignIn'
import { Layout } from './Layout'

export const contractorSignInRoutes: RouteObject[] = [
  {
    path: ContractorRoute.Root,
    element: <Navigate to={ContractorRoute.SignIn} replace />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: ContractorRoute.SignIn,
        element: <ContractorSignIn />,
      },
      {
        path: ContractorRoute.ForgotPassword,
        element: <ContractorForgetPassword />,
      },
      {
        path: ContractorRoute.AcceptInvite,
        element: <ContractorAcceptInvite />,
      },
    ],
  },
]
