import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { getCountryCodes } from '@/domains/Business/api'

export function useCountryCodes({ showAllCountries = false } = {}) {
  return useQuery({
    queryKey: [queryKeys.getCountryCodes, showAllCountries],
    queryFn: () => getCountryCodes({ showAllCountries }),
    select: (data) => data.data,
    staleTime: 30 * 1000 * 60,
  })
}
