import { useEffect, useState } from 'react'

import { Sheet, SheetContent } from '@/shared/ui'

import { TeamMember } from '../../features/Team/types'

import { BaseTeamDetails } from './BaseTeamDetails'
import { EditTeamDetails } from './EditTeamDetails'

export type ScreenType = 'base' | 'edit'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  member?: TeamMember
}

export const TeamDetailsSidebar = ({ isOpen, onOpenChange, member }: Props) => {
  const [screen, setScreen] = useState<ScreenType>('base')

  useEffect(() => {
    if (!isOpen) {
      setScreen('base')
    }
  }, [isOpen])

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        {(() => {
          switch (screen) {
            case 'base':
              return <BaseTeamDetails member={member} setScreen={setScreen} />

            case 'edit':
              return <EditTeamDetails member={member} setScreen={setScreen} />

            default:
              return null
          }
        })()}
      </SheetContent>
    </Sheet>
  )
}
