import { Navigate, RouteObject } from 'react-router-dom'

import { BaseRoute, BusinessRoute } from '@/constants/paths'

import { AcceptInvite } from './AcceptInvite'
import { ForgetPassword } from './ForgetPassword'
import { Layout } from './Layout'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'

export const loginRoutes: RouteObject[] = [
  {
    path: BaseRoute.Root,
    element: <Navigate to={BusinessRoute.SignIn} replace />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: BusinessRoute.SignIn,
        element: <SignIn />,
      },
      {
        path: BusinessRoute.SignUp,
        element: <SignUp />,
      },
      {
        path: BusinessRoute.ForgotPassword,
        element: <ForgetPassword />,
      },
      {
        path: BusinessRoute.AcceptInvite,
        element: <AcceptInvite />,
      },
    ],
  },
]
