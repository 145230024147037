import { api } from '@/lib/api'

import { BankAccount, Wallet } from '../types'

type PDFRequest = {
  type: BankAccount['type']
  id: Wallet['id']
}

export function getAccountPDF(data: PDFRequest) {
  return api.get<BlobPart>(
    `/v1/me/bank-accounts/wallet/${data.id}/type/${data.type}/pdf`,
    {
      responseType: 'blob',
    },
  )
}
