import { defineMessage, FormattedMessage } from 'react-intl'

import { getFullName } from '@/lib/typography'
import { GoBackButton } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { User } from '@/shared/icons/solid'
import { Button, Card, SlideInScreen, Typography } from '@/shared/ui'

import { ContractorPaymentType } from '../../../types'

import { ContractorDetailsSchema } from './ContractorDetailsStep'

const CONTRACT_TYPES = [
  {
    title: defineMessage({
      id: 'contractor.create.contractType.fixedRate',
      defaultMessage: 'Fixed rate contract',
    }),
    description: defineMessage({
      id: 'contractor.create.contractType.fixedRate.description',
      defaultMessage:
        'For contracts that have the payment amount each payment cycle',
    }),
    value: ContractorPaymentType.FIXED_RATE,
  },
  {
    title: defineMessage({
      id: 'contractor.create.contractType.payAsYouGo',
      defaultMessage: 'Pay-as-you-go contract',
    }),
    description: defineMessage({
      id: 'contractor.create.contractType.payAsYouGo.description',
      defaultMessage:
        'For contracts that require timesheets or work submissions each payment cycle',
    }),
    value: ContractorPaymentType.PAY_AS_YOU_GO,
  },
]

type Props = {
  contractorDetails?: ContractorDetailsSchema
  onBack: () => void
  onContinue: (value: ContractorPaymentType) => void
}

export const ContractTypeStep = ({
  contractorDetails,
  onBack,
  onContinue,
}: Props) => {
  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.create.contractType.title"
            defaultMessage="What type of contract do you want to create?"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.create.contractType.description"
            defaultMessage="Fixed price if you pay the same every month, pay as you go if the amount is different"
          />
        </Typography>

        <div className="p-6" />

        <Card size="upload">
          <div className="flex gap-2">
            <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
              <User className="size-5 text-neutral-gray-900" />
            </div>

            <div className="flex flex-col">
              <Typography bold>{getFullName(contractorDetails)}</Typography>
              <Typography
                variant="body-small"
                className="text-neutral-gray-600"
              >
                {contractorDetails?.email}
              </Typography>
            </div>
          </div>
        </Card>

        <div className="p-4" />

        <div className="flex flex-col gap-4">
          {CONTRACT_TYPES.map((contract) => {
            return (
              <Card
                key={contract.title.id}
                size="upload"
                className="flex w-full items-center justify-between"
                asChild
                scalable
              >
                <Button
                  size="inline"
                  variant="ghost"
                  onClick={() => {
                    onContinue(contract.value)
                  }}
                >
                  <div className="flex items-center gap-4">
                    <div className="flex flex-col">
                      <div className="flex gap-2">
                        <Typography bold>
                          <FormattedMessage {...contract.title} />
                        </Typography>
                      </div>
                      <Typography className="whitespace-break-spaces text-neutral-gray-600">
                        <FormattedMessage {...contract.description} />
                      </Typography>
                    </div>
                  </div>

                  <ChevronRight className="size-8 shrink-0 text-neutral-gray-600" />
                </Button>
              </Card>
            )
          })}
        </div>
      </SlideInScreen>
    </>
  )
}
