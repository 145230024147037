export const sessionStorageKeys = {
  deviceFingerprint: 'device-fingerprint',
  accessToken: 'access-token',
  refreshToken: 'refresh-token',
  twoFactorReset: 'two-factor-reset',

  cardsDetailsPublicKey: 'cards-public-key',
  cardsDetailsPrivateKey: 'cards-private-key',
}

export const localStorageKeys = {
  locale: 'preferred-locale',
}
