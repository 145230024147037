import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'
import { MOCK_COUNTRY_CODES } from '@/domains/Business/api'

import {
  MOCK_ONBOARDING_CONFIG,
  MOCK_ONBOARDING_STATE,
  MOCK_ONBOARDING_STATE_DATA,
  MOCK_ONBOARDING_VALIDATION_STATE,
  MOCKED_OWNER,
} from './onboarding.mocks'

export const onboardingHandlers = [
  http.get(`${BASE_API_DEV}/v1/onboarding`, async () => {
    return HttpResponse.json(MOCK_ONBOARDING_STATE)
  }),

  http.post(`${BASE_API_DEV}/v1/onboarding/start`, async () => {
    return HttpResponse.json(MOCK_ONBOARDING_STATE)
  }),

  http.get(`${BASE_API_DEV}/v1/onboarding/config`, async () => {
    return HttpResponse.json(MOCK_ONBOARDING_CONFIG)
  }),

  http.get(`${BASE_API_DEV}/v1/onboarding/step/:step`, async () => {
    return HttpResponse.json(MOCK_ONBOARDING_STATE_DATA)
  }),

  http.delete(
    `${BASE_API_DEV}/v1/onboarding-documents/step/:step/document/:document/name/:name`,
    async () => {
      return HttpResponse.json()
    },
  ),

  http.post(
    `${BASE_API_DEV}/onboarding-documents/step/:step/document/:field`,
    async () => {
      return HttpResponse.json({
        fileName:
          'CERTIFICATE_OF_INCORPORATION-23CA9EB9BFED604F53B42A86CA04FABF.JPEG',
      })
    },
  ),

  http.post(`${BASE_API_DEV}/v1/onboarding/step`, async () => {
    return HttpResponse.json()
  }),

  http.get(`${BASE_API_DEV}/v1/onboarding/beneficial-owners/:id`, async () => {
    return HttpResponse.json(MOCKED_OWNER)
  }),

  http.post(`${BASE_API_DEV}/v1/onboarding/beneficial-owners`, async () => {
    return HttpResponse.json(MOCKED_OWNER)
  }),

  http.delete(
    `${BASE_API_DEV}/v1/onboarding/beneficial-owners/:id`,
    async () => {
      return HttpResponse.json(MOCKED_OWNER)
    },
  ),

  http.put(`${BASE_API_DEV}/v1/onboarding/beneficial-owners/:id`, async () => {
    return HttpResponse.json(MOCKED_OWNER)
  }),

  http.get(`${BASE_API_DEV}/v1/onboarding/country-codes`, async () => {
    return HttpResponse.json(
      MOCK_COUNTRY_CODES.map((country) => ({
        ...country,
        availableForBusinessMembers: true,
      })),
    )
  }),

  http.post(`${BASE_API_DEV}/v1/onboardings/validations`, async () => {
    return HttpResponse.json(MOCK_ONBOARDING_VALIDATION_STATE)
  }),

  http.put(`${BASE_API_DEV}/v1/onboardings/validations`, async () => {
    return HttpResponse.json(MOCK_ONBOARDING_VALIDATION_STATE)
  }),

  http.post(`${BASE_API_DEV}/v1/onboardings/validations-invites`, async () => {
    return HttpResponse.json({})
  }),
]

export * from './onboarding.mocks'
