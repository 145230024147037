import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { Card, CardState } from '../../features/Cards/types'

type Props = {
  card: Card
}

export const CardNicknameCell = ({ card }: Props) => {
  const isTerminated = card.state === CardState.TERMINATED

  return (
    <div className="flex max-w-44 items-center">
      <Typography
        title={card.nickname}
        className={cn('line-clamp-1', isTerminated && 'line-through')}
      >
        {card.nickname}
      </Typography>
    </div>
  )
}
